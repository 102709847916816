import * as React from "react";
import { SiteHeader } from "./components/SiteHeader";
import { SiteFooter } from "./components/SiteFooter";
import { Router } from "./components/Router";
import { SiteModal } from "./components/SiteModal";
import { CONTEXTS } from "../model";
import { useContext } from "react";

export const View: React.FunctionComponent<{}> = () => {
  const modal = useContext(CONTEXTS.modal);
  const login_modal = useContext(CONTEXTS.login_modal);
  return (
    <div className="site">
      <fieldset
        {...((modal && modal.open) || (login_modal && login_modal.open)
          ? {
              disabled: true,
              tabIndex: -1
            }
          : {})}
      >
        <SiteHeader />
        <div className="site-body">
          <Router />
        </div>
        <SiteFooter />
      </fieldset>
      <SiteModal />
    </div>
  );
};
