import * as React from "react";
import { ApiReqViewProps } from "./WithApiRequest";
import { makeInfiniteList } from "./InfiniteList";
import { deep_equals } from "../../utils/common";
import { DEFAULT_API_QUERY } from "../../constants";

type ListedRecordType<M extends ApiPagedMethod> = ApiPagedResponseModel<M>;

export class ListView<
  M extends ApiPagedMethod,
  P extends object = {},
  S extends object = {}
> extends React.Component<P, { query: ApiQuery<ListedRecordType<M>> } & S> {
  _refresh_list?: () => void;

  componentDidUpdate(prevProps, prevState) {
    if (!("app_location" in this.props)) {
      return;
    }
    // if the app_location query changes (and nothing else), and it doesn't
    // match the query in state
    if (
      deep_equals(
        {
          ...prevProps.app_location,
          record: null,
          form_dirty: null,
          query: null
        },
        {
          // @ts-ignore
          ...this.props.app_location,
          record: null,
          form_dirty: null,
          query: null
        }
      ) &&
      !deep_equals(
        prevProps.app_location.query,
        // @ts-ignore
        this.props.app_location.query
      ) &&
      deep_equals(prevState.query, this.state.query) &&
      // @ts-ignore
      !deep_equals(this.props.app_location.query, this.state.query)
    ) {
      this.setState({
        // @ts-ignore
        query: this.props.app_location.query || DEFAULT_API_QUERY
      });
    }
  }

  nextPage = (current_page: number) => {
    // @ts-ignore
    this.setState({
      query: {
        ...this.state.query,

        page: current_page + 1
      }
    });
  };

  refreshList = () => {
    const { query } = this.state;
    if (query.page !== 0 || !this._refresh_list) {
      this.setState({
        query: {
          ...query,
          page: 0
        }
      });
    } else {
      this._refresh_list();
    }
  };

  createRecordAction: AppEvent<AppEventName>;
}

export const makeListView = <
  M extends ApiPagedMethod,
  MappedItem extends object = ApiResponseModel<M>
>(
  componentName: string,
  load_more: (current_page: number) => void,
  ItemView: React.ComponentType<
    { item: MappedItem } & Maybe<Context<"window_size">>
  >,
  buttonLabel?: string,
  emptyText?: React.ReactNode,
  map_items?: (records: ApiResponseModel<M>[]) => MappedItem[]
): React.FunctionComponent<ApiReqViewProps<M>> => {
  const ListView = makeInfiniteList<M, ApiResponseModel<M>, MappedItem>(
    load_more,
    ItemView,
    buttonLabel,
    emptyText,
    map_items
  );
  ListView.displayName = componentName;
  return ListView;
};
