import * as React from "react";
import { EVENTS, connect } from "../../model";
import { RequestComponent } from "../components/RequestComponent";
import { WithFloatingTitle } from "../components/common/floating-title";
import { InputWithFormHelpers } from "../components/common/inputs";
import { IMAGES_PREFIX, MIN_PASSWORD_LENGTH } from "../../constants";
import { RequestButton } from "../components/common/request-button";
import { FieldError } from "../components/Errors";
import {
  GOOGLE_LOGIN_REQUEST_ID,
  LOGIN_REQUEST_ID
} from "../../model/effects/constants";
import { GoogleLoginFormWrapper } from "../components/GoogleLoginFormWrapper";

class Login extends RequestComponent<
  "user",
  {},
  {
    email?: string;
    password?: string;
  }
> {
  constructor(props) {
    super(props);
    this.state = { request_id: LOGIN_REQUEST_ID };
    this.name = "Login";
  }
  name = "Login";

  onChangeInput = (field: "email" | "password") => e => {
    // @ts-ignore
    this.setState({ [field]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (email && password) {
      this.props.dispatchNow([EVENTS.LOGIN, { email, password }]);
    }
  };

  render() {
    const { email, password } = this.state;
    const res = this.getRequestErrors();
    return (
      <div className="login-page">
        <div className="login-page__logo">
          <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
        </div>
        <div className="login-page__form login-page__form__with-google">
          <GoogleLoginFormWrapper
            errors={this.getRequestErrors(GOOGLE_LOGIN_REQUEST_ID)}
          >
            <form id="login" onSubmit={this.onSubmit}>
              {res && res.status === 401 ? (
                <FieldError hasError={true}>
                  Invalid email or password
                </FieldError>
              ) : null}
              <WithFloatingTitle title="Email">
                <InputWithFormHelpers
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={this.onChangeInput("email")}
                  value={email}
                  submitOnEnter={true}
                />
              </WithFloatingTitle>
              <WithFloatingTitle title="Password">
                <InputWithFormHelpers
                  placeholder="Password"
                  type="password"
                  name="password"
                  minLength={MIN_PASSWORD_LENGTH}
                  onChange={this.onChangeInput("password")}
                  value={password}
                  submitOnEnter={true}
                />
              </WithFloatingTitle>
              <RequestButton
                pending={
                  this.requestIsPending() || this.requestSucceeded() || false
                }
                success={false}
                className="login-page__submit filled"
                type="submit"
                successText="Success!"
              >
                Log in to Thrive
              </RequestButton>
              <div className="login-page__forgot-pw">
                <a href="/forgot_password">Forgot password?</a>
              </div>
            </form>
          </GoogleLoginFormWrapper>
        </div>
      </div>
    );
  }
}

export const LoginConnected = connect(
  Login,
  true,
  ["user", "request"]
);
