import * as React from "react";
import { EVENTS, connect, CONTEXTS } from "../../../model";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import { ActionButtonWithIcon } from "../../components/common/button-with-icon";
import { WithApiRequest } from "../../components/WithApiRequest";
import { format_time_passed, get_touchpoint_desc } from "../../utils";
import { FacePile } from "../../components/FacePile";
import { ActionButton } from "../../components/common/action-link";
import { EmptyList } from "../../components/EmptyList";
import { ListView, makeListView } from "../../components/ListView";
import { TouchpointTypeIcon } from "../../components/TouchpointTypeIcon";
import { path_or, sort_by_id } from "../../../utils/common";
import { DetailPageLink } from "../../components/DetailPageLink";
import { Icon } from "../../components/Icon";
import { LinkWithCounter } from "../../components/ButtonWithCounter";
import { LikeButtonConnected } from "../../components/LikeButton";
import { SearchBarCollapsible } from "../../components/SearchBarCollapsible";
import { useContext } from "react";

export const TouchpointListViewItem: React.FunctionComponent<{
  item: Touchpoint | Interaction;
  firstOfPage?: number;
  button?: React.ReactNode;
}> = ({ item, button, firstOfPage }) => {
  const user = useContext(CONTEXTS.user);
  const self_id = user ? user.id : -1;
  item.participants = sort_by_id(item.participants);
  // @ts-ignore
  const time = new Date(item.started_at || item.created_at);
  return (
    <div
      className="record-list__item touchpoint-list__item"
      data-page-marker={firstOfPage}
    >
      <div className="touchpoint__top">
        <div className="touchpoint__top__left">
          <FacePile
            users={item.participants}
            type="linear"
            size="xs"
            main_user_id={item.created_by.id}
            max={10}
          />
          <span>{item.participants.length}</span>
        </div>
        <div className="touchpoint__top__right">
          <TouchpointTypeIcon type={item.type as TouchpointType} size="md" />
        </div>
      </div>
      <div className="touchpoint__middle">
        <DetailPageLink
          className="touchpoint__headline"
          place="activity"
          record={item}
          data-is-touchpoint={"emoji_responses" in item}
        >
          {get_touchpoint_desc(item, self_id)}
        </DetailPageLink>
        <div title={time.toLocaleString()} className="touchpoint__time">
          {format_time_passed(
            // @ts-ignore
            time,
            false,
            true
          )}
        </div>
        <div className="touchpoint__description">
          {(item as Touchpoint).description}
        </div>
        {(item as Touchpoint).touchpoint_photo && (
          <div className="touchpoint__photo">
            <img
              // @ts-ignore
              src={item.touchpoint_photo.photo_url}
              alt="Touchpoint photo"
              onLoad={({ target: el }) => {
                // @ts-ignore
                el.style.maxHeight = `${Math.min(480, 2 * el.naturalHeight)}px`;
              }}
            />
          </div>
        )}
      </div>
      <div className="touchpoint__bottom">
        {(item as Touchpoint).emoji_responses && (
          <div className="touchpoint__bottom__left">
            <LikeButtonConnected
              touchpoint_id={item.id}
              reactions={(item as Touchpoint).emoji_responses}
              touchpoint_updated_at={item.updated_at}
            />
          </div>
        )}
        <div className="touchpoint__bottom__right">
          <LinkWithCounter
            className="button"
            href={`/activity/${item.id}#comment_box`}
            count={(item as Touchpoint).total_comments}
          >
            <Icon name="comment" iconSize="xs" />
            Comment
          </LinkWithCounter>
        </div>
      </div>
    </div>
  );
};

class TouchpointList extends ListView<
  "GetAuthorizedTouchpoints",
  Context<"app_location" | "request">,
  { search_focused: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      search_focused: false,
      query: {
        pageSize: 10,
        ...path_or({}, ["app_location", "query"], props),
        page: 0
      }
    };
  }

  getRequestId = () => "";

  onChangeQuery = (query: ApiQuery<Touchpoint>) => {
    if (!this.state.query.filter && query.filter) {
      this.TouchpointListView = makeListView(
        "TouchpointListView",
        this.nextPage,
        TouchpointListViewItem,
        "Load More Activity",
        <EmptyList icon={<Icon name="logo" />}>
          There is no activity that matches this search criteria.
        </EmptyList>
      );
    }
    this.setState({ query });
  };

  onFocusSearch = (search_focused: boolean) => e => {
    this.setState({ search_focused });
  };

  createRecordAction = [
    EVENTS.GO_TO_PAGE,
    { place: "activity", id: "create" }
  ] as AppEvent<AppEventName>;

  TouchpointListView = makeListView(
    "TouchpointListView",
    this.nextPage,
    TouchpointListViewItem,
    "Load More Activity",
    <EmptyList
      icon={<Icon name="logo" />}
      button={
        <ActionButton event={this.createRecordAction} className="filled">
          Create a Touchpoint
        </ActionButton>
      }
    >
      Looks like you don’t have any activity yet. To get started, click
      “Create”.
    </EmptyList>
  );

  render() {
    const { query, search_focused } = this.state;
    return (
      <PageContent
        id="touchpoint-list"
        className={`touchpoint-page list-page page-with-search-bar${
          search_focused ? " search-focused" : ""
        }`}
        bodyType="no-container"
        pageHeader={
          <PageHeader
            title="Activity"
            button={
              <div className="touchpoint-list__actions">
                <SearchBarCollapsible
                  query={query}
                  onSubmit={this.onChangeQuery}
                  onFocus={this.onFocusSearch(true)}
                  onBlur={this.onFocusSearch(false)}
                  pending={
                    !!query.filter &&
                    path_or(
                      false,
                      ["request", this.getRequestId(), "pending"],
                      this.props
                    )
                  }
                  placeholder="Search by participant..."
                />
                <ActionButtonWithIcon
                  event={[
                    EVENTS.GO_TO_PAGE,
                    { place: "activity", id: "create" }
                  ]}
                  className="filled"
                  icon={<Icon name="add" />}
                >
                  Create
                </ActionButtonWithIcon>
              </div>
            }
            icon={<Icon name="activity" />}
          />
        }
      >
        <WithApiRequest
          payload={{
            method: query.filter
              ? "SearchTouchpointsByParticipants"
              : "GetAuthorizedTouchpoints",
            parameters: query
          }}
          View={this.TouchpointListView}
          getRequestId={func => {
            this.getRequestId = func;
          }}
        />
      </PageContent>
    );
  }
}

export const TouchpointListConnected = connect(
  TouchpointList,
  false,
  ["request", "app_location"]
);
