import * as React from "react";
import { maybeClassName } from "../../../utils/dom-helpers";

export const with_required = (required, title) =>
  `${required ? "* " : ""}${title}`;

/** wraps/adds a floating title to an input, textarea, or select element
 *  note: the wrapped element must have a `placeholder` set */
export const WithFloatingTitle: React.FunctionComponent<{
  className?: string;
  title: string;
  name?: string;
}> = ({ className, title, name, children }) => (
  <div className={`input__container${maybeClassName(className)}`}>
    {children}
    <label htmlFor={name} className="input__title">
      <span>{title}</span>
    </label>
  </div>
);
WithFloatingTitle.displayName = "WithFloatingTitle";

/** Inserts a floating title to an input, textarea, or select element
 *  NOTE: the sibling input must precede this component, must have a
 *  `placeholder` set, and the parent node must have `position: relative`
 */
export const FloatingTitle: React.FunctionComponent<{
  title: string;
  name?: string;
}> = ({ title, name }) => (
  <label htmlFor={name} className="input__title">
    <span>{title}</span>
  </label>
);
FloatingTitle.displayName = "FloatingTitle";
