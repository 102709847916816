import * as React from "react";
import { Reaction } from "./Reaction";
import { connect } from "../../model";
import { RequestComponent } from "./RequestComponent";
import { group_reactions } from "../utils";
import { path_or } from "../../utils/common";

export class ReactionListBase extends RequestComponent<
  "user",
  {
    touchpoint_id: number;
    reactions: EmojiResponse[];
    onChange?: Function;
    touchpoint_updated_at: string;
    onlyShowSelf?: boolean;
  },
  {
    grouped_reactions: ObjectOf<EmojiResponse[]>;
    should_override: boolean;
    override_reaction?: EmojiResponse;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: `reaction-list-${props.touchpoint_id}`,
      grouped_reactions: {},
      should_override: false
    };
  }

  componentDidMount(): void {
    this.setState({ grouped_reactions: this.groupReactions(false) });
  }

  componentDidUpdate(prevProps) {
    if (this.requestFinished(prevProps)) {
      const request = this.getRequest() as
        | SuccessfulAppRequest
        | FailedAppRequest;
      if (this.props.onChange) {
        this.props.onChange(true);
      } else if (
        request &&
        request.payload.method === "PostEmojiResponse" &&
        request.success
      ) {
        const reaction = path_or(undefined, ["response", "data"], request);
        this.setState({
          should_override: true,
          override_reaction: reaction,
          grouped_reactions: this.groupReactions(true, reaction)
        });
      } else if (
        request &&
        request.payload.method === "DeleteEmojiResponse" &&
        request.success
      ) {
        this.setState({
          should_override: true,
          override_reaction: undefined,
          grouped_reactions: this.groupReactions(true)
        });
      }
    }

    if (
      this.props.touchpoint_updated_at !== prevProps.touchpoint_updated_at ||
      this.props.reactions.length !== prevProps.reactions.length
    ) {
      this.setState({
        grouped_reactions: this.groupReactions(false),
        should_override: false,
        override_reaction: undefined
      });
    }
  }

  groupReactions = (
    should_override: boolean,
    override_reaction?: EmojiResponse
  ): ObjectOf<EmojiResponse[]> => {
    const { reactions, touchpoint_id, user, onlyShowSelf } = this.props;
    let filtered = reactions;

    if (should_override) {
      filtered = filtered
        .filter(r => !user || r.created_by.id !== user.id)
        .concat(override_reaction ? [override_reaction] : []);
    }

    if (onlyShowSelf) {
      filtered = filtered.filter(r => !user || r.created_by.id === user.id);
    }

    return group_reactions(filtered, touchpoint_id);
  };

  onClickReaction = (key, selfResponse?: EmojiResponse) => {
    if (this.requestIsPending()) {
      return;
    }
    if (selfResponse) {
      this.sendRequest(
        "DeleteEmojiResponse",
        { id: selfResponse.id },
        this.state.request_id
      );
    } else {
      this.sendRequest(
        "PostEmojiResponse",
        {
          emoji_response: {
            emoji_key: key,
            touchpoint_id: this.props.touchpoint_id
          }
        },
        this.state.request_id
      );
    }
  };

  render() {
    const { user, onlyShowSelf } = this.props;
    const { grouped_reactions } = this.state;
    const self_id = user ? user.id : -1;
    // TODO: make this into a reaction selection menu when we have our full dictionary of emojis
    return (
      <div className="reaction__list__container">
        <ul className="reaction__list">
          {Object.entries(grouped_reactions).map(([key, rs], i) => (
            <li key={i} className="reaction__list__item" data-count={rs.length}>
              <Reaction
                type={key}
                users={rs.map(r => r.created_by)}
                current_user_id={self_id}
                hideCount={onlyShowSelf}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.onClickReaction(
                    key,
                    rs.find(r => r.created_by.id === self_id)
                  );
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export const ReactionList = connect(
  ReactionListBase,
  true,
  ["user", "request"]
);
