import * as React from "react";
import { CONTEXTS } from "../../../model";
import { PageContent } from "../../components/PageContent";
import {
  HEADER_AVATAR_ELEM_ID,
  HEADER_NAME_ELEM_ID,
  UserDetailTouchpointListConnected
} from "./TouchpointList";
import { WithApiRequest } from "../../components/WithApiRequest";
import { useContext } from "react";

export const UserDetail: React.FC<{}> = () => {
  const app_location = useContext(CONTEXTS.app_location) as AppLocationAt<
    "users/id"
  >;
  return (
    <PageContent
      id="user-detail"
      className="list-page"
      bodyType="no-container"
      pageHeader={
        <div className="user-detail__header">
          <div className="user-detail__header__avatar">
            <div
              id={HEADER_AVATAR_ELEM_ID}
              className="user-detail__header__avatar__image"
            />
          </div>
          <div id={HEADER_NAME_ELEM_ID} className="user-detail__header__name" />
        </div>
      }
    >
      <WithApiRequest
        payload={{
          method: "GetUserById",
          parameters: {
            id: app_location.id
          }
        }}
        View={UserDetailTouchpointListConnected}
      />
    </PageContent>
  );
};
