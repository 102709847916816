import * as React from "react";
import { connect, EVENTS } from "../../../model";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import { Icon } from "../../components/Icon";
import { RequestComponent } from "../../components/RequestComponent";
import { RequestButton } from "../../components/common/request-button";
import { FormErrors } from "../../components/Errors";

const QUESTION_LABELS = [
  "1. I am content with my friendships and relationships.",
  "2. I have enough people I feel comfortable asking for help at any time.",
  "3. My relationships are as satisfying as I would want them to be."
];
const ANSWER_LABELS = ["Agree", "Neutral", "Disagree"];

class LonelinessRater extends RequestComponent<
  "user",
  WithAppLocationAt<"loneliness-rater">,
  { answers: number[]; error?: string; form_dirty: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: "loneliness-rater",
      answers: [-1, -1, -1],
      form_dirty: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      this.props.dispatchNow([
        EVENTS.SET_APP_LOCATION,
        {
          ...this.props.app_location,
          form_dirty: false
        }
      ]);
      this.props.dispatchLater(
        [
          EVENTS.GO_TO_PAGE,
          {
            place: "loneliness-rater",
            id: "result",
            record: (this.getRequest() as SuccessfulAppRequest<
              "AddLonelinessRating"
            >).response.data
          }
        ],
        500
      );
    }

    if (!prevState.form_dirty && this.state.form_dirty) {
      this.props.dispatchNow([
        EVENTS.SET_APP_LOCATION,
        {
          ...this.props.app_location,
          form_dirty: true
        }
      ]);
    }
  }

  changeAnswer = (question: number, answer: number) => () => {
    const answers = this.state.answers.slice();
    answers[question] = answer;
    this.setState({ answers, form_dirty: true });
  };

  submitForm = e => {
    e.preventDefault();
    const { user } = this.props;
    const { answers, request_id } = this.state;
    if (!user) {
      return;
    }
    if (
      answers.length === 3 &&
      !answers.map(a => a <= 3 && a >= 1).includes(false)
    ) {
      this.sendRequest(
        "AddLonelinessRating",
        { id: user.id, new_loneliness_rating: { ratings: answers } },
        request_id
      );
      this.setState({ error: undefined });
    } else {
      this.setState({ error: "Please answer all of the questions." });
    }
  };

  render() {
    const { answers, error } = this.state;
    const errors = this.getRequestErrors();
    return (
      <PageContent
        id="loneliness-rater"
        className="header-overlap-block header-overlap-sm"
        bodyType="full-width-block"
        pageHeader={
          <PageHeader
            title="Loneliness Rater"
            icon={<Icon name="loneliness" />}
          />
        }
      >
        <form id="loneliness-rater" onSubmit={this.submitForm}>
          <section className="loneliness-rater__section">
            <h3 className="loneliness-rater__section__title">
              Overall Relationship Fulfillment
            </h3>
            <p>
              We would like to ask you a few questions to enable us to measure
              how helpful our app is. When answering the questions, you could
              take account of the following:
            </p>
            <ul>
              <li>There are no right or wrong answers.</li>
              <li>We would like you to be completely honest.</li>
              <li>
                In answering the questions, it is best to think of your life as
                it generally is now.
              </li>
            </ul>
          </section>
          <section className="loneliness-rater__section">
            <h3 className="loneliness-rater__section__title">Questions</h3>
            <div className="loneliness-rater__questions">
              {QUESTION_LABELS.map((question, i) => (
                <div key={i} className="loneliness-rater__question">
                  <div className="loneliness-rater__question__label">
                    {question}
                  </div>
                  <div className="loneliness-rater__question__buttons">
                    {ANSWER_LABELS.map((label, j) => (
                      <button
                        key={j}
                        className="bordered"
                        type="button"
                        onClick={this.changeAnswer(i, j + 1)}
                        data-selected={answers[i] === j + 1}
                      >
                        {label}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
          <section className="loneliness-rater__footer">
            {(error || errors) && (
              <div className="loneliness-rater__errors">
                {error ? (
                  <div className="field-error">{error}</div>
                ) : (
                  <FormErrors response={errors} />
                )}
              </div>
            )}
            <RequestButton
              className="button filled"
              pending={this.requestIsPending()}
              success={this.requestSucceeded()}
              successText="Success!"
              type="submit"
            >
              Submit Answers
            </RequestButton>
          </section>
        </form>
      </PageContent>
    );
  }
}
export const LonelinessRaterConnected = connect(
  LonelinessRater,
  true,
  ["request", "user", "app_location"]
);
