import * as React from "react";
import { DetailPageLink } from "../../components/DetailPageLink";
import { ScreenSize, USER_ROLES } from "../../../constants";
import { FacePile } from "../../components/FacePile";
import { CONTEXTS } from "../../../model";
import { HealthScore } from "../../components/HealthScore";
import { get_list_of_names } from "../../utils";
import { useContext } from "react";

export interface GroupItem {
  name: string;
  score: number;
  members?: any[];
}

export interface GroupListItem<R extends GroupType> {
  item: GroupItem;
  record: GroupObjectInResponse<R>;
  new_location: {
    community?: AppLocationGroupSpec;
    thread?: AppLocationGroupSpec;
    family?: AppLocationGroupSpec;
  };
  clickable: boolean;
}

export type CommunityLocation = AppLocationAt<"communities"> &
  Partial<AppLocationAt<"communities/id/threads/id/families/id">>;

export const groupIsClickable = (
  group: GroupTypes[keyof GroupTypes],
  user: AppUser
): boolean =>
  user.user_role === USER_ROLES.STF ||
  (group["community_id"] === user.default_community_id &&
    "community_name" in group) ||
  (group["thread_id"] === user.default_thread_id &&
    ("thread_name" in group || user.user_role === USER_ROLES.GP)) ||
  group["group_id"] === user.default_group_id ||
  ("users" in group && group.users.some(u => u.id === user.id));
// || (group is a family) user is CM of the community that the family belongs to

export function GroupListViewItem<M extends GroupRequestMethod>(
  props: GroupListItem<GroupTypeForMethod<M>>
): React.ReactElement | null {
  const window_size = useContext(CONTEXTS.window_size);
  const user = useContext(CONTEXTS.user);
  const { item, record, new_location, clickable } = props;
  // @ts-ignore
  const content = (
    <>
      <div className="group-list__item__left">
        <HealthScore score={item.score} />
        <div className="group-list__item__title">{item.name || "Name"}</div>
      </div>
      {item.members && (
        <div className="group-list__item__right">
          {window_size >= ScreenSize.md && item.members ? (
            <>
              <FacePile users={item.members} type="linear" />
              <div className="touchpoint__users__names">
                {get_list_of_names(item.members, user ? user.id : -1)}
              </div>
            </>
          ) : (
            <div className="group-list__item__members">
              {item.members.length} members
            </div>
          )}
        </div>
      )}
    </>
  );

  return clickable ? (
    <DetailPageLink
      className="record-list__item group-list__item"
      // @ts-ignore
      location={{ place: "communities", record, ...new_location }}
    >
      {content}
    </DetailPageLink>
  ) : (
    <div className="record-list__item group-list__item">{content}</div>
  );
}
