import * as React from "react";
import { get_user_full_name } from "../utils";
import { Avatar } from "./Avatar";
import { FACEPILE_DEFAULT_MAX_ITEMS, IMAGES_PREFIX } from "../../constants";

const main_icon_size = (size: IconSize) => {
  return size === "xs" ? "md" : "lg";
};

export const FacePile: React.FC<{
  users: User[];
  type: "linear" | "pile";
  max?: number;
  size?: IconSize;
  main_user_id?: number;
}> = ({
  users,
  type,
  main_user_id,
  size = "sm",
  max = FACEPILE_DEFAULT_MAX_ITEMS
}) => {
  let pile: User[] = users;
  if (main_user_id) {
    pile = pile.sort((a, b) =>
      a.id === main_user_id ? -1 : b.id === main_user_id ? 1 : 0
    );
  }
  if (users.length > max) {
    pile = pile.slice(0, max - 1);
  }
  return (
    <div className="facepile" data-style={type}>
      <div data-count={Math.min(users.length, max)} className="facepile__pics">
        {pile.map(p => (
          <div
            key={p.id}
            className="facepile__item"
            data-main-user={p.id === main_user_id}
            title={get_user_full_name(p)}
          >
            <Avatar
              url={p.profile_photo_url}
              name={get_user_full_name(p)}
              size={p.id === main_user_id ? main_icon_size(size) : size}
            />
          </div>
        ))}
        {users.length > max && (
          <div
            className="facepile__item facepile__others"
            title={`${users.length - max + 1} more users`}
          >
            <img
              src={`${IMAGES_PREFIX}/icon-more_users.svg`}
              alt={`${users.length - max + 1} more users`}
            />
          </div>
        )}
      </div>
    </div>
  );
};
FacePile.displayName = "FacePile";
