import { current_screen_size } from "../utils/dom-helpers";
import { EV_SET_VALUE, EventBus } from "@thi.ng/interceptors";

const WINDOW_SIZE_UPDATE_DELAY = 150;

export const track_window_size = (bus: EventBus) => {
  let timer: any;
  const update_size = () => {
    bus.dispatchNow([EV_SET_VALUE, ["window_size", current_screen_size()]]);
  };
  update_size();
  window.addEventListener("resize", () => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }
    timer = setTimeout(update_size, WINDOW_SIZE_UPDATE_DELAY);
  });
};
