import * as React from "react";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import { WithApiRequest } from "../../components/WithApiRequest";
import { EVENTS, connect } from "../../../model";
import { CommunityBreadcrumb } from "./CommunityBreadcrumb";
import { HealthScore } from "../../components/HealthScore";
import { path_or } from "../../../utils/common";
import { GroupGraph } from "./GroupGraph";
import { group_to_graph_node, user_to_graph_node } from "../../utils";
import { GroupLeaderListItem } from "./GroupLeaderListItem";
import { groupIsClickable } from "./ListItem";

class CommunityDetailBase extends React.PureComponent<
  { app_location: AppLocationAt<"communities/id"> } & Context<"user">,
  { nodes: GroupGraphNode<"community">[] }
> {
  constructor(props) {
    super(props);
    this.state = {
      nodes: this.createNodes(props)
    };
  }

  componentDidMount(): void {
    if (!this.state.nodes && this.props.app_location.record) {
      this.setState({ nodes: this.createNodes() });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{ app_location: AppLocationAt<"communities/id"> }>
  ) {
    if (
      this.props.app_location.record &&
      (!prevProps.app_location.record ||
        (!prevProps.app_location.record.hub_scores &&
          this.props.app_location.record.hub_scores) ||
        this.props.app_location.record.community_id !==
          prevProps.app_location.record.community_id)
    ) {
      this.setState({ nodes: this.createNodes() });
    }
  }

  calculateConnections = (): ObjOf<number> => {
    const community: NetworkThreadsObject = path_or(
      undefined,
      ["app_location", "record"],
      this.props
    );
    if (
      !community ||
      !community.hub_scores ||
      community.hub_scores.length === 0
    ) {
      return {};
    }

    const center_id = community.community_manager
      ? community.community_manager.id
      : community.hub_scores[0].spoke_user.id;
    const connections: ObjOf<number> = {};

    community.hub_scores.forEach(s => {
      if (s.spoke_user.id != center_id) connections[s.spoke_user.id] = s.score;
    });

    return connections;
  };

  createNodes = (props = this.props): GroupGraphNode<"community">[] => {
    const { user } = this.props;
    const community: NetworkThreadsObject = path_or(
      undefined,
      ["app_location", "record"],
      props
    );
    if (
      !community ||
      !community.hub_scores ||
      community.threads.length === 0 ||
      !user
    ) {
      return [];
    }

    const { hub_scores, threads, community_manager } = community;
    return [
      {
        user: user_to_graph_node(community_manager),
        // @ts-ignore
        group: group_to_graph_node(community, true),
        type: "community",
        clickable: false
      } as GroupGraphNode<"community">
    ].concat(
      threads
        .map(group => {
          const n = hub_scores.find(s => s.thread_id === group.thread_id);
          return n
            ? {
                id: n.spoke_user ? n.spoke_user.id : n.thread_id,
                user: user_to_graph_node(n.spoke_user),
                group: group_to_graph_node(group),
                type: "thread",
                clickable: groupIsClickable(group, user)
              }
            : {
                id: group.thread_id,
                group: group_to_graph_node(group),
                type: "thread",
                clickable: groupIsClickable(group, user)
              };
        })
        .sort((a, b) => {
          if (a.clickable !== b.clickable) {
            return a.clickable ? -1 : 1;
          }
          if (a.user && !b.user) {
            return -1;
          }
          if (!a.user && b.user) {
            return 1;
          }
          return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
        })
        .map(
          ({ id, ...other }) => ({ ...other } as GroupGraphNode<"community">)
        )
    );
  };

  render() {
    const { app_location } = this.props;
    const { nodes } = this.state;
    return (
      <PageContent
        id="community-detail"
        className="group-detail-page"
        bodyType="no-margins"
        pageHeader={
          <PageHeader
            title={<CommunityBreadcrumb />}
            button={
              <div id="community-score" className="group-list__item__health">
                <HealthScore
                  score={path_or(
                    undefined,
                    ["record", "community_score"],
                    app_location
                  )}
                />
              </div>
            }
          />
        }
      >
        {nodes && nodes.length > 0 && (
          <GroupGraph
            group_type="community"
            nodes={nodes.filter(n => !!n)}
            calculateConnections={this.calculateConnections}
            ListItemView={GroupLeaderListItem}
            center_id={path_or(
              undefined,
              ["record", "community_manager", "id"],
              app_location
            )}
          />
        )}
      </PageContent>
    );
  }
}

const CommunityDetailBaseConnected = connect(
  CommunityDetailBase,
  true,
  ["app_location", "user"]
);

const CommunityDetail: React.FC<
  Dispatchers & {
    app_location: AppLocationAt<"communities/id">;
  }
> = ({ app_location, dispatchNow }) => (
  <section className="group-detail community-detail">
    <WithApiRequest
      id="threads-list"
      payload={{
        method: "GetThreadsByCommunity",
        parameters: { community_id: app_location.community.id }
      }}
      View={CommunityDetailBaseConnected}
      overrideRecord={app_location.record}
      onSuccess={({ response: { data } }) => {
        dispatchNow([EVENTS.GO_TO_PAGE, { ...app_location, record: data }]);
      }}
    />
  </section>
);

export const CommunityDetailConnected = connect(
  CommunityDetail,
  true,
  ["app_location"]
);
