import * as React from "react";
import { CONTEXTS, EVENTS } from "../../model";
import { generate_id } from "../../utils/common";
import { useContext, useEffect, useState } from "react";

export const GoogleLoginButton: React.FC<{}> = () => {
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);
  const [id] = useState(generate_id("google-login-button"));
  useEffect(
    () => dispatchNow([EVENTS.GOOGLE_LOGIN_BUTTON_MOUNTED, { id }]),
    []
  );
  return <div className="google-login-button" id={id} />;
};
GoogleLoginButton.displayName = "GoogleLoginButton";
