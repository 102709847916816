import * as React from "react";
import { get_list_of_names } from "../../utils";
import { format_time_passed, get_touchpoint_desc } from "../../utils";
import { FacePile } from "../../components/FacePile";
import { ScreenSize } from "../../../constants";
import { TouchpointTypeIcon } from "../../components/TouchpointTypeIcon";
import { sort_by_id } from "../../../utils/common";
import { ReactionList } from "../../components/ReactionList";

export const InteractionListViewItem: React.FunctionComponent<
  Context<"window_size"> & {
    self_id: number;
    item: Touchpoint | Interaction;
    button?: React.ReactNode;
  }
> = ({ item, self_id, window_size, button }) => {
  item.participants = sort_by_id(item.participants);
  // @ts-ignore
  const time = new Date(item.started_at || item.created_at);
  return (
    <>
      <div className="interaction__left">
        {window_size < ScreenSize.md && (
          <FacePile users={item.participants} type="pile" />
        )}
        <TouchpointTypeIcon type={item.type as TouchpointType} size="md" />
        <div className="interaction__description">
          <div className="interaction__headline">
            {get_touchpoint_desc(item, self_id)}
          </div>
          <div className="interaction__info">
            <div title={time.toLocaleString()} className="interaction__time">
              {format_time_passed(
                // @ts-ignore
                time,
                window_size < ScreenSize.md
              )}
            </div>
            {"emoji_responses" in item && (
              <ReactionList
                touchpoint_id={item.id}
                reactions={item.emoji_responses}
                touchpoint_updated_at={item.updated_at}
                onlyShowSelf={window_size < ScreenSize.md}
              />
            )}
          </div>
        </div>
      </div>
      <div className="interaction__right">
        <div className="interaction__users">
          {window_size >= ScreenSize.md && (
            <FacePile users={item.participants} type="linear" />
          )}
          <div className="interaction__users__names">
            {get_list_of_names(item.participants, self_id)}
          </div>
        </div>
        {button && <div className="interaction__button">{button}</div>}
      </div>
    </>
  );
};
