/**
 * Based on https://github.com/allforabit/umbrella/tree/react-context-example/examples/react-context
 */

import * as React from "react";
import { EventBus } from "@thi.ng/interceptors";
import { INITIAL_APP_STATE } from "../app/initial-state";
import { useContext } from "react";

// @ts-ignore
export const CONTEXTS: {
  [K in keyof AppContexts]: React.Context<AppContexts[K]>
} = {};
Object.entries(INITIAL_APP_STATE).forEach(([key, value]) => {
  CONTEXTS[key] = React.createContext(value);
});

export class Provider extends React.PureComponent<{ bus: EventBus }> {
  constructor(props) {
    super(props);
    this.state = {
      stateBuster: 0
    };
    Object.keys(INITIAL_APP_STATE).forEach(key => {
      if (key === "dispatchers") {
        this._state[key] = {
          dispatch: props.bus.dispatch.bind(props.bus),
          dispatchNow: props.bus.dispatchNow.bind(props.bus),
          dispatchLater: props.bus.dispatchLater.bind(props.bus)
        };
      } else {
        this._state[key] = props.bus.state.addView(key);
      }
    });
  }
  _state = {};
  raf: any;
  tick = () => {
    // Process event bus on each tick
    if (this.props.bus.processQueue()) {
      // Update component
      this.setState(_ => ({ stateBuster: Math.random() * 100 }));
    }
    this.raf = requestAnimationFrame(this.tick);
  };
  componentDidMount() {
    this.raf = requestAnimationFrame(this.tick);
  }
  componentWillUnmount() {
    cancelAnimationFrame(this.raf);
  }
  render() {
    return Object.entries(CONTEXTS).reduce(
      (children, [key, { Provider }]) => (
        <Provider
          // @ts-ignore
          value={
            key === "dispatchers"
              ? (this._state[key] as Dispatchers)
              : this._state[key].deref()
          }
        >
          {children}
        </Provider>
      ),

      this.props.children
    );
  }
}

// This wraps the given component in a higher order component
export const connect = <
  K extends keyof AppContexts,
  P extends object,
  Q extends boolean
>(
  Component: React.ComponentType<P & Context<K, Q>>,
  with_dispatchers: Q,
  select: K[]
): React.FunctionComponent<ExcludeProperties<P, Context<K, Q>>> => {
  if (with_dispatchers) {
    // @ts-ignore
    select.push("dispatchers");
  }
  const componentName = Component.displayName || Component.name;
  const Connected = select.reduce(
    (WrappedComponent, key: K) => (props: P): React.ReactElement => {
      // @ts-ignore
      const value = useContext(CONTEXTS[key]);
      WrappedComponent.displayName = `${componentName}-${key}`;
      // This uses a render prop (takes a function callback)
      // Consumer will send selected props only when they are actually updated
      return (
        <WrappedComponent
          {...props}
          // @ts-ignore
          {...(key === "dispatchers" ? { ...value } : { [key]: value })}
        />
      );
    },
    Component
  );

  Connected.displayName = `${Component.displayName ||
    Component.name}-Connected`;
  // @ts-ignore
  return Connected;
};
