import * as React from "react";
import { Avatar } from "../../components/Avatar";
import { enumerate_score } from "../../components/HealthScore";
import { Icon } from "../../components/Icon";
import { CommunityLeaderLabels } from "../../../constants";
import { HealthIcon } from "../../components/HealthIcon";

export const GroupLeaderListItem: React.FC<{
  node: GroupGraphNode<keyof GroupTypes>;
}> = ({ node }) => {
  const avatar = (
    <Avatar
      {...(node.user
        ? {
            name: node.user.name,
            url: node.user.profile_photo_url,
            size: "md"
          }
        : {
            // @ts-ignore
            name: node.group.group_name || node.group.thread_name,
            size: "md"
          })}
    />
  );
  const avatar_link = node.user ? (
    <a href={`/users/${node.user.id}`}>{avatar}</a>
  ) : (
    avatar
  );

  const node_child_type =
    node.type === "thread"
      ? "threads"
      : node.type === "family"
      ? "families"
      : undefined;
  const bottom_props = {
    className: "group-graph__user-list__item__bottom",
    ["data-state"]: enumerate_score(node.group.score),
    children: (
      <>
        <span>{node.group.name}</span>
        <span>
          <Icon name="family" />
          {node.group.score}%
        </span>
      </>
    )
  };
  return (
    <div className="group-graph__user-list__item">
      <div className="group-graph__user-list__item__top">
        {node.user ? (
          <>
            {avatar_link}
            {node.user.health && <HealthIcon {...node.user.health} />}
            <div className="group-graph__user-list__item__names">
              <a href={`/users/${node.user.id}`}>{node.user.name}</a>
              <span className="group-graph__user-list__item__role">
                {CommunityLeaderLabels[node.type]}
              </span>
            </div>
          </>
        ) : (
          <>
            <Icon name="help" />
            <span className="group-graph__user-list__item__role">
              No {CommunityLeaderLabels[node.type]}
            </span>
          </>
        )}
      </div>
      {node.group.parent_id &&
        (node.clickable ? (
          <a
            href={`./${node.group.parent_id}/${node_child_type}/${
              node.group.id
            }`}
            {...bottom_props}
          />
        ) : (
          <div {...bottom_props} />
        ))}
    </div>
  ); /*
  return (
    <div className="group-graph__user-list__item">
      <div className="group-graph__user-list__item__left">
        {avatar_link}
        <div className="group-graph__user-list__item__names">
          {node.user && <a href={`/users/${node.user.id}`}>{node.user.name}</a>}
          {"group_id" in group ? (
            <a href={`./${group.thread_id}/families/${group.group_id}`}>
              {group.group_name}
            </a>
          ) : "thread_id" in group ? (
            <a href={`./${group.community_id}/threads/${group.thread_id}`}>
              {group.thread_name}
            </a>
          ) : (
            <div>{group.community_name}</div>
          )}
        </div>
      </div>
      {
        // @ts-ignore
        <HealthScore {...score} />
      }
    </div>
  );*/
};
