import * as React from "react";
import { RequestComponent } from "../components/RequestComponent";
import { IMAGES_PREFIX, MIN_PASSWORD_LENGTH } from "../../constants";
import { GoogleLoginFormWrapper } from "../components/GoogleLoginFormWrapper";
import {
  GOOGLE_LOGIN_REQUEST_ID,
  LOGIN_REQUEST_ID
} from "../../model/effects/constants";
import { FieldError, FormErrors } from "../components/Errors";
import { WithFloatingTitle } from "../components/common/floating-title";
import { InputWithFormHelpers } from "../components/common/inputs";
import { RequestButton } from "../components/common/request-button";
import {
  connect,
  DEFAULT_APP_LOCATION,
  EVENTS,
  LOGIN_LOCATION
} from "../../model";
import { SvgLoadingSpinner } from "../components/icons";
import api from "../../api";

export class ZendeskLogin extends RequestComponent<
  "user" | "app_location",
  WithAppLocationAt<"zendesk-login">,
  { email?: string; password?: string; submitted?: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: LOGIN_REQUEST_ID
    };
    this.button = React.createRef();
    this.cancel_link = React.createRef();
  }

  button: React.RefObject<HTMLButtonElement>;
  cancel_link: React.RefObject<HTMLAnchorElement>;

  componentDidMount(): void {
    const {
      app_location: { query = {} }
    } = this.props;
    if (
      query.logout === "true" &&
      query.kind !== "error" &&
      this.cancel_link.current
    ) {
      this.cancel_link.current.click();
    } else if (this.shouldAutoLogin()) {
      this.setState({ submitted: true }, () => this.loginToZendesk());
    }
  }

  componentDidUpdate(prevProps) {
    if (this.shouldAutoLogin()) {
      this.setState({ submitted: true }, () => this.loginToZendesk());
    } else if (
      this.requestFinished(prevProps) &&
      this.requestSucceeded() &&
      !this.state.submitted
    ) {
      this.loginToZendesk();
    }
  }

  shouldAutoLogin = (): boolean => {
    const {
      app_location: { query = {} },
      user
    } = this.props;
    return (
      !!user &&
      !this.state.submitted &&
      query.logout !== "true" &&
      query.kind !== "error"
    );
  };

  loginToZendesk = () => {
    this.button.current && this.button.current.click();
    this.button.current && this.button.current.setAttribute("disabled", "true");
  };

  onChangeInput = (field: "email" | "password") => e => {
    // @ts-ignore
    this.setState({ [field]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (email && password) {
      this.props.dispatchNow([EVENTS.LOGIN, { email, password }]);
    }
  };

  render() {
    const {
      user,
      app_location: { query }
    } = this.props;
    const { email, password, submitted } = this.state;
    const login_res = this.getRequestErrors();
    const { return_to, kind, message, logout } = query || {};
    return (
      <div className="login-page">
        <div className="login-page__logo">
          <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
        </div>
        {logout === "true" && kind !== "error" && (
          <a
            href={user ? DEFAULT_APP_LOCATION.place : LOGIN_LOCATION.place}
            ref={this.cancel_link}
          />
        )}
        <div className="login-page__form login-page__form__with-google">
          {user ? (
            <form
              method="GET"
              action={api.client.ZendeskLoginURL({ return_to })}
              className="login-page__zendesk login-page__form"
            >
              <input type="hidden" name="return_to" value={return_to} />
              <button
                className="login-page__submit filled"
                ref={this.button}
                type="submit"
              >
                Login to Zendesk
              </button>
              {submitted && (
                <div className="login-page__zendesk__loading">
                  <SvgLoadingSpinner />
                </div>
              )}
              <FieldError hasError={kind === "error"}>{message}</FieldError>
            </form>
          ) : (
            <GoogleLoginFormWrapper
              errors={this.getRequestErrors(GOOGLE_LOGIN_REQUEST_ID)}
            >
              <form id="login" onSubmit={this.onSubmit}>
                <WithFloatingTitle title="Email">
                  <InputWithFormHelpers
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={this.onChangeInput("email")}
                    value={email}
                    submitOnEnter={true}
                  />
                </WithFloatingTitle>
                <WithFloatingTitle title="Password">
                  <InputWithFormHelpers
                    placeholder="Password"
                    type="password"
                    name="password"
                    minLength={MIN_PASSWORD_LENGTH}
                    onChange={this.onChangeInput("password")}
                    value={password}
                    submitOnEnter={true}
                  />
                </WithFloatingTitle>
                {login_res && login_res.status === 401 ? (
                  <FieldError hasError={true}>
                    Invalid email or password
                  </FieldError>
                ) : (
                  <FormErrors response={login_res} />
                )}
                <RequestButton
                  pending={
                    this.requestIsPending() || this.requestSucceeded() || false
                  }
                  success={false}
                  className="login-page__submit filled"
                  type="submit"
                  successText="Success!"
                >
                  Log in to Thrive
                </RequestButton>
                <div className="login-page__forgot-pw">
                  <a href="/forgot_password">Forgot password?</a>
                </div>
              </form>
            </GoogleLoginFormWrapper>
          )}
        </div>
      </div>
    );
  }
}

export const ZendeskLoginConnected = connect(
  ZendeskLogin,
  true,
  ["user", "app_location", "request"]
);
