import * as React from "react";
import { CONTEXTS } from "../../model";
import { AppModal } from "./common/modal";
import { LoginModalConnected } from "./LoginModal";
import { useContext } from "react";

export const SiteModal: React.FunctionComponent<{}> = () => {
  const modal = useContext(CONTEXTS.modal);
  const login_modal = useContext(CONTEXTS.login_modal);
  return (
    <div id="modalContainer" data-login-open={login_modal.open}>
      {modal.modal && (
        <AppModal
          id="app"
          {...modal.modal}
          closed={!modal.open}
          giveTabFocus={!login_modal.open}
          dismissOnClickBackdrop={true}
        />
      )}
      {login_modal.modal && (
        <LoginModalConnected
          id="login"
          {...login_modal.modal}
          closed={!login_modal.open}
          giveTabFocus={true}
          dismissOnClickBackdrop={false}
        />
      )}
    </div>
  );
};
