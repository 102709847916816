import * as React from "react";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import { WithApiRequest } from "../../components/WithApiRequest";
import { connect, EVENTS } from "../../../model";
import { CommunityBreadcrumb } from "./CommunityBreadcrumb";
import { HealthScore } from "../../components/HealthScore";
import { path_or } from "../../../utils/common";
import { GroupGraph } from "./GroupGraph";
import { group_to_graph_node, user_to_graph_node } from "../../utils";
import { GroupLeaderListItem } from "./GroupLeaderListItem";
import { groupIsClickable } from "./ListItem";

class ThreadDetailBase extends React.PureComponent<
  {
    app_location: AppLocationAt<"communities/id/threads/id">;
  } & Context<"user">,
  { nodes: GroupGraphNode<"thread">[] }
> {
  constructor(props) {
    super(props);
    this.state = {
      nodes: this.createNodes(props)
    };
  }

  componentDidUpdate(
    prevProps: Readonly<{
      app_location: AppLocationAt<"communities/id/threads/id">;
    }>
  ) {
    if (
      this.props.app_location.record &&
      (!prevProps.app_location.record ||
        (!prevProps.app_location.record.hub_scores &&
          this.props.app_location.record.hub_scores) ||
        this.props.app_location.record.community_id !==
          prevProps.app_location.record.community_id ||
        this.props.app_location.record.thread_id !==
          prevProps.app_location.record.thread_id)
    ) {
      this.setState({ nodes: this.createNodes() });
    }
  }

  calculateConnections = (): ObjOf<number> => {
    const thread: NetworkGroupsObject = path_or(
      undefined,
      ["app_location", "record"],
      this.props
    );
    if (!thread || !thread.hub_scores || thread.hub_scores.length === 0) {
      return {};
    }

    const center_id = thread.thread_manager
      ? thread.thread_manager.id
      : thread.hub_scores[0].spoke_user.id;
    const connections: ObjOf<number> = {};
    thread.hub_scores.forEach(s => {
      if (s.spoke_user.id != center_id) connections[s.spoke_user.id] = s.score;
    });

    return connections;
  };

  createNodes = (props = this.props): GroupGraphNode<"thread">[] => {
    const { user } = this.props;
    const thread: NetworkGroupsObject = path_or(
      undefined,
      ["app_location", "record"],
      props
    );
    if (!thread || !thread.hub_scores || thread.groups.length === 0 || !user) {
      return [];
    }

    const { hub_scores, groups, thread_manager } = thread;

    return [
      {
        user: user_to_graph_node(thread_manager),
        // @ts-ignore
        group: group_to_graph_node(thread, true),
        type: "thread",
        clickable: false
      } as GroupGraphNode<"thread">
    ].concat(
      groups
        .map(group => {
          const n = hub_scores.find(s => s.group_id === group.group_id);
          return n
            ? {
                id: n.spoke_user ? n.spoke_user.id : n.group_id,
                user: user_to_graph_node(n.spoke_user),
                group: group_to_graph_node(group),
                type: "family",
                clickable: groupIsClickable(group, user)
              }
            : {
                id: group.group_id,
                group: group_to_graph_node(group),
                type: "family",
                clickable: groupIsClickable(group, user)
              };
        })
        .sort((a, b) => {
          if (a.clickable !== b.clickable) {
            return a.clickable ? -1 : 1;
          }
          if (a.user && !b.user) {
            return -1;
          }
          if (!a.user && b.user) {
            return 1;
          }
          return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
        })
        .map(({ id, ...other }) => ({ ...other } as GroupGraphNode<"thread">))
    );
  };

  render() {
    const { app_location } = this.props;
    const { nodes } = this.state;
    return (
      <PageContent
        id="thread-detail"
        className="group-detail-page"
        bodyType="no-margins"
        pageHeader={
          <PageHeader
            title={<CommunityBreadcrumb />}
            button={
              <HealthScore
                score={path_or(
                  undefined,
                  ["record", "thread_score"],
                  app_location
                )}
              />
            }
          />
        }
      >
        {nodes && nodes.length > 0 && (
          <GroupGraph
            group_type="thread"
            nodes={nodes.filter(n => !!n)}
            calculateConnections={this.calculateConnections}
            ListItemView={GroupLeaderListItem}
            center_id={path_or(
              undefined,
              ["record", "thread_manager", "id"],
              app_location
            )}
          />
        )}
      </PageContent>
    );
  }
}

const ThreadDetailBaseConnected = connect(
  ThreadDetailBase,
  true,
  ["app_location", "user"]
);

const ThreadDetail: React.FC<
  Dispatchers & {
    app_location: AppLocationAt<"communities/id/threads/id">;
  }
> = ({ app_location, dispatchNow }) => (
  <section className="group-detail thread-detail">
    <WithApiRequest
      payload={{
        method: "GetGroupsByThread",
        parameters: { thread_id: app_location.thread.id }
      }}
      View={ThreadDetailBaseConnected}
      overrideRecord={app_location.record}
      onSuccess={({ response: { data } }) => {
        dispatchNow([EVENTS.GO_TO_PAGE, { ...app_location, record: data }]);
      }}
    />
  </section>
);

export const ThreadDetailConnected = connect(
  ThreadDetail,
  true,
  ["app_location"]
);
