import * as React from "react";
import { connect, EVENTS } from "../../../model";
import { PageHeader } from "../../components/PageHeader";
import { Icon } from "../../components/Icon";
import { PageContent } from "../../components/PageContent";

const NOT_LONELY_TEXT = (score: number) => (
  <>
    <p>
      Your score is {score}. You are less likely to be lonely. Even if you
      aren’t feeling lonely, there are actions you can take to help others.
    </p>
    <p>
      Is there someone in your life who could benefit from more human
      connection? Take a few minutes to reach out – call just to chat, schedule
      lunch, or go over for a visit. In the workplace, gym or local community
      center, try asking colleagues or friends out for coffee to catch up.
    </p>
  </>
);

const LONELY_TEXT = (score: number) => (
  <>
    <p>Your score is {score}. You are more likely to be lonely.</p>
    <p>
      Everyone feels lonely sometimes. Connecting with others in-person can
      help.
    </p>
    <p>Here are a few ideas on how to make it happen:</p>
    <ul>
      <li>
        Call a friend or family member to say hi, set up time to go to lunch, or
        drop by for a visit.
      </li>
      <li>
        Stop and talk to others the next time you walk through the office or go
        to the gym or coffee shop.
      </li>
      <li>
        Sign up for a group exercise class to break a sweat and meet new people.
      </li>
      <li>
        Get the right amount of sleep each night. Try not to use technology
        right before bedtime and set an alarm to avoid oversleeping.
      </li>
      <li>
        Get involved in your community. VolunteerMatch, Create the Good®, and
        United Way® can match you with volunteer opportunities based on your
        interests.
      </li>
    </ul>
  </>
);

export const LonelinessRaterResponse: React.FC<
  Dispatchers & WithAppLocationAt<"loneliness-rater/result">
> = ({ app_location, dispatchNow }) => {
  if (!app_location.record || app_location.record.result == null) {
    dispatchNow([EVENTS.GO_TO_PAGE, { place: "loneliness-rater" }]);
    return null;
  }

  const { result } = app_location.record;
  const scale: React.ReactNode[] = [];
  for (let i = 3; i < 10; i++) {
    scale.push(
      <div
        key={i}
        className="loneliness-rater__result__scale__item"
        data-active={i === result}
      >
        {i}
      </div>
    );
  }

  return (
    <PageContent
      id="loneliness-rater-result"
      className="header-overlap-block header-overlap-sm"
      bodyType="full-width-block"
      pageHeader={
        <PageHeader
          title="Loneliness Rater"
          icon={<Icon name="loneliness" />}
        />
      }
    >
      <h3>Your Results</h3>
      <div className="loneliness-rater__result__container">
        <div className="loneliness-rater__result__scale">{scale}</div>
        <div className="loneliness-rater__result__labels">
          <span>Not Lonely</span>
          <span>Lonely</span>
        </div>
      </div>
      <div className="loneliness-rater__result__description">
        <h2>Thank you for completing the loneliness rater survey.</h2>
        {result < 6 ? NOT_LONELY_TEXT(result) : LONELY_TEXT(result)}
      </div>
      <div className="loneliness-rater__result__done">
        <a className="button filled" href="/">
          Done
        </a>
      </div>
    </PageContent>
  );
};

export const LonelinessRaterResponseConnected = connect(
  LonelinessRaterResponse,
  true,
  ["app_location"]
);
