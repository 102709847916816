import * as React from "react";
import { Icon } from "../Icon";

export const MultiValueListItem: React.FC<{
  option: InputOption<number | string>;
  onRemove: () => void;
  active?: boolean;
}> = ({ option, onRemove, active }) => (
  <div className="mult-val-input__value" data-active={active}>
    <div
      className="mult-val-input__value__button"
      onClick={e => {
        e.preventDefault();
        onRemove();
      }}
    >
      <Icon name="remove-circle" />
    </div>
    <div className="mult-val-input__value__label" data-text={option.label}>
      {option.label}
    </div>
  </div>
);
MultiValueListItem.displayName = "MultiValueListItem";
