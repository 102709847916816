import * as React from "react";
import { CONTEXTS, EVENTS } from "../../model";
import { maybeClassName } from "../../utils/dom-helpers";
import { useContext } from "react";

export const ExpandableImage: React.FC<
  {
    filename?: string;
    maxThumbnailHeight:
      | number
      | ((imgHeight: number, imgWidth: number) => number);
  } & HTMLImageProps
> = ({ filename, maxThumbnailHeight, className, ...props }) => {
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);

  const previewImage = e => {
    e.preventDefault();
    if (!props.src) {
      return false;
    }
    dispatchNow([
      EVENTS.OPEN_APP_MODAL,
      {
        title: filename,
        className: "expandable-image-modal",
        body: () => <img {...props} />
      }
    ]);
  };

  const setHeight = ({ target: el }) => {
    if (!(el instanceof HTMLImageElement)) {
      return;
    }
    const maxHeight =
      typeof maxThumbnailHeight === "number"
        ? maxThumbnailHeight
        : maxThumbnailHeight(el.naturalHeight, el.naturalWidth);

    el.style.maxHeight = `${Math.min(maxHeight, 2 * el.naturalHeight)}px`;
  };

  return (
    <img
      className={`expandable-image${maybeClassName(className)}`}
      {...props}
      onClick={e => {
        props.onClick && props.onClick(e);
        previewImage(e);
      }}
      onLoad={setHeight}
    />
  );
};
