import * as React from "react";
import { add_item_to, remove_item_from } from "../../../utils/common";
import { InteractionChecklist } from "../../components/InteractionChecklist";

class InteractionsModal extends React.PureComponent<
  ModalBodyProps & {
    selected: () => Interaction[];
    onSuccess: (interactions: Interaction[]) => void;
  },
  { selected: Interaction[]; filter: string; error?: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected(),
      filter: ""
    };
  }

  onChangeInteractions = (interaction: Interaction, checked: boolean) => {
    this.setState({
      selected: checked
        ? add_item_to(this.state.selected, interaction)
        : remove_item_from(this.state.selected, v => v.id === interaction.id)
    });
  };

  render() {
    const { onDismiss, onSuccess } = this.props;
    const { selected } = this.state;
    return (
      <>
        <div className="interaction__modal__body">
          <InteractionChecklist
            id="interaction-checklist"
            method="GetInteractionsByUser"
            onChange={this.onChangeInteractions}
            selected={selected}
          />
        </div>
        <div className="interaction__modal__footer">
          <button
            onClick={onDismiss}
            className="bordered interaction__modal__dismiss"
          >
            Cancel
          </button>
          <button
            onClick={e => {
              onSuccess(this.state.selected);
              onDismiss(e);
            }}
            className="filled interaction__modal__success"
            disabled={selected.length === 0 || selected.length > 10}
          >
            Add Interactions
          </button>
        </div>
      </>
    );
  }
}

export const makeInteractionsModal = (
  selected: () => Interaction[],
  onSuccess: (interactions: Interaction[]) => void
): React.FC<ModalBodyProps & Context<"request", true>> => {
  const interactionsModal: React.FC<ModalBodyProps> = ({ ...props }) => (
    <InteractionsModal {...props} onSuccess={onSuccess} selected={selected} />
  );
  interactionsModal.displayName = "InteractionsModal";
  return interactionsModal;
};
