import * as React from "react";
import { Error404PageNotFound } from "./common/common";

export const ApiRequestErrorPage: React.FC<{
  response: SuperAgentResponse;
  error: Error;
}> = ({ response }) => {
  const message = response
    ? response.status === 401
      ? "You must be logged in to view this page."
      : response.status === 403
      ? "You are not authorized to view this page."
      : "Please try again in a moment."
    : "Please try again in a moment.";
  return response && response.status === 404 ? (
    <Error404PageNotFound />
  ) : (
    <div className="with-api-request__default-error">
      <div>Something went wrong!</div>
      <div>{message}</div>
      {response && <div>Status Code: {response.status}</div>}
    </div>
  );
};
