import { current_screen_size } from "../utils/dom-helpers";
import { url_to_app_location } from "../model/routing";

export const INITIAL_APP_STATE: AppContexts = {
  ensure_gapi: () => ({}),
  google_signin_available: null,
  // @ts-ignore
  app_location: url_to_app_location(window.location),
  window_size: current_screen_size(),
  modal: {
    open: false as false,
    modal: null
  },
  login_modal: {
    open: false as false,
    modal: null
  },
  user: null,
  auth_expired: false,
  request: {},
  notifications: null,
  // @ts-ignore
  dispatchers: {}
};
