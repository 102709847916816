import * as React from "react";
import { ApiReqViewProps, WithApiRequest } from "./WithApiRequest";
import { ListView } from "./ListView";

export type PagedEndpointsFor<
  T extends ApiPagedResponseModel<ApiPagedMethod>
> = keyof Pick<
  { [K in ApiPagedMethod]: K },
  {
    [K in ApiPagedMethod]: ApiPagedResponseModel<K> extends T ? K : never
  }[ApiPagedMethod]
>;

const RESEND_DELAY = 500;

export abstract class RecordChecklist<
  T extends Diff<ApiPagedResponseModel<ApiPagedMethod>, Installation>,
  P extends object = {},
  S extends object = {}
> extends ListView<
  PagedEndpointsFor<T>,
  {
    id: string;
    method: PagedEndpointsFor<T>;
    onChange: (record: T, checked: boolean) => void;
    selected: T[];
    filter?: string;
  } & P,
  {
    query: Omit<ApiQuery<T>, "sortBy" | "sortDir"> &
      ApiPagedMethodParameters<PagedEndpointsFor<T>>;
  } & S
> {
  constructor(props) {
    super(props);
    // @ts-ignore
    this.state = {
      query: {
        page: 0,
        pageSize: 10
      }
    };
  }
  timer;

  shouldComponentUpdate(nextProps) {
    if (this.props.filter !== nextProps.filter) {
      let changeQuery = { ...this.state.query };
      changeQuery.page = 0;
      changeQuery.filter = nextProps.filter;

      this.withDelay(() => this.setState({ query: changeQuery }));
      return false;
    }
    return true;
  }

  withDelay = (func: () => void) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      clearTimeout(this.timer);
      this.timer = null;
      func();
    }, RESEND_DELAY);
  };

  onChange = (e, record: T) => {
    if (!e.target.value || isNaN(parseInt(e.target.value))) {
      return false;
    }
    return this.props.onChange(record, e.target.checked);
  };

  abstract ChecklistItemView: React.FC<{ item: T }>;

  abstract ChecklistView: React.FunctionComponent<
    ApiReqViewProps<PagedEndpointsFor<T>>
  >;

  render() {
    return (
      <div className="record-checklist">
        <WithApiRequest
          id={this.props.id}
          View={this.ChecklistView}
          payload={{
            method: this.props.method,
            // @ts-ignore
            parameters: this.state.query
          }}
        />
      </div>
    );
  }
}
