import * as React from "react";
import { CONTEXTS } from "../../../model";
import { WithApiRequest } from "../../components/WithApiRequest";
import { Error404PageNotFound } from "../../components/common/common";
import { PageContent } from "../../components/PageContent";
import { ExpenseFormConnected } from "./Form";
import { useContext } from "react";

export const CreateExpensePage: React.FC<{}> = () => {
  const app_location = useContext(CONTEXTS.app_location);
  return app_location.place === "activity" &&
    typeof app_location.id === "number" ? (
    <PageContent
      pageHeader="Create an Expense"
      id="expense-create"
      className="expense-page expense-form header-overlap-block header-overlap-sm"
      bodyType="full-width-block"
    >
      <WithApiRequest
        View={ExpenseFormConnected}
        payload={{
          method: "GetTouchpointById",
          parameters: {
            touchpoint_id: app_location.id
          }
        }}
      />
    </PageContent>
  ) : (
    <Error404PageNotFound />
  );
};
