/** Context-aware affordances for semantic navigation. */
import * as React from "react";
import { EVENTS, CONTEXTS } from "../../../model";
import { useContext } from "react";

export const ActionLink: React.FC<
  { location: AppLocation } & HTMLAnchorProps
> = ({ location, onClick, ...props }) => {
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);
  return (
    <a
      onClick={e => {
        e.preventDefault();
        onClick && onClick(e);
        dispatchNow([EVENTS.GO_TO_PAGE, location]);
      }}
      {...props}
    />
  );
};

/** An HTML element that takes a state action when clicked. */
type PseudoProps = HTMLButtonProps & { event: AppEvent<AppEventName> };
export const ActionButton: React.FunctionComponent<PseudoProps> = ({
  event,
  onClick,
  ...props
}) => {
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);
  return (
    <button
      {...props}
      onClick={e => {
        e.preventDefault();
        const result = onClick ? onClick(e) : undefined;
        dispatchNow(event);
        return result;
      }}
    />
  );
};
