import { get_list_of_names, get_user_full_name } from "../../view/utils";
import { TouchpointTypes } from "../../constants";
import { capitalize } from "../../utils/common";
import { DEFAULT_APP_LOCATION, LOGIN_LOCATION } from "./constants";
import { query_to_string, string_to_query } from "../../utils/query-string";

/** Convert `AppLocation` to the appropriate window title */
export const app_location_to_title = (
  location: AppLocation,
  self_id: number
): string => {
  const arr: any[] = ["Thrive"];
  switch (location.place) {
    case "users":
      if (location.id) {
        if (location.record) {
          arr.push(get_user_full_name(location.record));
        }
        arr.push("Shared Activity");
      } else {
        arr.push("Users");
      }
      break;
    case "activity":
      if (!location.id) {
        arr.push("Activity");
      } else if (location.id === "create") {
        arr.push("Create a Touchpoint");
      } else {
        // @ts-ignore
        if (!location.expense) {
          // @ts-ignore
          if (location.edit) {
            arr.push("Edit Touchpoint");
          } else {
            arr.push("Touchpoint Details");
          }
          // @ts-ignore
          const { record } = location;
          if (record) {
            arr.push(
              `${TouchpointTypes[record.type]} with ${get_list_of_names(
                record.participants.filter(p => p.id !== self_id),
                self_id,
                3
              )}`
            );
          } else {
            arr.push(location.id);
          }
          // @ts-ignore
        } else if (location.expense === "create") {
          arr.push("Create an Expense");
          // @ts-ignore
        } else if (location.edit) {
          arr.push("Update Expense");
        } else {
          arr.push("Expense Details");
        }
      }
      break;
    case "communities":
      arr.push("Communities");
      if (location.community != null) {
        // TODO: Use names instead of ids in title
        // @ts-ignore
        const { community, thread, family } = location;
        const others = [community, thread, family]
          .filter(
            v =>
              !!v &&
              (!!v.name ||
                !!v.community_name ||
                !!v.thread_name ||
                !!v.group_name)
          )
          .map(
            v => v.name || v.community_name || v.thread_name || v.group_name
          );
        const main = others.pop();
        main &&
          arr.push(
            `${main}${others.length > 0 ? ` (${others.join(" > ")})` : ""}`
          );
      }
      break;
    /*case "conversations":
      if (location.id) {
        arr.push("Conversation Details");
      } else {
        arr.push("Conversations");
      }
      break;*/
    case "loneliness-rater":
      arr.push("Loneliness Rater");
      break;
    case "weekly-check-in":
      arr.push("Weekly Check-In");
      break;
    case "privacy-policy":
      arr.push("Privacy Policy");
      break;
    case "terms-of-service":
      arr.push("Terms of Service");
      break;
    case "forgot_password":
      arr.push("Forgot Password");
      break;
    default:
      arr.push(capitalize(location.place));
  }
  return arr.join(" | ");
};
export const LoginTitle = app_location_to_title(LOGIN_LOCATION, -1);

/** check if the user is on the forgot password page, and put the email/token in app location
 * IF they're both set in the query params */
export const parse_setup_page_url = (
  url: Partial<Location>
): AppLocationAt<"register"> | AppLocationAt<"forgot_password"> | undefined => {
  let [, place] = (url.pathname || "").split("/");
  const param_strings = url.search ? url.search.slice(1).split("&") : [];
  const params: any = {};
  param_strings.forEach(p => {
    const [key, value] = p.split("=");
    params[decodeURIComponent(key)] = decodeURIComponent(value);
  });
  return place === "forgot_password"
    ? params.token && params.email
      ? {
          ...params,
          place
        }
      : { place }
    : place === "register" && params.token && params.email
    ? { place, ...params }
    : undefined;
};

/** check if the user is on the register page AND the email/token query params are set */
export const is_registering = (location?: AppLocation): boolean =>
  !!location &&
  location.place === "register" &&
  !!location.email &&
  !!location.token;

export const resetting_password = (location?: AppLocation): boolean =>
  !!location &&
  location.place === "forgot_password" &&
  ((!!location.email && !!location.token) ||
    (!location.email && !location.token));

/** Convert `Location` object to `AppLocation` */
export const url_to_app_location = (url: Partial<Location>): AnyAppLocation => {
  const setup_page = parse_setup_page_url(url);
  if (setup_page) {
    return setup_page;
  }
  let [
    ,
    place = DEFAULT_APP_LOCATION.place,
    id,
    subpath,
    subpath_id,
    subsubpath,
    subsubpath_id
  ] = (url.pathname || "").split("/");
  const loc: AnyAppLocation = {
    // @ts-ignore
    place,
    hash: url.hash ? url.hash.replace("#", "") : undefined,
    query: url.search ? string_to_query(url.search) : undefined
  };
  /** If on the register page without email/token, redirect to login */
  if (loc.place === "register") {
    return LOGIN_LOCATION;
  } else if (
    (loc.place === "users" ||
      loc.place === "activity" ||
      loc.place ===
        "weekly-check-in") /*||
      loc.place === "conversations"*/ &&
    (id === "create" || !isNaN(parseInt(id)))
  ) {
    loc.id = id === "create" ? id : parseInt(id);
    if (loc.place === "activity" && typeof loc.id === "number") {
      if (
        subpath === "expense" &&
        (subpath_id === "create" || !isNaN(parseInt(subpath_id)))
      ) {
        loc.expense =
          subpath_id === "create" ? subpath_id : parseInt(subpath_id);
      }
      if (subpath === "edit" || subsubpath === "edit") {
        loc.edit = true;
      }
    }
  } else if (loc.place === "communities") {
    if (id != null && !isNaN(parseInt(id))) {
      loc.community = { id: parseInt(id) };
      if (subpath === "threads" && !isNaN(parseInt(subpath_id))) {
        loc.thread = { id: parseInt(subpath_id) };
        if (subsubpath === "families" && !isNaN(parseInt(subsubpath_id))) {
          loc.family = { id: parseInt(subsubpath_id) };
        }
      }
    }
  } else if (loc.place === "loneliness-rater") {
    // @ts-ignore
    loc.id = "result";
  }
  return loc;
};

/** Convert `AppLocation` to url string */
export const app_location_to_url = (
  location: AppLocation & { hash?: string }
): string => {
  const arr: any[] = [];
  arr.push(location.place);
  if (
    (location.place === "users" ||
      location.place === "activity" ||
      location.place ===
        "weekly-check-in") /* ||
      location.place === "conversations"*/ &&
    location.id
  ) {
    arr.push(location.id);
    if (location.place === "activity" && typeof location.id === "number") {
      // @ts-ignore
      if (location.expense) {
        // @ts-ignore
        arr.push("expense", location.expense);
      }
      // @ts-ignore
      if (location.edit) {
        arr.push("edit");
      }
    }
  } else if (location.place === "communities") {
    // TODO: Use names instead of ids in title
    // @ts-ignore
    const { community, thread, family } = location;
    if (community) {
      arr.push(community.id);
    }
    if (thread) {
      arr.push("threads");
      arr.push(thread.id);
    }
    if (family) {
      arr.push("families");
      arr.push(family.id);
    }
  }
  const base = `/${arr.join("/")}${
    // @ts-ignore
    location.query ? query_to_string(location.query) : ""
  }`;
  if (location.place === "register") {
    return `${base}?email=${location.email}&token=${location.token}`;
  }
  if (
    location.place === "loneliness-rater" &&
    "record" in location &&
    location["id"] === "result"
  ) {
    return `${base}/result`;
  }
  if (location.hash) {
    return `${base}#${location.hash}`;
  }
  return base;
};
