// @ts-ignore
import request, { SuperAgentStatic, SuperAgentRequest } from "superagent";

export type RequestHeaders = {
  [header: string]: string;
};
export type RequestHeadersHandler = (headers: RequestHeaders) => RequestHeaders;

export type ConfigureAgentHandler = (
  agent: SuperAgentStatic
) => SuperAgentStatic;

export type ConfigureRequestHandler = (
  agent: SuperAgentRequest
) => SuperAgentRequest;

export type CallbackHandler = (err: any, res?: request.Response) => void;
export type Logger = {
  log: (line: string) => any;
};

export interface CommonRequestOptions {
  $queryParameters?: {
    [param: string]: any;
  };
  $domain?: string;
  $path?: string | ((path: string) => string);
}

/**
 * Web services for the Thrive mobile apps/webapp
 * @class Thrive
 * @param {(string)} [domainOrOptions] - The project domain.
 */
export class Thrive implements ApiConnector {
  private domain: string = "";
  private errorHandlers: CallbackHandler[] = [];
  private requestHeadersHandler?: RequestHeadersHandler;
  private configureAgentHandler?: ConfigureAgentHandler;
  private configureRequestHandler?: ConfigureRequestHandler;

  constructor(domain?: string, private logger?: Logger) {
    if (domain) {
      this.domain = domain;
    }
  }

  getDomain() {
    return this.domain;
  }

  addErrorHandler(handler: CallbackHandler) {
    this.errorHandlers.push(handler);
  }

  setRequestHeadersHandler(handler: RequestHeadersHandler) {
    this.requestHeadersHandler = handler;
  }

  setConfigureAgentHandler(handler: ConfigureAgentHandler) {
    this.configureAgentHandler = handler;
  }

  setConfigureRequestHandler(handler: ConfigureRequestHandler) {
    this.configureRequestHandler = handler;
  }

  request(
    method: string,
    url: string,
    body: any,
    headers: RequestHeaders,
    queryParameters: any,
    form: any,
    reject: CallbackHandler,
    resolve: CallbackHandler
  ) {
    if (this.logger) {
      this.logger.log(`Call ${method} ${url}`);
    }

    const agent = this.configureAgentHandler
      ? this.configureAgentHandler(request)
      : request;

    let req = agent(method, url);
    if (this.configureRequestHandler) {
      req = this.configureRequestHandler(req);
    }

    req = req.query(queryParameters);

    if (body) {
      req.send(body);

      if (typeof body === "object" && !(body.constructor.name === "Buffer")) {
        headers["Content-Type"] = "application/json";
      }
    }

    if (Object.keys(form).length > 0) {
      if (headers["Content-Type"] === "application/x-www-form-urlencoded") {
        // @ts-ignore
        req.send(new URLSearchParams(Object.entries(form)).toString());
      } else {
        const formData = new FormData();
        Object.entries(form).forEach(([key, val]: any) => {
          val instanceof File
            ? formData.append(key, val, val.name)
            : formData.append(key, val);
        });
        req.send(formData);
      }
    }

    if (this.requestHeadersHandler) {
      headers = this.requestHeadersHandler({
        ...headers
      });
    }

    Object.keys(headers).forEach(key => {
      req.set(key, headers[key]);
    });

    req.end((error, response) => {
      if (error || !response.ok) {
        reject(error);
        this.errorHandlers.forEach(handler => handler(error));
      } else {
        resolve(response);
      }
    });
  }

  GetUsersURL(
    parameters: {
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
      filter?: string;
      excludeSelf?: boolean;
      exclude_connected?: boolean;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (parameters.filter !== undefined) {
      queryParameters.filter = parameters.filter;
    }

    if (parameters.excludeSelf !== undefined) {
      queryParameters.excludeSelf = parameters.excludeSelf;
    }

    if (parameters.exclude_connected !== undefined) {
      queryParameters.exclude_connected = parameters.exclude_connected;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetUsers
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   * @param {string} parameters.filter - optional parameter containing part of a first name, last name, or both to search for
   * @param {boolean} parameters.excludeSelf -
   * @param {boolean} parameters.exclude_connected -
   */
  GetUsers(parameters: {
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    filter?: string;
    excludeSelf?: boolean;
    exclude_connected?: boolean;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<User>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.filter !== undefined) {
        queryParameters.filter = parameters.filter;
      }

      if (parameters.excludeSelf !== undefined) {
        queryParameters.excludeSelf = parameters.excludeSelf;
      }

      if (parameters.exclude_connected !== undefined) {
        queryParameters.exclude_connected = parameters.exclude_connected;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetUserByIdURL(
    parameters: {
      id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetUserById
   * @param {number} parameters.id -
   */
  GetUserById(parameters: {
    id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<User>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PutUserURL(
    parameters: {
      id: number;
      email?: string;
      sales_force_id?: string;
      first_name?: string;
      last_name?: string;
      profile_photo_file?: {};
      phone_number?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PutUser
   * @param {number} parameters.id -
   * @param {string} parameters.email -
   * @param {string} parameters.sales_force_id -
   * @param {string} parameters.first_name -
   * @param {string} parameters.last_name -
   * @param {file} parameters.profile_photo_file -
   * @param {string} parameters.phone_number -
   */
  PutUser(parameters: {
    id: number;
    email?: string;
    sales_force_id?: string;
    first_name?: string;
    last_name?: string;
    profile_photo_file?: {};
    phone_number?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<User>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.email !== undefined) {
        form.email = parameters.email;
      }

      if (parameters.sales_force_id !== undefined) {
        form.sales_force_id = parameters.sales_force_id;
      }

      if (parameters.first_name !== undefined) {
        form.first_name = parameters.first_name;
      }

      if (parameters.last_name !== undefined) {
        form.last_name = parameters.last_name;
      }

      if (parameters.profile_photo_file !== undefined) {
        form.profile_photo_file = parameters.profile_photo_file;
      }

      if (parameters.phone_number !== undefined) {
        form.phone_number = parameters.phone_number;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  DisableUserURL(
    parameters: {
      id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#DisableUser
   * @param {number} parameters.id -
   */
  DisableUser(parameters: {
    id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<object>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "DELETE",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  SetUserPasswordURL(
    parameters: {
      id: number;
      set_password: SetPasswordBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}/setPassword";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#SetUserPassword
   * @param {number} parameters.id -
   * @param {} parameters.set_password -
   */
  SetUserPassword(parameters: {
    id: number;
    set_password: SetPasswordBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<User>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}/setPassword";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.set_password !== undefined) {
        body = parameters.set_password;
      }

      if (parameters.set_password === undefined) {
        reject(new Error("Missing required parameter: set_password"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  AddHealthRatingURL(
    parameters: {
      id: number;
      new_health_rating: HealthRatingBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}/healthRating";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#AddHealthRating
   * @param {number} parameters.id -
   * @param {} parameters.new_health_rating -
   */
  AddHealthRating(parameters: {
    id: number;
    new_health_rating: HealthRatingBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<RatingResult>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}/healthRating";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.new_health_rating !== undefined) {
        body = parameters.new_health_rating;
      }

      if (parameters.new_health_rating === undefined) {
        reject(new Error("Missing required parameter: new_health_rating"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  AddLonelinessRatingURL(
    parameters: {
      id: number;
      new_loneliness_rating: LonelinessRatingBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}/lonelinessRating";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#AddLonelinessRating
   * @param {number} parameters.id -
   * @param {} parameters.new_loneliness_rating -
   */
  AddLonelinessRating(parameters: {
    id: number;
    new_loneliness_rating: LonelinessRatingBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<RatingResult>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/user/{id}/lonelinessRating";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.new_loneliness_rating !== undefined) {
        body = parameters.new_loneliness_rating;
      }

      if (parameters.new_loneliness_rating === undefined) {
        reject(new Error("Missing required parameter: new_loneliness_rating"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PostInstallationURL(
    parameters: {
      inst: Installation;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/installation/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PostInstallation
   * @param {} parameters.inst -
   */
  PostInstallation(parameters: {
    inst: Installation;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<InstallationRecord>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/installation/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.inst !== undefined) {
        body = parameters.inst;
      }

      if (parameters.inst === undefined) {
        reject(new Error("Missing required parameter: inst"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PutInstallationURL(
    parameters: {
      id: string;
      inst: Installation;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/installation/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PutInstallation
   * @param {string} parameters.id -
   * @param {} parameters.inst -
   */
  PutInstallation(parameters: {
    id: string;
    inst: Installation;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<InstallationRecord>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/installation/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.inst !== undefined) {
        body = parameters.inst;
      }

      if (parameters.inst === undefined) {
        reject(new Error("Missing required parameter: inst"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  LoginURL(parameters: {} & CommonRequestOptions): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/getToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#Login
   */
  Login(parameters: {
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Token>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/getToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  LogoutURL(parameters: {} & CommonRequestOptions): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/clearToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#Logout
   */
  Logout(parameters: {
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<object>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/clearToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  RefreshTokenURL(parameters: {} & CommonRequestOptions): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/refreshToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#RefreshToken
   */
  RefreshToken(parameters: {
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Token>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/refreshToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  RegisterURL(
    parameters: {
      email: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/register";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.email !== undefined) {
      queryParameters.email = parameters.email;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#Register
   * @param {string} parameters.email -
   */
  Register(parameters: {
    email: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<object>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/register";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.email !== undefined) {
        queryParameters.email = parameters.email;
      }

      if (parameters.email === undefined) {
        reject(new Error("Missing required parameter: email"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  ExchangeRegistrationTokenURL(
    parameters: {
      email: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/exchangeRegistrationToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.email !== undefined) {
      queryParameters.email = parameters.email;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#ExchangeRegistrationToken
   * @param {string} parameters.email -
   */
  ExchangeRegistrationToken(parameters: {
    email: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Token>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/exchangeRegistrationToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.email !== undefined) {
        queryParameters.email = parameters.email;
      }

      if (parameters.email === undefined) {
        reject(new Error("Missing required parameter: email"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  ForgotPasswordURL(
    parameters: {
      email: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/forgotPassword";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.email !== undefined) {
      queryParameters.email = parameters.email;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#ForgotPassword
   * @param {string} parameters.email -
   */
  ForgotPassword(parameters: {
    email: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<object>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/forgotPassword";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.email !== undefined) {
        queryParameters.email = parameters.email;
      }

      if (parameters.email === undefined) {
        reject(new Error("Missing required parameter: email"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  AuthWithGoogleURL(
    parameters: {
      google_auth_credential: GoogleAuthCredentialBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/googleAuth/getToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#AuthWithGoogle
   * @param {} parameters.google_auth_credential -
   */
  AuthWithGoogle(parameters: {
    google_auth_credential: GoogleAuthCredentialBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Token>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/googleAuth/getToken";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.google_auth_credential !== undefined) {
        body = parameters.google_auth_credential;
      }

      if (parameters.google_auth_credential === undefined) {
        reject(new Error("Missing required parameter: google_auth_credential"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  ZendeskLoginURL(
    parameters: {
      return_to?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/zendesk";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.return_to !== undefined) {
      queryParameters.return_to = parameters.return_to;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#ZendeskLogin
   * @param {string} parameters.return_to -
   */
  ZendeskLogin(parameters: {
    return_to?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<void>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/login/zendesk";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.return_to !== undefined) {
        queryParameters.return_to = parameters.return_to;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetAuthorizedTouchpointsURL(
    parameters: {
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
      withUser?: number;
      include_interactions?: boolean;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (parameters.withUser !== undefined) {
      queryParameters.withUser = parameters.withUser;
    }

    if (parameters.include_interactions !== undefined) {
      queryParameters.include_interactions = parameters.include_interactions;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetAuthorizedTouchpoints
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   * @param {number} parameters.withUser -
   * @param {boolean} parameters.include_interactions -
   */
  GetAuthorizedTouchpoints(parameters: {
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    withUser?: number;
    include_interactions?: boolean;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<Touchpoint>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.withUser !== undefined) {
        queryParameters.withUser = parameters.withUser;
      }

      if (parameters.include_interactions !== undefined) {
        queryParameters.include_interactions = parameters.include_interactions;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PostTouchpointURL(
    parameters: {
      touchpoint: TouchpointBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PostTouchpoint
   * @param {} parameters.touchpoint -
   */
  PostTouchpoint(parameters: {
    touchpoint: TouchpointBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Touchpoint>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.touchpoint !== undefined) {
        body = parameters.touchpoint;
      }

      if (parameters.touchpoint === undefined) {
        reject(new Error("Missing required parameter: touchpoint"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetTouchpointsByGroupURL(
    parameters: {
      group_id: number;
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
      include_interactions?: boolean;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/group/{group_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{group_id}", `${parameters.group_id}`);
    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (parameters.include_interactions !== undefined) {
      queryParameters.include_interactions = parameters.include_interactions;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetTouchpointsByGroup
   * @param {number} parameters.group_id -
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   * @param {boolean} parameters.include_interactions -
   */
  GetTouchpointsByGroup(parameters: {
    group_id: number;
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    include_interactions?: boolean;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<Touchpoint>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/group/{group_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{group_id}", `${parameters.group_id}`);

      if (parameters.group_id === undefined) {
        reject(new Error("Missing required parameter: group_id"));
        return;
      }

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.include_interactions !== undefined) {
        queryParameters.include_interactions = parameters.include_interactions;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetTouchpointsByThreadURL(
    parameters: {
      thread_id: number;
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
      include_interactions?: boolean;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/thread/{thread_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{thread_id}", `${parameters.thread_id}`);
    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (parameters.include_interactions !== undefined) {
      queryParameters.include_interactions = parameters.include_interactions;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetTouchpointsByThread
   * @param {number} parameters.thread_id -
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   * @param {boolean} parameters.include_interactions -
   */
  GetTouchpointsByThread(parameters: {
    thread_id: number;
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    include_interactions?: boolean;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<Touchpoint>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/thread/{thread_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{thread_id}", `${parameters.thread_id}`);

      if (parameters.thread_id === undefined) {
        reject(new Error("Missing required parameter: thread_id"));
        return;
      }

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.include_interactions !== undefined) {
        queryParameters.include_interactions = parameters.include_interactions;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  SearchTouchpointsByParticipantsURL(
    parameters: {
      filter: string;
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
      include_interactions?: boolean;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/search";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.filter !== undefined) {
      queryParameters.filter = parameters.filter;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (parameters.include_interactions !== undefined) {
      queryParameters.include_interactions = parameters.include_interactions;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#SearchTouchpointsByParticipants
   * @param {string} parameters.filter -
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   * @param {boolean} parameters.include_interactions -
   */
  SearchTouchpointsByParticipants(parameters: {
    filter: string;
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    include_interactions?: boolean;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<Touchpoint>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/search";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.filter !== undefined) {
        queryParameters.filter = parameters.filter;
      }

      if (parameters.filter === undefined) {
        reject(new Error("Missing required parameter: filter"));
        return;
      }

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.include_interactions !== undefined) {
        queryParameters.include_interactions = parameters.include_interactions;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetTouchpointByIdURL(
    parameters: {
      touchpoint_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{touchpoint_id}", `${parameters.touchpoint_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetTouchpointById
   * @param {number} parameters.touchpoint_id -
   */
  GetTouchpointById(parameters: {
    touchpoint_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Touchpoint>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{touchpoint_id}", `${parameters.touchpoint_id}`);

      if (parameters.touchpoint_id === undefined) {
        reject(new Error("Missing required parameter: touchpoint_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  DeleteTouchpointURL(
    parameters: {
      id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#DeleteTouchpoint
   * @param {number} parameters.id -
   */
  DeleteTouchpoint(parameters: {
    id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<number>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "DELETE",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  UpdateTouchpointURL(
    parameters: {
      id: number;
      touchpoint: TouchpointBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#UpdateTouchpoint
   * @param {number} parameters.id -
   * @param {} parameters.touchpoint -
   */
  UpdateTouchpoint(parameters: {
    id: number;
    touchpoint: TouchpointBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Touchpoint>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.touchpoint !== undefined) {
        body = parameters.touchpoint;
      }

      if (parameters.touchpoint === undefined) {
        reject(new Error("Missing required parameter: touchpoint"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  UploadTouchpointPhotoURL(
    parameters: {
      touchpoint_id: number;
      photo_file: {};
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}/photo";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{touchpoint_id}", `${parameters.touchpoint_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#UploadTouchpointPhoto
   * @param {number} parameters.touchpoint_id -
   * @param {file} parameters.photo_file -
   */
  UploadTouchpointPhoto(parameters: {
    touchpoint_id: number;
    photo_file: {};
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Touchpoint>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}/photo";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{touchpoint_id}", `${parameters.touchpoint_id}`);

      if (parameters.touchpoint_id === undefined) {
        reject(new Error("Missing required parameter: touchpoint_id"));
        return;
      }

      if (parameters.photo_file !== undefined) {
        form.photo_file = parameters.photo_file;
      }

      if (parameters.photo_file === undefined) {
        reject(new Error("Missing required parameter: photo_file"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  DeleteTouchpointPhotoURL(
    parameters: {
      touchpoint_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}/photo";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{touchpoint_id}", `${parameters.touchpoint_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#DeleteTouchpointPhoto
   * @param {number} parameters.touchpoint_id -
   */
  DeleteTouchpointPhoto(parameters: {
    touchpoint_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Touchpoint>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}/photo";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{touchpoint_id}", `${parameters.touchpoint_id}`);

      if (parameters.touchpoint_id === undefined) {
        reject(new Error("Missing required parameter: touchpoint_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "DELETE",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PostTouchpointExpenseURL(
    parameters: {
      id: number;
      receipt_amount: number;
      total_amount_requested: number;
      expense_category: string;
      reimbursement_type: {};
      vendor_name: string;
      is_pre_approved: boolean;
      is_supplemental: boolean;
      receipt_image_file: {};
      description?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{id}/expense";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PostTouchpointExpense
   * @param {number} parameters.id -
   * @param {number} parameters.receipt_amount -
   * @param {number} parameters.total_amount_requested -
   * @param {string} parameters.expense_category -
   * @param {object} parameters.reimbursement_type -
   * @param {string} parameters.vendor_name -
   * @param {boolean} parameters.is_pre_approved -
   * @param {boolean} parameters.is_supplemental -
   * @param {file} parameters.receipt_image_file -
   * @param {string} parameters.description -
   */
  PostTouchpointExpense(parameters: {
    id: number;
    receipt_amount: number;
    total_amount_requested: number;
    expense_category: string;
    reimbursement_type: {};
    vendor_name: string;
    is_pre_approved: boolean;
    is_supplemental: boolean;
    receipt_image_file: {};
    description?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Expense>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{id}/expense";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.receipt_amount !== undefined) {
        form.receipt_amount = parameters.receipt_amount;
      }

      if (parameters.receipt_amount === undefined) {
        reject(new Error("Missing required parameter: receipt_amount"));
        return;
      }

      if (parameters.total_amount_requested !== undefined) {
        form.total_amount_requested = parameters.total_amount_requested;
      }

      if (parameters.total_amount_requested === undefined) {
        reject(new Error("Missing required parameter: total_amount_requested"));
        return;
      }

      if (parameters.expense_category !== undefined) {
        form.expense_category = parameters.expense_category;
      }

      if (parameters.expense_category === undefined) {
        reject(new Error("Missing required parameter: expense_category"));
        return;
      }

      if (parameters.reimbursement_type !== undefined) {
        form.reimbursement_type = parameters.reimbursement_type;
      }

      if (parameters.reimbursement_type === undefined) {
        reject(new Error("Missing required parameter: reimbursement_type"));
        return;
      }

      if (parameters.vendor_name !== undefined) {
        form.vendor_name = parameters.vendor_name;
      }

      if (parameters.vendor_name === undefined) {
        reject(new Error("Missing required parameter: vendor_name"));
        return;
      }

      if (parameters.is_pre_approved !== undefined) {
        form.is_pre_approved = parameters.is_pre_approved;
      }

      if (parameters.is_pre_approved === undefined) {
        reject(new Error("Missing required parameter: is_pre_approved"));
        return;
      }

      if (parameters.is_supplemental !== undefined) {
        form.is_supplemental = parameters.is_supplemental;
      }

      if (parameters.is_supplemental === undefined) {
        reject(new Error("Missing required parameter: is_supplemental"));
        return;
      }

      if (parameters.receipt_image_file !== undefined) {
        form.receipt_image_file = parameters.receipt_image_file;
      }

      if (parameters.receipt_image_file === undefined) {
        reject(new Error("Missing required parameter: receipt_image_file"));
        return;
      }

      if (parameters.description !== undefined) {
        form.description = parameters.description;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  UpdateTouchpointExpenseURL(
    parameters: {
      expense_id: number;
      receipt_amount: number;
      total_amount_requested: number;
      expense_category: string;
      reimbursement_type: {};
      vendor_name: string;
      is_pre_approved: boolean;
      is_supplemental: boolean;
      receipt_image_file?: {};
      description?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}/expense/{expense_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{expense_id}", `${parameters.expense_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#UpdateTouchpointExpense
   * @param {number} parameters.expense_id -
   * @param {number} parameters.receipt_amount -
   * @param {number} parameters.total_amount_requested -
   * @param {string} parameters.expense_category -
   * @param {object} parameters.reimbursement_type -
   * @param {string} parameters.vendor_name -
   * @param {boolean} parameters.is_pre_approved -
   * @param {boolean} parameters.is_supplemental -
   * @param {file} parameters.receipt_image_file -
   * @param {string} parameters.description -
   */
  UpdateTouchpointExpense(parameters: {
    expense_id: number;
    receipt_amount: number;
    total_amount_requested: number;
    expense_category: string;
    reimbursement_type: {};
    vendor_name: string;
    is_pre_approved: boolean;
    is_supplemental: boolean;
    receipt_image_file?: {};
    description?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Expense>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}/expense/{expense_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{expense_id}", `${parameters.expense_id}`);

      if (parameters.expense_id === undefined) {
        reject(new Error("Missing required parameter: expense_id"));
        return;
      }

      if (parameters.receipt_amount !== undefined) {
        form.receipt_amount = parameters.receipt_amount;
      }

      if (parameters.receipt_amount === undefined) {
        reject(new Error("Missing required parameter: receipt_amount"));
        return;
      }

      if (parameters.total_amount_requested !== undefined) {
        form.total_amount_requested = parameters.total_amount_requested;
      }

      if (parameters.total_amount_requested === undefined) {
        reject(new Error("Missing required parameter: total_amount_requested"));
        return;
      }

      if (parameters.expense_category !== undefined) {
        form.expense_category = parameters.expense_category;
      }

      if (parameters.expense_category === undefined) {
        reject(new Error("Missing required parameter: expense_category"));
        return;
      }

      if (parameters.reimbursement_type !== undefined) {
        form.reimbursement_type = parameters.reimbursement_type;
      }

      if (parameters.reimbursement_type === undefined) {
        reject(new Error("Missing required parameter: reimbursement_type"));
        return;
      }

      if (parameters.vendor_name !== undefined) {
        form.vendor_name = parameters.vendor_name;
      }

      if (parameters.vendor_name === undefined) {
        reject(new Error("Missing required parameter: vendor_name"));
        return;
      }

      if (parameters.is_pre_approved !== undefined) {
        form.is_pre_approved = parameters.is_pre_approved;
      }

      if (parameters.is_pre_approved === undefined) {
        reject(new Error("Missing required parameter: is_pre_approved"));
        return;
      }

      if (parameters.is_supplemental !== undefined) {
        form.is_supplemental = parameters.is_supplemental;
      }

      if (parameters.is_supplemental === undefined) {
        reject(new Error("Missing required parameter: is_supplemental"));
        return;
      }

      if (parameters.receipt_image_file !== undefined) {
        form.receipt_image_file = parameters.receipt_image_file;
      }

      if (parameters.description !== undefined) {
        form.description = parameters.description;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  DeleteExpenseURL(
    parameters: {
      expense_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}/expense/{expense_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{expense_id}", `${parameters.expense_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#DeleteExpense
   * @param {number} parameters.expense_id -
   */
  DeleteExpense(parameters: {
    expense_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<number>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/touchpoint/{touchpoint_id}/expense/{expense_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{expense_id}", `${parameters.expense_id}`);

      if (parameters.expense_id === undefined) {
        reject(new Error("Missing required parameter: expense_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "DELETE",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetConversationsURL(
    parameters: {
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetConversations
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   */
  GetConversations(parameters: {
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<Conversation>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  CreateConversationURL(
    parameters: {
      conversation: ConversationBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#CreateConversation
   * @param {} parameters.conversation -
   */
  CreateConversation(parameters: {
    conversation: ConversationBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Conversation>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.conversation !== undefined) {
        body = parameters.conversation;
      }

      if (parameters.conversation === undefined) {
        reject(new Error("Missing required parameter: conversation"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetConversationByIdURL(
    parameters: {
      id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetConversationById
   * @param {number} parameters.id -
   */
  GetConversationById(parameters: {
    id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Conversation>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  UploadVideoURL(
    parameters: {
      video: {};
      uuid: string;
      conversation_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/video";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#UploadVideo
   * @param {file} parameters.video -
   * @param {string} parameters.uuid -
   * @param {number} parameters.conversation_id -
   */
  UploadVideo(parameters: {
    video: {};
    uuid: string;
    conversation_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Video>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/video";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters.video !== undefined) {
        form.video = parameters.video;
      }

      if (parameters.video === undefined) {
        reject(new Error("Missing required parameter: video"));
        return;
      }

      if (parameters.uuid !== undefined) {
        form.uuid = parameters.uuid;
      }

      if (parameters.uuid === undefined) {
        reject(new Error("Missing required parameter: uuid"));
        return;
      }

      path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

      if (parameters.conversation_id === undefined) {
        reject(new Error("Missing required parameter: conversation_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  MarkVideoAsWatchedURL(
    parameters: {
      id: number;
      conversation_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/video/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#MarkVideoAsWatched
   * @param {number} parameters.id -
   * @param {number} parameters.conversation_id -
   */
  MarkVideoAsWatched(parameters: {
    id: number;
    conversation_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<ArrayResponse<Video>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/video/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

      if (parameters.conversation_id === undefined) {
        reject(new Error("Missing required parameter: conversation_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  MarkVideosAsWatchedURL(
    parameters: {
      body: {};
      conversation_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/video/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#MarkVideosAsWatched
   * @param {} parameters.body -
   * @param {number} parameters.conversation_id -
   */
  MarkVideosAsWatched(parameters: {
    body: {};
    conversation_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<ArrayResponse<Video>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/video/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.body !== undefined) {
        body = parameters.body;
      }

      if (parameters.body === undefined) {
        reject(new Error("Missing required parameter: body"));
        return;
      }

      path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

      if (parameters.conversation_id === undefined) {
        reject(new Error("Missing required parameter: conversation_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  StartIntroGuidedEngagementURL(
    parameters: {
      conversation_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/startIntroGuidedEngagement";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#StartIntroGuidedEngagement
   * @param {number} parameters.conversation_id -
   */
  StartIntroGuidedEngagement(parameters: {
    conversation_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Conversation>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/startIntroGuidedEngagement";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

      if (parameters.conversation_id === undefined) {
        reject(new Error("Missing required parameter: conversation_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  EndIntroGuidedEngagementURL(
    parameters: {
      conversation_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/endIntroGuidedEngagement";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#EndIntroGuidedEngagement
   * @param {number} parameters.conversation_id -
   */
  EndIntroGuidedEngagement(parameters: {
    conversation_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Conversation>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/endIntroGuidedEngagement";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

      if (parameters.conversation_id === undefined) {
        reject(new Error("Missing required parameter: conversation_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "DELETE",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  NextIntroGuidedEngagementURL(
    parameters: {
      conversation_id: number;
      current_question_number: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path =
      "/conversation/{conversation_id}/nextIntroGuidedEngagement/{current_question_number}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

    path = path.replace(
      "{current_question_number}",
      `${parameters.current_question_number}`
    );

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#NextIntroGuidedEngagement
   * @param {number} parameters.conversation_id -
   * @param {number} parameters.current_question_number -
   */
  NextIntroGuidedEngagement(parameters: {
    conversation_id: number;
    current_question_number: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Conversation>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path =
      "/conversation/{conversation_id}/nextIntroGuidedEngagement/{current_question_number}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

      if (parameters.conversation_id === undefined) {
        reject(new Error("Missing required parameter: conversation_id"));
        return;
      }

      path = path.replace(
        "{current_question_number}",
        `${parameters.current_question_number}`
      );

      if (parameters.current_question_number === undefined) {
        reject(
          new Error("Missing required parameter: current_question_number")
        );
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  NudgeIntroGuidedEngagementURL(
    parameters: {
      conversation_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/nudgeIntroGuidedEngagement";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#NudgeIntroGuidedEngagement
   * @param {number} parameters.conversation_id -
   */
  NudgeIntroGuidedEngagement(parameters: {
    conversation_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Conversation>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/conversation/{conversation_id}/nudgeIntroGuidedEngagement";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{conversation_id}", `${parameters.conversation_id}`);

      if (parameters.conversation_id === undefined) {
        reject(new Error("Missing required parameter: conversation_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetPeopleURL(
    parameters: {
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetPeople
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   */
  GetPeople(parameters: {
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<User>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  RequestConnectedUserURL(
    parameters: {
      connected_user: ConnectedUsersBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#RequestConnectedUser
   * @param {} parameters.connected_user -
   */
  RequestConnectedUser(parameters: {
    connected_user: ConnectedUsersBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<ConnectionRequestUsers>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.connected_user !== undefined) {
        body = parameters.connected_user;
      }

      if (parameters.connected_user === undefined) {
        reject(new Error("Missing required parameter: connected_user"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  SearchPeopleURL(
    parameters: {
      filter: string;
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/search";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.filter !== undefined) {
      queryParameters.filter = parameters.filter;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#SearchPeople
   * @param {string} parameters.filter -
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   */
  SearchPeople(parameters: {
    filter: string;
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<UserWithConnectionStatus>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/search";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.filter !== undefined) {
        queryParameters.filter = parameters.filter;
      }

      if (parameters.filter === undefined) {
        reject(new Error("Missing required parameter: filter"));
        return;
      }

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetPendingConnectionsURL(
    parameters: {
      page: number;
      pageSize: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/pending";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetPendingConnections
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   */
  GetPendingConnections(parameters: {
    page: number;
    pageSize: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<ConnectionRequestUsers>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/pending";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  ResolveConnectionRequestURL(
    parameters: {
      connection_request_id: number;
      resolve_connection_request: ResolveConnectionRequestBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/{connection_request_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace(
      "{connection_request_id}",
      `${parameters.connection_request_id}`
    );

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#ResolveConnectionRequest
   * @param {number} parameters.connection_request_id -
   * @param {} parameters.resolve_connection_request -
   */
  ResolveConnectionRequest(parameters: {
    connection_request_id: number;
    resolve_connection_request: ResolveConnectionRequestBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<ConnectionRequestRecord>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/{connection_request_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace(
        "{connection_request_id}",
        `${parameters.connection_request_id}`
      );

      if (parameters.connection_request_id === undefined) {
        reject(new Error("Missing required parameter: connection_request_id"));
        return;
      }

      if (parameters.resolve_connection_request !== undefined) {
        body = parameters.resolve_connection_request;
      }

      if (parameters.resolve_connection_request === undefined) {
        reject(
          new Error("Missing required parameter: resolve_connection_request")
        );
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  AdminAddConnectedUserURL(
    parameters: {
      users_to_connect: UsersToConnectBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/adminAddConnectedUser";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#AdminAddConnectedUser
   * @param {} parameters.users_to_connect -
   */
  AdminAddConnectedUser(parameters: {
    users_to_connect: UsersToConnectBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<ArrayResponse<User>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/people/adminAddConnectedUser";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.users_to_connect !== undefined) {
        body = parameters.users_to_connect;
      }

      if (parameters.users_to_connect === undefined) {
        reject(new Error("Missing required parameter: users_to_connect"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PingURL(parameters: {} & CommonRequestOptions): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/ping/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#Ping
   */
  Ping(parameters: {
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Ping>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/ping/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetUnprocessedActionsForUserURL(
    parameters: {} & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/action/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetUnprocessedActionsForUser
   */
  GetUnprocessedActionsForUser(parameters: {
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<ArrayResponse<Action>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/action/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetActionByIdURL(
    parameters: {
      action_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/action/{action_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{action_id}", `${parameters.action_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetActionById
   * @param {number} parameters.action_id -
   */
  GetActionById(parameters: {
    action_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Action>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/action/{action_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{action_id}", `${parameters.action_id}`);

      if (parameters.action_id === undefined) {
        reject(new Error("Missing required parameter: action_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  DismissActionURL(
    parameters: {
      action_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/action/{action_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{action_id}", `${parameters.action_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#DismissAction
   * @param {number} parameters.action_id -
   */
  DismissAction(parameters: {
    action_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Action>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/action/{action_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{action_id}", `${parameters.action_id}`);

      if (parameters.action_id === undefined) {
        reject(new Error("Missing required parameter: action_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "DELETE",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  SubmitActionResponseURL(
    parameters: {
      action_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/action/{action_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{action_id}", `${parameters.action_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#SubmitActionResponse
   * @param {number} parameters.action_id -
   */
  SubmitActionResponse(parameters: {
    action_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Action>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/action/{action_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      path = path.replace("{action_id}", `${parameters.action_id}`);

      if (parameters.action_id === undefined) {
        reject(new Error("Missing required parameter: action_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "PUT",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetGroupsByUserURL(
    parameters: {
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/group/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetGroupsByUser
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   */
  GetGroupsByUser(parameters: {
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<Group>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/group/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetGroupsWithScoresByUserURL(
    parameters: {
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/group/scores";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetGroupsWithScoresByUser
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   */
  GetGroupsWithScoresByUser(parameters: {
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<Score>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/group/scores";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetGroupByIdURL(
    parameters: {
      group_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/group/{group_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{group_id}", `${parameters.group_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetGroupById
   * @param {number} parameters.group_id -
   */
  GetGroupById(parameters: {
    group_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Group>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/group/{group_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{group_id}", `${parameters.group_id}`);

      if (parameters.group_id === undefined) {
        reject(new Error("Missing required parameter: group_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetInteractionsByUserURL(
    parameters: {
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
      isAssociated?: boolean;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/interaction/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (parameters.isAssociated !== undefined) {
      queryParameters.isAssociated = parameters.isAssociated;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetInteractionsByUser
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   * @param {boolean} parameters.isAssociated - if true, returns associated interactions; if false, returns unassociated; if null/undefined, returns all interactions
   */
  GetInteractionsByUser(parameters: {
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    isAssociated?: boolean;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<Interaction>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/interaction/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.isAssociated !== undefined) {
        queryParameters.isAssociated = parameters.isAssociated;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PostInteractionURL(
    parameters: {
      interaction: InteractionBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/interaction/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PostInteraction
   * @param {} parameters.interaction -
   */
  PostInteraction(parameters: {
    interaction: InteractionBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Interaction>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/interaction/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.interaction !== undefined) {
        body = parameters.interaction;
      }

      if (parameters.interaction === undefined) {
        reject(new Error("Missing required parameter: interaction"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetAllCommunitiesURL(parameters: {} & CommonRequestOptions): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetAllCommunities
   */
  GetAllCommunities(parameters: {
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<ArrayResponse<NetworkCommunityObject>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetCommunityByIdURL(
    parameters: {
      community_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{community_id}", `${parameters.community_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetCommunityById
   * @param {number} parameters.community_id -
   */
  GetCommunityById(parameters: {
    community_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<NetworkCommunityObject>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{community_id}", `${parameters.community_id}`);

      if (parameters.community_id === undefined) {
        reject(new Error("Missing required parameter: community_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetThreadsByCommunityURL(
    parameters: {
      community_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}/thread";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{community_id}", `${parameters.community_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetThreadsByCommunity
   * @param {number} parameters.community_id -
   */
  GetThreadsByCommunity(parameters: {
    community_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<NetworkThreadsObject>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}/thread";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{community_id}", `${parameters.community_id}`);

      if (parameters.community_id === undefined) {
        reject(new Error("Missing required parameter: community_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetThreadByIdURL(
    parameters: {
      thread_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}/thread/{thread_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{thread_id}", `${parameters.thread_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetThreadById
   * @param {number} parameters.thread_id -
   */
  GetThreadById(parameters: {
    thread_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<NetworkThread>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}/thread/{thread_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{thread_id}", `${parameters.thread_id}`);

      if (parameters.thread_id === undefined) {
        reject(new Error("Missing required parameter: thread_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetGroupsByThreadURL(
    parameters: {
      thread_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}/thread/{thread_id}/group";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{thread_id}", `${parameters.thread_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetGroupsByThread
   * @param {number} parameters.thread_id -
   */
  GetGroupsByThread(parameters: {
    thread_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<NetworkGroupsObject>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}/thread/{thread_id}/group";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{thread_id}", `${parameters.thread_id}`);

      if (parameters.thread_id === undefined) {
        reject(new Error("Missing required parameter: thread_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetGroupWithScoresURL(
    parameters: {
      group_id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}/thread/{thread_id}/group/{group_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{group_id}", `${parameters.group_id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetGroupWithScores
   * @param {number} parameters.group_id -
   */
  GetGroupWithScores(parameters: {
    group_id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Score>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/community/{community_id}/thread/{thread_id}/group/{group_id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{group_id}", `${parameters.group_id}`);

      if (parameters.group_id === undefined) {
        reject(new Error("Missing required parameter: group_id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PostWeeklyCheckinURL(
    parameters: {
      weekly_checkin: WeeklyCheckinBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/weeklyCheckin/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PostWeeklyCheckin
   * @param {} parameters.weekly_checkin -
   */
  PostWeeklyCheckin(parameters: {
    weekly_checkin: WeeklyCheckinBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<Interaction>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/weeklyCheckin/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.weekly_checkin !== undefined) {
        body = parameters.weekly_checkin;
      }

      if (parameters.weekly_checkin === undefined) {
        reject(new Error("Missing required parameter: weekly_checkin"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetCommentsByTouchpointIdURL(
    parameters: {
      touchpoint_id: number;
      page: number;
      pageSize: number;
      sortBy?: string;
      sortDir?: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/comment/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (parameters.touchpoint_id !== undefined) {
      queryParameters.touchpoint_id = parameters.touchpoint_id;
    }

    if (parameters.page !== undefined) {
      queryParameters.page = parameters.page;
    }

    if (parameters.pageSize !== undefined) {
      queryParameters.pageSize = parameters.pageSize;
    }

    if (parameters.sortBy !== undefined) {
      queryParameters.sortBy = parameters.sortBy;
    }

    if (parameters.sortDir !== undefined) {
      queryParameters.sortDir = parameters.sortDir;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   * Returns an array of comments for the specified touchpoint_id
   * @method
   * @name Thrive#GetCommentsByTouchpointId
   * @param {number} parameters.touchpoint_id -
   * @param {number} parameters.page -
   * @param {number} parameters.pageSize -
   * @param {string} parameters.sortBy -
   * @param {string} parameters.sortDir -
   */
  GetCommentsByTouchpointId(parameters: {
    touchpoint_id: number;
    page: number;
    pageSize: number;
    sortBy?: string;
    sortDir?: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<PagedArrayResponse<TouchpointComment>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/comment/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.touchpoint_id !== undefined) {
        queryParameters.touchpoint_id = parameters.touchpoint_id;
      }

      if (parameters.touchpoint_id === undefined) {
        reject(new Error("Missing required parameter: touchpoint_id"));
        return;
      }

      if (parameters.page !== undefined) {
        queryParameters.page = parameters.page;
      }

      if (parameters.page === undefined) {
        reject(new Error("Missing required parameter: page"));
        return;
      }

      if (parameters.pageSize !== undefined) {
        queryParameters.pageSize = parameters.pageSize;
      }

      if (parameters.pageSize === undefined) {
        reject(new Error("Missing required parameter: pageSize"));
        return;
      }

      if (parameters.sortBy !== undefined) {
        queryParameters.sortBy = parameters.sortBy;
      }

      if (parameters.sortDir !== undefined) {
        queryParameters.sortDir = parameters.sortDir;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PostCommentURL(
    parameters: {
      comment: TouchpointCommentBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/comment/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PostComment
   * @param {} parameters.comment -
   */
  PostComment(parameters: {
    comment: TouchpointCommentBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<TouchpointComment>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/comment/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.comment !== undefined) {
        body = parameters.comment;
      }

      if (parameters.comment === undefined) {
        reject(new Error("Missing required parameter: comment"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetCommentByIdURL(
    parameters: {
      id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/comment/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   * Returns a comment for the specified comment id
   * @method
   * @name Thrive#GetCommentById
   * @param {number} parameters.id -
   */
  GetCommentById(parameters: {
    id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<TouchpointComment>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/comment/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PostEmojiResponseURL(
    parameters: {
      emoji_response: EmojiResponseBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/emojiResponse/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PostEmojiResponse
   * @param {} parameters.emoji_response -
   */
  PostEmojiResponse(parameters: {
    emoji_response: EmojiResponseBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<EmojiResponse>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/emojiResponse/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.emoji_response !== undefined) {
        body = parameters.emoji_response;
      }

      if (parameters.emoji_response === undefined) {
        reject(new Error("Missing required parameter: emoji_response"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  DeleteEmojiResponseURL(
    parameters: {
      id: number;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/emojiResponse/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    path = path.replace("{id}", `${parameters.id}`);

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#DeleteEmojiResponse
   * @param {number} parameters.id -
   */
  DeleteEmojiResponse(parameters: {
    id: number;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<EmojiResponse>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/emojiResponse/{id}";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      path = path.replace("{id}", `${parameters.id}`);

      if (parameters.id === undefined) {
        reject(new Error("Missing required parameter: id"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "DELETE",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  PostAppActivityURL(
    parameters: {
      app_activity: AppActivityBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/app_activity/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#PostAppActivity
   * @param {} parameters.app_activity -
   */
  PostAppActivity(parameters: {
    app_activity: AppActivityBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<AppActivity>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/app_activity/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.app_activity !== undefined) {
        body = parameters.app_activity;
      }

      if (parameters.app_activity === undefined) {
        reject(new Error("Missing required parameter: app_activity"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  GetBadgeCountsURL(parameters: {} & CommonRequestOptions): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/badges/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#GetBadgeCounts
   */
  GetBadgeCounts(parameters: {
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<BadgeCounts>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/badges/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        "GET",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  ZendeskPushNotifyURL(
    parameters: {
      zendesk_push_notify_body: ZendeskPushNotifyBody;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/zendesk_push_notify/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#ZendeskPushNotify
   * @param {} parameters.zendesk_push_notify_body -
   */
  ZendeskPushNotify(parameters: {
    zendesk_push_notify_body: ZendeskPushNotifyBody;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<StdResponse<ZendeskPushNotifyResponse>>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/zendesk_push_notify/";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      if (parameters.zendesk_push_notify_body !== undefined) {
        body = parameters.zendesk_push_notify_body;
      }

      if (parameters.zendesk_push_notify_body === undefined) {
        reject(
          new Error("Missing required parameter: zendesk_push_notify_body")
        );
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  AuthenticateZendeskURL(
    parameters: {
      user_token: string;
    } & CommonRequestOptions
  ): string {
    let queryParameters: any = {};
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/zendeskAuth";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    if (
      parameters.$queryParameters !== undefined &&
      parameters.$queryParameters !== null
    ) {
      queryParameters = {
        ...queryParameters,
        ...parameters.$queryParameters
      };
    }

    queryParameters = {};

    let keys = Object.keys(queryParameters);
    return (
      domain +
      path +
      (keys.length > 0
        ? "?" +
          keys
            .map(key => key + "=" + encodeURIComponent(queryParameters[key]))
            .join("&")
        : "")
    );
  }

  /**
   *
   * @method
   * @name Thrive#AuthenticateZendesk
   * @param {string} parameters.user_token -
   */
  AuthenticateZendesk(parameters: {
    user_token: string;
    $queryParameters?: any;
    $domain?: string;
    $path?: string | ((path: string) => string);
  }): Promise<ResponseWithBody<ZendeskAuthResponse>> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = "/zendeskAuth";
    if (parameters.$path) {
      path =
        typeof parameters.$path === "function"
          ? parameters.$path(path)
          : parameters.$path;
    }

    let body: any;
    let queryParameters: any = {};
    let headers: any = {};
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/x-www-form-urlencoded";

      if (parameters.user_token !== undefined) {
        form.user_token = parameters.user_token;
      }

      if (parameters.user_token === undefined) {
        reject(new Error("Missing required parameter: user_token"));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function(
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        "POST",
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }
}

export default Thrive;
