import * as React from "react";
import { class_names } from "../../utils/dom-helpers";

export const FieldError: React.FC<{
  name?: string;
  hasError: boolean;
  formSubmitted?: boolean;
}> = ({ formSubmitted, hasError, name, children }) => (
  <div
    data-error={hasError}
    data-submitted={formSubmitted}
    className={class_names({ [`__${name}`]: !!name }, "field-error")}
  >
    {children}
  </div>
);
FieldError.displayName = "FieldError";

export const FieldErrors: React.FC<{
  field?: {
    name: string;
    label: string;
  };
  formSubmitted?: boolean;
  errors: string[];
}> = ({ errors, field, formSubmitted }) => {
  const errs = field
    ? errors
        .filter(e => e.indexOf(field.name) !== -1)
        .map(e => e.replace(field.name, field.label))
    : errors;
  return errors.length > 0 ? (
    <div
      className={class_names(
        { [`__${field ? field.name : ""}`]: field && !!field.name },
        "field__errors"
      )}
    >
      {errs.map((e, i) => (
        <FieldError
          name={field ? field.name : undefined}
          formSubmitted={formSubmitted}
          key={i}
          hasError={true}
        >
          {e}
        </FieldError>
      ))}
    </div>
  ) : null;
};
FieldErrors.displayName = "FieldErrors";

export const FormErrors = <T extends ApiMethod>({
  response: res,
  field,
  formSubmitted
}: {
  response: ErrorResponse | undefined;
  field?: {
    name: keyof ApiResponseModel<T>;
    label: string;
  };
  formSubmitted?: boolean;
  children?: React.ReactElement;
}): React.ReactElement | null => {
  return res ? (
    <section className="form-section__errors">
      {res.errors.length > 0 ? (
        <FieldErrors
          errors={res.errors}
          formSubmitted={formSubmitted}
          //@ts-ignore
          field={field}
        />
      ) : (
        <div className="form-section__errors__message">
          Something went wrong. (Status Code {res.status || "Unknown"})
        </div>
      )}
    </section>
  ) : null;
};

FormErrors.displayName = "FormErrors";
