import * as React from "react";
import { ActionTypeIcon } from "./ActionTypeIcon";
import { format_time_passed } from "../utils";
import { ScreenSize } from "../../constants";
import { Icon } from "./Icon";
import { EVENTS } from "../../model";
import { HealthRaterModalSpec } from "./HealthRaterModal";
import { ActionButton } from "./common/action-link";
import { makeConnectionRequestResponseModal } from "../pages/People/ConnectionRequestResponseModal";
import { UserAvatar } from "./Avatar";
import { path_or } from "../../utils/common";

export const NotificationCenterItem: React.FC<{
  item: Action;
  last: boolean;
  window_size: ScreenSize;
}> = ({ item, last }) => {
  const requestor = path_or(undefined, ["metadata", "requesting_user"], item);
  const icon =
    // @ts-ignore
    item.job_type === "connection_request" && requestor ? (
      // @ts-ignore
      <UserAvatar user={item.metadata.requesting_user} size="sm" />
    ) : (
      <ActionTypeIcon
        className="notif-menu__item__icon"
        type={item.job_type as JobType}
      />
    );

  const children = (
    <div className="notif-menu__item__padding">
      <div className="touchpoint__left">
        {icon}
        <div className="touchpoint__description">
          <div className="touchpoint__headline">{item.action_text}</div>
          <div className="touchpoint__time">
            {format_time_passed(new Date(item.time_to_send || item.created_at))}
          </div>
        </div>
      </div>
      <div className="touchpoint__right">
        {/* TODO: show only if unread */}
        <Icon name="unreads" className="notif-menu__icon" />
      </div>
    </div>
  );

  const container_props = {
    className: "record-list__item touchpoint-list__item notif-menu__item",
    "data-last": last,
    children
  };

  switch (item.job_type) {
    case "daily_followup":
      return <a href="/activity/create" {...container_props} />;
    case "daily_health_rating":
      return (
        <ActionButton
          event={[EVENTS.OPEN_APP_MODAL, HealthRaterModalSpec]}
          {...container_props}
        />
      );
    case "loneliness_rating":
      return (
        <ActionButton
          event={[
            EVENTS.SET_APP_LOCATION,
            {
              place: "loneliness-rater",
              action: item as ActionOfType<"loneliness_rating">
            }
          ]}
          {...container_props}
        />
      );
    case "weekly_check_in":
      return (
        <ActionButton
          event={[
            EVENTS.SET_APP_LOCATION,
            {
              place: "weekly-check-in",
              id: item.id,
              action: item as ActionOfType<"weekly_check_in">
            }
          ]}
          {...container_props}
        />
      );
    // @ts-ignore
    case "connection_request":
      return (
        <ActionButton
          event={[
            EVENTS.OPEN_APP_MODAL,
            {
              title: "Connection Request",
              body: makeConnectionRequestResponseModal(item),
              className: "response__modal"
            }
          ]}
          {...container_props}
        />
      );
    default:
      return null;
  }
};
NotificationCenterItem.displayName = "NotificationCenterItem";
