import * as React from "react";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import { WithApiRequest } from "../../components/WithApiRequest";
import { get_user_full_name } from "../../utils";
import { Avatar } from "../../components/Avatar";
import { ListView, makeListView } from "../../components/ListView";
import { EmptyList } from "../../components/EmptyList";
import { DetailPageLink } from "../../components/DetailPageLink";
import { connect, EVENTS } from "../../../model";
import {
  ActionLinkWithIcon,
  LinkWithIcon
} from "../../components/common/button-with-icon";
import { UserFamilyList } from "./FamilyList";
import { Icon } from "../../components/Icon";
import { deep_equals, path_or } from "../../../utils/common";
import { CreateConnectionModal } from "./CreateModal";
import { DropdownMenu } from "../../components/common/dropdown-menu";
import { class_names } from "../../../utils/dom-helpers";
import { SearchBarCollapsible } from "../../components/SearchBarCollapsible";
import { DEFAULT_API_QUERY } from "../../../constants";

export const PeopleListViewItem: React.FC<{
  item: User;
  firstOfPage?: number;
}> = ({ item, firstOfPage, children }) => {
  const name = get_user_full_name(item);
  const health =
    item.health_rating != null
      ? {
          rating: item.health_rating,
          date: item.health_rating_created_at
        }
      : undefined;
  return (
    <DetailPageLink
      place="users"
      record={item}
      className="record-list__item people-list__item"
      data-page-marker={firstOfPage}
    >
      <div className="people-list__item__left">
        <Avatar
          url={item.profile_photo_url}
          name={name}
          size="md"
          health={health}
        />
        <div className="user__name">{name}</div>
      </div>
      {children}
    </DetailPageLink>
  );
};

class PeopleList extends ListView<
  "SearchPeople" | "GetPeople",
  WithAppLocationAt<"people"> & Context<"user">,
  { requestType: "SearchPeople" | "GetPeople"; search_focused: boolean }
> {
  constructor(props) {
    super(props);
    const query = {
      pageSize: 10,
      ...path_or({}, ["app_location", "query"], props),
      page: 0,
      sortBy: "last_name",
      sortDir: "asc"
    };
    this.state = {
      requestType: (query.filter || "").trim() ? "SearchPeople" : "GetPeople",
      // @ts-ignore,
      query,
      search_focused: false
    };
    this.PeopleListViewItem.displayName = "PeopleListViewItem-WithButtons";
  }

  componentDidUpdate(prevProps, prevState) {
    if (!("app_location" in this.props)) {
      return;
    }
    // if the app_location query changes (and nothing else), and it doesn't
    // match the query in state
    if (
      deep_equals(
        {
          ...prevProps.app_location,
          record: null,
          form_dirty: null,
          query: null
        },
        {
          // @ts-ignore
          ...this.props.app_location,
          record: null,
          form_dirty: null,
          query: null
        }
      ) &&
      !deep_equals(
        prevProps.app_location.query,
        // @ts-ignore
        this.props.app_location.query
      ) &&
      deep_equals(prevState.query, this.state.query) &&
      // @ts-ignore
      !deep_equals(this.props.app_location.query, this.state.query)
    ) {
      const value = `${path_or(
        "",
        ["app_location", "query", "filter"],
        this.props
      )}`.trim();
      this.setState({
        // @ts-ignore
        query: this.props.app_location.query || DEFAULT_API_QUERY,
        requestType: !!value ? "SearchPeople" : "GetPeople"
      });
    }
  }

  onChangeFilter = (query: ApiQuery<UserWithConnectionStatus>) => {
    const value = (query.filter || "").trim();
    this.setState({
      requestType: !!value ? "SearchPeople" : "GetPeople",
      query: {
        ...this.state.query,
        page: value !== this.state.query.filter ? 0 : this.state.query.page,
        filter: `${query.filter || ""}`.trimLeft().replace(/\s+/g, " ")
      }
    });
  };

  PeopleListViewItem: React.FC<{
    item: User | UserWithConnectionStatus;
    firstOfPage?: number;
  }> = ({ item, firstOfPage, children }) => {
    const email_button = (
      <LinkWithIcon
        href={`mailto:${item.email}`}
        icon={<Icon name="email" />}
        className="filled"
      >
        Email
      </LinkWithIcon>
    );

    const tp_button = (
      <ActionLinkWithIcon
        className="filled"
        icon={<Icon name="logging" />}
        event={this.createTouchpointAction(item)}
        onClick={e => {
          e.stopPropagation();
          return false;
        }}
      >
        Log a Touchpoint
      </ActionLinkWithIcon>
    );
    const buttons = [email_button, tp_button];

    if ("is_connected" in item && !item.is_connected) {
      if (item["is_pending_request"]) {
        buttons.push(
          <div className="dropdown-menu__item__disabled icon-button">
            <div className="icon-button__icon">
              <Icon name="connection-request" />
            </div>
            <span className="icon-button__text">
              Connection Request Pending
            </span>
          </div>
        );
      } else {
        buttons.push(
          <ActionLinkWithIcon
            icon={<Icon name="connection-request" />}
            event={this.connectionsModalAction(item)}
            onClick={e => {
              e.stopPropagation();
              return false;
            }}
          >
            Request Connection
          </ActionLinkWithIcon>
        );
      }
    }

    return (
      <PeopleListViewItem firstOfPage={firstOfPage} item={item}>
        <div className="people-list__item__right">
          {!!this.props.user && item.id !== this.props.user.id && (
            <>
              <div className="people-list__item__actions">
                <div className="people-list__item__actions__label">Actions</div>
                <div className="people-list__item__actions__buttons">
                  {email_button}
                  {tp_button}
                </div>
              </div>
              <DropdownMenu
                className="actions-menu"
                button={<Icon name="more" />}
                items={buttons}
              />
            </>
          )}
        </div>
        {children}
      </PeopleListViewItem>
    );
  };

  createTouchpointAction = user =>
    [
      EVENTS.GO_TO_PAGE,
      {
        place: "activity",
        id: "create",
        // @ts-ignore,
        record: {
          participants: [user]
        }
      }
    ] as AppEvent<"ev_go_to_page">;

  peopleListView = makeListView(
    "PeopleListView",
    this.nextPage,
    this.PeopleListViewItem,
    "Load More People",
    <EmptyList icon={<Icon name="groups" />}>No matches found.</EmptyList>
  );

  connectionsModalAction = (
    user: User | UserWithConnectionStatus
  ): AppEvent<AppEventName> => [
    EVENTS.OPEN_APP_MODAL,
    {
      title: "Confirm Connection Request",
      body: (props: ModalBodyProps) => (
        <CreateConnectionModal {...props} user={user} />
      ),
      className: "request__modal",
      success: {
        keepOpen: false,
        onClick: () => {}
      }
    }
  ];

  onFocusSearch = (search_focused: boolean) => e => {
    this.setState({ search_focused });
  };

  getRequestId = () => "";

  getPayload = (): ApiQuery<User | UserWithConnectionStatus> => {
    const { filter, sortDir, sortBy, ...other } = this.state.query;
    if (filter) {
      // @ts-ignore
      return { filter, ...other } as ApiQuery<UserWithConnectionStatus>;
    } else {
      return { sortDir, sortBy, ...other } as ApiQuery<User>;
    }
  };

  render() {
    const { user } = this.props;
    const { query, search_focused, requestType } = this.state;
    const pending = path_or(
      false,
      ["request", this.getRequestId(), "pending"],
      this.props
    );
    const { filter } = query;
    return (
      <PageContent
        id="people-list"
        className={`list-page page-with-search-bar${
          search_focused ? " search-focused" : ""
        }`}
        bodyType="full-width-block"
        pageHeader={
          <PageHeader
            title="People"
            icon={<Icon name="people" />}
            button={
              <SearchBarCollapsible
                query={query}
                pending={!!filter && pending}
                onSubmit={this.onChangeFilter}
                onFocus={this.onFocusSearch(true)}
                onBlur={this.onFocusSearch(false)}
              />
            }
          />
        }
      >
        <section
          className={`people-list__section ${class_names(
            { "--hide": requestType === "SearchPeople" },
            "family-list__container"
          )}`}
        >
          <h3 className="people-list__section__title">Families</h3>
          <UserFamilyList user={user} />
        </section>

        <section
          className="people-list__section people-list-fade-in"
          data-search={requestType === "SearchPeople"}
        >
          <h3 className="people-list__section__title">
            {requestType === "SearchPeople" ? "Search Results" : "Friends"}
          </h3>
          <WithApiRequest
            id={"PeopleList"}
            payload={{
              // @ts-ignore,
              method: this.state.requestType,
              parameters: this.getPayload()
            }}
            View={this.peopleListView}
            refresh={func => {
              this.forceUpdate();
              this.refreshList = func;
            }}
            getRequestId={func => {
              this.getRequestId = func;
            }}
          />
        </section>
      </PageContent>
    );
  }
}

export const PeopleListConnected = connect(
  PeopleList,
  false,
  ["app_location", "user"]
);
