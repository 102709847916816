import * as React from "react";
import { EVENTS, connect } from "../../../model";
import { generate_id } from "../../../utils/common";
import { RequestComponent } from "../../components/RequestComponent";
import { RequestButton } from "../../components/common/request-button";
import { get_user_full_name } from "../../utils";
import { ConnectionModalContent } from "./ConnectionModalContent";
import { FormErrors } from "../../components/Errors";

class CreateConnectionModalBase extends RequestComponent<
  "request",
  ModalBodyProps & { user: UserWithConnectionStatus },
  { request_id: string; error?: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: generate_id("create-connection")
    };
  }

  componentDidUpdate(prevProps) {
    if (this.requestFinished(prevProps)) {
      const request = this.props.request[this.state.request_id] as
        | SuccessfulAppRequest
        | FailedAppRequest;
      if (request.success) {
        this.props.user.is_pending_request = true;
        if (this.props.onSuccess) {
          setTimeout(this.props.onSuccess, 1200);
        } else {
          this.props.dispatchLater([EVENTS.CLOSE_APP_MODAL], 1200);
        }
      } else {
        this.setState({ error: request.error.message });
      }
    }
  }

  onSubmit = () => {
    this.sendRequest(
      "RequestConnectedUser",
      {
        connected_user: {
          connected_user_id: this.props.user.id
        }
      },
      this.state.request_id
    );
  };

  render() {
    const { onDismiss, user } = this.props;
    const request = this.props.request[this.state.request_id] || {};
    return (
      <ConnectionModalContent
        user={user}
        text={`Do you want to request a connection with ${get_user_full_name(
          user
        )}?`}
        buttons={[
          <button
            key="cancel"
            onClick={onDismiss}
            className="bordered people__modal__dismiss"
          >
            Cancel
          </button>,
          <RequestButton
            key="confirm"
            onClick={this.onSubmit}
            className="filled people__modal__success"
            pending={request.pending}
            success={!request.pending && request.success}
            successText="Success!"
          >
            Confirm
          </RequestButton>
        ]}
        error={<FormErrors response={this.getRequestErrors()} />}
      />
    );
  }
}

export const CreateConnectionModal = connect(
  CreateConnectionModalBase,
  true,
  ["request"]
);
