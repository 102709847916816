import * as React from "react";
import { PLACEHOLDER_AVATAR_URL } from "../../constants";
import { maybeClassName } from "../../utils/dom-helpers";
import { HealthIcon } from "./HealthIcon";
import { get_user_full_name } from "../utils";

export const Avatar: React.FC<
  Omit<HTMLDivProps, "size"> & {
    name: string;
    url?: string;
    size: IconSize;
    health?: {
      rating: number;
      date?: string;
    };
  }
> = ({ url, size, name, className, health, children, ...props }) => {
  return (
    <div
      {...props}
      data-size={size}
      className={`user-avatar${maybeClassName(className)}`}
    >
      <img
        src={url || PLACEHOLDER_AVATAR_URL}
        alt={`User profile picture for ${name}`}
      />
      {health && (
        <HealthIcon
          {...health}
          className="user-avatar__health"
          userName={name}
        />
      )}
      {children}
    </div>
  );
};
Avatar.displayName = "Avatar";

export const UserAvatar: React.FC<
  Omit<HTMLDivProps, "size"> & {
    user: User;
    size: IconSize;
    showHealth?: boolean;
  }
> = ({ user, size, showHealth, ...props }) => {
  const name = get_user_full_name(user);
  const health =
    user.health_rating != null && showHealth
      ? {
          rating: user.health_rating,
          date: user.health_rating_created_at
        }
      : undefined;
  return (
    <Avatar
      {...props}
      name={name}
      size={size}
      health={health}
      url={user.profile_photo_url}
    />
  );
};
UserAvatar.displayName = "UserAvatar";
