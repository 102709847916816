import * as React from "react";
import { EVENTS, connect, DEFAULT_APP_LOCATION } from "../../model";
import { RequestComponent } from "./RequestComponent";
import { IMAGES_PREFIX, MIN_PASSWORD_LENGTH } from "../../constants";
import { WithFloatingTitle } from "./common/floating-title";
import { InputWithFormHelpers } from "./common/inputs";
import { RequestButton } from "./common/request-button";
import { Error404PageNotFound } from "./common/common";
import { FieldError, FormErrors } from "./Errors";
import { SET_PASSWORD_REQUEST_ID } from "../../model/effects/constants";

class SetPasswordForm extends RequestComponent<
  "user",
  { user_response: Token },
  {
    password?: string;
    confirm_password?: string;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: SET_PASSWORD_REQUEST_ID
    };
  }

  componentDidUpdate() {
    if (this.requestSucceeded() && this.props.user) {
      this.props.dispatchNow([EVENTS.GO_TO_PAGE, DEFAULT_APP_LOCATION]);
    }
  }

  onChangeInput = (field: "confirm_password" | "password") => e => {
    // @ts-ignore
    this.setState({ [field]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { token, user } = this.props.user_response;
    const { confirm_password, password } = this.state;
    if (
      password &&
      confirm_password === password &&
      password.length >= MIN_PASSWORD_LENGTH
    ) {
      this.props.dispatchNow([
        EVENTS.SET_PASSWORD,
        { id: user.id, password, token }
      ]);
    }
  };

  render() {
    const { user } = this.props.user_response;
    const { password, confirm_password } = this.state;
    if (!user) {
      return <Error404PageNotFound />;
    }
    return (
      <div className="login-page">
        <div className="login-page__logo">
          <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
        </div>
        <form className="login-page__form" id="login" onSubmit={this.onSubmit}>
          <div className="set-password-page__prompt">
            Please choose a password to use with the email{" "}
            <span>{user.email}</span>.
          </div>
          <input type="hidden" name="email" id="email" value={user.email} />
          <WithFloatingTitle title="Password">
            <InputWithFormHelpers
              required={true}
              placeholder="Password"
              type="password"
              name="password"
              minLength={MIN_PASSWORD_LENGTH}
              onChange={this.onChangeInput("password")}
              value={password}
            />
          </WithFloatingTitle>
          <WithFloatingTitle title="Confirm Password">
            <InputWithFormHelpers
              required={true}
              type="password"
              name="confirm_password"
              placeholder="Confirm Password"
              minLength={MIN_PASSWORD_LENGTH}
              onChange={this.onChangeInput("confirm_password")}
              value={confirm_password}
              validate={v => v === password}
            />
            <FieldError
              hasError={confirm_password !== password}
              name="confirm-password"
            >
              Passwords must match!
            </FieldError>
          </WithFloatingTitle>
          <FormErrors<"SetUserPassword">
            response={this.getRequestErrors()}
            formSubmitted={!!this.getRequest()}
          />
          <RequestButton
            className="login-page__submit filled"
            type="submit"
            pending={this.requestIsPending()}
            success={this.requestSucceeded()}
            successText="Success!"
          >
            Set Password
          </RequestButton>
        </form>
      </div>
    );
  }
}

export const SetPasswordFormConnected = connect(
  SetPasswordForm,
  true,
  ["request", "user"]
);
