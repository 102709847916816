import * as React from "react";
import { maybeClassName } from "../../utils/dom-helpers";
import { Icon } from "./Icon";

const TOUCHPOINT_TYPE_ICON_MAP: ObjectOf<FontIconName> = {
  "In-person": "people",
  Call: "phone",
  Email: "email",
  Text: "text-chat",
  Video: "communication",
  Other: "activity",
  Summary: "logging",
  undefined: "logging"
};

export const TouchpointTypeIcon: React.FC<
  {
    type: TouchpointType;
    size: IconSize;
  } & MaybeClass
> = ({ size, type, className, ...props }) => (
  <div
    {...props}
    data-size={size}
    className={`touchpoint__icon${maybeClassName(className)}`}
  >
    <Icon name={TOUCHPOINT_TYPE_ICON_MAP[type]} />
  </div>
);
TouchpointTypeIcon.displayName = "TouchpointTypeIcon";
