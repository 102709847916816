import * as React from "react";
import rotate from "reset-image-orientation";
import { PageContent } from "../components/PageContent";
import { connect } from "../../model";
import { get_user_full_name } from "../utils";
import { Error404PageNotFound, FormSection } from "../components/common/common";
import { FormErrors } from "../components/Errors";
import { Avatar } from "../components/Avatar";
import { ButtonWithIcon } from "../components/common/button-with-icon";
import { InputWithFormHelpers } from "../components/common/inputs";
import { WithFloatingTitle } from "../components/common/floating-title";
import { RequestComponent } from "../components/RequestComponent";
import { RequestButton } from "../components/common/request-button";
import { PhoneNumberInput } from "../components/common/phone-number-input";
import { Icon } from "../components/Icon";

class UserProfile extends RequestComponent<
  "user" | "auth_expired",
  {},
  {
    user: Partial<
      Pick<User, "id" | "first_name" | "last_name" | "phone_number"> & {
        profile_photo_file: File;
      }
    >;
    image_preview?: { name: string; data: string };
    form_dirty?: boolean;
  }
> {
  constructor(props) {
    super(props);
    const { id, first_name, last_name, phone_number = undefined } = props.user;
    this.state = {
      user: { id, first_name, last_name, phone_number },
      request_id: "user-profile"
    };
    this.file_input = React.createRef();
  }
  name = "UserProfile";
  file_input: React.RefObject<HTMLInputElement>;

  componentDidUpdate(prevProps) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      setTimeout(() => window.location.reload(), 1200);
    }
  }

  onChangePicture = e => {
    const reader = new FileReader();

    const file = e.target.files[0];

    reader.onload = ev => {
      ev.target &&
        rotate(file, data => {
          ev.target &&
            this.setState({
              user: {
                ...this.state.user,
                profile_photo_file: file
              },
              image_preview: {
                name: file.name,
                data: data || (ev.target["result"] as string)
              },
              form_dirty: true
            });
        });
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  onChangeInput = (name: "first_name" | "last_name" | "phone_number") => e => {
    this.setState({
      user: {
        ...this.state.user,
        [name]: e.target.value === "undefined" ? "" : e.target.value
      },
      form_dirty: true
    });
  };

  onSubmit = e => {
    e.preventDefault();
    if (!this.state.form_dirty) {
      return false;
    }
    const user = {};
    Object.entries(this.state.user).forEach(([k, v]) => {
      if (v != null) {
        user[k] = v;
      }
    });
    // @ts-ignore
    this.sendRequest("PutUser", user, this.state.request_id);
    return false;
  };

  render() {
    const { user, image_preview } = this.state;
    if (!user || !this.props.user) {
      return this.props.auth_expired ? () => null : <Error404PageNotFound />;
    }
    const { first_name, last_name, phone_number } = user;
    const name = get_user_full_name(this.props.user);
    return (
      <PageContent
        id="user-profile"
        bodyType="full-width-block"
        className="header-overlap-sm"
        pageHeader={
          <div className="user-profile__header">
            <div className="user-profile__header__avatar">
              <div className="user-profile__header__avatar__image">
                <Avatar
                  name={name}
                  size="lg"
                  url={
                    image_preview
                      ? image_preview.data
                      : this.props.user.profile_photo_url
                  }
                />
              </div>
              <div className="user-profile__header__avatar__edit">
                <ButtonWithIcon
                  icon={<Icon name="edit" />}
                  side="left"
                  onClick={e => {
                    e.preventDefault();
                    this.file_input &&
                      this.file_input.current &&
                      this.file_input.current.click();
                  }}
                >
                  Change your picture
                </ButtonWithIcon>
                <input
                  ref={this.file_input}
                  id="profile-photo"
                  type="file"
                  accept="image/*"
                  onChange={this.onChangePicture}
                />
              </div>
            </div>
            <div className="user-profile__header__name">{name}</div>
          </div>
        }
      >
        <form id="user-profile" onSubmit={this.onSubmit}>
          <FormSection className="user-profile" title="Account Information">
            <WithFloatingTitle title="First Name">
              <InputWithFormHelpers
                placeholder="First Name"
                required={true}
                type="text"
                value={first_name}
                onChange={this.onChangeInput("first_name")}
                submitOnEnter={false}
                disabled
              />
            </WithFloatingTitle>
            <WithFloatingTitle title="Last Name">
              <InputWithFormHelpers
                placeholder="Last Name"
                required={true}
                type="text"
                value={last_name}
                onChange={this.onChangeInput("last_name")}
                submitOnEnter={false}
                disabled
              />
            </WithFloatingTitle>
            <WithFloatingTitle title="Phone Number">
              <PhoneNumberInput
                placeholder="Phone Number"
                required={false}
                type="text"
                value={phone_number}
                onChange={this.onChangeInput("phone_number")}
                submitOnEnter={false}
                disabled
              />
            </WithFloatingTitle>
          </FormSection>
          <FormErrors<"PutUser"> response={this.getRequestErrors()} />
          <div className="user-profile__submit">
            <RequestButton
              type="submit"
              className="filled"
              pending={this.requestIsPending()}
              success={this.requestSucceeded()}
              successText="Success!"
            >
              Save Changes to Profile
            </RequestButton>
          </div>
        </form>
      </PageContent>
    );
  }
}
export const UserProfileConnected = connect(
  UserProfile,
  true,
  ["request", "user", "auth_expired"]
);
