import * as React from "react";
import { Icon } from "./Icon";
import { deep_equals } from "../../utils/common";
import { RequestButton } from "./common/request-button";

export class SearchBarCollapsible<
  T extends User | Touchpoint
> extends React.Component<
  {
    query: ApiQuery<T>;
    onFocus?: (e) => void;
    onBlur?: (e) => void;
    onSubmit: (q: ApiQuery<T>) => void;
    autoSubmit?: boolean;
    side?: "left" | "right";
    pending: boolean;
    placeholder?: string;
  },
  { query: ApiQuery<T>; focused: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      query: props.query,
      focused: false
    };
    this.input = React.createRef();
  }
  input: React.RefObject<HTMLInputElement>;
  timer: any;

  static defaultProps = {
    autoSubmit: true,
    side: "right"
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !deep_equals(
        prevProps.query,
        // @ts-ignore
        this.props.query
      ) &&
      deep_equals(prevState.query, this.state.query) &&
      // @ts-ignore
      !deep_equals(this.props.query, this.state.query)
    ) {
      this.setState({ query: this.props.query });
    }
  }

  shouldExpand = () => {
    return !!(this.state.focused || this.state.query.filter);
  };

  onClickButton = () => {
    if (this.state.query.filter) {
      this.onSubmit();
    } else if (!this.state.focused && this.input.current) {
      this.input.current.focus();
    }
  };

  onSubmit = (e?) => {
    e && e.preventDefault();
    if (!deep_equals(this.props.query, this.state.query)) {
      this.props.onSubmit(this.state.query);
    }
  };

  autoSubmit = () => {
    if (!this.props.autoSubmit) {
      return;
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.onSubmit();
      clearTimeout(this.timer);
      this.timer = null;
    }, 700);
  };

  onChangeInput = e => {
    const { query } = this.state;
    // @ts-ignore
    this.setState(
      {
        query: {
          ...query,
          filter: e.target.value.trimLeft().replace(/\s+/g, " ")
        }
      },
      this.autoSubmit
    );
  };

  render() {
    const {
      query: { filter },
      focused
    } = this.state;

    return (
      <form
        className="search-bar search-bar__collapsible"
        data-show={this.shouldExpand()}
        data-minimize={!focused && !!filter}
        data-side={this.props.side || "right"}
        data-focused={this.state.focused}
        onSubmit={this.onSubmit}
      >
        <RequestButton
          type="button"
          className="search-bar__button button filled"
          onClick={this.onClickButton}
          pending={this.props.pending}
          successText=""
        >
          <Icon name="search" iconSize="xs" />
        </RequestButton>
        <input
          ref={this.input}
          type="text"
          name="search-bar-input"
          className="search-bar__input"
          value={filter || ""}
          placeholder={this.props.placeholder}
          size={
            !focused && !!filter
              ? Math.min(25, Math.round(1.2 * filter.length))
              : undefined
          }
          onChange={this.onChangeInput}
          onFocus={e => {
            this.props.onFocus && this.props.onFocus(e);
            this.setState({ focused: true });
          }}
          onBlur={e => {
            this.props.onBlur && this.props.onBlur(e);
            this.setState({ focused: false });
          }}
          onKeyDown={e => {
            if (e.key === "Escape" && this.input.current) {
              this.input.current.blur();
            }
          }}
        />
      </form>
    );
  }
}
