import * as React from "react";
import { connect, EVENTS } from "../../../model";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import { Icon } from "../../components/Icon";
import { RequestComponent } from "../../components/RequestComponent";
import { RequestButton } from "../../components/common/request-button";
import { FieldErrors, FormErrors } from "../../components/Errors";
import {
  ApiReqViewProps,
  WithApiRequest
} from "../../components/WithApiRequest";
import { EmptyList } from "../../components/EmptyList";
import moment from "../../../utils/moment";
import { add_item_to, remove_item_from } from "../../../utils/common";
import { UserChecklistItemView } from "../../components/UserChecklist";
import {
  RadioButton,
  TextAreaWithFormHelpers
} from "../../components/common/inputs";
import { DropdownSelectInput } from "../../components/common/dropdown-select-input";
import {
  FloatingTitle,
  WithFloatingTitle
} from "../../components/common/floating-title";
import { get_label_from_value } from "../../components/common/common";
import { MultiValueListItem } from "../../components/common/multi-value-list-item";
import { TpCategoryOptions } from "../Touchpoint/Form";

class WeeklyCheckInForm extends RequestComponent<
  "user",
  ApiReqViewProps<"GetGroupById"> & WithAppLocationAt<"weekly-check-in">,
  {
    answers: WeeklyCheckinBody;
    error?: string;
    form_dirty: boolean;
    form_submitted: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: "weekly-check-in",
      answers: {
        connections: props.response.data.users
          .filter(u => u.id !== (props.user ? props.user.id : -1))
          .map(u => ({ user_id: u.id })),
        categories: [],
        discussed: "",
        next_steps: "",
        could_not_connect: ""
      },
      form_dirty: false,
      form_submitted: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      if (this.state.request_id === "weekly-check-in") {
        this.setState({ request_id: "complete-action" }, () => {
          this.sendRequest(
            "SubmitActionResponse",
            { action_id: this.props.app_location.id },
            this.state.request_id
          );
        });
      } else {
        this.props.dispatchNow([
          EVENTS.SET_APP_LOCATION,
          {
            ...this.props.app_location,
            form_dirty: false
          }
        ]);
        this.props.dispatchLater(
          [EVENTS.GO_TO_PAGE, { place: "activity" }],
          1200
        );
      }

      if (!prevState.form_dirty && this.state.form_dirty) {
        this.props.dispatchNow([
          EVENTS.SET_APP_LOCATION,
          {
            ...this.props.app_location,
            form_dirty: true
          }
        ]);
      }
    }
  }

  changeConnections = (user_id: number, connected: boolean) => {
    this.setState({
      answers: {
        ...this.state.answers,
        connections: [
          ...this.state.answers.connections.filter(c => c.user_id !== user_id),
          { user_id, connected }
        ]
      },
      form_dirty: true
    });
  };

  changeCategories = (category: string, checked: boolean) => {
    this.setState({
      answers: {
        ...this.state.answers,
        categories: checked
          ? add_item_to(this.state.answers.categories, category)
          : remove_item_from(this.state.answers.categories, i => i === category)
      }
    });
  };

  changeAnswer = (
    name: Exclude<keyof WeeklyCheckinBody, "connections" | "categories">
  ) => e => {
    this.setState({
      answers: {
        ...this.state.answers,
        [name]: e.target.value
      }
    });
  };

  submitForm = e => {
    e.preventDefault();
    const { answers, request_id } = this.state;
    if (
      answers.categories.length > 0 &&
      !!answers.discussed &&
      !!answers.next_steps &&
      (!!answers.could_not_connect ||
        answers.connections.filter(c => c.connected === false).length === 0)
    ) {
      this.sendRequest(
        "PostWeeklyCheckin",
        { weekly_checkin: answers },
        request_id
      );
      this.setState({ error: undefined, form_submitted: true });
    } else {
      this.setState({ error: "Please answer all of the questions." });
    }
  };

  formatDate = (): string => {
    const { action } = this.props.app_location;
    const the_week = moment(action.time_to_send || action.created_at).subtract(
      1,
      "week"
    );
    const next_week = moment(the_week).endOf("week");
    const this_week = moment(the_week).startOf("week");
    const week_start = this_week.format("D");
    const week_end = next_week.format("D");
    const this_month = this_week.format("MMMM");
    const this_year = this_week.format("YYYY");
    let middle = "-";
    let end = this_year;
    if (parseInt(this_year) < parseInt(next_week.format("YYYY"))) {
      middle = `, ${this_year} - ${next_week.format("MMMM")} `;
      end = next_week.format("YYYY");
    } else if (parseInt(week_end) < parseInt(week_start)) {
      middle = ` - ${next_week.format("MMMM")} `;
    }
    return `${this_month} ${week_start}${middle}${week_end}, ${end}`;
  };

  render() {
    const { user, app_location, response } = this.props;
    const { users } = response.data;
    const { answers, error, form_submitted, request_id } = this.state;
    if (!user) {
      return null;
    }
    if (
      app_location.action.state === "fulfilled" ||
      app_location.action.state === "dismissed"
    ) {
      return (
        <div className="weekly-check-in__already-processed">
          You've already addressed this action!
        </div>
      );
    }

    const errors = this.getRequestErrors();
    const date = this.formatDate();

    const cat_values = answers.categories.map(value => ({
      value,
      label: get_label_from_value(TpCategoryOptions, value) || value
    }));
    const couldnt_connect: User[] = answers.connections
      .filter(c => c.connected === false)
      .map(c => users.find(u => c.user_id === u.id))
      .filter(u => !!u) as User[];

    return (
      <form id="weekly-check-in" onSubmit={this.submitForm}>
        <h3>{date}</h3>
        <section className="weekly-check-in__section">
          <div className="weekly-check-in__question">
            Who did you connect with from your family this week?
          </div>
          <div className="weekly-check-in__user-checklist">
            {users
              .filter(u => u.id !== user.id)
              .map(u => {
                const connection: any =
                  answers.connections.find(c => c.user_id === u.id) || {};
                return (
                  <UserChecklistItemView
                    key={u.id}
                    size="md"
                    item={u}
                    button={
                      <>
                        <RadioButton
                          name={`user-${u.id}-connected`}
                          id={`user-${u.id}-connected-false`}
                          // @ts-ignore
                          value={false}
                          checked={connection.connected === false}
                          onChange={() => this.changeConnections(u.id, false)}
                          label="Attempted"
                        />
                        <RadioButton
                          name={`user-${u.id}-connected`}
                          id={`user-${u.id}-connected-true`}
                          // @ts-ignore
                          value={true}
                          checked={connection.connected === true}
                          onChange={() => this.changeConnections(u.id, true)}
                          label="Able to Connect"
                        />
                      </>
                    }
                  />
                );
              })}
          </div>
          {errors && (
            <FieldErrors
              errors={errors.errors}
              formSubmitted={form_submitted}
              field={{ name: "connections", label: "Connections" }}
            />
          )}
        </section>
        <section className="weekly-check-in__section">
          <div className="weekly-check-in__question">
            What was the purpose of those interactions?
          </div>

          <DropdownSelectInput
            required={cat_values.length === 0}
            options={TpCategoryOptions.filter(
              opt => cat_values.findIndex(o => o.value === opt.value) === -1
            )}
            value=""
            onSelect={opt => opt && this.changeCategories(opt.value, true)}
            inputProps={{ placeholder: "Touchpoint Purpose" }}
            defaultBlankOption={true}
          >
            <FloatingTitle
              title={
                cat_values.length === 0
                  ? "Touchpoint Purpose"
                  : "Touchpoint Purpose *"
              }
            />
          </DropdownSelectInput>
          <div className="create-touchpoint__category__values">
            {cat_values.map(opt => (
              <MultiValueListItem
                key={opt.value}
                option={opt}
                onRemove={() => this.changeCategories(opt.value, false)}
              />
            ))}
          </div>
          {errors && (
            <FieldErrors
              errors={errors.errors}
              formSubmitted={form_submitted}
              field={{ name: "categories", label: "Touchpoint Purpose" }}
            />
          )}
        </section>
        <section className="weekly-check-in__section">
          <div className="weekly-check-in__question">What did you discuss?</div>
          <WithFloatingTitle title="Description">
            <TextAreaWithFormHelpers
              className="weekly-check-in__textarea"
              name="discussed"
              value={answers.discussed}
              onChange={this.changeAnswer("discussed")}
              required={true}
            />
          </WithFloatingTitle>
          {errors && (
            <FieldErrors
              errors={errors.errors}
              formSubmitted={form_submitted}
              field={{ name: "discussed", label: "Description" }}
            />
          )}
        </section>
        <section className="weekly-check-in__section">
          <div className="weekly-check-in__question">
            What were the agreed next steps?
          </div>
          <WithFloatingTitle title="Next Steps">
            <TextAreaWithFormHelpers
              className="weekly-check-in__textarea"
              name="next_steps"
              value={answers.next_steps}
              onChange={this.changeAnswer("next_steps")}
              required={true}
            />
          </WithFloatingTitle>
          {errors && (
            <FieldErrors
              errors={errors.errors}
              formSubmitted={form_submitted}
              field={{ name: "next_steps", label: "Next Steps" }}
            />
          )}
        </section>
        {couldnt_connect.length > 0 && (
          <section className="weekly-check-in__section">
            <div className="weekly-check-in__question">
              We noticed you were unable to connect with some of your family
              members:
            </div>
            <div className="weekly-check-in__user-checklist">
              {couldnt_connect.map(u => (
                <UserChecklistItemView
                  key={u.id}
                  size="md"
                  item={u}
                  button={<div />}
                />
              ))}
            </div>
            <div className="weekly-check-in__question">
              What are some ways that you can work with them next week?
            </div>
            <WithFloatingTitle title="Ways to Improve">
              <TextAreaWithFormHelpers
                className="weekly-check-in__textarea"
                name="could_not_connect"
                value={answers.could_not_connect}
                onChange={this.changeAnswer("could_not_connect")}
                required={true}
              />
            </WithFloatingTitle>
            {errors && (
              <FieldErrors
                errors={errors.errors}
                formSubmitted={form_submitted}
                field={{ name: "could_not_connect", label: "Ways to Improve" }}
              />
            )}
          </section>
        )}
        <section className="weekly-check-in__footer">
          {(error || errors) && (
            <div className="weekly-check-in__errors">
              {error ? (
                <div className="field-error">{error}</div>
              ) : (
                <FormErrors response={errors} />
              )}
            </div>
          )}
          <RequestButton
            className="button filled"
            pending={
              !(
                (request_id === "weekly-check-in" &&
                  !this.requestIsPending()) ||
                (request_id === "complete-action" && this.requestSucceeded())
              )
            }
            success={this.requestSucceeded()}
            successText="Success!"
            type="submit"
          >
            Submit Answers
          </RequestButton>
        </section>
      </form>
    );
  }
}

const WeeklyCheckInFormConnected = connect(
  WeeklyCheckInForm,
  true,
  ["user", "request", "app_location"]
);

const WeeklyCheckInWithGroupBase: React.FC<Context<"user">> = ({ user }) =>
  user && user.default_group_id ? (
    <WithApiRequest
      id="get-user-family"
      View={WeeklyCheckInFormConnected}
      payload={{
        method: "GetGroupById",
        parameters: {
          group_id: user.default_group_id
        }
      }}
    />
  ) : (
    <EmptyList icon={<Icon name="family" />}>
      Looks like you don't have a default family set.
    </EmptyList>
  );

const WeeklyCheckInWithGroup = connect(
  WeeklyCheckInWithGroupBase,
  false,
  ["user"]
);

const WeeklyCheckInBase: React.FC<
  Dispatchers & WithAppLocationAt<"weekly-check-in"> & Context<"notifications">
> = ({ app_location, notifications, dispatchNow }) => (
  <PageContent
    id="weekly-check-in"
    className="header-overlap-block header-overlap-sm"
    bodyType="full-width-block"
    pageHeader={
      <PageHeader title="Weekly Check-In" icon={<Icon name="family" />} />
    }
  >
    <WithApiRequest
      id="weekly-check-in-action"
      View={WeeklyCheckInWithGroup}
      payload={{
        method: "GetActionById",
        parameters: {
          action_id: app_location.id
        }
      }}
      overrideRecord={
        app_location.action ||
        (notifications
          ? notifications.find(a => a.id === app_location.id)
          : undefined)
      }
      onSuccess={res => {
        dispatchNow([
          EVENTS.SET_APP_LOCATION,
          {
            ...app_location,
            action: res.response.data as ActionOfType<"weekly_check_in">
          }
        ]);
      }}
    />
  </PageContent>
);

export const WeeklyCheckIn = connect(
  WeeklyCheckInBase,
  true,
  ["app_location"]
);
