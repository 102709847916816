import * as React from "react";
import { connect } from "../../../model";
import { ListView, makeListView } from "../../components/ListView";
import { EmptyList } from "../../components/EmptyList";
import { TouchpointListViewItem } from "../Touchpoint/List";
import { WithApiRequest } from "../../components/WithApiRequest";
import { FormSection } from "../../components/common/common";
import { Icon } from "../../components/Icon";
import { path_or } from "../../../utils/common";

export const HEADER_AVATAR_ELEM_ID = "user-detail__header__avatar__image";
export const HEADER_NAME_ELEM_ID = "user-detail__header__name";

class GroupActivityList<
  T extends "GetTouchpointsByGroup" | "GetTouchpointsByThread"
> extends ListView<T, Context<"app_location"> & { id: number; method: T }> {
  constructor(props) {
    super(props);
    this.state = {
      // @ts-ignore
      query: {
        pageSize: 10,
        ...path_or({}, ["app_location", "query"], props),
        page: 0
      }
    };
  }

  TouchpointListView = makeListView(
    "TouchpointListView",
    this.nextPage,
    TouchpointListViewItem,
    "Load More Activity",
    <EmptyList icon={<Icon name="logo" />}>
      Looks like there isn't any activity in this{" "}
      {this.props.method === "GetTouchpointsByGroup" ? "family" : "thread"} yet.
    </EmptyList>
  );

  render() {
    const { id, method } = this.props;
    const group_type = method === "GetTouchpointsByGroup" ? "group" : "thread";
    return (
      <FormSection title="Activity" className="group-detail__touchpoints">
        <WithApiRequest
          id={`${group_type}-${id}-activity`}
          payload={{
            method: method,
            // @ts-ignore
            parameters: {
              ...this.state.query,
              [`${group_type}_id`]: id
            }
          }}
          View={this.TouchpointListView}
        />
      </FormSection>
    );
  }
}
export const GroupActivityListConnected = connect(
  GroupActivityList,
  true,
  ["app_location"]
);
