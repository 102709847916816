import * as React from "react";
import { EVENTS, connect } from "../../model";
import { RequestComponent } from "../components/RequestComponent";
import { IMAGES_PREFIX } from "../../constants";
import { DefaultPendingComponent } from "../components/common/common";
import { SetPasswordFormConnected } from "../components/SetPasswordForm";
import { GET_USER_WITH_TEMP_TOKEN_REQUEST_ID } from "../../model/effects/constants";

class Register extends RequestComponent<never, {}, { user_response?: Token }> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: GET_USER_WITH_TEMP_TOKEN_REQUEST_ID
    };
  }

  componentDidMount(): void {
    this.props.dispatchNow([EVENTS.GET_USER_WITH_TEMP_TOKEN]);
  }

  componentDidUpdate(prevProps) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      const req = this.getRequest() as SuccessfulAppRequest<
        "ExchangeRegistrationToken"
      >;
      if (req.response.data.user.needs_password_reset) {
        this.setState({ user_response: req.response.data });
      }
    }
  }

  render() {
    const { user_response } = this.state;
    const request = this.getRequest();
    if (!request) {
      return null;
    }
    return user_response ? (
      <SetPasswordFormConnected user_response={user_response} />
    ) : this.requestIsPending() ? (
      <div className="with-api-request__pending">
        <div className="with-api-request__pending__content">
          <DefaultPendingComponent />
        </div>
      </div>
    ) : !request.pending && request.success === false ? (
      <div className="with-api-request__default-error">
        <div>Something went wrong!</div>
        {(request as FailedAppRequest).response ? (
          <>
            <div>
              {//
              // @ts-ignore
              request.response.status === 401
                ? "This registration link has expired."
                : "Please try again in a moment."}
            </div>
            <div>
              Status Code:{" "}
              {
                // @ts-ignore
                request.response.status
              }
            </div>
          </>
        ) : (
          <div>Please try again in a moment.</div>
        )}
      </div>
    ) : (
      <div className="login-page">
        <div className="login-page__logo">
          <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
        </div>
        <div className="login-page__form">
          <div className="set-password__already-done">
            You've already completed signup!
            <br />
            <a href="/login">Click here to log in.</a>
          </div>
        </div>
      </div>
    );
  }
}
export const RegisterConnected = connect(
  Register,
  true,
  ["request"]
);
