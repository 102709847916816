import * as React from "react";
import * as ReactDOM from "react-dom";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import {
  ApiReqViewProps,
  WithApiRequest
} from "../../components/WithApiRequest";
import { connect, EVENTS } from "../../../model";
import { CommunityBreadcrumb } from "./CommunityBreadcrumb";
import { GroupGraph, NODE_COUNT_LIMIT } from "./GroupGraph";
import { HealthScore } from "../../components/HealthScore";
import { deep_equals, path_or, sort_by_id } from "../../../utils/common";
import { user_to_graph_node } from "../../utils";
import { Avatar } from "../../components/Avatar";
import { DetailPageLink } from "../../components/DetailPageLink";
import { GroupActivityListConnected } from "./GroupActivityList";

const FamilyGraphListItem: React.FC<{ node: GroupGraphNode<"family"> }> = ({
  node
}) =>
  node.user ? (
    <DetailPageLink place="users" record={node.user}>
      <Avatar
        url={node.user.profile_photo_url}
        name={node.user.name}
        size="md"
      />
      <span>{node.user.name}</span>
    </DetailPageLink>
  ) : null;
FamilyGraphListItem.displayName = "FamilyGraphListItem";

class FamilyDetailViewBase extends React.Component<
  Dispatchers &
    ApiReqViewProps<"GetGroupWithScores"> & {
      app_location: AppLocationAt<"communities/id/threads/id/families/id">;
    },
  { nodes: GroupGraphNode<"family">[] }
> {
  constructor(props) {
    super(props);
    this.state = {
      nodes: this.getUsers(props)
    };
  }

  componentDidMount(): void {
    const { dispatchNow, app_location, response } = this.props;
    const { place, community, thread, family } = app_location;
    const record = response.data;
    const new_location: any = {
      place
    };

    if (Array.isArray(record)) {
    } else if (record.community_id) {
      new_location.community = {
        id: record.community_id,
        name: record.community_name
      };
      if (record.thread_id) {
        new_location.thread = {
          id: record.thread_id,
          name: record.thread_name
        };
        if (record.group_id) {
          new_location.family = {
            id: record.group_id,
            name: record.group_name
          };
        }
      }
    }

    if (!deep_equals(new_location, { place, community, thread, family }, 3)) {
      dispatchNow([
        EVENTS.SET_APP_LOCATION,
        { ...app_location, ...new_location, record }
      ]);
    }
  }

  calculateConnections = (center_id?: number): ObjOf<number> => {
    const { users, bidirectional } = path_or(
      {},
      ["response", "data", "group_data"],
      this.props
    );
    if (!users || !bidirectional || users.length === 0) {
      return {};
    }

    const center_user = users.find(u => u.id === center_id) || users[0];
    center_id = center_user.id;
    const all_connections = bidirectional.filter(
      b => b.user_id_a === center_id || b.user_id_b === center_id
    );
    const connections: ObjOf<number> = {};
    for (let i = 0; i < all_connections.length; i++) {
      const other_id =
        center_id === all_connections[i].user_id_a
          ? all_connections[i].user_id_b
          : all_connections[i].user_id_a;
      if (connections[other_id] != null || other_id === center_id) {
        continue;
      }
      const other_user = users.find(u => u.id === other_id);
      if (other_user) {
        connections[other_user.id] = all_connections[i].score;
      }
    }

    return connections;
  };

  getUsers = (props = this.props): GroupGraphNode<"family">[] => {
    const { users } = path_or({}, ["response", "data", "group_data"], props);
    if (!users || users.length === 0) {
      return [];
    }
    return sort_by_id(
      users.slice(0, NODE_COUNT_LIMIT).map(u => ({
        id: u.id,
        user: user_to_graph_node(u)
      }))
    ).map(({ id, ...other }) => ({ ...other } as GroupGraphNode<"family">));
  };

  render() {
    const { response, app_location } = this.props;
    const { group_score } = response.data;
    const { nodes } = this.state;
    const score_node = document.getElementById("family-score");
    return (
      <>
        <GroupGraph
          group_type="family"
          ListItemView={FamilyGraphListItem}
          nodes={nodes}
          calculateConnections={this.calculateConnections}
        />
        {score_node &&
          ReactDOM.createPortal(
            <HealthScore score={group_score} />,
            score_node
          )}
        <GroupActivityListConnected
          id={app_location.family.id}
          method="GetTouchpointsByGroup"
        />
      </>
    );
  }
}

const FamilyDetailView = connect(
  FamilyDetailViewBase,
  true,
  ["app_location"]
);

const FamilyDetailBase: React.FC<{
  app_location: AppLocationAt<"communities/id/threads/id/families/id">;
}> = ({ app_location }) => {
  const override =
    app_location.record &&
    app_location.record.group_id === app_location.family.id
      ? app_location.record
      : undefined;
  return (
    <PageContent
      id="family-detail"
      className="group-detail-page"
      bodyType="no-margins"
      pageHeader={
        <PageHeader
          title={<CommunityBreadcrumb />}
          button={
            <div id="family-score" className="group-list__item__health" />
          }
        />
      }
    >
      <WithApiRequest
        View={FamilyDetailView}
        payload={{
          method: "GetGroupWithScores",
          parameters: {
            group_id: app_location.family.id
          }
        }}
        overrideRecord={override}
      />
    </PageContent>
  );
};
FamilyDetailBase.displayName = "FamilyDetail";

export const FamilyDetail = connect(
  FamilyDetailBase,
  false,
  ["app_location"]
);
