import {
  FX_DELAY,
  FX_DISPATCH_NOW,
  FX_DISPATCH_ASYNC,
  EventDef,
  dispatchNow,
  valueSetter,
  EV_SET_VALUE,
  ensurePred,
  EV_UPDATE_VALUE,
  valueUpdater,
  EventBus
} from "@thi.ng/interceptors";
import * as EVENTS from "./event-names";
import * as FX from "../effects/effect-names";
import { MODAL_ANIMATION_DELAY } from "../../constants";
import { LOGIN_MODAL_PROPS } from "../../view/components/LoginModal";
import {
  ANON_ONLY_PAGES,
  DEFAULT_APP_LOCATION,
  LOGIN_LOCATION,
  NO_AUTH_PAGES,
  PUBLIC_PAGES
} from "../routing";
import { deep_equals } from "../../utils/common";
import {
  GOOGLE_LOGIN_REQUEST_ID,
  LOGIN_REQUEST_ID
} from "../effects/constants";

export interface ClientMethodEventPayload {
  method: ApiMethod;
  parameters: object;
}

const ensureRequestId = ensurePred((_, e) => !(!e || !e[1] || !e[1].id));

export const events: AppEventNameMap<EventDef> = {
  [EVENTS.ERROR]: [
    (_, [__, result]) => console.error(`ERROR in dispatcher:`, result)
  ],

  [EVENTS.GO_TO_PAGE]: (state: AppContexts, [__, location]) => {
    if (!location || !location.place) {
      if (state.app_location.place === "login") {
        return {};
      }
      return {
        [FX_DISPATCH_NOW]: [EVENTS.SET_APP_LOCATION, DEFAULT_APP_LOCATION]
      };
    }
    if (!NO_AUTH_PAGES.includes(location.place)) {
      return {
        // refresh auth token each time the user navigates to a new page
        // this keeps the token from expiring while the user is actively
        // using the site
        [FX.REFRESH_AUTH]: { nextEvent: [EVENTS.SET_APP_LOCATION, location] }
      };
    }
    return { [FX_DISPATCH_NOW]: [EVENTS.SET_APP_LOCATION, location] };
  },

  [EVENTS.SET_APP_LOCATION]: (
    state: AppContexts,
    [__, location],
    bus: EventBus
  ) => {
    const event_with = (loc): any => ({
      [FX_DISPATCH_NOW]: [EV_SET_VALUE, ["app_location", loc]],
      [FX.REFRESH_ACTIONS]: {}
    });
    if (
      (state.user && location.place === "login") ||
      (state.user && ANON_ONLY_PAGES.includes(location.place)) ||
      (!state.user && state.auth_expired)
    ) {
      return {};
    }

    // If unauthorized and accessing a page that requires auth, redirect to login
    if (
      !state.user &&
      (!PUBLIC_PAGES.includes(location.place) ||
        (!state.auth_expired &&
          (!state.app_location ||
            !NO_AUTH_PAGES.includes(state.app_location.place))))
    ) {
      return event_with(LOGIN_LOCATION);
    }
    // @ts-ignore
    const { form_dirty, ...loc } = state.app_location;
    const { form_dirty: new_dirty, ...new_loc } = location;
    if (!form_dirty || (deep_equals(loc, new_loc) && !new_dirty)) {
      return event_with(location);
    }
    return {
      [FX_DISPATCH_NOW]: [
        EVENTS.OPEN_APP_MODAL,
        {
          id: "confirm-leave-form",
          className: "confirm-leave-form",
          body: () =>
            "Are you sure you want to leave the page? All progress on this form will be lost.",
          success: {
            text: "Leave Page",
            onClick: () =>
              bus.dispatchNow([EVENTS.SET_APP_LOCATION_FORCE, location]),
            className: "filled"
          },
          dismiss: {
            text: "Stay Here",
            onClick: () => {},
            className: "bordered"
          }
        }
      ]
    };
  },

  // @ts-ignore
  [EVENTS.SET_APP_LOCATION_FORCE]: (_, [__, location]) => ({
    [FX_DISPATCH_NOW]: [
      [EV_SET_VALUE, ["app_location.form_dirty", false]],
      [EVENTS.GO_TO_PAGE, location]
    ]
  }),

  /**
   * CLIENT CONNECTOR CALLS
   */
  /** Invoke a client connector method with the provided payload */
  [EVENTS.CALL_CLIENT_METHOD]: [
    ensureRequestId,
    (_, [__, { id, payload, retryAfterRelogin }]) => ({
      [FX_DISPATCH_NOW]: [
        [
          EV_SET_VALUE,
          [
            `request.${id}`,
            {
              payload,
              pending: true,
              success: undefined,
              response: undefined,
              error: undefined
            }
          ]
        ]
      ] as ThingEvent[],
      [FX.CALL_CLIENT_METHOD]: { id, payload, retryAfterRelogin }
    })
  ],
  /** Invoke a client connector method with the provided payload */
  [EVENTS.SEND_XHR_REQUEST]: [
    ensureRequestId,
    (_, [__, { id, payload }]) => ({
      [FX_DISPATCH_NOW]: [
        [
          EV_SET_VALUE,
          [
            `request.${id}`,
            {
              payload,
              pending: true,
              success: undefined,
              response: undefined,
              error: undefined
            }
          ]
        ]
      ] as ThingEvent[],
      [FX.SEND_XHR_REQUEST]: { id, payload }
    })
  ],
  /** Clear request for given id */
  [EVENTS.CLEAR_CLIENT_REQUEST]: [
    ensureRequestId,
    valueUpdater("request", (request: ObjectOf<AppRequest>, { id }) => {
      // @ts-ignore
      request[id] = undefined;
      delete request[id];
      return request;
    })
  ],
  /** Set app request response */
  [EVENTS.SET_CLIENT_RESPONSE]: [
    ensureRequestId,
    (_, [state, { id, response, success, error }]) => ({
      [FX_DISPATCH_NOW]: [
        [
          EV_UPDATE_VALUE,
          [
            `request.${id}`,
            prev => ({ ...prev, pending: false, success, response, error })
          ]
        ]
      ]
    })
  ],

  /** User Login */
  [EVENTS.LOGIN]: (_, [__, payload]) => ({
    [FX_DISPATCH_NOW]: [
      [
        EV_SET_VALUE,
        [
          `request.login`,
          {
            payload,
            pending: true
          }
        ]
      ]
    ] as ThingEvent[],
    [FX.LOGIN]: payload
  }),
  [EVENTS.LOGIN_WITH_GOOGLE_SUCCESS]: () => ({ [FX.REFRESH_AUTH]: {} }),
  [EVENTS.LOGIN_WITH_GOOGLE_FAIL]: () => {},
  [EVENTS.LOGOUT]: () => ({ [FX.LOGOUT]: {} }),
  [EVENTS.RELOGIN]: (_, [__, nextEvent]) => ({ [FX.RELOGIN]: nextEvent }),

  [EVENTS.GOOGLE_LOGIN_BUTTON_MOUNTED]: (_, [, opts]) => ({
    [FX.RENDER_GOOGLE_LOGIN_BUTTON]: opts
  }),

  [EVENTS.GOOGLE_AUTHENTICATE_SUCCESS]: (_, [, user]) => ({
    [FX_DISPATCH_NOW]: [
      [EV_SET_VALUE, [`request.${GOOGLE_LOGIN_REQUEST_ID}`, { pending: true }]]
    ],
    [FX_DISPATCH_ASYNC]: [
      FX.LOGIN_WITH_GOOGLE,
      { id_token: user.getAuthResponse().id_token },
      EVENTS.LOGIN_WITH_GOOGLE_SUCCESS,
      EVENTS.LOGIN_WITH_GOOGLE_FAIL
    ]
  }),

  [EVENTS.GOOGLE_AUTHENTICATE_FAIL]: (_, [, event]) => {
    console.warn(`GOOGLE AUTHENTICATE FAILED`, event);
    return {
      [FX_DISPATCH_NOW]: [[EV_SET_VALUE, [`google_signin_available`, false]]]
    };
  },

  [EVENTS.GOOGLE_AUTH_SET_AVAILABLE]: (_, [, { available, error }]) => {
    !available &&
      console.warn(`Failed to initialize Google Sign In button`, error);
    return {
      [FX_DISPATCH_NOW]: [
        [EV_SET_VALUE, [`google_signin_available`, available]]
      ]
    };
  },

  /** Get unregistered user for signup */
  [EVENTS.GET_USER_WITH_TEMP_TOKEN]: () => ({
    [FX_DISPATCH_NOW]: [
      [
        EV_SET_VALUE,
        [
          `request.get-user-with-temp-token`,
          {
            payload: {},
            pending: true,
            success: undefined,
            response: undefined,
            error: undefined
          }
        ]
      ]
    ] as ThingEvent[],
    [FX.GET_USER_WITH_TEMP_TOKEN]: {}
  }),
  /** Get unregistered user for signup */
  [EVENTS.REQUEST_PASSWORD_RESET]: (_, [__, payload]) => ({
    [FX_DISPATCH_NOW]: [
      [
        EV_SET_VALUE,
        [
          `request.${payload.request_id}`,
          {
            payload: {},
            pending: true,
            success: undefined,
            response: undefined,
            error: undefined
          }
        ]
      ]
    ] as ThingEvent[],
    [FX.REQUEST_PASSWORD_RESET]: payload
  }),
  /** Set user password */
  [EVENTS.SET_PASSWORD]: (_, [__, payload]) => ({
    [FX_DISPATCH_NOW]: [
      [
        EV_SET_VALUE,
        [
          `request.set-password`,
          {
            payload,
            pending: true,
            success: undefined,
            response: undefined,
            error: undefined
          }
        ]
      ]
    ] as ThingEvent[],
    [FX.SET_PASSWORD]: payload
  }),
  [EVENTS.SET_APP_USER]: [valueSetter("user")],
  [EVENTS.SET_AUTH_EXPIRED]: [valueSetter("auth_expired")],
  [EVENTS.REFRESH_ACTIONS]: (_, [__, refresh_auth]) =>
    refresh_auth === false
      ? {
          [FX.REFRESH_ACTIONS]: {}
        }
      : {
          [FX.REFRESH_AUTH]: {}
        },

  // Modal events
  [EVENTS.SET_APP_MODAL_OPEN]: [valueSetter("modal.open")],
  [EVENTS.SET_APP_MODAL_CONTENT]: (_, [__, modal], bus) =>
    // @ts-ignore
    modal !== null || !bus.state.deref().modal.open
      ? {
          [FX_DISPATCH_NOW]: [EV_SET_VALUE, ["modal.modal", modal]]
        }
      : {},
  [EVENTS.OPEN_APP_MODAL]: [
    (_, [__, modal]) => ({
      [FX_DISPATCH_NOW]: [EVENTS.SET_APP_MODAL_CONTENT, modal]
    }),
    dispatchNow([EVENTS.SET_APP_MODAL_OPEN, true])
  ],
  [EVENTS.CLOSE_APP_MODAL]: [
    dispatchNow([EVENTS.SET_APP_MODAL_OPEN, false]),
    () => ({
      [FX_DISPATCH_ASYNC]: [
        FX_DELAY,
        [MODAL_ANIMATION_DELAY, null],
        EVENTS.SET_APP_MODAL_CONTENT,
        EVENTS.ERROR
      ]
    })
  ],
  [EVENTS.OPEN_LOGIN_MODAL]: [
    (_, [__, { nextEvent }], bus) => ({
      [FX_DISPATCH_NOW]: [
        EVENTS.SET_LOGIN_MODAL_CONTENT,
        {
          ...LOGIN_MODAL_PROPS,
          dismiss: {
            onClick: () => {
              window.location.reload();
              // if reload somehow fails or gets cancelled
              setTimeout(
                () =>
                  // @ts-ignore
                  bus.dispatchNow(
                    [EVENTS.SET_AUTH_EXPIRED, false],
                    [EVENTS.GO_TO_PAGE, { place: "login" }]
                  ),
                500
              );
            }
          },
          success: {
            // after re-logging in, clear auth_expired flag and login request,
            // and if a next event was provided, dispatch it
            onClick: () => {
              // @ts-ignore
              bus.dispatchNow(
                [EVENTS.SET_AUTH_EXPIRED, false],
                [EVENTS.CLEAR_CLIENT_REQUEST, { id: LOGIN_REQUEST_ID }],
                ...(nextEvent ? [nextEvent] : [])
              );
            }
          }
        }
      ]
    }),
    dispatchNow([EV_SET_VALUE, ["login_modal.open", true]])
  ],
  [EVENTS.CLOSE_LOGIN_MODAL]: [
    dispatchNow([EV_SET_VALUE, ["login_modal.open", false]]),
    () => ({
      [FX_DISPATCH_ASYNC]: [
        FX_DELAY,
        [MODAL_ANIMATION_DELAY, null],
        EVENTS.SET_LOGIN_MODAL_CONTENT,
        EVENTS.ERROR
      ]
    })
  ],
  [EVENTS.SET_LOGIN_MODAL_CONTENT]: [valueSetter("login_modal.modal")]
};
