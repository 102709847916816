import { ScreenSize } from "../constants";

const matches = (node, selector) =>
  node
    ? node.msMatchesSelector
      ? node.msMatchesSelector(selector)
      : node.matches(selector)
    : false;

export const hasAnySelector = (
  el: HTMLElement,
  selector: string | string[]
): boolean => {
  return Array.isArray(selector)
    ? selector.filter(sel => matches(el, sel)).length > 0
    : matches(el, selector);
};

export const closestAncestor = (
  el: HTMLElement,
  selector: string | string[]
): HTMLElement | null => {
  if (hasAnySelector(el, selector)) {
    return el;
  }
  while (
    (el = el.parentElement as HTMLElement) &&
    !hasAnySelector(el, selector)
  );
  return el || null;
};

export const maybeClassName = str => (str ? ` ${str}` : "");

/** Copied from mindgrub-react since parcel won't work */
/** Serialize a set of map keys into a list of DOM class names.  For each
 * property of the given object having a truthy value, the key will be included
 * in the list of classes.  If a prefix is provided, it will be prepended to
 * each class name and also included in the list itself. */
export const class_names = (classes: object, prefix: string) =>
  Object.entries(classes)
    .filter(([_, value]) => value)
    .map(([key]) => (prefix ? prefix : "") + key)
    .concat(prefix ? [prefix] : [])
    .join(" ");

/** Map pixel widths to sizes as defined in `/style/lib/breakpoints.styl` */
const screen_size_from = (width: number): ScreenSize => {
  if (width <= ScreenSize.xs) return ScreenSize.xs;
  if (width <= ScreenSize.sm) return ScreenSize.sm;
  if (width <= ScreenSize.md) return ScreenSize.md;
  if (width <= ScreenSize.lg) return ScreenSize.lg;
  if (width <= ScreenSize.xl) return ScreenSize.xl;
  return ScreenSize.xxl;
};

const current_window_width = () =>
  window.innerWidth ||
  (document.documentElement && document.documentElement.clientWidth) ||
  document.body.clientWidth;

export const current_screen_size = () =>
  screen_size_from(current_window_width());

export const scroll_top = (top: number = 0) => {
  document.body.scrollTop = top;
  document.documentElement.scrollTop = top;
};
