import * as React from "react";
import { class_names, maybeClassName } from "../../../utils/dom-helpers";
import { SvgLoadingSpinner } from "../icons";

const PENDING_ANIMATION_DURATION = 300;

export class RequestButton<P extends object = {}> extends React.Component<
  HTMLButtonProps &
    P & {
      pending: boolean;
      success?: boolean;
      successText: React.ReactNode;
    },
  {
    animationIsPlaying: boolean;
    cur_pending: boolean;
    width?: number;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      animationIsPlaying: false,
      cur_pending: props.pending
    };
    this.success_text = React.createRef();
    this.content = React.createRef();
  }
  success_text: React.RefObject<HTMLDivElement>;
  content: React.RefObject<HTMLDivElement>;
  anim_timer: any = null;

  componentDidMount(): void {
    this.setState({
      width: this.getWidth()
    });
  }

  componentWillUnmount(): void {
    if (this.anim_timer) {
      clearTimeout(this.anim_timer);
    }
  }

  componentDidUpdate() {
    const { cur_pending, animationIsPlaying } = this.state;
    const { pending } = this.props;
    // if the status is changing and there's no animation playing
    if (pending !== cur_pending && !animationIsPlaying) {
      // set the next status and play the animation
      this.playAnimation();
    }
  }

  playAnimation = () => {
    const { pending } = this.props;
    const { cur_pending, animationIsPlaying } = this.state;
    if (this.anim_timer) {
      clearTimeout(this.anim_timer);
    }
    // if an animation is in queue and no animation is currently playing
    if (cur_pending !== pending && !animationIsPlaying) {
      this.setState(
        {
          animationIsPlaying: true,
          cur_pending: pending,
          width: this.getWidth() || this.state.width
        },
        () => {
          this.anim_timer = setTimeout(() =>
            // after the duration of the animation, remove the status from the queue
            // and set it as the current status, then play the next animation
            {
              this.setState(
                { animationIsPlaying: false },
                this.maybePlayNextAnimation
              );
              this.anim_timer = null;
            }, PENDING_ANIMATION_DURATION);
        }
      );
    } else {
      this.anim_timer = setTimeout(() => {
        this.anim_timer = null;
        this.maybePlayNextAnimation();
      }, PENDING_ANIMATION_DURATION);
    }
  };

  maybePlayNextAnimation = () => {
    const { pending } = this.props;
    if (this.state.cur_pending !== pending) {
      this.playAnimation();
    }
  };

  getWidth = () => {
    if (
      this.success_text &&
      this.success_text.current &&
      this.content &&
      this.content.current
    ) {
      return Math.max(
        this.content.current.offsetWidth,
        this.success_text.current.offsetWidth
      );
    }
  };

  render() {
    const {
      className,
      success,
      successText,
      children,
      pending: _,
      ...props
    } = this.props;
    const { width, cur_pending } = this.state;
    return (
      <button
        {...props}
        className={`${class_names(
          {
            ["--pending"]: cur_pending,
            ["--success"]: success
          },
          "request-button"
        )}${maybeClassName(className)}`}
        style={width ? { width } : {}}
      >
        <div className="request-button__content" ref={this.content}>
          {children}
        </div>
        <div className="request-button__pending">{<SvgLoadingSpinner />}</div>
        <div className="request-button__success" ref={this.success_text}>
          {successText}
        </div>
      </button>
    );
  }
}

/*
export class RequestButtonWithIcon extends RequestButton<ButtonWithIconProps> {
  render() {
    const {
      className,
      pending,
      success,
      successText,
      children,
      icon,
      side = "left",
      ...props
    } = this.props;
    return null;
  }
}
*/
