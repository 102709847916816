import * as React from "react";
import { maybeClassName } from "../../../utils/dom-helpers";
import { Row } from "./responsive-grid";
import { project_from_list } from "../../../utils/common";
import { SvgLoadingSpinner, SvgLoadMore } from "../icons";

export const get_label_from_value = <T extends string | number>(
  options: InputOptions<T>,
  value: T
): string | null =>
  project_from_list(options, { value }, "label") as string | null;

export const get_value_from_label = <T extends string | number>(
  options: InputOptions<T>,
  label: string
): T | null => project_from_list(options, { label }, "value") as T | null;

export const Error404PageNotFound: React.SFC<{}> = () => (
  <div className="error-404">
    <span className="http-error-text">
      The page you requested does not exist. (Error: 404)
    </span>
  </div>
);
Error404PageNotFound.displayName = "Error404PageNotFound";

export const ColoredBackdropHeader: React.SFC<{
  className?: string;
}> = ({ className, children }) => (
  <Row className={`colored-backdrop-header${maybeClassName(className)}`}>
    <header>{children}</header>
  </Row>
);
ColoredBackdropHeader.displayName = "ColoredBackdropHeader";

export const DefaultPendingComponent: React.FunctionComponent<{}> = () => (
  <div className="loading-spinner">
    <SvgLoadingSpinner />
  </div>
);
DefaultPendingComponent.displayName = "DefaultPendingComponent";

export const LoadingDots: React.FunctionComponent<{}> = () => (
  <div className="loading-dots">
    <SvgLoadMore />
  </div>
);
LoadingDots.displayName = "LoadingDots";

export const FormSection: React.FC<
  { title?: React.ReactNode } & MaybeClass
> = ({ title, className, children, ...props }) => (
  <section {...props} className={`form-section${maybeClassName(className)}`}>
    {title && <div className="form-section__title">{title}</div>}
    <div className="form-section__content">{children}</div>
  </section>
);
FormSection.displayName = "FormSection";

export const DetailSection: React.FC<
  { title: React.ReactNode } & MaybeClass
> = ({ title, className, children }) => (
  <section className={`detail-section${maybeClassName(className)}`}>
    <dt className="detail-section__title">{title}</dt>
    <dd className="detail-section__content">{children}</dd>
  </section>
);
DetailSection.displayName = "DetailSection";

export const InputRow: React.FC<
  MaybeClass & { description?: React.ReactNode }
> = ({ className, description, children }) => (
  <div className={`input-row${maybeClassName(className)}`}>
    {description && <div className="input-row__description">{description}</div>}
    {children}
  </div>
);
InputRow.displayName = "InputRow";
