import * as React from "react";
import { maybeClassName } from "../../utils/dom-helpers";
import { Icon } from "./Icon";

const ACTION_TYPE_ICON_MAP: ObjectOf<FontIconName> = {
  daily_health_rating: "logging",
  loneliness_rating: "loneliness",
  weekly_check_in: "family",
  daily_followup: "activity",
  undefined: "logging"
};

export const ActionTypeIcon: React.FC<
  {
    type: JobType;
  } & MaybeClass
> = ({ type, className, ...props }) => (
  <div
    {...props}
    data-job-type={type}
    className={`action__icon${maybeClassName(className)}`}
  >
    <Icon name={ACTION_TYPE_ICON_MAP[type]} />
  </div>
);
