import * as EVENTS from "../../model/events/event-names";
import { EventBus } from "@thi.ng/interceptors";
import { deep_equals } from "../../utils/common";
import { scroll_top } from "../../utils/dom-helpers";
import {
  ANON_ONLY_PAGES,
  DEFAULT_APP_LOCATION,
  SETUP_PAGES,
  LOGIN_LOCATION
} from "./constants";
import {
  app_location_to_title,
  app_location_to_url,
  url_to_app_location
} from "./utils";

export * from "./constants";
export * from "./utils";

const on_click_link = (update: (location: AnyAppLocation) => void) => e => {
  let target = e.target as HTMLAnchorElement;

  if (!target) {
    return;
  }

  while (target && target.nodeName !== "A") {
    target = target.parentNode as HTMLAnchorElement;
    if (!target) return;
  }

  /* Don't process external links */
  if (target.host !== window.location.host) return;

  /* Ignore if link opens in another tab. */
  if (!target || target.target) {
    return;
  }

  const place: any = target.pathname.split("/")[1];
  // Setup pages should hard link, so that the app reinitializes
  if (SETUP_PAGES.includes(place)) {
    return true;
  }
  e.preventDefault();
  update(url_to_app_location(target));
  return false;
};

/** Update window url to reflect the `app_location` in state */
const update_url = (location: AppLocation, title: string) => {
  const new_url = app_location_to_url(location);
  const old_url =
    window.location.pathname + window.location.search + window.location.hash;

  if (new_url && new_url !== old_url) {
    if (old_url === "/login" || old_url === "/") {
      window.history.replaceState({}, title, new_url);
    } else {
      window.history.pushState({}, title, new_url);
    }
    document.title = title;
  } else if (title !== document.title) {
    document.title = title;
  }
};

export const watch_app_location = (
  bus: EventBus,
  initial_location: AppLocation
): void => {
  // Macro for dispatching event to update `app_location`
  const go_to_page = (location: AnyAppLocation) => {
    if (
      (!location || !location.place) &&
      bus.deref().app_location.place === "login"
    ) {
      return;
    }
    bus.dispatchNow([
      EVENTS.GO_TO_PAGE,
      !location || !location.place ? DEFAULT_APP_LOCATION : location
    ]);
  };

  // Macro for dispatching event to update `app_location`
  // this sets the value directly without refreshing the auth token first
  const force_update_location = (location: AppLocation) =>
    bus.dispatchNow([
      EVENTS.SET_APP_LOCATION,
      !location || !location.place ? DEFAULT_APP_LOCATION : location
    ]);

  // Watcher to monitor state changes and update app_location/url accordingly
  const on_update_location = (
    _,
    old_state: AppContexts,
    new_state: AppContexts
  ) => {
    const { app_location, modal, user } = new_state;
    const new_place = app_location.place;
    if (!old_state.user && user && new_place === "login") {
      // if the user just logged in, send the user to the page they navigated to before
      // they were sent to the login page (defaults to home)
      force_update_location(
        ANON_ONLY_PAGES.includes(initial_location.place)
          ? DEFAULT_APP_LOCATION
          : initial_location
      );
      initial_location = LOGIN_LOCATION;
      scroll_top();
    } else if (
      !deep_equals(
        { ...old_state.app_location, form_dirty: null, record: null },
        { ...new_state.app_location, form_dirty: null, record: null }
      )
    ) {
      // if the location changed, update the url
      update_url(
        app_location,
        app_location_to_title(app_location, user ? user.id : -1)
      );
      if (modal.open === true) {
        bus.dispatchNow([EVENTS.CLOSE_APP_MODAL]);
      }
      if (
        !deep_equals(
          {
            ...old_state.app_location,
            form_dirty: null,
            record: null,
            query: { ...(old_state.app_location["query"] || {}), page: 0 }
          },
          {
            ...new_state.app_location,
            form_dirty: null,
            record: null,
            query: { ...(new_state.app_location["query"] || {}), page: 0 }
          }
        )
      ) {
        scroll_top();
      }
    }
  };

  bus.state.addWatch("app_location", on_update_location);

  document.body.addEventListener("click", e => {
    on_click_link(go_to_page)(e);
  });

  window.addEventListener("popstate", () => {
    const state = bus.state.deref();
    const location = url_to_app_location(window.location);
    if (location) {
      if (
        state.user &&
        ANON_ONLY_PAGES.includes((location as AppLocation).place)
      ) {
        update_url(
          state.app_location,
          app_location_to_title(state.app_location, state.user.id)
        );
      } else {
        go_to_page(location);
      }
    }
  });

  window.onbeforeunload = e => {
    const state = bus.state.deref();
    if (state.app_location.form_dirty === true) {
      e.preventDefault();
      return "Are you sure you want to leave the page? All progress on this form will be lost.";
    }
  };

  const { app_location, user } = bus.state.deref();
  const title = app_location_to_title(app_location, user ? user.id : -1);
  update_url(app_location, title);
  document.title = title;
};
