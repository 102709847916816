import * as React from "react";
import { RequestComponent } from "../../components/RequestComponent";
import { connect, EVENTS } from "../../../model";
import { path_or } from "../../../utils/common";
import { RequestButton } from "../../components/common/request-button";
import { ConnectionModalContent } from "./ConnectionModalContent";
import { FormErrors } from "../../components/Errors";

class ConnectionRequestResponseModal extends RequestComponent<
  never,
  ModalBodyProps & { action: Action }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: `${props.action.id}`
    };
  }

  componentDidUpdate(prevProps) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      this.props.dispatchNow([EVENTS.REFRESH_ACTIONS, true]);
      setTimeout(this.props.onDismiss, 1200);
    }
  }

  sendRequest_ = (accept: boolean) => () => {
    this.setState(
      {
        request_id: `${this.state.request_id}-${accept ? "accept" : "decline"}`
      },
      () =>
        this.sendRequest(
          "ResolveConnectionRequest",
          {
            resolve_connection_request: { accept },
            connection_request_id: path_or(
              -1,
              ["metadata", "request_id"],
              this.props.action
            )
          },
          this.state.request_id
        )
    );
  };

  requestIsFor(accept: boolean) {
    return accept
      ? this.state.request_id.endsWith("accept")
      : this.state.request_id.endsWith("decline");
  }

  render() {
    const { action } = this.props;
    const requestor = path_or(
      undefined,
      ["metadata", "requesting_user"],
      action
    );

    return (
      <ConnectionModalContent
        user={requestor}
        text={action.action_text}
        buttons={[
          <RequestButton
            key="accept"
            className="filled"
            pending={this.requestIsFor(true) && this.requestIsPending()}
            success={this.requestIsFor(true) && this.requestSucceeded()}
            successText="Success!"
            onClick={this.sendRequest_(true)}
          >
            Accept
          </RequestButton>,
          <RequestButton
            key="decline"
            className="bordered"
            pending={this.requestIsFor(false) && this.requestIsPending()}
            success={this.requestIsFor(false) && this.requestSucceeded()}
            successText="Success!"
            onClick={this.sendRequest_(false)}
          >
            Decline
          </RequestButton>
        ]}
        error={<FormErrors response={this.getRequestErrors()} />}
      />
    );
  }
}
const ConnectionRequestResponseModalConnected = connect(
  ConnectionRequestResponseModal,
  true,
  ["request"]
);

export const makeConnectionRequestResponseModal = (
  action: Action
): React.ComponentType<ModalBodyProps> => {
  const ResponseModal: React.FC<ModalBodyProps> = (props: ModalBodyProps) => (
    <ConnectionRequestResponseModalConnected {...props} action={action} />
  );
  ResponseModal.displayName = "ResponseModal";
  return ResponseModal;
};
