import * as React from "react";
import { CONTEXTS, EVENTS } from "../../model";
import { DefaultDropdownButton, DropdownMenu } from "./common/dropdown-menu";
import { SvgLoadMore } from "./icons";
import { get_user_full_name } from "../utils";
import { Avatar } from "./Avatar";
import { Icon } from "./Icon";
import { ActionButtonWithIcon } from "./common/button-with-icon";
import { NotificationCenterItem } from "./NotificationCenterItem";
import { HealthIcon } from "./HealthIcon";
import { HealthRaterModalSpec } from "./HealthRaterModal";
import { class_names } from "../../utils/dom-helpers";
import { create_nav_links } from "./SiteNavigation";
import { ScreenSize } from "../../constants";
import { path_or } from "../../utils/common";
import { REFRESH_ACTIONS_REQUEST_ID } from "../../model/effects/constants";
import { useContext, useEffect } from "react";

export const NotificationCenter: React.FC<{}> = () => {
  const request = useContext(CONTEXTS.request);
  const notifications = useContext(CONTEXTS.notifications) || [];
  const user = useContext(CONTEXTS.user);
  const app_location = useContext(CONTEXTS.app_location);
  const window_size = useContext(CONTEXTS.window_size);
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);

  const requestIsPending = () =>
    path_or(false, [REFRESH_ACTIONS_REQUEST_ID, "pending"], request);

  const requestSucceeded = () => !requestIsPending() && !!notifications;

  if (!user) {
    return null;
  }

  useEffect(() => {
    if (!requestIsPending() && !notifications) {
      dispatchNow([EVENTS.REFRESH_ACTIONS, true]);
    }
  }, []);

  const classes_for = (name: string) =>
    class_names({ "--other": true, [`--${name}`]: true }, "notif-menu__item");

  let items: React.ReactNode[] = [
    <div className="notif-menu__item notif-menu__item--noclick">
      You don't have any recent activity.
    </div>
  ];
  if (requestIsPending()) {
    items = [
      <div className="notif-menu__item notif-menu__item--loading notif-menu__item--noclick">
        <SvgLoadMore />
      </div>
    ];
  } else if (requestSucceeded() && notifications.length > 0) {
    items = notifications.map((item, i) => (
      <NotificationCenterItem
        key={item.id}
        item={item}
        last={i === notifications.length - 1}
        window_size={window_size}
      />
    ));
  }
  items = items.concat([
    <a href="/activity" className={classes_for("all")}>
      <Icon name="activity" />
      <span>View all recent activity...</span>
    </a>,
    <a href="/account" className={classes_for("profile")}>
      <Avatar
        url={user.profile_photo_url}
        name={get_user_full_name(user)}
        size="sm"
      />
      <span>View/edit your profile</span>
    </a>,
    <ActionButtonWithIcon
      className={classes_for("health")}
      icon={
        <HealthIcon
          rating={user.health_rating == null ? -1 : user.health_rating}
          date={user.health_rating_created_at}
        />
      }
      event={[EVENTS.OPEN_APP_MODAL, HealthRaterModalSpec]}
    >
      Daily Health Rating
    </ActionButtonWithIcon>,
    <ActionButtonWithIcon
      icon={<Icon name="logout" iconSize="sm" />}
      // @ts-ignore
      event={[EVENTS.LOGOUT]}
      className={classes_for("logout")}
    >
      Logout
    </ActionButtonWithIcon>
  ]);
  if (window_size < ScreenSize.md) {
    items = create_nav_links(app_location.place, !!user).concat(items);
  }
  return (
    <DropdownMenu
      items={items}
      className="notif-menu"
      onOpen={() => {
        if (!requestIsPending()) {
          dispatchNow([EVENTS.REFRESH_ACTIONS, true]);
        }
      }}
      button={
        window_size < ScreenSize.md ? (
          <div className="site-header__menu__button">
            {requestSucceeded() && notifications.length > 0 && (
              <div className="notif-menu__count-icon">
                {notifications.length}
              </div>
            )}
            <DefaultDropdownButton />
          </div>
        ) : (
          <div
            className="site-header__link site-header__link__user"
            data-active={app_location.place === "account"}
          >
            <div className="notif-menu__count-icon">
              {requestSucceeded() ? notifications.length : null}
            </div>
            <Avatar
              url={user.profile_photo_url}
              name={get_user_full_name(user)}
              size="sm"
            />
            <span data-text={get_user_full_name(user)}>
              {get_user_full_name(user)}
            </span>
          </div>
        )
      }
    />
  );
};
