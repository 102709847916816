import * as React from "react";

export const EmptyList: React.FC<{
  icon?: React.ReactNode;
  button?: React.ReactNode;
}> = ({ icon, button, children }) => (
  <div className="empty-list">
    <div className="record-list__empty">
      {icon && <div className="record-list__empty__icon">{icon}</div>}
      {button ? (
        <div className="record-list__empty__content">
          <div className="record-list__empty__text">{children}</div>
          <div className="record-list__empty__button">{button}</div>
        </div>
      ) : (
        <div className="record-list__empty__text-only">{children}</div>
      )}
    </div>
  </div>
);
EmptyList.displayName = "EmptyList";
