import * as React from "react";
import { useContext } from "react";
import { SiteNavigationLinks } from "./SiteNavigation";
import { IMAGES_PREFIX, ScreenSize } from "../../constants";
import { CONTEXTS } from "../../model";
import { NotificationCenter } from "./NotificationCenter";

export const SiteHeader: React.FunctionComponent<{}> = () => {
  const app_location = useContext(CONTEXTS.app_location);
  const window_size = useContext(CONTEXTS.window_size);
  const user = useContext(CONTEXTS.user);
  return (
    <div className="site-header">
      <header className="site-header__container">
        <div className="site-header__left">
          <div className="site-header__logo">
            <a
              href={
                app_location.place === "privacy-policy" ||
                app_location.place === "terms-of-service"
                  ? "/login"
                  : "/"
              }
            >
              <img
                className="site-header__logo__icon"
                src={`${IMAGES_PREFIX}/logo_thread.svg`}
                alt="Thread Logo"
              />
            </a>
          </div>
          {window_size > ScreenSize.sm &&
            user &&
            app_location.place !== "register" && (
              <SiteNavigationLinks place={app_location.place} />
            )}
        </div>
        {user && app_location.place !== "register" && (
          <div className="site-header__right">
            {/*window_size < ScreenSize.md && (
            <SiteHeaderMenu place={app_location.place} />
          )*/}
            <NotificationCenter />
          </div>
        )}
      </header>
    </div>
  );
};
