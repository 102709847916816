import {
  ExpenseCategories,
  ExpenseReimbursementTypes,
  MAX_FILE_SIZE
} from "../../../constants";
import { RequestComponent } from "../../components/RequestComponent";
import { generate_id, get_file_name, path_or } from "../../../utils/common";
import * as React from "react";
import { connect, EVENTS } from "../../../model";
import { FormSection, InputRow } from "../../components/common/common";
import { DropdownSelectInput } from "../../components/common/dropdown-select-input";
import {
  FloatingTitle,
  WithFloatingTitle
} from "../../components/common/floating-title";
import { PeopleListViewItem } from "../People/List";
import { CreatorMarker } from "../../components/CreatorMarker";
import { BooleanCheckbox } from "../../components/BooleanCheckbox";
import {
  InputWithFormHelpers,
  TextAreaWithFormHelpers
} from "../../components/common/inputs";
import { FormSectionList } from "../../components/FormSectionList";
import { ButtonWithIcon } from "../../components/common/button-with-icon";
import { Icon } from "../../components/Icon";
import { FieldError, FormErrors } from "../../components/Errors";
import { ActionButton } from "../../components/common/action-link";
import { RequestButton } from "../../components/common/request-button";
import { ApiReqViewProps } from "../../components/WithApiRequest";
import { Filename } from "../../components/common/file-name";
import { ApiRequestErrorPage } from "../../components/ApiRequestErrorPage";

type ExpenseBody = Omit<
  ApiMethodParameters<"PostTouchpointExpense">,
  "$queryParameters" | "$domain"
> & { receipt_image_file: File };

const ExpCategoryOptions: InputOptions<string> = Object.entries(
  ExpenseCategories
).map(([k, v]) => ({ value: k, label: v }));

const ExpReimbOptions: InputOptions<string> = Object.entries(
  ExpenseReimbursementTypes
).map(([k, v]) => ({ value: k, label: v }));

class ExpenseForm extends RequestComponent<
  "request" | "user" | "app_location",
  ApiReqViewProps<"GetTouchpointById"> & {
    app_location: AppLocationAt<
      "activity/id/expense/create" | "activity/id/expense/id/edit"
    >;
  },
  {
    expense: Partial<ExpenseBody>;
    image_preview?: {
      name: string;
      data: string;
    };
    form_submitted: boolean;
    file_too_big?: boolean;
    form_dirty: boolean;
  }
> {
  constructor(props) {
    super(props);
    const {
      app_location: { id: touchpoint_id, edit, expense: expense_id },
      response: { data }
    } = props;
    this.touchpoint_id = touchpoint_id;
    let expense: Partial<Expense> = {
      is_supplemental: false,
      is_pre_approved: false
    };
    let image_preview: any = undefined;
    const touchpoint = path_or(data, ["app_location", "record"], props);
    const record =
      touchpoint && touchpoint.expenses
        ? touchpoint.expenses.find(e => e.id === expense_id)
        : undefined;
    if (record) {
      const {
        receipt_image_url,
        created_at,
        created_by,
        exported_on,
        updated_at,
        touchpoint_id,
        ...form_fields
      } = record;
      expense = form_fields;
      if (receipt_image_url) {
        image_preview = {
          name: get_file_name(receipt_image_url),
          data: receipt_image_url
        };
      }
    }
    this.state = {
      expense,
      request_id: `${edit ? "update" : "create"}-expense-${generate_id()}-for-${
        this.touchpoint_id
      }`,
      image_preview,
      form_submitted: false,
      form_dirty: false
    };
    this.file_input = React.createRef();
    this.timers = {};
  }
  file_input: React.RefObject<HTMLInputElement>;
  touchpoint_id: number;
  timers: ObjectOf<any>;

  componentDidMount(): void {
    this.onChangeInput("id")(this.touchpoint_id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      this.props.dispatchNow([
        EVENTS.SET_APP_LOCATION,
        {
          ...this.props.app_location,
          form_dirty: false
        }
      ]);
      this.props.dispatchLater(
        [
          EVENTS.GO_TO_PAGE,
          {
            place: "activity",
            id: this.touchpoint_id,
            // @ts-ignore
            expense: this.props.request[this.state.request_id].response.data.id
          }
        ],
        1200
      );
    }

    if (!prevState.form_dirty && this.state.form_dirty) {
      this.props.dispatchNow([
        EVENTS.SET_APP_LOCATION,
        {
          ...this.props.app_location,
          form_dirty: true
        }
      ]);
    }
  }

  onChangeInput = <K extends keyof ExpenseBody>(name: K) => (
    value: ExpenseBody[K]
  ) => {
    const { expense } = this.state;
    this.setState({
      form_dirty: name !== "id",
      expense: {
        ...expense,
        is_pre_approved:
          expense.is_pre_approved &&
          (name === "is_supplemental" ? !!value : expense.is_supplemental),
        [name]: value
      }
    });
  };

  uploadPhoto = e => {
    const file = e.target.files[0];
    if (file.iconSize > MAX_FILE_SIZE) {
      this.setState({ file_too_big: true });
      return;
    }

    const reader = new FileReader();

    reader.onload = ev => {
      ev.target &&
        this.setState({
          form_dirty: true,
          expense: {
            ...this.state.expense,
            receipt_image_file: file
          },
          image_preview: {
            name: file.name,
            data: ev.target["result"] as string
          },
          file_too_big: false
        });
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  previewImage = e => {
    e.preventDefault();
    const { image_preview } = this.state;
    if (!image_preview) {
      return false;
    }
    this.props.dispatchNow([
      EVENTS.OPEN_APP_MODAL,
      {
        title: image_preview.name,
        className: "expense-create__receipt__preview-modal",
        body: () => <img src={image_preview.data} alt="Receipt image preview" />
      }
    ]);
  };

  onSubmitForm = e => {
    const edit = path_or(false, ["app_location", "edit"], this.props);
    e.preventDefault();
    !this.state.form_submitted && this.setState({ form_submitted: true });
    this.sendRequest(
      edit ? "UpdateTouchpointExpense" : "PostTouchpointExpense",
      {
        ...(edit && this.props.app_location.expense !== "create"
          ? { expense_id: this.props.app_location.expense }
          : {}),
        ...(this.state.expense as ExpenseBody)
      },
      this.state.request_id
    );
    return false;
  };

  render() {
    const {
      response,
      user,
      // @ts-ignore
      app_location
    } = this.props;
    const { participants, created_by } = response.data;
    const { expense, image_preview, form_submitted, file_too_big } = this.state;
    const {
      receipt_amount,
      total_amount_requested,
      expense_category,
      reimbursement_type,
      vendor_name,
      is_pre_approved,
      is_supplemental,
      description
    } = expense;

    if (!user || !participants.some(p => p.id === user.id)) {
      return (
        <ApiRequestErrorPage
          error={new Error("You are not authorized to perform this action.")}
          //@ts-ignore
          response={{ status: 403 }}
        />
      );
    }

    return (
      <form
        id="expense-create"
        className="expense-create__form page-form"
        data-submitted={form_submitted}
        onSubmit={this.onSubmitForm}
      >
        <FormSection title="Details" className="expense-create__details">
          <InputRow description="What type of expense is this?">
            <DropdownSelectInput
              required={true}
              options={ExpCategoryOptions}
              defaultBlankOption={true}
              value={expense_category as ExpenseCategory}
              onSelect={opt =>
                opt && this.onChangeInput("expense_category")(opt.value)
              }
              inputProps={{ placeholder: "Expense Category" }}
            >
              <FloatingTitle title="Expense Category" />
            </DropdownSelectInput>
          </InputRow>
        </FormSection>
        <FormSection
          title="Participants"
          className="expense-create__participants"
        >
          <div className="touchpoint-detail__participants__list user-checklist">
            {participants.map((p, i) => (
              <PeopleListViewItem key={p.id} item={p}>
                {p.id === created_by.id ? (
                  <CreatorMarker type="touchpoint" />
                ) : user && p.id === user.id ? (
                  <CreatorMarker type="expense" />
                ) : null}
              </PeopleListViewItem>
            ))}
          </div>
        </FormSection>
        <FormSection className="expense-create__booleans">
          <InputRow className="expense-create__supplemental">
            <BooleanCheckbox
              id="is_supplemental"
              label={
                <div className="input-row__description">
                  Supplemental Expense?
                </div>
              }
              value={is_supplemental ? "true" : "false"}
              onChangeInput={this.onChangeInput("is_supplemental")}
            />
          </InputRow>
          {is_supplemental && (
            <InputRow className="expense-create__preapproved">
              <BooleanCheckbox
                id="is_pre_approved"
                label={
                  <div className="input-row__description">
                    Pre-Approval by Site Coordinator?
                  </div>
                }
                value={is_pre_approved ? "true" : "false"}
                onChangeInput={this.onChangeInput("is_pre_approved")}
              />
            </InputRow>
          )}
        </FormSection>
        <FormSection
          title="Receipt Information"
          className="expense-create__receipt__info"
        >
          <WithFloatingTitle title="Vendor Name">
            <InputWithFormHelpers
              placeholder="Vendor Name"
              required={true}
              type="text"
              value={vendor_name}
              onChange={e => this.onChangeInput("vendor_name")(e.target.value)}
              submitOnEnter={false}
            />
          </WithFloatingTitle>
          <WithFloatingTitle title="Receipt Amount ($)">
            <InputWithFormHelpers
              placeholder="Receipt Amount ($)"
              required={true}
              type="number"
              step={0.01}
              min={0}
              value={receipt_amount}
              onChange={e =>
                this.onChangeInput("receipt_amount")(e.target.valueAsNumber)
              }
              submitOnEnter={false}
            />
          </WithFloatingTitle>
          <WithFloatingTitle title="Total Amount Requested ($)">
            <InputWithFormHelpers
              placeholder="Total Amount Requested ($)"
              required={true}
              type="number"
              step={0.01}
              min={0}
              value={total_amount_requested}
              onChange={e =>
                this.onChangeInput("total_amount_requested")(
                  e.target.valueAsNumber
                )
              }
              submitOnEnter={false}
            />
          </WithFloatingTitle>
          <DropdownSelectInput
            required={true}
            options={ExpReimbOptions}
            defaultBlankOption={true}
            value={reimbursement_type as ExpenseReimbursement}
            onSelect={opt =>
              opt && this.onChangeInput("reimbursement_type")(opt.value)
            }
            inputProps={{ placeholder: "Reimbursement Type" }}
          >
            <FloatingTitle title="Reimbursement Type" />
          </DropdownSelectInput>
        </FormSection>
        <FormSectionList
          className="expense-create__receipt__image"
          items={image_preview ? [image_preview] : []}
          title="Receipt Photo"
          renderItem={f => (
            <div
              key={0}
              className="expense-create__receipt__preview empty-list"
              data-empty={false}
            >
              <div className="expense-create__receipt__filename">
                <Filename>{f.name}</Filename>
              </div>
              <button
                type="button"
                className="bordered"
                onClick={this.previewImage}
              >
                Preview Image
              </button>
            </div>
          )}
          button={
            <ButtonWithIcon
              type="button"
              className="filled"
              icon={<Icon name="add" />}
              side="left"
              onClick={e => {
                e.preventDefault();
                this.file_input &&
                  this.file_input.current &&
                  this.file_input.current.click();
              }}
            >
              Upload Photo
            </ButtonWithIcon>
          }
          emptyLabel="No receipt photo has been added."
        >
          <input
            ref={this.file_input}
            id="upload-receipt-photo"
            type="file"
            accept="image/*"
            onChange={this.uploadPhoto}
            required={!image_preview}
            tabIndex={-1}
          />
          <FieldError
            hasError={!expense.receipt_image_file && !image_preview}
            formSubmitted={form_submitted}
          >
            You must upload a receipt image.
          </FieldError>
          <FieldError hasError={!!file_too_big}>
            Maximum file size is 1MB.
          </FieldError>
        </FormSectionList>
        <InputRow
          description="Describe this expense:"
          className="expense-create__description"
        >
          <WithFloatingTitle title="Description">
            <TextAreaWithFormHelpers
              onChange={e => this.onChangeInput("description")(e.target.value)}
              showCharLimit
              value={description}
            />
          </WithFloatingTitle>
        </InputRow>
        <FormErrors
          response={this.getRequestErrors()}
          formSubmitted={form_submitted}
        />
        <div className="form-section__footer">
          <div className="expense-create__edit-warning">
            Note: expense details must be finalized by Sunday at 3PM EST.
          </div>
          <div className="form-section__footer__buttons">
            <ActionButton
              type="button"
              data-button="cancel"
              className="bordered"
              event={[
                EVENTS.GO_TO_PAGE,
                {
                  ...app_location,
                  edit: false,
                  form_dirty: false,
                  expense:
                    app_location["expense"] === "create"
                      ? undefined
                      : app_location["expense"]
                }
              ]}
            >
              Cancel
            </ActionButton>
            <RequestButton
              pending={this.requestIsPending()}
              success={this.requestSucceeded()}
              successText="Success!"
              className="filled"
              type="submit"
              onClick={() => {
                !this.state.form_submitted &&
                  this.setState({ form_submitted: true });
              }}
            >
              {app_location["edit"] ? "Update" : "Create"} Expense
            </RequestButton>
          </div>
        </div>
      </form>
    );
  }
}

export const ExpenseFormConnected = connect(
  ExpenseForm,
  true,
  ["request", "user", "app_location"]
);
