import * as React from "react";
import { InputWithFormHelpers } from "../../components/common/inputs";
import { UserChecklist } from "../../components/UserChecklist";
import { add_item_to, remove_item_from } from "../../../utils/common";
import { Icon } from "../../components/Icon";

class ParticipantsModal extends React.PureComponent<
  ModalBodyProps & {
    selected: () => User[];
    onSuccess: (participants: User[]) => void;
  },
  { selected: User[]; filter: string; error?: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected(),
      filter: ""
    };
  }

  onChangeParticipants = (user: User, checked: boolean) => {
    this.setState({
      selected: checked
        ? add_item_to(this.state.selected, user)
        : remove_item_from(this.state.selected, v => v.id === user.id)
    });
  };

  onChangeFilter = e => this.setState({ filter: e.target.value });

  render() {
    const { onDismiss, onSuccess } = this.props;
    const { filter, selected } = this.state;
    return (
      <>
        <div className="people__modal__search">
          <div className="people__modal__search__bar">
            <Icon name="search" />
            <InputWithFormHelpers
              value={filter}
              onChange={this.onChangeFilter}
            />
          </div>
        </div>
        <div className="people__modal__body">
          <UserChecklist
            id="user-checklist"
            method="GetUsers"
            selected={selected}
            filter={filter}
            onChange={this.onChangeParticipants}
          />
        </div>
        <div className="people__modal__footer">
          <button
            onClick={onDismiss}
            className="bordered people__modal__dismiss"
          >
            Cancel
          </button>
          <button
            onClick={e => {
              onSuccess(this.state.selected);
              onDismiss(e);
            }}
            className="filled people__modal__success"
            disabled={selected.length === 0 || selected.length > 50}
          >
            Add Participants
          </button>
        </div>
      </>
    );
  }
}

export const makeParticipantsModal = (
  selected: () => User[],
  onSuccess: (participants: User[]) => void
): React.FC<ModalBodyProps & Context<"request", true>> => {
  const participantsModal: React.FC<ModalBodyProps> = ({ ...props }) => (
    <ParticipantsModal {...props} onSuccess={onSuccess} selected={selected} />
  );
  participantsModal.displayName = "ParticipantsModal";
  return participantsModal;
};
