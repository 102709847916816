import ThriveClient, * as Client from "./client";
import { get_error_response_status } from "../utils/common";

// TODO: actually inject these so we don't have to initialize them

class Api {
  constructor() {
    this.client = new ThriveClient(`${window.location.origin}/api`);
    this.client.setRequestHeadersHandler(this.handleRequestHeaders);
    this.client.addErrorHandler(this.onUnauthorized);
  }

  client: ThriveClient;
  user?: {
    token: string;
    csrf?: string;
    record: AppUser;
  };

  onUnauthorized: Client.CallbackHandler = (err, res?) => {
    if (get_error_response_status(err, res) === 401) {
      this.user = undefined;
      window["zEAuthenticateWithJwt"] = () => {};
      window["zEAuthLogout"] && window["zEAuthLogout"]();
    }
  };

  set_user = (r: ResponseWithBody<StdResponse<Token>>) => {
    if (r.statusCode === 200 && r.body && r.body.data && r.body.data.user) {
      const { token, user, csrf } = r.body.data;
      this.user = { token, csrf, record: user };
      this.setZendeskJwt(user.zendesk_uuid);
      return r;
    }
    console.error(r.error);
    throw new Error(
      r.status !== 200
        ? `RegistrationToken response had status ${r.status}`
        : `RegistrationToken response was not valid`
    );
  };

  google_login = async (
    id_token: string
  ): Promise<ResponseWithBody<StdResponse<Token>>> =>
    api.client
      .AuthWithGoogle({ google_auth_credential: { id_token } })
      .then(this.set_user);

  login = async (
    email: string,
    password: string
  ): Promise<ResponseWithBody<StdResponse<Token>>> => {
    return new Promise((resolve, reject) =>
      this.client.request(
        "GET",
        api.client.LoginURL({}),
        undefined,
        {
          Authorization: `Basic ${btoa(`${email}:${password}`)}`,
          Credentials: "same-origin"
        },
        undefined,
        {},
        reject,
        resolve
      )
    ).then(this.set_user);
  };

  logout = async (): Promise<void> => {
    return new Promise((resolve, reject) =>
      this.client.request(
        "POST",
        api.client.LogoutURL({}),
        undefined,
        {},
        undefined,
        {},
        reject,
        resolve
      )
    ).then((r: ResponseWithBody<StdResponse<any>>) => {
      if (r.statusCode === 200) {
        window["zEAuthLogout"] && window["zEAuthLogout"]();
        if (this.user) {
          delete this.user.csrf;
          delete this.user.token;
          delete this.user.record;
          delete this.user;
        }
      }
    });
  };

  forgot_password = async (
    email
  ): Promise<ResponseWithBody<StdResponse<Token>>> => {
    return new Promise((resolve, reject) =>
      this.client.request(
        "GET",
        api.client.ForgotPasswordURL({ email }),
        undefined,
        {},
        undefined,
        {},
        reject,
        resolve
      )
    ).then((r: ResponseWithBody<StdResponse<Token>>) => {
      // TODO: convert this to instanceof check
      // @ts-ignore
      if (r.statusCode === 200) {
        return r;
      } else {
        console.error(r.error);
        throw new Error(`forgotPassword response had status ${r.status}`);
      }
    });
  };

  get_user_with_temp_token = async (
    email,
    token
  ): Promise<ResponseWithBody<StdResponse<Token>>> => {
    return new Promise((resolve, reject) =>
      this.client.request(
        "GET",
        api.client.ExchangeRegistrationTokenURL({ email }),
        undefined,
        { Authorization: `Bearer ${token}` },
        undefined,
        {},
        reject,
        resolve
      )
    ).then((r: ResponseWithBody<StdResponse<Token>>) => {
      // TODO: convert this to instanceof check
      // @ts-ignore
      if (r.statusCode === 200 && r.body && r.body.data && r.body.data.token) {
        return r;
      } else {
        console.error(r.error);
        throw new Error(
          r.status !== 200
            ? `exchangeRegistrationToken response had status ${r.status}`
            : `exchangeRegistrationToken response did not contain auth token`
        );
      }
    });
  };
  set_password = async (
    id,
    password,
    token
  ): Promise<ResponseWithBody<StdResponse<User>>> => {
    return new Promise((resolve, reject) =>
      this.client.request(
        "PUT",
        api.client.SetUserPasswordURL({ id, set_password: { password } }),
        { password },
        { Authorization: `Bearer ${token}` },
        undefined,
        {},
        reject,
        resolve
      )
    ).then((r: ResponseWithBody<StdResponse<User>>) => {
      // TODO: convert this to instanceof check
      // @ts-ignore
      if (r.statusCode === 200 && r.body && r.body.data) {
        return r;
      } else {
        console.error(r.error);
        throw new Error(
          r.status !== 200
            ? `SetUserPassword response had status ${r.status}`
            : `SetUserPassword response was not valid`
        );
      }
    });
  };

  refresh_auth = (
    token?: string
  ): Promise<ResponseWithBody<StdResponse<Token>>> => {
    return new Promise((resolve, reject) =>
      this.client.request(
        "GET",
        api.client.RefreshTokenURL({}),
        undefined,
        token
          ? { Authorization: `Bearer ${token}` }
          : {
              ["x-use-cookie"]: "true"
            },
        undefined,
        {},
        reject,
        resolve
      )
    ).then((r: ResponseWithBody<StdResponse<Token>>) => {
      // TODO: convert this to instanceof check
      // @ts-ignore
      if (r.statusCode === 200 && r.body && r.body.data && r.body.data.user) {
        const { token, user, csrf } = r.body.data;
        let get_zendesk_jwt = false;
        if (!this.user) {
          get_zendesk_jwt = true;
        }
        this.user = {
          token,
          csrf,
          record: user
        };
        if (get_zendesk_jwt) {
          this.setZendeskJwt(user.zendesk_uuid);
        }
        return r;
      } else {
        console.error(r.error);
        throw new Error(
          r.status !== 200
            ? `RegistrationToken response had status ${r.status}`
            : `RegistrationToken response was not valid`
        );
      }
    });
  };

  setZendeskJwt = uuid => {
    try {
      if (!uuid) {
        window["zEGetAuthJwt"] = cb => {
          cb("");
        };
      }
      window["zEGetAuthJwt"] = cb => {
        this.client.AuthenticateZendesk({ user_token: uuid }).then(response => {
          if (response.statusCode === 200 && response.body.jwt) {
            const { jwt } = response.body;
            cb(jwt);
          }
        });
      };
    } catch (e) {
      console.error(`Error while trying to set Zendesk JWT:`, e);
    }
  };

  handleRequestHeaders: Client.RequestHeadersHandler = ({
    xhr,
    ...headers
  }: Client.RequestHeaders) => {
    if (xhr === "true") {
      return headers;
    }
    headers.Credentials = "same-origin";
    if (this.user && this.user.csrf) {
      headers["x-xsrf-token"] = this.user.csrf;
      headers["x-use-cookie"] = "true";
    } else if (this.user && this.user.token) {
      headers["Authorization"] = `Bearer ${this.user.token}`;
    }
    return headers;
  };
}

const api = new Api();
export default api;
