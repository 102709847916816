import * as React from "react";
import { CONTEXTS } from "../../model";
import { Icon } from "./Icon";
import { useContext } from "react";

type NavLink = {
  href: string;
  text: string;
  icon: FontIconName;
};
const DEFAULT_LINKS: NavLink[] = [
  { href: "/activity", text: "Activity", icon: "activity" },
  { href: "/people", text: "People", icon: "people" },
  { href: "/communities", text: "Communities", icon: "family" }
  // { href: "/conversations", text: "Conversations", Icon: SvgConversations }
];

export const create_nav_links = (
  active: string,
  logged_in: boolean
): React.ReactNode[] =>
  logged_in || true
    ? DEFAULT_LINKS.map(({ icon, href, text }) => (
        <a
          key={href}
          className="site-header__link"
          data-active={href === `/${active}`}
          href={href}
          tabIndex={href === `/${active}` ? -1 : undefined}
        >
          <Icon name={icon} />
          <span data-text={text}>{text}</span>
        </a>
      ))
    : [];

export const SiteNavigationLinks: React.FunctionComponent<{
  place: string;
}> = ({ place }) => {
  const user = useContext(CONTEXTS.user);
  return (
    <div className="site-header__links">{create_nav_links(place, !!user)}</div>
  );
};
