import * as React from "react";
import { generate_id } from "../../../utils/common";
import { closestAncestor, maybeClassName } from "../../../utils/dom-helpers";

export const DefaultDropdownButton: React.FC<{}> = () => (
  <div className="dropdown-menu__button">
    <span className="dropdown-menu__button--line" />
    <span className="dropdown-menu__button--line" />
    <span className="dropdown-menu__button--line" />
    <span className="dropdown-menu__button--line" />
  </div>
);

export class DropdownMenu extends React.Component<{
  className?: string;
  button?: React.ReactNode;
  items: React.ReactNode[];
  onOpen?: (e) => void;
}> {
  constructor(props) {
    super(props);
    this.menu = React.createRef();
    this.links = React.createRef();
    this.list = React.createRef();
  }
  menu: React.RefObject<HTMLDivElement>;
  links: React.RefObject<HTMLDivElement>;
  list: React.RefObject<HTMLUListElement>;
  menu_id: string;
  displayName: string = "DropdownMenu";

  componentDidMount() {
    // set menu to closed by default, and set the id that we'll
    // use in the click listener
    const menu = this.menu.current;
    if (menu) {
      menu.dataset.open = "false";
      menu.id = generate_id("dropdown-menu");
    }
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.closeMenu);
  }

  componentDidUpdate() {
    const menu = this.menu.current;
    const links = this.links.current;
    const list = this.list.current;
    if (!menu || !links || !list) {
      return;
    }
    if (menu.dataset.open === "true") {
      links.style.height = `${list.getBoundingClientRect().height}px`;
    }
  }

  closeMenu = e => {
    const menu = this.menu.current;
    const links = this.links.current;
    if (menu && !closestAncestor(e.target, menu.id)) {
      menu.dataset.open = "false";
      if (links) {
        links.style.height = "0";
      }
    }
    document.removeEventListener("click", this.closeMenu);
  };

  toggle = e => {
    e.stopPropagation();
    const menu = this.menu.current;
    const links = this.links.current;
    const list = this.list.current;
    if (!menu || !links || !list) {
      return;
    }

    // if the menu is open, remove the listener and set the height to 0
    if (menu.dataset.open === "true") {
      document.removeEventListener("click", this.closeMenu);
      links.style.height = "0";
    } else {
      // otherwise add the listener and set its height to the height of the ul
      document.addEventListener("click", this.closeMenu);
      links.style.height = `${list.getBoundingClientRect().height}px`;
      this.props.onOpen && this.props.onOpen(e);
    }
    // toggle the open state
    menu.dataset.open = `${menu.dataset.open === "false"}`;
  };

  render() {
    const { button, className, items } = this.props;
    return (
      <div
        className={`dropdown-menu${maybeClassName(className)}`}
        ref={this.menu}
        id={this.menu_id}
        onClick={this.toggle}
      >
        {button ? (
          <button className="dropdown-menu__button--custom">{button}</button>
        ) : (
          <DefaultDropdownButton />
        )}
        <div ref={this.links} className="dropdown-menu__links">
          <ul ref={this.list} className="dropdown-menu__links__list">
            {items.map((item, key) =>
              item ? <li key={key}>{item}</li> : null
            )}
          </ul>
        </div>
      </div>
    );
  }
}
