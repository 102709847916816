import api from "../../api/index";
import * as EVENTS from "../events/event-names";
import * as FX from "./effect-names";
import { PLACEHOLDER_AVATAR_URL } from "../../constants";
import { get_error_response_status, path_or } from "../../utils/common";
import { EV_SET_VALUE } from "@thi.ng/interceptors";
import {
  GET_USER_WITH_TEMP_TOKEN_REQUEST_ID,
  GOOGLE_LOGIN_REQUEST_ID,
  LOGIN_REQUEST_ID,
  LOGOUT_REQUEST_ID,
  PASSWORD_RESET_REQUEST_ID,
  REFRESH_ACTIONS_REQUEST_ID,
  SET_PASSWORD_REQUEST_ID
} from "./constants";

const handle_request_error = (
  id: string,
  bus: Dispatchers,
  log_str = "Error response from request"
) => (error: any) => {
  console.error(
    `${log_str} \`${id}\`:\n`,
    path_or("", ["response", "body", "errors"], error),
    "\n",
    error
  );
  bus.dispatchNow([
    EVENTS.SET_CLIENT_RESPONSE,
    {
      id,
      success: false,
      error,
      response: error.response
    }
  ]);
};

const login_with = (id, promise, bus) => {
  return promise
    .then(res => {
      const { user } = res.body.data;
      if (!user.profile_photo_url)
        user.profile_photo_url = PLACEHOLDER_AVATAR_URL;

      bus.dispatchNow([EVENTS.SET_APP_USER, user]);
      bus.dispatchNow([
        EVENTS.SET_CLIENT_RESPONSE,
        { id: id, response: res.body, success: true }
      ]);
    })
    .catch(err => {
      handle_request_error(id, bus)(err);
      // need to throw this so that the FX.LOGIN_WITH_GOOGLE call fails
      throw err;
    });
};

export const effects: AppEffectNameMap = {
  [FX.RENDER_GOOGLE_LOGIN_BUTTON]: ({ id }, bus) =>
    bus.state
      .deref()
      .ensure_gapi()
      .then(gapi => {
        gapi.signin2.render(id, {
          width: 240,
          height: 50,
          longtitle: true,
          onsuccess(data) {
            bus.dispatch([EVENTS.GOOGLE_AUTHENTICATE_SUCCESS, data]);
          },
          onfailure(error) {
            if (error.error !== "popup_closed_by_user") {
              bus.dispatchNow([EVENTS.GOOGLE_AUTHENTICATE_FAIL, error]);
            }
          }
        });
      })
      .then(() => {
        bus.dispatchNow([
          EVENTS.GOOGLE_AUTH_SET_AVAILABLE,
          { available: true }
        ]);
      })
      .catch(error => {
        if (error !== "popup_closed_by_user") {
          bus.dispatchNow([
            EVENTS.GOOGLE_AUTH_SET_AVAILABLE,
            { available: false, error }
          ]);
        }
      }),

  [FX.CALL_CLIENT_METHOD]: ({ id, payload, retryAfterRelogin }, bus) => {
    const { method, parameters } = payload;
    try {
      // TS: type of `api.client[method]` is a union of all methods so type of
      // params is unknown
      api.client[method](parameters as any)
        // @ts-ignore
        .then(res => {
          if (
            res.statusCode >= 400 ||
            !res.body ||
            !res.body.data ||
            !res.body.status
          ) {
            handle_request_error(id, bus)(
              new Error(`Status code ${res.statusCode}: ${res.text}`)
            );
          } else {
            bus.dispatchNow([
              EVENTS.SET_CLIENT_RESPONSE,
              { id, response: res.body, success: true }
            ]);
          }
        })
        .catch(err => {
          // if 401, auth token has expired, show a modal for user
          // to re-login
          if (get_error_response_status(err) === 401) {
            // don't re-login if the failed request was login
            if (method !== "Login" && method !== "ZendeskLogin") {
              bus.dispatchNow(
                [
                  EVENTS.RELOGIN,
                  retryAfterRelogin
                    ? {
                        nextEvent: [
                          EVENTS.CALL_CLIENT_METHOD,
                          { id, payload, retryAfterRelogin }
                        ] as AppEvent<"ev_call_client_method">
                      }
                    : {}
                ],
                // if the request is not set to resend after the user has
                // logged back in, clear it
                ...(!retryAfterRelogin
                  ? ([[EVENTS.CLEAR_CLIENT_REQUEST, { id }]] as AppEvent<
                      AppEventName
                    >[])
                  : [])
              );
            } else {
              handle_request_error(id, bus, "Login attempt failed")(err);
            }
          } else {
            handle_request_error(id, bus, "Error response from api call")(err);
          }
        });
    } catch (error) {
      handle_request_error(
        id,
        bus,
        `ERROR while dispatching CALL_CLIENT_METHOD "${method}", id:`
      )(error);
    }
  },

  [FX.LOGIN]: ({ email, password }, bus) =>
    login_with(LOGIN_REQUEST_ID, api.login(email, password), bus),

  [FX.LOGIN_WITH_GOOGLE]: ({ id_token }, bus) =>
    login_with(GOOGLE_LOGIN_REQUEST_ID, api.google_login(id_token), bus),

  [FX.LOGOUT]: (_, bus) => {
    api
      .logout()
      .then(() => {
        bus.state
          .deref()
          .ensure_gapi()
          .then(gapi => {
            if (gapi && gapi.auth2) {
              const auth = gapi.auth2.getAuthInstance();
              if (auth) auth.disconnect();
            }
          });
      })
      .catch(handle_request_error(LOGOUT_REQUEST_ID, bus))
      .finally(() => window.location.assign("/login"));
  },

  [FX.REFRESH_AUTH]: ({ nextEvent }, bus) => {
    api
      .refresh_auth()
      // if successful, refresh the user object in app state
      // and dispatch the next event if one was provided
      .then(res => {
        const { user } = res.body.data;
        if (!user.profile_photo_url) {
          user.profile_photo_url = PLACEHOLDER_AVATAR_URL;
        }
        bus.dispatchNow(
          [EVENTS.SET_APP_USER, user],
          [EVENTS.REFRESH_ACTIONS, false],
          ...(nextEvent ? [nextEvent] : [])
        );
      })
      .catch(err => {
        const { place } = bus.state.app_location;
        // if auth is expired, dispatch relogin event
        if (
          get_error_response_status(err) === 401 &&
          place !== "login" &&
          place !== "zendesk-login"
        ) {
          bus.dispatchNow([EVENTS.RELOGIN, { nextEvent }]);
        } else {
          console.error(`Error while refreshing auth token:`, err);
        }
      });
  },

  [FX.GET_USER_WITH_TEMP_TOKEN]: (_, bus) => {
    const id = GET_USER_WITH_TEMP_TOKEN_REQUEST_ID;
    const { email, token } = bus.state.deref().app_location;
    if (!email || !token) {
      bus.dispatchNow([
        EVENTS.ERROR,
        new Error(
          `in FX.GET_USER_WITH_TEMP_TOKEN: missing ${!token ? "token" : ""}${
            !token && !email ? " and " : ""
          }${!email ? "email" : ""}`
        )
      ]);
    }
    api
      .get_user_with_temp_token(email, token)
      .then(res => {
        bus.dispatchNow([
          EVENTS.SET_CLIENT_RESPONSE,
          { id, response: res.body, success: true }
        ]);
      })
      .catch(handle_request_error(id, bus));
  },

  [FX.REQUEST_PASSWORD_RESET]: (
    { request_id = PASSWORD_RESET_REQUEST_ID, email },
    bus
  ) => {
    if (!email) {
      bus.dispatchNow([
        EVENTS.ERROR,
        new Error(`in FX.REQUEST_PASSWORD_RESET: missing email`)
      ]);
    }
    api
      .forgot_password(email)
      .then(res => {
        bus.dispatchNow([
          EVENTS.SET_CLIENT_RESPONSE,
          { id: request_id, response: res.body, success: true }
        ]);
      })
      .catch(handle_request_error(request_id, bus));
  },

  [FX.SET_PASSWORD]: ({ id, password, token }, bus) => {
    const req_id = SET_PASSWORD_REQUEST_ID;
    api
      .set_password(id, password, token)
      .then(res => {
        bus.dispatchNow([
          EVENTS.SET_CLIENT_RESPONSE,
          { id: req_id, response: res.body, success: true }
        ]);
        bus.dispatchLater(
          // @ts-ignore
          [EV_SET_VALUE, ["app_location", { place: "login" }]],
          1500
        );
      })
      .catch(handle_request_error(req_id, bus));
  },

  [FX.RELOGIN]: (nextEvent, bus) => {
    bus.dispatchNow(
      // set the auth_expired flag, clear the user, and open the re-login modal
      [EVENTS.SET_AUTH_EXPIRED, true],
      [EVENTS.SET_APP_USER, null],
      [EVENTS.OPEN_LOGIN_MODAL, nextEvent]
    );
  },
  [FX.SEND_XHR_REQUEST]: ({ id, payload }, bus) => {
    const {
      method,
      url,
      body = undefined,
      headers = {},
      queryParameters = undefined
    } = payload;
    return new Promise((resolve, reject) =>
      api.client.request(
        method,
        url,
        body,
        {
          ...headers,
          xhr: "true"
        },
        queryParameters,
        {},
        reject,
        resolve
      )
    )
      .then((res: SuperAgentResponse) => {
        bus.dispatchNow([
          EVENTS.SET_CLIENT_RESPONSE,
          { id, response: res, success: true }
        ]);
      })
      .catch(handle_request_error(id, bus));
  },

  [FX.REFRESH_ACTIONS]: ({}, bus) => {
    const req_id = REFRESH_ACTIONS_REQUEST_ID;
    api.client
      .GetUnprocessedActionsForUser({})
      .then(res => {
        bus.dispatchNow([
          EVENTS.SET_CLIENT_RESPONSE,
          { id: req_id, response: res.body, success: true }
        ]);
        // @ts-ignore: custom type doesn't include built-in events
        bus.dispatchNow([EV_SET_VALUE, ["notifications", res.body.data]]);
      })
      .catch(handle_request_error(req_id, bus));
  }
};
