import * as React from "react";
import { maybeClassName } from "../../utils/dom-helpers";

export const ButtonWithCounter: React.FC<
  HTMLButtonProps & { count: number }
> = ({ count, children, className, ...props }) => (
  <button
    className={`button-with-counter${maybeClassName(className)}`}
    {...props}
  >
    <div className="button-with-counter__count">{count}</div>
    <div className="button-with-counter__button">{children}</div>
  </button>
);

export const LinkWithCounter: React.FC<HTMLAnchorProps & { count: number }> = ({
  count,
  children,
  className,
  ...props
}) => (
  <a className={`button-with-counter${maybeClassName(className)}`} {...props}>
    <div className="button-with-counter__count">{count}</div>
    <div className="button-with-counter__button">{children}</div>
  </a>
);
