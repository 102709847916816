import * as React from "react";
import { FormSection, InputRow } from "./common/common";
import { EmptyList } from "./EmptyList";
import { maybeClassName } from "../../utils/dom-helpers";

export const FormSectionList = <T extends object>({
  items,
  title,
  description,
  className,
  renderItem,
  button,
  emptyLabel,
  listClassName,
  children
}: {
  items: T[];
  title: React.ReactNode;
  description?: React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode | null;
  button: React.ReactNode;
  emptyLabel: React.ReactNode;
  listClassName?: string;
  children?: React.ReactNode;
} & MaybeClass) => (
  <FormSection
    className={`form-section-list${maybeClassName(className)}`}
    data-count={items.length}
    title={
      <>
        <span>{title}</span>
        {button}
      </>
    }
  >
    <InputRow description={description}>
      {items.length > 0 ? (
        <div
          className={`form-section-list__list${maybeClassName(listClassName)}`}
        >
          {items.map(renderItem)}
        </div>
      ) : (
        <EmptyList button={button}>{emptyLabel}</EmptyList>
      )}
      {children}
    </InputRow>
  </FormSection>
);
FormSectionList.displayName = "FormSectionList";
