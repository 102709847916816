import * as React from "react";
import { RequestComponent } from "./RequestComponent";
import { connect, EVENTS } from "../../model";
import { WithFloatingTitle } from "./common/floating-title";
import { InputWithFormHelpers } from "./common/inputs";
import { FormErrors } from "./Errors";
import { RequestButton } from "./common/request-button";
import { Modal } from "./common/modal";
import { MIN_PASSWORD_LENGTH } from "../../constants";
import {
  GOOGLE_LOGIN_REQUEST_ID,
  LOGIN_REQUEST_ID
} from "../../model/effects/constants";
import { GoogleLoginFormWrapper } from "./GoogleLoginFormWrapper";

class LoginModalBody extends RequestComponent<
  "user",
  ModalBodyProps,
  {
    email?: string;
    password?: string;
    user_pass_mode: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = { request_id: LOGIN_REQUEST_ID, user_pass_mode: false };
    this.name = "Login";
  }
  name = "LoginModal";

  componentDidUpdate(prevProps) {
    if (
      this.requestFinished(prevProps) &&
      this.requestSucceeded() &&
      this.props.onSuccess
    ) {
      this.props.onSuccess();
    }
  }

  onChangeInput = (field: "email" | "password") => e => {
    // @ts-ignore
    this.setState({ [field]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    console.log(`submitting user-pass form`);
    const { email, password } = this.state;
    if (email && password) {
      this.props.dispatchNow([EVENTS.LOGIN, { email, password }]);
    }
  };

  render() {
    const { email, password, user_pass_mode } = this.state;
    return (
      <>
        <div className="login-modal__prompt">
          Your session has expired.
          <br />
          Please log back in.
        </div>

        <div className="login-page__form login-page__form__with-google">
          <GoogleLoginFormWrapper
            errors={this.getRequestErrors(GOOGLE_LOGIN_REQUEST_ID)}
          >
            <form
              id="login"
              onSubmit={this.onSubmit}
              data-mode={user_pass_mode ? "userpass" : "google"}
            >
              <FormErrors response={this.getRequestErrors()} />
              <WithFloatingTitle title="Email">
                <InputWithFormHelpers
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={this.onChangeInput("email")}
                  value={email}
                  submitOnEnter={true}
                />
              </WithFloatingTitle>
              <WithFloatingTitle title="Password">
                <InputWithFormHelpers
                  placeholder="Password"
                  type="password"
                  name="password"
                  minLength={MIN_PASSWORD_LENGTH}
                  onChange={this.onChangeInput("password")}
                  value={password}
                  submitOnEnter={true}
                />
              </WithFloatingTitle>
              <RequestButton
                pending={
                  this.requestIsPending() || this.requestSucceeded() || false
                }
                success={this.getRequest() ? this.requestSucceeded() : false}
                className="login-page__submit filled"
                type="submit"
                successText="Success!"
              >
                Log in to Thrive
              </RequestButton>
              <button
                className="filled gray login-modal__cancel"
                onClick={this.props.onDismiss}
              >
                Cancel
              </button>
            </form>
          </GoogleLoginFormWrapper>
        </div>
      </>
    );
  }
}

const LoginModalBodyConnected = connect(
  LoginModalBody,
  true,
  ["user", "request"]
);

export class LoginModal extends Modal {
  name = "LoginModal";
  closeModal = () => {
    this.props.dispatchNow([EVENTS.CLOSE_LOGIN_MODAL]);
  };
}

export const LoginModalConnected = connect(
  LoginModal,
  true,
  []
);

export const LOGIN_MODAL_PROPS: ModalProps = {
  className: "login-modal",
  body: LoginModalBodyConnected
};
