import * as React from "react";
import { connect } from "../../model";
import { GoogleLoginButton } from "./GoogleLoginButton";
import { SvgLoadingSpinner } from "./icons";
import { FormErrors } from "./Errors";

class LoginFormWrapperBase extends React.Component<
  Context<"google_signin_available" | "request", true> & {
    errors?: ErrorResponse;
  },
  {
    user_pass_mode: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = { user_pass_mode: false };
  }

  componentDidUpdate(prevProps): void {
    if (
      prevProps.google_signin_available === null &&
      this.props.google_signin_available !== null
    ) {
      this.setState({ user_pass_mode: !this.props.google_signin_available });
    }
  }

  toggleUserPass = () => {
    this.setState({ user_pass_mode: !this.state.user_pass_mode });
  };

  render() {
    const { children, google_signin_available } = this.props;
    const { user_pass_mode } = this.state;
    return (
      <div
        className="login-wrapper"
        data-mode={
          user_pass_mode || google_signin_available === false
            ? "userpass"
            : "google"
        }
        data-google-available={google_signin_available === true}
        data-loading={google_signin_available === null}
      >
        <div className="login-wrapper__loading">
          <SvgLoadingSpinner />
        </div>
        <div className="login-wrapper__content">
          <div className="login-wrapper__google">
            <div className="login-wrapper__google__content">
              <h2>Get Started</h2>
              <div className="login-wrapper__google__prompt">
                Please sign in with your Thread.org Google account to begin.
              </div>
              <GoogleLoginButton />
              <FormErrors response={this.props.errors} />
            </div>
          </div>
          {google_signin_available && (
            <div
              className="login-wrapper__mode-button"
              onClick={this.toggleUserPass}
            >
              <span>
                Sign in with{" "}
                {user_pass_mode ? "Google?" : "an email and password?"}
              </span>
            </div>
          )}
          <div className="login-wrapper__user-pass">
            <div className="login-wrapper__user-pass__content">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export const GoogleLoginFormWrapper = connect(
  LoginFormWrapperBase,
  true,
  ["google_signin_available", "request"]
);
