import * as React from "react";
import { maybeClassName } from "../../utils/dom-helpers";

export const Icon: React.FC<
  { name: FontIconName; iconSize?: IconSize } & PropsOfElem<HTMLSpanElement>
> = ({ name, iconSize, className, ...props }) => (
  <i
    data-size={iconSize}
    className={`thrive-icon i-thrive-${name}${maybeClassName(className)}`}
    {...props}
  />
);
