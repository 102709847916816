import * as React from "react";
import { parse_text } from "../../utils/parse-plain-text";
import { RequestComponent } from "./RequestComponent";
import { generate_id } from "../../utils/common";
import { connect, EVENTS } from "../../model";
import { DefaultPendingComponent } from "./common/common";

class DisplayPlainTextFile extends RequestComponent<
  never,
  { src: string; id?: string },
  { formatted?: React.ReactNode[] }
> {
  constructor(props) {
    super(props);
    this.state = { request_id: props.id || generate_id("plain-text") };
  }

  componentDidMount() {
    this.props.dispatchNow([
      EVENTS.SEND_XHR_REQUEST,
      {
        id: this.state.request_id,
        payload: {
          method: "GET",
          url: this.props.src
        }
      }
    ]);
  }

  componentDidUpdate(prevProps): void {
    if (!this.requestFinished(prevProps)) {
      return;
    }
    const request = this.getRequest();
    if (!request || !this.requestSucceeded()) {
      return;
    }
    const response: SuperAgentResponse = (request as any).response;
    if (prevProps.text !== response.text) {
      this.setState({
        formatted: parse_text(response.text)
      });
    }
  }

  render() {
    const { formatted = null } = this.state;
    const errors = this.getRequestErrors();
    return (
      <div className="formatted-plain-text__container">
        {this.requestIsPending() ? (
          <DefaultPendingComponent />
        ) : this.requestSucceeded() ? (
          <div className="formatted-plain-text">{formatted}</div>
        ) : (
          <div className="with-api-request__default-error">
            <div>Something went wrong!</div>
            <div>Please try again in a moment.</div>
            {errors && <div>Status Code: {errors.status}</div>}
          </div>
        )}
      </div>
    );
  }
}

export const DisplayPlainTextFileConnected = connect(
  DisplayPlainTextFile,
  true,
  ["request"]
);
