import * as React from "react";
import { CONTEXTS } from "../../model";
import { Checkbox, RadioButtons } from "./common/inputs";
import { ScreenSize } from "../../constants";
import { Icon } from "./Icon";
import { useContext } from "react";

export const BooleanCheckbox: React.FC<
  CheckboxProps & {
    trueLabel?: string;
    falseLabel?: string;
    value: "true" | "false";
    onChangeInput: (value: boolean) => void;
  }
> = ({
  onChange,
  checked,
  value,
  trueLabel = "Yes",
  falseLabel = "No",
  onChangeInput,
  ...props
}) => {
  const window_size = useContext(CONTEXTS.window_size);
  return window_size <= ScreenSize.sm ? (
    <Checkbox
      onChange={e => onChangeInput(e.target.checked)}
      checked={value === "true"}
      {...props}
    >
      <span className="radio-button__button">
        <Icon name="check" />
      </span>
    </Checkbox>
  ) : (
    <>
      <RadioButtons
        onSelectOption={opt => onChangeInput(opt.value === "true")}
        value={value}
        options={[
          { value: "true", label: trueLabel },
          { value: "false", label: falseLabel }
        ]}
        {...props}
      />
    </>
  );
};
