import * as React from "react";
import { IMAGES_PREFIX } from "../../constants";

export const SiteFooter: React.FunctionComponent<{}> = () => (
  <div className="site-footer">
    <div className="site-footer__content">
      <div className="site-footer__logo">
        <img src={`${IMAGES_PREFIX}/logo_white_thread.svg`} alt="Thread Logo" />
      </div>
      <div className="site-footer__text">
        <a href="/privacy-policy">Privacy Policy</a>
        <span> | </span>
        <a href="/terms-of-service">Terms of Service</a>
        <span> | </span>
        <a href="mailto:info@bmore.com,support@thread.com">Contact Us</a>
      </div>
    </div>
  </div>
);
SiteFooter.displayName = "SiteFooter";
