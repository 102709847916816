import * as React from "react";
import { EVENTS, connect } from "../../../model";
import { RequestComponent } from "../../components/RequestComponent";
import { RequestButton } from "../../components/common/request-button";

class DeleteExpenseModal extends RequestComponent<
  never,
  ModalBodyProps & { expense_id: number; touchpoint_id: number }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: `delete-touchpoint-${props.id}`
    };
  }

  componentDidUpdate(prevProps) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      const { onSuccess, onDismiss, dispatchNow, touchpoint_id } = this.props;
      setTimeout(() => {
        onSuccess && onSuccess();
        onDismiss();
        dispatchNow([
          EVENTS.GO_TO_PAGE,
          { place: "activity", id: touchpoint_id }
        ]);
      }, 1200);
    }
  }

  render() {
    const { onDismiss, expense_id } = this.props;
    const errors = this.getRequestErrors();
    return (
      <>
        <div className="touchpoint-delete__modal__body">
          Are you sure you want to delete this expense?
          <strong>This action is permanent and cannot be reversed.</strong>
        </div>
        <div className="touchpoint-delete__modal__footer">
          <button
            onClick={onDismiss}
            className="bordered touchpoint-delete__modal__dismiss"
          >
            Cancel
          </button>
          <RequestButton
            className="filled touchpoint-delete__modal__success"
            successText="Success!"
            success={this.requestSucceeded()}
            pending={this.requestIsPending()}
            onClick={() =>
              this.sendRequest(
                "DeleteExpense",
                { expense_id },
                this.state.request_id
              )
            }
          >
            Confirm
          </RequestButton>
        </div>
        {errors && (
          <div className="touchpoint-delete__modal__error">{errors.errors}</div>
        )}
      </>
    );
  }
}
export const DeleteExpenseModalConnected = connect(
  DeleteExpenseModal,
  true,
  ["request"]
);
