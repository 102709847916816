import * as React from "react";
import { CONTEXTS, EVENTS } from "../../../model";
import { WithApiRequest } from "../../components/WithApiRequest";
import { PageContent } from "../../components/PageContent";
import { ExpenseFormConnected } from "./Form";
import { useContext } from "react";

export const UpdateExpensePage: React.FC<{}> = () => {
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);
  const app_location = useContext(CONTEXTS.app_location) as AppLocationAt<
    "activity/id/expense/id/edit"
  >;
  return (
    <PageContent
      pageHeader="Update Expense"
      id="expense-update"
      className="expense-page expense-form header-overlap-block header-overlap-sm"
      bodyType="full-width-block"
    >
      <WithApiRequest
        View={ExpenseFormConnected}
        payload={{
          method: "GetTouchpointById",
          parameters: {
            touchpoint_id: app_location.id
          }
        }}
        id={`expense-read-for-update-${app_location.id}`}
        onSuccess={req =>
          req.response.data &&
          dispatchNow([
            EVENTS.GO_TO_PAGE,
            {
              ...app_location,
              record: req.response.data
            }
          ])
        }
        overrideRecord={app_location.record}
      />
    </PageContent>
  );
};
