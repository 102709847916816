import * as React from "react";
import { useContext } from "react";
import { CONTEXTS } from "../../model";
import { LoginConnected } from "../pages/Login";
import { Error404PageNotFound } from "./common/common";
import { UserProfileConnected } from "../pages/Profile";
import { PeopleListConnected } from "../pages/People/List";
import { TouchpointListConnected } from "../pages/Touchpoint/List";
import { TouchpointDetail } from "../pages/Touchpoint/Detail";
import { CreateExpensePage } from "../pages/Expense/Create";
import { ExpenseDetail } from "../pages/Expense/Detail";
import { UserDetail } from "../pages/User/Detail";
import { RegisterConnected } from "../pages/Register";
import { TermsOfServicePage } from "../pages/Legal/TermsOfService";
import { PrivacyPolicyPage } from "../pages/Legal/PrivacyPolicy";
import { ForgotPasswordConnected } from "../pages/ForgotPassword";
import { CommunityList } from "../pages/Community/CommunityList";
import { ThreadDetailConnected } from "../pages/Community/ThreadDetail";
import { FamilyDetail } from "../pages/Community/FamilyDetail";
import { CommunityDetailConnected } from "../pages/Community/CommunityDetail";
import { LonelinessRaterConnected } from "../pages/CheckIn/LonelinessRater";
import { WeeklyCheckIn } from "../pages/CheckIn/WeeklyCheckIn";
import { UpdateTouchpointPage } from "../pages/Touchpoint/Update";
import { CreateTouchpointPage } from "../pages/Touchpoint/Create";
import { UpdateExpensePage } from "../pages/Expense/Update";
import { LonelinessRaterResponseConnected } from "../pages/CheckIn/LonelinessRaterResponse";
import { ZendeskLoginConnected } from "../pages/ZendeskLogin";

const ROUTER_MAP: AppRoutes = {
  login: { page: LoginConnected },
  register: { page: RegisterConnected },
  forgot_password: { page: ForgotPasswordConnected },
  users: {
    [":id"]: {
      page: UserDetail
    }
  },
  activity: {
    page: TouchpointListConnected,
    [":id"]: {
      page: TouchpointDetail,
      expense: {
        [":id"]: {
          page: ExpenseDetail,

          edit: { page: UpdateExpensePage }
        },
        create: { page: CreateExpensePage }
      },
      edit: { page: UpdateTouchpointPage }
    },
    create: { page: CreateTouchpointPage }
  },
  people: { page: PeopleListConnected },
  communities: {
    page: CommunityList,
    [":id"]: {
      page: CommunityDetailConnected,
      threads: {
        [":id"]: {
          page: ThreadDetailConnected,
          families: {
            [":id"]: {
              page: FamilyDetail
            }
          }
        }
      }
    }
  },
  /*conversations: {
    page: PlaceholderPage,
    [":id"]: {
      page: PlaceholderPage
    }
  },*/
  //settings: { page: PlaceholderPage },
  ["loneliness-rater"]: {
    page: LonelinessRaterConnected,
    result: { page: LonelinessRaterResponseConnected }
  },
  ["weekly-check-in"]: { [":id"]: { page: WeeklyCheckIn } },
  account: { page: UserProfileConnected },
  ["privacy-policy"]: { page: PrivacyPolicyPage },
  ["terms-of-service"]: { page: TermsOfServicePage },
  ["zendesk-login"]: { page: ZendeskLoginConnected }
};

/** Gets the page component corresponding to the current AppLocation */
const get_router_page = (location: AnyAppLocation) => {
  const { place, id, expense, community, thread, family, edit } = location;
  const _location: any[] = [place, id];
  if (expense) {
    _location.push("expense", expense);
  }
  if (edit) {
    _location.push("edit");
  }

  if (community) {
    _location.push(community.id);
    if (thread) {
      _location.push("threads", thread.id);
      if (family) {
        _location.push("families", family.id);
      }
    }
  }

  const page = _location.reduce((cur, next) => {
    if (cur == null || next == null) {
      return cur;
    }
    return typeof next === "number" ? cur[":id"] : cur[next];
  }, ROUTER_MAP);

  return (page && page.page) || Error404PageNotFound;
};

export const Router: React.FC<{}> = () => {
  const app_location = useContext(CONTEXTS.app_location);
  const PageComponent = get_router_page(app_location as AnyAppLocation);
  return <PageComponent />;
};
