import * as React from "react";
import { DAILY_HEALTH_RATING_MAP, IMAGES_PREFIX } from "../../constants";
import moment from "../../utils/moment";
import { maybeClassName } from "../../utils/dom-helpers";

export const HealthIcon: React.FC<
  {
    rating: number;
    date?: string;
    userName?: string;
  } & MaybeClass
> = ({ rating, date, userName, className }) => {
  const mood = DAILY_HEALTH_RATING_MAP[rating + 1];
  const updated = date
    ? `.\nUpdated ${moment(new Date(date)).calendar()}.`
    : ".";
  const desc = userName
    ? `${
        userName ? `${userName}'s` : "Your"
      } latest health rating is: ${mood}${updated}`
    : `Health rating: ${mood}${updated}`;
  return (
    <span className={`health-icon${maybeClassName(className)}`} title={desc}>
      <img src={`${IMAGES_PREFIX}/icon-health-${mood}.svg`} alt={desc} />
    </span>
  );
};
