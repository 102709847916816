import * as React from "react";
import { get_emoji, get_list_of_names } from "../utils";
import { capitalize } from "../../utils/common";
import { Icon } from "./Icon";

export const Reaction: React.FC<{
  type: string;
  users: User[];
  current_user_id: number;
  onClick: (e) => void;
  hideCount?: boolean;
}> = ({ type, users, current_user_id, onClick, hideCount }) => (
  <button
    type="button"
    className={`reaction${
      users.some(u => u.id === current_user_id) ? " active" : ""
    }`}
    onClick={onClick}
    title={capitalize(get_list_of_names(users, current_user_id, 4))}
    data-hide-count={hideCount}
  >
    <span className="reaction__icon">
      <Icon name={get_emoji(type)} />
    </span>
    <span className="reaction__count">{users.length}</span>
  </button>
);
