import * as React from "react";
import { EVENTS, CONTEXTS } from "../../model";
import { maybeClassName } from "../../utils/dom-helpers";
import { useContext } from "react";

type DetailPageLocation = {
  [K in keyof AppRouteMap]: AppRouteMap[K] extends {
    record: object | undefined;
  }
    ? K
    : never
}[keyof AppRouteMap];
type DetailPageRecordTypes = {
  [K in DetailPageLocation]: AppRouteMap[K] extends {
    record: infer R | undefined;
  }
    ? R
    : never
};

// link that will also set the target record in AppLocation
export const DetailPageLink = <L extends DetailPageLocation>({
  record,
  place,
  children,
  className,
  location,
  ...props
}: HTMLDivProps & {
  place: AppRouteMap[L]["place"];
  record: DetailPageRecordTypes[L];
  location?: AppLocationAt<DetailPageLocation>;
}): React.ReactElement | null => {
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);
  return (
    <div
      {...props}
      tabIndex={0}
      className={`detail-page-link${maybeClassName(className)}`}
      onClick={e => {
        e.preventDefault();
        dispatchNow([
          EVENTS.GO_TO_PAGE,
          // @ts-ignore
          location || {
            place,
            id: record.id,
            record
          }
        ]);
        return false;
      }}
    >
      {children}
    </div>
  );
};
