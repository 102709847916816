import * as React from "react";
import { ApiReqViewProps } from "../../components/WithApiRequest";
import { EmptyList } from "../../components/EmptyList";
import {
  CommunityLocation,
  GroupListItem,
  GroupListViewItem
} from "./ListItem";
import { connect, EVENTS } from "../../../model";
import { deep_equals } from "../../../utils/common";
import { USER_ROLES } from "../../../constants";

interface GroupListProps<
  M extends GroupRequestMethod,
  D extends GroupResponseModel<M> = GroupResponseModel<M>,
  R extends GroupObjectInResponse<D> = GroupObjectInResponse<D>,
  T extends GroupTypeForMethod<M> = GroupTypeForMethod<M>
> {
  get_items: (r: D) => GroupListItem<T>[];
  emptyText: React.ReactNode;
}

export class GroupListBase<
  M extends GroupRequestMethod,
  D extends GroupResponseModel<M> = GroupResponseModel<M>,
  R extends GroupObjectInResponse<D> = GroupObjectInResponse<D>
> extends React.Component<
  GroupListProps<M, D, R> &
    ApiReqViewProps<M> &
    Context<"user", true> & { app_location: CommunityLocation },
  {
    mapped_records: GroupListItem<GroupTypeForMethod<M>>[];
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      mapped_records: []
    };
  }

  componentDidMount(): void {
    const { dispatchNow, app_location, response } = this.props;
    const { place, community, thread, family } = app_location;
    const record = response.data;
    const new_location: any = {
      place
    };

    if (Array.isArray(record)) {
    } else if (record.community_id) {
      new_location.community = {
        id: record.community_id,
        name: record.community_name
      };
      // @ts-ignore
      if (record.thread_id) {
        new_location.thread = {
          // @ts-ignore
          id: record.thread_id,
          // @ts-ignore
          name: record.thread_name
        };
        if (family) {
          new_location.family = family;
        }
      }
    }

    if (!deep_equals(new_location, { place, community, thread, family }, 2)) {
      dispatchNow([EVENTS.SET_APP_LOCATION, { ...new_location, record }]);
    }
  }

  static getDerivedStateFromProps(props) {
    return {
      // @ts-ignore
      mapped_records: props.get_items(props.response.data)
    };
  }

  render() {
    const { emptyText } = this.props;
    const { mapped_records } = this.state;
    let section = "";
    return (
      <div className="group-list">
        {mapped_records.length > 0
          ? mapped_records.map((props, i) => {
              const item = <GroupListViewItem key={i} {...props} />;
              if (
                this.props.user &&
                this.props.user.user_role === USER_ROLES.STF
              ) {
                return item;
              }
              if (!section && props.clickable) {
                section = "clickable";
                return (
                  <React.Fragment key="my-communities">
                    <h3>My Communities</h3>
                    {item}
                  </React.Fragment>
                );
              } else if (section === "clickable" && !props.clickable) {
                section = "other";
                return (
                  <React.Fragment key="other-communities">
                    <h3>Other Communities</h3>
                    {item}
                  </React.Fragment>
                );
              }
              return item;
            })
          : emptyText || <EmptyList>No records found</EmptyList>}
      </div>
    );
  }
}

const GroupList = connect(
  GroupListBase,
  true,
  ["app_location", "user"]
);

export const makeGroupListView = <
  M extends GroupRequestMethod,
  D extends GroupResponseModel<M> = GroupResponseModel<M>,
  R extends GroupObjectInResponse<D> = GroupObjectInResponse<D>
>(
  componentName: string,
  get_items: (r: D) => GroupListItem<GroupTypeForMethod<M>>[],
  emptyText: React.ReactNode
): React.FunctionComponent<ApiReqViewProps<M>> => {
  const ListView: React.FunctionComponent<ApiReqViewProps<M>> = props => (
    <GroupList {...props} get_items={get_items} emptyText={emptyText} />
  );
  ListView.displayName = componentName;
  return ListView;
};
