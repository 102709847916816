import * as React from "react";
import { maybeClassName } from "../../../utils/dom-helpers";

export const Filename: React.FC<{ children: string } & MaybeClass> = ({
  children,
  className
}) => {
  if (typeof children !== "string") {
    return (
      <div className={`filename${maybeClassName(className)}`}>{children}</div>
    );
  }
  const [, name, extension] = children.match(/(.*)(\.[a-z0-9]+)$/i);
  return (
    <div className={`filename${maybeClassName(className)}`} title={children}>
      <div className="filename__name">{name || extension || children}</div>
      {name && extension && (
        <div className="filename__extension">{extension}</div>
      )}
    </div>
  );
};
Filename.displayName = "Filename";
