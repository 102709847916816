import * as React from "react";
import {
  closestAncestor,
  hasAnySelector,
  maybeClassName
} from "../../../utils/dom-helpers";
import { EVENTS, connect } from "../../../model";
import { Icon } from "../Icon";

export class Modal extends React.PureComponent<
  Context<never, true> &
    ModalProps & {
      id: string;
      giveTabFocus: boolean;
      dismissOnClickBackdrop?: boolean;
      closed: boolean;
    }
> {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }
  modal: React.RefObject<HTMLDivElement>;

  componentDidMount() {
    this.props.giveTabFocus &&
      window.addEventListener("keydown", this.onKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.giveTabFocus && !this.props.giveTabFocus) {
      window.removeEventListener("keydown", this.onKeyDown);
    } else if (!prevProps.giveTabFocus && this.props.giveTabFocus) {
      window.addEventListener("keydown", this.onKeyDown);
    }
  }

  onKeyDown = e => {
    const { id, giveTabFocus } = this.props;
    if (e.key === "Tab") {
      if (!closestAncestor(e.target, `#${id}-modal-backdrop`)) {
        const node = document.querySelector(
          `#${id}-modal-backdrop .modal__x-button`
        );
        node instanceof HTMLElement && node.focus();
        e.preventDefault();
        e.stopPropagation();
        return false;
      } else if (
        hasAnySelector(e.target, `#${id}-modal-backdrop .modal__x-button`)
      ) {
        document.body.focus();
      }
    }
    if (
      e.key === "Escape" &&
      document.querySelectorAll(".select__options[data-open='true']").length ===
        0 &&
      giveTabFocus
    ) {
      this.onDismiss(e);
    }
  };

  closeModal = () => {
    this.props.dispatchNow([EVENTS.CLOSE_APP_MODAL]);
  };

  onDismiss = e => {
    const { dismiss } = this.props;
    dismiss && dismiss.onClick && dismiss.onClick(e);
    this.closeModal();
  };

  onSuccess = e => {
    const { success } = this.props;
    if (!success) return;
    success.onClick && success.onClick(e);
    !success.keepOpen && this.closeModal();
  };

  render() {
    const {
      id,
      title,
      body: Body,
      className,
      success,
      dismiss,
      closed,
      giveTabFocus,
      dismissOnClickBackdrop
    } = this.props;
    return (
      <div
        ref={this.modal}
        data-dismissed={closed}
        className={`modal__backdrop${
          className ? ` ${className}__backdrop` : ""
        }`}
        id={`${id}-modal-backdrop`}
        onClick={
          dismissOnClickBackdrop
            ? e => {
                if ((e.target as HTMLElement).id === `${id}-modal-backdrop`) {
                  this.onDismiss(e);
                }
              }
            : undefined
        }
      >
        <div className="modal__container">
          <div className={`modal${maybeClassName(className)}`}>
            <div className="modal__header">
              <div className="modal__title">{title}</div>
              <div className="modal__close">
                <button
                  tabIndex={giveTabFocus ? 0 : undefined}
                  className="modal__x-button"
                  onClick={this.onDismiss}
                >
                  <Icon name="remove" />
                </button>
              </div>
            </div>
            <div className="modal__body">
              <Body onDismiss={this.onDismiss} onSuccess={this.onSuccess} />
            </div>
            {((success && success.text) || (dismiss && dismiss.text)) && (
              <div className="modal__footer">
                {success && success.text && (
                  <button
                    className={`modal__success-button${maybeClassName(
                      success.className
                    )}`}
                    onClick={this.onSuccess}
                  >
                    {success.text}
                  </button>
                )}
                {dismiss && dismiss.text && (
                  <button
                    className={`modal__dismiss-button${maybeClassName(
                      dismiss.className
                    )}`}
                    onClick={this.onDismiss}
                  >
                    {dismiss.text}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const AppModal = connect(
  Modal,
  true,
  []
);
