import * as React from "react";
import { EVENTS, connect } from "../../../model";
import {
  add_item_to,
  capitalize,
  generate_id,
  path_or,
  remove_item_from
} from "../../../utils/common";
import { makeParticipantsModal } from "./ParticipantsModal";
import {
  ActionButtonWithIcon,
  ButtonWithIcon
} from "../../components/common/button-with-icon";
import { UserChecklistItemView } from "../../components/UserChecklist";
import {
  TouchpointCategories,
  TouchpointTypes,
  ScreenSize
} from "../../../constants";
import { DropdownSelectInput } from "../../components/common/dropdown-select-input";
import {
  FloatingTitle,
  WithFloatingTitle
} from "../../components/common/floating-title";
import {
  FormSection,
  get_label_from_value,
  InputRow
} from "../../components/common/common";
import {
  CheckList,
  TextAreaWithFormHelpers
} from "../../components/common/inputs";
import { ActionButton } from "../../components/common/action-link";
import { RequestButton } from "../../components/common/request-button";
import { RequestComponent } from "../../components/RequestComponent";
import { MultiValueListItem } from "../../components/common/multi-value-list-item";
import { BooleanCheckbox } from "../../components/BooleanCheckbox";
import { FormSectionList } from "../../components/FormSectionList";
import { DatetimeInput } from "../../components/common/datetime-input";
import { shift_date_by, now } from "../../datetime-utils";
import { get_touchpoint_desc } from "../../utils";
import { FieldError, FormErrors } from "../../components/Errors";
import moment from "../../../utils/moment";
import { makeInteractionsModal } from "./InteractionsModal";
import { InteractionListViewItem } from "./InteractionListViewItem";
import { Icon } from "../../components/Icon";
import { ApiRequestErrorPage } from "../../components/ApiRequestErrorPage";

const TpTypeOptions: InputOptions<string> = Object.entries(
  TouchpointTypes
).map(([k, v]) => ({ value: k, label: v }));

export const TpCategoryOptions: InputOptions<string> = Object.entries(
  TouchpointCategories
)
  .filter(([k]) => k !== "Other")
  .map(([k, v]) => ({ value: k, label: v }));

class CreateTouchpointForm extends RequestComponent<
  "request" | "window_size" | "user" | "app_location",
  {
    record?: Touchpoint;
    app_location: AppLocationAt<"activity/create" | "activity/id/edit">;
  },
  {
    participants: User[];
    interactions: Interaction[];
    touchpoint: Partial<TouchpointBody>;
    description_set: boolean;
    form_submitted: boolean;
    form_dirty: boolean;
  }
> {
  constructor(props) {
    super(props);
    const {
      app_location: { edit }
    } = props;
    const def = {
      was_successful: false,
      indirect: false,
      started_at: shift_date_by(now(), -1, "hour")
        .toISOString()
        .replace(/:\d{2}\.\d{3}Z/, ":00.000Z"),
      ended_at: now()
        .toISOString()
        .replace(/:\d{2}\.\d{3}Z/, ":00.000Z"),
      participants: path_or(
        [],
        ["app_location", "query", "participants"],
        props
      ),
      interactions: path_or(
        [],
        ["app_location", "query", "interactions"],
        props
      )
    };
    const touchpoint = {
      ...def,
      ...path_or(props.record || def, ["app_location", "record"], props)
    };
    this.state = {
      request_id: generate_id(`${edit ? "update" : "create"}-touchpoint`),
      participants: touchpoint.participants || [],
      interactions: touchpoint.interactions || [],
      touchpoint,
      description_set: !!touchpoint.description,
      form_submitted: false,
      form_dirty: false
    };
  }

  componentDidMount() {
    // @ts-ignore
    if (this.props.app_location.edit) {
      return;
    }
    if (this.state.interactions.length === 0) {
      this.props.dispatchNow(this.interactionsModalAction);
    } else if (this.state.participants.length === 0) {
      this.props.dispatchNow(this.participantsModalAction);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      this.props.dispatchNow([
        EVENTS.SET_APP_LOCATION,
        {
          ...this.props.app_location,
          form_dirty: false
        }
      ]);
      this.props.dispatchLater(
        [
          EVENTS.GO_TO_PAGE,
          {
            place: "activity",
            // @ts-ignore
            id: this.props.request[this.state.request_id].response.data.id
          }
        ],
        1200
      );
    }
    if (!prevState.form_dirty && this.state.form_dirty) {
      this.props.dispatchNow([
        EVENTS.SET_APP_LOCATION,
        {
          ...this.props.app_location,
          form_dirty: true
        }
      ]);
    }
  }

  removeParticipant = (user: User) => {
    this.setParticipants(
      remove_item_from(this.state.participants, v => v.id === user.id)
    );
  };

  setParticipants = (participants: User[]) => {
    const { touchpoint, description_set } = this.state;
    const new_state: any = { participants, form_dirty: true };
    if (!description_set) {
      new_state.touchpoint = {
        ...touchpoint,
        description: this.getDescription(touchpoint, participants)
      };
    }
    this.setState(new_state);
  };

  getParticipantsFromInteractions = (interactions: Interaction[]): User[] =>
    interactions.reduce(
      (a: User[], b: Interaction) =>
        a.concat(b.participants.filter(u => !a.some(au => au.id === u.id))),
      []
    );

  getTypeFromInteractions = (interactions: Interaction[]): string | undefined =>
    interactions.length > 0
      ? interactions.reduce(
          (a: string, b: Interaction): string => (a === b.type ? a : "Summary"),
          interactions[0].type
        )
      : undefined;

  removeInteraction = (interaction: Interaction) => {
    this.setInteractions(
      remove_item_from(this.state.interactions, v => v.id === interaction.id)
    );
  };

  setInteractions = (interactions: Interaction[]) => {
    const { touchpoint } = this.state;
    const new_state: any = {
      interactions,
      participants: this.getParticipantsFromInteractions(interactions),
      form_dirty: true,
      touchpoint: {
        ...touchpoint,
        type: this.getTypeFromInteractions(interactions)
      }
    };
    this.setState(new_state);
  };

  setTouchpoint = <K extends keyof TouchpointBody>(
    key: K,
    value: TouchpointBody[K]
  ) => {
    const { touchpoint, description_set } = this.state;
    const tp = {
      ...touchpoint,
      [key]: value
    };
    if (!description_set && key !== "description") {
      tp.description = this.getDescription(tp);
    }
    this.setState({
      touchpoint: tp,
      description_set: description_set || key === "description",
      form_dirty: true
    });
  };

  getDescription = (
    touchpoint: Partial<Touchpoint>,
    participants: User[] = this.state.participants
  ): string | undefined => {
    const { user } = this.props;
    if (touchpoint.type && participants.length > 0 && user) {
      return get_touchpoint_desc(
        // @ts-ignore
        { ...touchpoint, created_by: user, participants },
        user.id
      ).replace("You", capitalize(user.first_name));
    }
  };

  onSelectValue = (name: keyof TouchpointBody) => (
    opt?: InputOption<string>
  ) => {
    opt && this.setTouchpoint(name, opt.value);
  };

  onChange = <K extends keyof TouchpointBody>(name: K) => (
    value: TouchpointBody[K]
  ) => {
    this.setTouchpoint(name, value);
  };

  onSelectCategory = (checked, opt?: InputOption<string>) => {
    if (!opt) {
      return;
    }
    const { categories = [] } = this.state.touchpoint;
    this.setTouchpoint(
      "categories",
      checked
        ? add_item_to(categories, opt.value)
        : remove_item_from(categories, v => v === opt.value)
    );
  };

  onSubmitForm = e => {
    const { touchpoint, participants, interactions } = this.state;
    e.preventDefault();
    if (participants.length === 0) {
      const input = document.getElementById("create-touchpoint");
      input && input.scrollIntoView({ behavior: "smooth" });
      return false;
    }
    const edit = path_or(false, ["app_location", "edit"], this.props);
    this.sendRequest(
      edit ? "UpdateTouchpoint" : "PostTouchpoint",
      // @ts-ignore
      {
        ...(edit ? { id: this.props.app_location.id } : {}),
        touchpoint: {
          ...(touchpoint as TouchpointBody),
          participant_ids: participants.map(u => u.id),
          interaction_ids: interactions.map(u => u.id),
          description:
            (touchpoint.description ? touchpoint.description.trim() : "") ||
            this.getDescription(touchpoint, participants)
        }
      },
      this.state.request_id
    );
    return false;
  };

  ParticipantsModal = makeParticipantsModal(
    () => this.state.participants,
    this.setParticipants
  );
  participantsModalAction: AppEvent<AppEventName> = [
    EVENTS.OPEN_APP_MODAL,
    {
      title: "Participants",
      body: this.ParticipantsModal,
      className: "people__modal"
    }
  ];

  InteractionsModal = makeInteractionsModal(
    () => this.state.interactions,
    this.setInteractions
  );
  interactionsModalAction: AppEvent<AppEventName> = [
    EVENTS.OPEN_APP_MODAL,
    {
      title: "Associate Interactions",
      body: this.InteractionsModal,
      className: "people__modal"
    }
  ];

  render() {
    const {
      window_size,
      user,
      // @ts-ignore
      app_location: { edit, ...back_location }
    } = this.props;
    const {
      interactions,
      participants,
      touchpoint,
      form_submitted
    } = this.state;
    const {
      type,
      categories = [],
      was_successful,
      indirect,
      description,
      started_at = "",
      ended_at = "",
      creator_rating
    } = touchpoint;
    const cat_values = categories.map(value => ({
      value,
      label: get_label_from_value(TpCategoryOptions, value) || value
    }));

    const time_now = now().toISOString();
    const window_is_med = window_size > ScreenSize.sm;

    const req_errors = this.getRequestErrors();

    if (edit && (!user || !participants.some(p => p.id === user.id))) {
      return (
        <ApiRequestErrorPage
          error={new Error("You are not authorized to perform this action.")}
          //@ts-ignore
          response={{ status: 403 }}
        />
      );
    }

    const self_id = user ? user.id : -1;
    return (
      <form
        id="create-touchpoint"
        className="create-touchpoint__form page-form"
        onSubmit={this.onSubmitForm}
        data-submitted={form_submitted}
      >
        <FormSectionList
          className="create-touchpoint__record-list"
          listClassName="create-touchpoint__record-list__list interaction-checklist"
          items={interactions}
          title="Interactions"
          renderItem={p => (
            <div
              key={p.id}
              className="record-list__item interaction-list__item"
            >
              <InteractionListViewItem
                item={p}
                self_id={self_id}
                window_size={window_size}
                button={
                  <button
                    type="button"
                    className="create-touchpoint__record-list__remove"
                    onClick={e => {
                      e.preventDefault();
                      this.removeInteraction(p);
                    }}
                  >
                    <Icon name="remove-circle" />
                  </button>
                }
              />
            </div>
          )}
          button={
            <ActionButtonWithIcon
              type="button"
              className="filled"
              event={this.interactionsModalAction}
              icon={<Icon name="add" />}
              side="left"
            >
              Add Interactions
            </ActionButtonWithIcon>
          }
          emptyLabel="You haven’t associated any interactions with this TouchPoint."
          description={
            window_is_med
              ? "What interactions are part of this TouchPoint?"
              : undefined
          }
        />
        <FormSectionList
          className="create-touchpoint__record-list create-touchpoint__user-list"
          listClassName="create-touchpoint__record-list__list user-checklist"
          items={participants}
          title="Participants"
          renderItem={p => (
            <UserChecklistItemView
              key={p.id}
              size="md"
              item={p}
              button={
                <button
                  type="button"
                  className="create-touchpoint__record-list__remove"
                  onClick={e => {
                    e.preventDefault();
                    this.removeParticipant(p);
                  }}
                >
                  <Icon name="remove-circle" />
                </button>
              }
            />
          )}
          button={
            <ActionButtonWithIcon
              type="button"
              className="filled"
              event={this.participantsModalAction}
              icon={<Icon name="add" />}
              side="left"
            >
              Add Participants
            </ActionButtonWithIcon>
          }
          emptyLabel="No participants have been added yet."
          description={window_is_med ? "Who did you connect with?" : undefined}
        >
          <FieldError hasError={participants.length === 0}>
            You must add at least 1 participant.
          </FieldError>
        </FormSectionList>
        <FormSection title="Details">
          <InputRow
            description="What kind of interaction was this touchpoint?"
            className="create-touchpoint__rating"
          >
            <ButtonWithIcon
              className="create-touchpoint__rating__good bordered"
              icon="icon-thumbs_up.svg"
              side="left"
              data-selected={creator_rating === 0}
              onClick={() => this.onChange("creator_rating")(0)}
            >
              Good
            </ButtonWithIcon>
            <ButtonWithIcon
              className="create-touchpoint__rating__okay bordered"
              icon="icon-thumbs_side.svg"
              side="left"
              data-selected={creator_rating === 1}
              onClick={() => this.onChange("creator_rating")(1)}
            >
              Okay
            </ButtonWithIcon>
            <ButtonWithIcon
              className="create-touchpoint__rating__bad bordered"
              icon="icon-thumbs_down.svg"
              side="left"
              data-selected={creator_rating === 2}
              onClick={() => this.onChange("creator_rating")(2)}
            >
              Bad
            </ButtonWithIcon>
            <FormErrors<"PostTouchpoint">
              response={req_errors}
              formSubmitted={form_submitted}
              field={{ name: "creator_rating", label: "Touchpoint Rating" }}
            />
          </InputRow>
          <InputRow description="What kind of interaction was this touchpoint?">
            <DropdownSelectInput
              required={true}
              options={TpTypeOptions}
              value={type || ""}
              onSelect={this.onSelectValue("type")}
              inputProps={{ placeholder: "Touchpoint Type" }}
              defaultBlankOption={true}
              disabled={interactions.length > 0}
            >
              <FloatingTitle title="Touchpoint Type" />
            </DropdownSelectInput>
            <FormErrors<"PostTouchpoint">
              response={req_errors}
              formSubmitted={form_submitted}
              field={{ name: "type", label: "Touchpoint Type" }}
            />
          </InputRow>
          <InputRow className="create-touchpoint__was-successful">
            <BooleanCheckbox
              label={
                <div className="input-row__description">
                  Were you able to connect?
                </div>
              }
              name="was_successful"
              value={was_successful ? "true" : "false"}
              onChangeInput={this.onChange("was_successful")}
            />
            <FormErrors<"PostTouchpoint">
              response={req_errors}
              formSubmitted={form_submitted}
              field={{
                name: "was_successful",
                label: "Touchpoint Successful"
              }}
            />
          </InputRow>
          {user &&
          user.user_role !== 5 &&
          participants.length > 1 &&
          participants.some(participant => participant.user_role === 5) ? (
            <InputRow className="create-touchpoint__indirect">
              <BooleanCheckbox
                label={
                  <div className="input-row__description">
                    Was this an indirect touchpoint?
                  </div>
                }
                name="indirect"
                value={indirect ? "true" : "false"}
                onChangeInput={this.onChange("indirect")}
              />
              <FormErrors<"PostTouchpoint">
                response={req_errors}
                formSubmitted={form_submitted}
                field={{
                  name: "indirect",
                  label: "Touchpoint Successful"
                }}
              />
            </InputRow>
          ) : (
            <div style={{ display: "none" }} />
          )}
          <InputRow
            className="create-touchpoint__category"
            description="Select one or more categories for this TouchPoint:"
          >
            {!window_is_med ? (
              <>
                <DropdownSelectInput
                  required={cat_values.length === 0}
                  options={TpCategoryOptions.filter(
                    opt =>
                      cat_values.findIndex(o => o.value === opt.value) === -1
                  )}
                  value=""
                  onSelect={this.onSelectCategory.bind(this, true)}
                  inputProps={{ placeholder: "Touchpoint Category" }}
                  defaultBlankOption={true}
                >
                  <FloatingTitle
                    title={
                      cat_values.length === 0 ? "Categories" : "Categories *"
                    }
                  />
                </DropdownSelectInput>
                <div className="create-touchpoint__category__values">
                  {cat_values.map(opt => (
                    <MultiValueListItem
                      key={opt.value}
                      option={opt}
                      onRemove={() => this.onSelectCategory(false, opt)}
                    />
                  ))}
                </div>
              </>
            ) : (
              <CheckList
                options={TpCategoryOptions}
                onSelectOption={(opt: InputOption<string>, checked) =>
                  this.onSelectCategory(checked, opt)
                }
                values={cat_values}
              />
            )}
            <FormErrors<"PostTouchpoint">
              response={req_errors}
              formSubmitted={form_submitted}
              field={{
                name: "categories",
                label: "Categories"
              }}
            />
          </InputRow>
          <InputRow description="When was this touchpoint?">
            <div className="create-touchpoint__start">
              <DatetimeInput
                required={true}
                value={started_at}
                max={ended_at || time_now}
                onChange={e =>
                  this.onChange("started_at")(
                    moment(e.target.value).toISOString()
                  )
                }
                submitOnEnter={false}
              >
                <FloatingTitle title="Start Time" />
              </DatetimeInput>
            </div>
            <div className="create-touchpoint__end">
              <DatetimeInput
                required={true}
                value={ended_at}
                min={started_at}
                onChange={e =>
                  this.onChange("ended_at")(
                    moment(e.target.value).toISOString()
                  )
                }
                max={time_now}
                submitOnEnter={false}
              >
                <FloatingTitle title="End Time" />
              </DatetimeInput>
            </div>
            <FormErrors<"PostTouchpoint">
              response={req_errors}
              formSubmitted={form_submitted}
              field={{
                name: "started_at",
                label: "Start Time"
              }}
            />
          </InputRow>
          <InputRow
            description="Describe this touchpoint:"
            className="create-touchpoint__description"
          >
            <WithFloatingTitle title="Description">
              <TextAreaWithFormHelpers
                onChange={e => this.onChange("description")(e.target.value)}
                showCharLimit
                value={description}
              />
            </WithFloatingTitle>
          </InputRow>
        </FormSection>
        <FormErrors response={req_errors} formSubmitted={form_submitted} />
        <div className="form-section__footer">
          <div className="form-section__footer__buttons">
            <ActionButton
              type="button"
              data-button="cancel"
              className="bordered"
              event={[
                EVENTS.GO_TO_PAGE,
                {
                  place: "activity",
                  ...(edit ? { ...back_location, form_dirty: false } : {})
                }
              ]}
            >
              Cancel
            </ActionButton>
            <RequestButton
              pending={this.requestIsPending()}
              success={this.requestSucceeded()}
              successText="Success!"
              className="filled"
              type="submit"
              onClick={() => {
                !this.state.form_submitted &&
                  this.setState({ form_submitted: true });
              }}
            >
              {edit ? "Update" : "Create"} Touchpoint
            </RequestButton>
          </div>
        </div>
      </form>
    );
  }
}

export const CreateTouchpointFormConnected = connect(
  CreateTouchpointForm,
  true,
  ["request", "window_size", "user", "app_location"]
);
