import * as React from "react";
import { makeListView } from "./ListView";
import { EmptyList } from "./EmptyList";
import { PagedEndpointsFor, RecordChecklist } from "./RecordChecklist";
import { path_or } from "../../utils/common";
import { connect } from "../../model";
import { FacePile } from "./FacePile";
import { TouchpointTypeIcon } from "./TouchpointTypeIcon";
import { get_touchpoint_desc } from "../utils";
import { ApiReqViewProps, WithApiRequest } from "./WithApiRequest";
import moment from "../../utils/moment";
import { Icon } from "./Icon";

type InteractionListEndpoints = PagedEndpointsFor<Interaction>;
type MappedInteraction = Interaction & { is_border: boolean };

export const InteractionChecklistItemView: React.FC<{
  self_id: number;
  item: Interaction;
  button: React.ReactNode;
}> = ({ item, self_id, button }) => {
  return (
    <label
      htmlFor={`interaction-${item.id}`}
      className="record-list__item interaction-checklist__item"
    >
      <div className="interaction__left">
        <FacePile
          users={item.participants.filter(p => p.id !== self_id)}
          type="pile"
        />
        <TouchpointTypeIcon type={item.type as TouchpointType} size="sm" />
        <div className="interaction__description">
          <div className="interaction__time">
            {moment(new Date(item.created_at)).format("h:mm A")}
          </div>
          <div className="interaction__headline">
            {get_touchpoint_desc(item, self_id)}
          </div>
        </div>
      </div>
      <div className="interaction__right">{button}</div>
    </label>
  );
};

class InteractionChecklistBase extends RecordChecklist<
  Interaction,
  Context<"user">
> {
  constructor(props) {
    super(props);
    this.state = {
      // @ts-ignore
      query: {
        page: 0,
        pageSize: 10,
        isAssociated: false
      }
    };
  }

  calculateBorders = (records: Interaction[]): MappedInteraction[] => {
    if (records.length === 0) {
      return [];
    }
    return records.map((r, i) => {
      const prev = records[i - 1];
      if (
        prev == null ||
        moment(new Date(r.created_at)).dayOfYear() !==
          moment(new Date(prev.created_at)).dayOfYear()
      ) {
        return { ...r, is_border: true };
      }
      return { ...r, is_border: false };
    });
  };

  ChecklistItemView: React.FC<{ item: MappedInteraction }> = ({ item }) => {
    const self_id = path_or(-1, ["user", "id"], this.props);
    const { is_border, ...record } = item;
    return (
      <>
        {item.is_border && (
          <div className="interaction-checklist__item interaction-checklist__border">
            <span>
              {moment(new Date(item.created_at)).calendar(moment(), {
                sameDay: "[Today]",
                lastDay: "[Yesterday]",
                lastWeek: "MMMM D, YYYY",
                sameElse: "MMMM D, YYYY"
              })}
            </span>
          </div>
        )}
        <input
          className="interaction-checklist__item__input"
          type="checkbox"
          id={`interaction-${item.id}`}
          value={item.id}
          checked={!!this.props.selected.find(i => i.id === item.id)}
          onChange={e => this.onChange(e, item)}
        />
        <InteractionChecklistItemView
          item={record}
          self_id={self_id}
          button={<Icon name="check" className="radio-button__button" />}
        />
      </>
    );
  };

  ChecklistView: React.FC<
    ApiReqViewProps<InteractionListEndpoints>
  > = makeListView<InteractionListEndpoints, MappedInteraction>(
    "InteractionChecklistView",
    this.nextPage,
    this.ChecklistItemView,
    undefined,
    <EmptyList>
      You don't have any unassigned interactions at the moment.
    </EmptyList>,
    this.calculateBorders
  );

  render() {
    return (
      <div className="record-checklist">
        <WithApiRequest
          id={this.props.id}
          View={this.ChecklistView}
          payload={{
            method: this.props.method,
            // @ts-ignore
            parameters: this.state.query
          }}
        />
      </div>
    );
  }
}

export const InteractionChecklist = connect(
  InteractionChecklistBase,
  false,
  ["request", "user"]
);
