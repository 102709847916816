export const SET_ENV = "ev_set_env";
export const ERROR = "ev_error";
export const GO_TO_PAGE = "ev_go_to_page";
export const SET_APP_LOCATION = "ev_set_app_location";
export const SET_APP_LOCATION_FORCE = "ev_set_app_location_force";

// CLIENT CONNECTOR EVENTS
export const CALL_CLIENT_METHOD = "ev_call_client_method";
export const SEND_XHR_REQUEST = "ev_send_xhr_request";
export const CLEAR_CLIENT_REQUEST = "ev_clear_client_request";
export const SET_CLIENT_RESPONSE = "ev_set_client_response";

// Login
export const LOGIN = "ev_login";
export const LOGIN_WITH_GOOGLE_SUCCESS = "ev_login_with_google_success";
export const LOGIN_WITH_GOOGLE_FAIL = "ev_login_with_google_fail";
export const GOOGLE_LOGIN_BUTTON_MOUNTED = "ev_google_login_button_mounted";
export const GOOGLE_AUTHENTICATE_SUCCESS = "ev_google_authenticate_success";
export const GOOGLE_AUTHENTICATE_FAIL = "ev_google_authenticate_fail";
export const GOOGLE_AUTH_SET_AVAILABLE = "ev_google_auth_set_available";
export const LOGOUT = "ev_logout";
export const RELOGIN = "ev_relogin";
export const GET_USER_WITH_TEMP_TOKEN = "ev_get_user_with_temp_token";
export const REQUEST_PASSWORD_RESET = "ev_request_password_reset";
export const SET_PASSWORD = "ev_set_password";
export const SET_APP_USER = "ev_set_app_user";
export const SET_AUTH_EXPIRED = "ev_set_auth_expired";
export const REFRESH_ACTIONS = "ev_refresh_actions";

// Modals
export const SET_APP_MODAL_OPEN = "ev_set_app_modal_open";
export const SET_APP_MODAL_CONTENT = "ev_set_app_modal_content";
export const OPEN_APP_MODAL = "ev_open_app_modal";
export const CLOSE_APP_MODAL = "ev_close_app_modal";
export const OPEN_LOGIN_MODAL = "ev_open_login_modal";
export const CLOSE_LOGIN_MODAL = "ev_close_login_modal";
export const SET_LOGIN_MODAL_CONTENT = "ev_set_login_modal_content";
