import * as React from "react";
import { CONTEXTS } from "../../../model";
import { Breadcrumb, Crumb } from "../../components/common/breadcrumb";
import { CommunityLocation } from "./ListItem";
import { Icon } from "../../components/Icon";
import { useContext } from "react";

export const CommunityBreadcrumb: React.FC<{
  separator?: React.ReactNode;
}> = ({ separator }) => {
  const app_location = useContext(CONTEXTS.app_location) as CommunityLocation;
  const crumbs: Crumb[] = [
    {
      label: "Communities",
      href: "/communities"
    }
  ];
  const { community, thread, family, record } = app_location;
  if (community) {
    const community_name = community.name
      ? community.name
      : record
      ? record.community_name
      : undefined;
    community_name &&
      crumbs.push({
        label: community_name,
        href: `/communities/${community.id}`
      });
    if (community_name && thread) {
      const thread_name = thread.name
        ? thread.name
        : record
        ? record.thread_name
        : undefined;
      crumbs.push({
        label: thread_name,
        href: `/communities/${community.id}/threads/${thread.id}`
      });
      if (thread_name && family) {
        const family_name = family.name
          ? family.name
          : record
          ? record.family_name
          : undefined;
        crumbs.push({
          label: family_name,
          href: `/communities/${community.id}/threads/${thread.id}/families/${
            family.id
          }`
        });
      }
    }
  }

  return (
    <div className="community-breadcrumb__container">
      <Icon name="family" />
      <Breadcrumb items={crumbs} separator={separator} />
    </div>
  );
};
