import * as React from "react";
import { maybeClassName } from "../../utils/dom-helpers";

/**
 * bodyType:
 *   - `normal-block` (default): content inside white block with gray margins
 *   - `full-width-block`: content inside white block that spans entire viewport width
 *   - `no-container`: margins but no background styling for content container
 *   - `no-margins`: no margins or background styling for content container
 * */
export const PageContent: React.FunctionComponent<
  MaybeClass & {
    pageHeader: React.ReactNode;
    bodyType?:
      | "no-container"
      | "no-margins"
      | "full-width-block"
      | "normal-block";
    id: string;
  }
> = ({ pageHeader, id, className, children, bodyType = "normal-block" }) => (
  <section
    data-page={id}
    className={`page-content${maybeClassName(className)}`}
    id={`page-${id}`}
  >
    <div className="page-button-portal" id="page-button-portal" />
    <header className="page-header">{pageHeader}</header>
    <div className={`page-body${maybeClassName(bodyType)}`}>
      <div className="page-body__content">{children}</div>
    </div>
  </section>
);
PageContent.displayName = "PageContent";
