import * as React from "react";

export const enumerate_score = (
  score?: number
): "good" | "okay" | "bad" | "unknown" =>
  score == null
    ? "unknown"
    : score <= 49
    ? "bad"
    : score <= 82
    ? "okay"
    : "good";

export const HealthScore: React.FC<{ score: number }> = ({ score }) => (
  <div className="group__health" data-state={enumerate_score(score)}>
    {score == null ? "?" : `${score}%`}
  </div>
);
HealthScore.displayName = "HealthScore";
