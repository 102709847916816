import * as React from "react";
import { capitalize } from "../../utils/common";
import { Icon } from "./Icon";

export const CreatorMarker: React.FC<{ type: "expense" | "touchpoint" }> = ({
  type
}) => (
  <span
    data-for={type}
    title={`${capitalize(type)} creator`}
    className="creator-marker"
  >
    {type === "expense" ? "$" : <Icon name="pencil" />}
  </span>
);
CreatorMarker.displayName = "CreatorMarker";
