export const RENDER_GOOGLE_LOGIN_BUTTON = "fx_render_google_login_button";
export const CALL_CLIENT_METHOD = "fx_call_client_method";
export const LOGIN = "fx_login";
export const LOGOUT = "fx_logout";
export const LOGIN_WITH_GOOGLE = "fx_login_with_google";
export const LOGOUT_WITH_GOOGLE = "fx_logout_with_google";
export const RELOGIN = "fx_relogin";
export const REFRESH_AUTH = "fx_refresh_auth";
export const GET_USER_WITH_TEMP_TOKEN = "fx_get_user_with_temp_token";
export const REQUEST_PASSWORD_RESET = "fx_request_password_reset";
export const SET_PASSWORD = "fx_set_password";
export const SEND_XHR_REQUEST = "fx_send_xhr_request";
export const REFRESH_ACTIONS = "fx_refresh_actions";
