import * as React from "react";
import * as ReactDOM from "react-dom";
import { EVENTS, connect } from "../../../model";
import {
  ApiReqViewProps,
  WithApiRequest
} from "../../components/WithApiRequest";
import { DetailSection, FormSection } from "../../components/common/common";
import { PageContent } from "../../components/PageContent";
import { ActionButtonWithIcon } from "../../components/common/button-with-icon";
import { PageHeader } from "../../components/PageHeader";
import { PeopleListViewItem } from "../People/List";
import { TouchpointTypeIcon } from "../../components/TouchpointTypeIcon";
import {
  ExpenseCategories,
  TouchpointCategories,
  TouchpointTypes
} from "../../../constants";
import { FormSectionList } from "../../components/FormSectionList";
import { CreatorMarker } from "../../components/CreatorMarker";
import moment from "../../../utils/moment";
import { get_touchpoint_desc } from "../../utils";
import { Icon } from "../../components/Icon";
import { CommentsList } from "./CommentsList";
import { DropdownMenu } from "../../components/common/dropdown-menu";
import { ActionButton, ActionLink } from "../../components/common/action-link";
import { DeleteTouchpointModalConnected } from "./DeleteModal";
import { TouchpointPhotoUploadConnected } from "./PhotoUpload";
import { LikeButtonConnected } from "../../components/LikeButton";

const TouchpointDetailView: (
  refresh
) => React.FC<
  ApiReqViewProps<"GetTouchpointById"> &
    Context<"user"> & { app_location: AppLocationAt<"activity/id"> }
> = refresh => {
  const View: React.FC<
    ApiReqViewProps<"GetTouchpointById"> &
      Context<"user"> & { app_location: AppLocationAt<"activity/id"> }
  > = ({ response, pending, user, app_location }) => {
    if (!response || pending) {
      return null;
    }
    const {
      id,
      participants,
      description,
      started_at,
      ended_at,
      created_at,
      created_by,
      categories,
      expenses = [] as ExpenseRecord[],
      type,
      was_successful,
      indirect,
      emoji_responses,
      total_comments,
      updated_at,
      touchpoint_photo
    } = response.data;
    const created = moment(created_at);
    const start = moment(started_at);
    const end = moment(ended_at);
    let start_time = start.format("h:mm:ss A");
    let end_time = end.format("h:mm:ss A");
    if (end.unix() - start.unix() >= 60 || end.minutes() !== start.minutes()) {
      start_time = start.format("h:mm A");
      end_time = end.format("h:mm A");
    }
    const editable =
      !!user &&
      user.id === created_by.id &&
      // @ts-ignore
      moment(moment.now()).diff(moment(created)) < 12 * 60 * 60 * 1000;

    const is_participant =
      !!user && participants.some(participant => participant.id === user.id);

    const header_date_node = document.getElementById(
      `touchpoint-header-date-${id}`
    );

    const deleteModalAction: AppEvent<AppEventName> = [
      EVENTS.OPEN_APP_MODAL,
      {
        title: "Delete Touchpoint?",
        body: props => <DeleteTouchpointModalConnected {...props} id={id} />,
        className: "touchpoint-delete__modal"
      }
    ];

    return (
      <div className="touchpoint-detail__container">
        <FormSection
          className="touchpoint-detail__top-header"
          title={
            <div data-editable={editable}>
              <div>Participants</div>
              {editable && (
                <DropdownMenu
                  className="actions-menu"
                  button={<Icon name="more" />}
                  items={[
                    <ActionLink location={{ ...app_location, edit: true }}>
                      Edit Touchpoint
                    </ActionLink>,
                    expenses.length > 0 ? null : (
                      <ActionButton
                        // icon={<Icon name="remove" />}
                        event={deleteModalAction}
                      >
                        Delete Touchpoint
                      </ActionButton>
                    )
                  ]}
                />
              )}
            </div>
          }
        >
          <div className="touchpoint-detail__participants__list user-checklist">
            {participants.map(p => (
              <PeopleListViewItem key={p.id} item={p}>
                {p.id === created_by.id ? (
                  <CreatorMarker type="touchpoint" />
                ) : null}
              </PeopleListViewItem>
            ))}
          </div>
        </FormSection>
        <FormSection title="Details">
          <dl className="touchpoint-detail__details">
            <DetailSection
              title="Touchpoint Type"
              className="touchpoint-detail__type"
            >
              <TouchpointTypeIcon
                type={type as TouchpointType}
                size="xs"
                data-screen-above="sm"
              />
              <div className="touchpoint-detail__type__name">
                {TouchpointTypes[type]}
              </div>
            </DetailSection>
            <DetailSection title="Able to Connect?">
              {was_successful ? "Yes" : "No"}
            </DetailSection>
            {created_by.user_role !== 5 &&
            participants.length > 1 &&
            participants.some(participant => participant.user_role === 5) ? (
              <DetailSection title="Indirect Touchpoint?">
                {indirect ? "Yes" : "No"}
              </DetailSection>
            ) : (
              <div style={{ display: "none" }} />
            )}
            <DetailSection
              title="Touchpoint Date & Time"
              className="touchpoint-detail__date"
            >
              <div className="touchpoint-detail__date__values">
                <span>
                  <span data-label data-screen-below="md">
                    Start:{" "}
                  </span>
                  {start.format("MMMM D, YYYY")} {start_time}
                </span>
                <span>
                  <span data-label data-screen-below="md">
                    End:{" "}
                  </span>
                  {end.format("MMMM D, YYYY")} {end_time}
                </span>
              </div>
            </DetailSection>
            <DetailSection
              title="Category"
              className="touchpoint-detail__categories"
            >
              {categories.map(c => TouchpointCategories[c]).join(", ")}
            </DetailSection>
            <DetailSection
              title="Description"
              className="touchpoint-detail__description"
            >
              {description ||
                get_touchpoint_desc(response.data, user ? user.id : -1)}
            </DetailSection>
          </dl>
        </FormSection>
        <FormSectionList
          className="touchpoint-detail__expenses"
          listClassName="touchpoint-detail__expenses__list"
          title="Expenses"
          emptyLabel="No expenses have been added yet."
          items={expenses}
          button={
            is_participant ? (
              <ActionButtonWithIcon
                type="button"
                className="filled"
                event={[
                  EVENTS.GO_TO_PAGE,
                  {
                    place: "activity",
                    id,
                    expense: "create"
                  }
                ]}
                icon={<Icon name="add" />}
                side="left"
              >
                Add Expense
              </ActionButtonWithIcon>
            ) : (
              undefined
            )
          }
          renderItem={(exp: ExpenseRecord) => (
            <a
              key={exp.id}
              href={`/activity/${id}/expense/${exp.id}`}
              className="record-list__item expense-list__item"
            >
              <div className="expense-list__item__image">
                <img
                  src={exp.receipt_image_url}
                  alt={`Receipt from ${exp.vendor_name}`}
                />
              </div>
              <div className="expense-list__item__details">
                <div className="expense-list__item__title">
                  {exp.vendor_name}
                </div>
                <div className="expense-list__item__info">
                  {ExpenseCategories[exp.expense_category]}, $
                  {exp.receipt_amount}
                </div>
              </div>
            </a>
          )}
        />
        {(editable || touchpoint_photo) && (
          <TouchpointPhotoUploadConnected
            touchpoint_id={id}
            touchpoint_photo={touchpoint_photo}
            editable={editable}
            refresh={refresh}
          />
        )}
        <FormSection
          className="comments__section"
          title={
            <>
              <div className="comments__section__title">
                Comments
                <span id="comment-count-badge" className="count-badge" />
              </div>
              <LikeButtonConnected
                touchpoint_id={id}
                reactions={emoji_responses}
                onChange={refresh}
                touchpoint_updated_at={updated_at}
              />
            </>
          }
        >
          <CommentsList touchpoint_id={id} total_comments={total_comments} />
        </FormSection>
        {header_date_node &&
          ReactDOM.createPortal(start.format("MMMM D, YYYY"), header_date_node)}
      </div>
    );
  };
  return connect(
    View,
    false,
    ["app_location", "user"]
  );
};

export class TouchpointDetailBase extends React.Component<
  Dispatchers & WithAppLocationAt<"activity/id">
> {
  refresh = () => {};
  setRefresh = func => {
    this.refresh = func;
    this.View = TouchpointDetailView(this.refresh);
  };
  View = TouchpointDetailView(this.refresh);

  render() {
    const { app_location, dispatchNow } = this.props;
    this.View.displayName = "TouchpointDetailView";
    return (
      <PageContent
        id="touchpoint-detail"
        className="touchpoint-page detail-page header-overlap-block header-overlap-sm"
        bodyType="full-width-block"
        pageHeader={
          <PageHeader
            title="Touchpoint"
            icon={<Icon name="activity" />}
            button={
              <div
                id={`touchpoint-header-date-${app_location.id}`}
                className="touchpoint-detail__header-date"
              />
            }
          />
        }
      >
        <WithApiRequest
          payload={{
            method: "GetTouchpointById",
            parameters: {
              touchpoint_id: app_location.id
            }
          }}
          refresh={this.setRefresh}
          View={this.View}
          id={`touchpoint-detail-${app_location.id}`}
          onSuccess={req =>
            req.response.data &&
            dispatchNow([
              EVENTS.GO_TO_PAGE,
              {
                ...app_location,
                record: req.response.data
              }
            ])
          }
          overrideRecord={app_location.record}
        />
      </PageContent>
    );
  }
}

export const TouchpointDetail = connect(
  TouchpointDetailBase,
  true,
  ["app_location"]
);
