import * as React from "react";
import { InputWithDropdown } from "./input-with-dropdown";
import { maybeClassName } from "../../../utils/dom-helpers";
import { ElementWithFormHelpers } from "./inputs";
import { get_label_from_value, get_value_from_label } from "./common";

export class DropdownSelectInput<
  T extends string | number
> extends React.Component<
  {
    options: InputOptions<T>;
    value: T;
    onSelect: (opt?: InputOption<T>) => void;
    inputProps?: HTMLSelectProps;
    defaultBlankOption?: boolean;
    required?: boolean;
    disabled?: boolean;
  } & MaybeClass
> {
  constructor(props) {
    super(props);
    //@ts-ignore
    this.select_input.displayName = "select_input";
  }
  select_input = ({
    className,
    onChange,
    placeholder,
    onBlur,
    onFocus,
    value: value_text,
    ...props
  }) => {
    const {
      inputProps = {},
      options,
      value = "",
      defaultBlankOption
    } = this.props;
    const {
      ref: _,
      className: pClassName,
      onChange: pOnChange,
      onBlur: pOnBlur,
      onFocus: pOnFocus,
      required: pRequired,
      ...pProps
    } = inputProps;

    const pseudo_value = get_value_from_label(options, value_text);

    return (
      <PseudoSelectInput
        {...pProps}
        className={`${maybeClassName(className)}${maybeClassName(pClassName)}`}
        options={options}
        required={pRequired}
        onChange={args => {
          onChange && onChange(args);
          pOnChange && pOnChange(args);
        }}
        onBlur={args => {
          onBlur && onBlur(args);
          pOnBlur && pOnBlur(args);
        }}
        onFocus={args => {
          onFocus && onFocus(args);
          pOnFocus && pOnFocus(args);
        }}
        validate={value => !pRequired || value != null}
        // @ts-ignore
        value={pseudo_value == null ? value : pseudo_value}
        defaultBlankOption={defaultBlankOption}
        {...props}
      />
    );
  };

  render() {
    const {
      className,
      children,
      options,
      required,
      value,
      disabled
    } = this.props;

    return (
      <div
        className={`select__container pseudo-select${maybeClassName(
          className
        )}`}
      >
        <InputWithDropdown
          options={options}
          allowBlank={!required}
          onSelect={this.props.onSelect}
          Input={this.select_input}
          disabled={disabled}
          value={value != null ? (`${value}` as T) : undefined}
        >
          {children}
        </InputWithDropdown>
      </div>
    );
  }
}

const onAction = (action, preventDefault: boolean) => e => {
  preventDefault && e.preventDefault();
  action && action(e);
};

// renders a hidden `select` element as a fallback and a visible readonly input to display
// the selected value
class PseudoSelectInput<
  T extends string | number
> extends ElementWithFormHelpers<
  HTMLSelectElement,
  T,
  { options: InputOptions<T>; defaultBlankOption?: boolean }
> {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }
  // @ts-ignore
  input: React.RefObject<HTMLInputElement>;
  // need to create a listener since keydown events don't trigger
  // on readonly inputs.
  on_input_focus = e => {
    const { onFocus, onKeyDown } = this.props;
    this.withElement(elem => {
      elem.click();
      //@ts-ignore
      onKeyDown && elem.addEventListener("keydown", onKeyDown);
    });
    onFocus && onFocus(e);
  };

  on_input_blur = e => {
    const { onBlur, onKeyDown } = this.props;
    this.withElement(elem => {
      //@ts-ignore
      onKeyDown && elem.removeEventListener("keydown", onKeyDown);
    });
    onBlur && onBlur(e);
  };

  render() {
    const {
      className,
      onClick,
      placeholder,
      value,
      options,
      defaultBlankOption,
      ref,
      validate,
      submitOnEnter,
      onSubmitInput,
      onKeyDown,
      onFocus,
      onBlur,
      onChangeDebounce,
      ...props
    } = this.props;

    return (
      <>
        <select
          {...props}
          className="pseudo-select__hidden-input"
          onChange={this.onChange}
          value={value}
          tabIndex={-1}
          onKeyDown={this.onKeyDown}
        >
          {(value == null || value === "" || defaultBlankOption) && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map(({ label, value }) => (
            <option key={value} data-value={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <input
          ref={this.input}
          type="text"
          readOnly={true}
          className={`pseudo-select__input${maybeClassName(className)}`}
          placeholder={placeholder}
          data-value={this.value}
          data-empty={this.empty}
          data-valid={this.valid}
          data-dirty={this.dirty}
          required={props.required && value == null}
          //@ts-ignore
          value={get_label_from_value(options, value) || ""}
          onClick={onAction(onClick, true)}
          onFocus={this.on_input_focus}
          onBlur={this.on_input_blur}
        />
      </>
    );
  }
}
