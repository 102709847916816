/**
 * Only chrome supports `datetime-local` inputs, so we need a version
 * that uses `date` and `time` inputs instead. On top of that, Safari
 * doesn't support any of those, so thanks to Apple, we need components
 * that simulate date/time inputs (to be added soon).
 * */

import * as React from "react";
import {
  ISO_REGEX,
  supportsInputType,
  time_to_comparable,
  validate_datetime,
  to_local_datetime,
  input_values_to_iso,
  ISO_PATTERN,
  round_to_minutes,
  str_to_input_values
} from "../../datetime-utils";
import { InputWithFormHelpers } from "./inputs";
import { DateInput } from "./date-input";
import { TimeInput } from "./time-input";
import moment from "../../../utils/moment";

/** ALL DATETIME VALUES PASSED TO THIS COMPONENT SHOULD BE ISO DATETIME STRINGS */
export class DatetimeInput extends InputWithFormHelpers<
  string,
  {
    min?: string;
    max?: string;
  }
> {
  constructor(props) {
    super(props);
    this.supportsDatetimeLocal = supportsInputType("datetime-local");
    this.supportsDate = supportsInputType("date");
    this.supportsTime = supportsInputType("time");
  }
  supportsDatetimeLocal: boolean;
  supportsDate: boolean;
  supportsTime: boolean;

  validate = (value?: string) => {
    const { min, max } = this.props;
    return validate_datetime(
      moment(value).toISOString() || "",
      this.input.current || undefined,
      min ? moment(min) : undefined,
      max ? moment(max) : undefined
    );
  };

  render() {
    const {
      value,
      min,
      max,
      children,
      type,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      onChange,
      ref,
      ...props
    } = this.props;

    const val = value ? to_local_datetime(value) : "";

    return this.supportsDatetimeLocal ? (
      <label className="input__container">
        <input
          type="datetime-local"
          data-value={val}
          data-empty={this.empty}
          data-valid={this.valid}
          data-dirty={this.dirty}
          min={min ? to_local_datetime(min) : undefined}
          max={max ? to_local_datetime(max) : undefined}
          {...props}
          defaultValue={val}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
        />
        {children}
      </label>
    ) : (
      <DatetimeComboInput
        {...props}
        children={children}
        value={value}
        min={min}
        max={max}
        onChange={this.handleChange}
      />
    );
  }
}

/**
 * @property {string} state.date in format yyyy-mm-dd
 * @property {string} state.time in format hh:mm
 * @property {string} props.value in format `yyyy-mm-ddThh:mm`
 * @property {string} props.min in format `yyyy-mm-ddThh:mm`
 * @property {string} props.max in format `yyyy-mm-ddThh:mm`
 */
export class DatetimeComboInput extends InputWithFormHelpers<
  string,
  HTMLInputProps & {
    //onChangeValue: (value: string) => void
    /** format `yyyy-mm-ddThh:mm` */
    value?: string;
    /** format `yyyy-mm-ddThh:mm` */
    min?: string;
    /** format `yyyy-mm-ddThh:mm` */
    max?: string;
  },
  {
    cur_value?: string;
    date: string;
    time: string;
    range: DateTimeRange;
  }
> {
  constructor(props) {
    super(props);
    const { date, time } = str_to_input_values(props.value || "");
    const state: any = {
      cur_value: date && time ? props.value : undefined,
      date: date || "",
      time: time || "",
      range: {
        date_range: {},
        time_range: {}
      }
    };
    state.range = this.computeValidRange(state);
    this.state = state;

    this.focus = false;
    this.date_focused = false;
    this.time_focused = false;
    this.inputs = React.createRef();
  }
  inputs: React.RefObject<HTMLDivElement>;
  focus: boolean;
  date_focused: boolean;
  time_focused: boolean;

  validate = (value: string) => {
    const { min, max } = this.props;
    const { date_range, time_range } = this.state.range;
    const min_ =
      date_range.min && time_range.min
        ? input_values_to_iso(date_range.min, time_range.min)
        : min;
    const max_ =
      date_range.max && time_range.max
        ? input_values_to_iso(date_range.max, time_range.max)
        : max;
    return validate_datetime(
      value || "",
      this.input.current || undefined,
      min_ ? moment(min_) : undefined,
      max_ ? moment(max_) : undefined
    );
  };

  componentDidMount() {
    // necessary because apparently the normal listener we set in render() doesn't get
    // called when we manually dispatch a change event
    this.withElement(elem => {
      // @ts-ignore
      elem.onchange = this.handleChange;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { value = "", min, max } = this.props;
    const { time, date, cur_value } = this.state;
    let new_state: any = {};
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.cur_value &&
      this.state.cur_value === prevState.cur_value &&
      ISO_REGEX.test(value) &&
      moment(value).isValid()
    ) {
      new_state = {
        cur_value: round_to_minutes(value),
        ...str_to_input_values(value)
      };
    }
    if (
      date !== prevState.date ||
      time !== prevState.time ||
      prevProps.max !== max ||
      prevProps.min !== min
    ) {
      new_state.range = this.computeValidRange();
    }

    if (cur_value !== prevState.cur_value) {
      this.withElement(elem => {
        elem.value = cur_value || "";
        elem.dispatchEvent(new Event("change"));
      });
    }

    if (Object.keys(new_state).length > 0) {
      this.setState(new_state);
    }
  }

  focusInputOnClick = e => {
    if (e.target.tagName === "INPUT") {
      e.preventDefault();
      return false;
    } else if (this.inputs.current) {
      const inputs = this.inputs.current.getElementsByTagName("input");
      for (let i = 0; i < inputs.length; i++) {
        if (!inputs[i].disabled && inputs[i].name) {
          setTimeout(() => inputs[i].focus(), 10);
          return;
        }
      }
    }
  };

  onFocus = (name: "date" | "time") => () => {
    this[`${name}_focused`] = true;
    this.focus = this.time_focused || this.date_focused;
    this.forceUpdate();
  };

  onBlur = (name: "date" | "time") => () => {
    this[`${name}_focused`] = false;
    this.focus = this.time_focused || this.date_focused;
    this.forceUpdate();
  };

  onClear = () => {
    this.setState({
      date: "",
      time: ""
    });
  };

  onChangeInput = (name: "date" | "time") => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // @ts-ignore
    this.setState({ [name]: e.target.value }, this.maybeUpdateValue);
  };

  maybeUpdateValue = () => {
    const { date, time } = this.state;
    if (date && time) {
      this.setState({ cur_value: input_values_to_iso(date, time) });
    } else if (!date && !time) {
      this.setState({ cur_value: undefined });
    }
  };

  computeValidRange = (state = this.state): DateTimeRange => {
    const { min, max } = this.props;
    const mins: any = min ? str_to_input_values(min) : {};
    const maxs: any = max ? str_to_input_values(max) : {};
    const { time, date } = state;
    const date_range: any = { min: mins.date, max: maxs.date };
    const time_range: any = {};

    const m_date = moment(date);
    const m_min_date = moment(mins.date);
    const m_max_date = moment(maxs.date);

    if (date && mins.date && mins.time && m_min_date.isSame(m_date)) {
      time_range.min = mins.time;
    } else {
      date_range.min = mins.date;
      const _time = time_to_comparable(time);
      const min_time = time_to_comparable(mins.time);
      if (_time != null && min_time != null && _time <= min_time && mins.date) {
        date_range.min = str_to_input_values(
          m_min_date
            .clone()
            .add(1, "day")
            .toISOString()
        ).date;
      }
    }
    if (date && maxs.date && maxs.time && m_max_date.isSame(m_date)) {
      time_range.max = maxs.time;
    } else {
      date_range.max = maxs.date;
      const _time = time_to_comparable(time);
      const max_time = time_to_comparable(maxs.time);
      if (_time != null && max_time != null && _time >= max_time && maxs.date) {
        date_range.max = str_to_input_values(
          m_max_date
            .clone()
            .subtract(1, "day")
            .toISOString()
        ).date;
      }
    }
    return { date_range, time_range };
  };

  render() {
    const {
      value,
      type,
      min,
      max,
      onSubmitInput,
      submitOnEnter,
      onChangeDebounce,
      validate,
      children,
      ref,
      onChange,
      ...props
    } = this.props;
    const { cur_value, date, time, range } = this.state;
    const { date_range, time_range } = range;
    const m_value = moment(cur_value)
      .local()
      .toISOString();

    return (
      <div className="datetime__container input__container">
        <input
          ref={this.input}
          {...props}
          data-empty={this.empty}
          data-valid={this.valid}
          data-dirty={this.dirty}
          data-focus={this.focus}
          data-value={m_value}
          className="datetime__hidden"
          value={m_value}
          pattern={ISO_PATTERN}
          onKeyDown={this.onKeyDown}
          tabIndex={-1}
        />
        <div
          ref={this.inputs}
          className="datetime__inputs"
          data-empty={this.empty}
          data-valid={this.valid}
          data-dirty={this.dirty}
          data-focus={this.focus}
          data-value={m_value}
          onClick={this.focusInputOnClick}
        >
          <DateInput
            value={date}
            onChange={this.onChangeInput("date")}
            submitOnEnter={false}
            onFocus={this.onFocus("date")}
            onBlur={this.onBlur("date")}
            onClear={this.onClear}
            {...date_range}
          />
          <div className="datetime__inputs__hide-x" />
          <TimeInput
            value={time}
            onChange={this.onChangeInput("time")}
            submitOnEnter={false}
            onFocus={this.onFocus("time")}
            onBlur={this.onBlur("time")}
            onClear={this.onClear}
            {...time_range}
          />
        </div>
        {children}
      </div>
    );
  }
}
