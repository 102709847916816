import * as React from "react";
import { handle_after } from "../../../utils/common";
import { class_names } from "../../../utils/dom-helpers";
import { Icon } from "../Icon";

const BLUR_FOCUS_DELAY = 50;
const OPTION_HEIGHT = 32; //px
const NUM_OPTIONS = 5;
const LIST_PADDING = 0; //px
const LIST_PADDING_TOTAL = 2 * LIST_PADDING; //px

interface InputWithDropdownPropsBase<T extends string | number> {
  Input: React.ComponentType<HTMLInputProps | HTMLSelectProps>;
  options: InputOptions<T>;
  onSelect: (option?: InputOption<T>) => void;
  allowBlank?: boolean;
  allowCustom?: boolean;
  allowMultiple?: boolean;
  stayOpenAfterSelect?: boolean;
  value?: T;
  disabled?: boolean;
}

interface InputWithDropdownPropsSingle<T extends string | number>
  extends InputWithDropdownPropsBase<T> {
  allowMultiple?: false;
}
interface InputWithDropdownPropsMultiple<T extends string | number>
  extends InputWithDropdownPropsBase<T> {
  allowMultiple: true;
  selected: InputOptions<T>;
}

type InputWithDropdownProps<T extends string | number> =
  | InputWithDropdownPropsSingle<T>
  | InputWithDropdownPropsMultiple<T>;

/**
 *  Displays a list of options below a given text input component
 *  NOTE: CONTAINER MUST BE `position: relative` FOR THIS COMPONENT
 *  TO LOOK RIGHT.
 */
export class InputWithDropdown<
  T extends string | number
> extends React.Component<
  InputWithDropdownProps<T>,
  {
    active_index: number; // index of the option highlighted in the dropdown list
    open: boolean; // whether or not the list is displayed
    hold_open_on_blur: boolean; // whether to hold the menu open after the input is blurred
  }
> {
  menu: React.RefObject<HTMLDivElement>;
  items: React.RefObject<HTMLUListElement>;

  constructor(props) {
    super(props);
    this.state = {
      active_index: -1,
      open: false,
      hold_open_on_blur: false
    };
    this.menu = React.createRef();
    this.items = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.disabled && this.props.disabled) {
      this.close_menu(false);
    }
    if (!this.state.open && this.state.active_index > -1) {
      this.setState({ active_index: -1 });
    }
  }

  next_item = () => {
    const { options } = this.props;
    const { active_index } = this.state;
    const max = options.length - 1;

    this.setState(
      {
        open: true,
        active_index: !open || active_index === max ? -1 : active_index + 1
      },
      this.scroll_menu(active_index)
    );
  };

  prev_item = () => {
    const { options } = this.props;
    const { active_index } = this.state;
    const max = options.length - 1;
    this.setState(
      {
        open: true,
        active_index: !open || active_index === -1 ? max : active_index - 1
      },
      this.scroll_menu(active_index)
    );
  };

  // key down listener for text input
  on_key_down = e => {
    switch (e.key) {
      // move down the list (or to the top if at the end)
      case "ArrowDown":
        e.preventDefault();
        this.next_item();
        return;

      // move up the list (or to the bottom if at the top)
      case "ArrowUp":
        e.preventDefault();
        this.prev_item();
        return;

      // select the highlighted option
      case "Enter":
        e.preventDefault();
        this.select_value();
        return;

      // close the menu
      case "Escape":
        e.preventDefault();
        this.close_menu();
        return;
    }
  };

  // called when a value is selected from the list
  select_value = () => {
    const {
      options,
      allowCustom,
      allowBlank,
      stayOpenAfterSelect,
      onSelect,
      value
    } = this.props;
    const { active_index } = this.state;
    if (
      active_index !== -1 ||
      (allowBlank && value == null) ||
      (allowCustom && value != null)
    ) {
      if (allowCustom && value != null && active_index === -1) {
        onSelect({ value, label: `${value}` });
      } else {
        onSelect(options[active_index]);
      }
      if (stayOpenAfterSelect) {
        this.setState(
          { active_index: -1, hold_open_on_blur: false },
          this.scroll_menu(-1)
        );
      } else {
        this.close_menu();
      }
    }
  };

  clear_value = () => {
    if (this.props.allowBlank) {
      this.props.onSelect();
    }
  };

  open_menu = () => {
    !this.props.disabled &&
      this.setState({
        active_index: -1,
        open: true
      });
  };

  close_menu = (wait?: boolean) => {
    const { active_index } = this.state;
    setTimeout(
      () =>
        this.setState(
          { active_index: -1, open: false, hold_open_on_blur: false },
          () => setTimeout(this.scroll_menu(active_index), 500)
        ),
      wait ? BLUR_FOCUS_DELAY * 2 : 0
    );
  };

  // scroll the active_index into view
  scroll_menu = (prev_index: number) => () => {
    const { allowCustom, value } = this.props;
    if (!this.items || !this.items.current) {
      return;
    }
    const items = this.items.current;
    let { active_index } = this.state;
    if (value && allowCustom) {
      active_index++;
    }

    // if the new active item is outside the current scroll window
    if (
      items.scrollTop <=
        active_index * OPTION_HEIGHT - NUM_OPTIONS * OPTION_HEIGHT ||
      items.scrollTop > OPTION_HEIGHT * active_index
    ) {
      // adjust scroll by the difference between the old and new active index
      items.scrollTop += OPTION_HEIGHT * (active_index - prev_index);
    }
  };

  /* Close the menu when the user leaves the input.
  setTimeout is used to hold off closing the menu until
  the click handler is actually fired. We don't have a better
  way to track focus. */
  on_input_blur = handle_after(this, "onBlur", () =>
    window.setTimeout(
      () => !this.state.hold_open_on_blur && this.close_menu(true),
      BLUR_FOCUS_DELAY
    )
  );
  on_input_focus = handle_after(this, "onFocus", () =>
    window.setTimeout(this.open_menu.bind(this), BLUR_FOCUS_DELAY)
  );

  render() {
    const {
      options,
      Input,
      children,
      allowCustom,
      allowBlank,
      disabled,
      value = ""
    } = this.props;
    const { active_index, open } = this.state;
    const menu = this.menu && this.menu.current;
    const listHeight = Math.min(NUM_OPTIONS, options.length) * OPTION_HEIGHT;
    // get the pos of the bottom of the menu, if it were opened below the input
    const rect =
      menu && menu.parentElement && menu.parentElement.getBoundingClientRect();
    const menuBottom = rect ? rect.top + rect.height + listHeight : 0;

    const optsList =
      allowCustom && value !== ""
        ? [{ value, label: `Create option "${value}"` }].concat(options)
        : options;

    const interactionProps = disabled
      ? {}
      : {
          onKeyDown: this.on_key_down,
          onClick: this.on_input_focus,
          onFocus: this.on_input_focus,
          onBlur: this.on_input_blur,
          onChange: this.open_menu
        };

    return (
      <>
        <Input
          key="InputWithDropdown-input"
          className={class_names(
            {
              "--open": open
            },
            "select__input"
          )}
          onKeyDown={this.on_key_down}
          onClick={this.on_input_focus}
          onFocus={this.on_input_focus}
          onBlur={this.on_input_blur}
          onChange={this.open_menu}
          required={
            !allowBlank &&
            (this.props.allowMultiple ? this.props.selected.length > 0 : true)
          }
          disabled={disabled}
          {...interactionProps}
          autoComplete="off"
          {...(active_index !== -1 && options[active_index]
            ? { value: options[active_index].label }
            : {})}
          {...(this.props.allowMultiple ? { values: this.props.selected } : {})}
        />
        <div
          ref={this.menu}
          style={{
            height: `${
              open
                ? options.length > 0
                  ? listHeight + LIST_PADDING_TOTAL
                  : OPTION_HEIGHT + LIST_PADDING_TOTAL
                : 0
            }px`,
            maxHeight: `${listHeight + LIST_PADDING_TOTAL}px`
          }}
          data-open={open}
          // open the menu upwards if it's too close to the bottom of the window
          data-direction={
            menuBottom >= window.innerHeight - OPTION_HEIGHT ? "up" : "down"
          }
          className="select__options"
        >
          <ul
            style={{ height: `${listHeight + LIST_PADDING_TOTAL}px` }}
            ref={this.items}
            className="select__options__list"
            onMouseLeave={() =>
              this.setState({ active_index: allowCustom ? 0 : -1 })
            }
          >
            {optsList.length > 0 ? (
              <>
                {optsList.map(({ value: oValue, label }, i) => {
                  if (allowCustom && !!value) {
                    i--;
                  }
                  return (
                    <li
                      key={i}
                      id={`${oValue}`}
                      style={{ height: `${OPTION_HEIGHT}px` }}
                      data-text={label}
                      data-active={i === active_index}
                      data-selected={
                        this.props.allowMultiple
                          ? !!this.props.selected.find(o => o.value === oValue)
                          : oValue === value
                      }
                      data-is-custom={
                        allowCustom && i === -1 && !!value ? true : undefined
                      }
                      onMouseEnter={() =>
                        open && this.setState({ active_index: i })
                      }
                      onClick={this.select_value}
                    >
                      {label}
                    </li>
                  );
                })}
              </>
            ) : (
              <li className="select__no-matches">No matches found.</li>
            )}
          </ul>
        </div>
        {!!value && allowBlank && open && (
          <span className="select__clear" onClick={this.clear_value}>
            <Icon name="remove-circle" />
          </span>
        )}
        <Icon
          name="caret"
          className="select__caret"
          onClick={e => {
            e.preventDefault();
            this.close_menu();
          }}
        />
        {children}
      </>
    );
  }
}
