import * as React from "react";
import { EVENTS } from "../../model";
import { path_or } from "../../utils/common";

/**
 * Base class for components that call api methods and process the responses
 */
export abstract class RequestComponent<
  K extends keyof AppContexts,
  P extends object = {},
  S extends object = {}
> extends React.PureComponent<
  Context<K | "request", true> & {
    retryAfterRelogin?: boolean;
  } & P,
  S & { request_id: string }
> {
  componentWillUnmount() {
    this.props.dispatchNow([
      EVENTS.CLEAR_CLIENT_REQUEST,
      { id: this.state.request_id }
    ]);
  }

  /**
   * Helper function to determine if the request is finished,
   * Intended for use inside `componentDidUpdate`
   *
   * @param prevProps `prevProps` argument from `componentDidUpdate`
   */
  requestFinished(prevProps: Readonly<Context<K | "request", true> & P>) {
    const { request } = this.props;
    const { request_id } = this.state;
    if (
      prevProps.request &&
      prevProps.request[request_id] &&
      prevProps.request[request_id].pending &&
      request &&
      request[request_id] &&
      !request[request_id].pending
    ) {
      return true;
    }
  }

  /** Macro for dispatching api call event */
  sendRequest = <M extends ApiMethod>(
    method: M,
    parameters: M extends ApiPagedMethod
      ? ApiPagedMethodParameters<M>
      : ApiMethodParameters<M>,
    id: string
  ) => {
    const { retryAfterRelogin, dispatchNow } = this.props;
    dispatchNow([
      EVENTS.CALL_CLIENT_METHOD,
      { payload: { method, parameters }, id, retryAfterRelogin }
    ]);
  };

  getRequest(id?: string): AppRequest | null {
    id = id || this.state.request_id;
    return (id && this.props.request && this.props.request[id]) || null;
  }

  /** Macros for request status */
  requestIsPending(id?: string): boolean {
    const request = this.getRequest(id);
    return request ? request.pending : false;
  }

  requestSucceeded(id?: string): boolean | undefined {
    const request = this.getRequest(id);
    return !request || request.pending ? undefined : request.success;
  }

  getRequestErrors = (id?: string): ErrorResponse | undefined => {
    const req = this.getRequest(id);
    if (!req || req.pending || req.success) {
      return;
    }
    const api_response = path_or(undefined, ["response", "body"], req);
    if (
      typeof api_response === "object" &&
      api_response.status &&
      api_response.errors
    ) {
      return api_response;
    }
    const status = path_or(
      path_or(
        path_or(undefined, ["error", "code"], req || {}),
        ["error", "status"],
        req || {}
      ),
      ["response", "body", "status"],
      req || {}
    );
    const message = path_or(undefined, ["error", "message"], req || {});
    return {
      status,
      errors: message ? [message] : []
    };
  };
}
