import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import * as React from "react";
import { CreateTouchpointFormConnected } from "./Form";

export const CreateTouchpointPage = () => (
  <PageContent
    id="create-touchpoint"
    className="touchpoint-page touchpoint-form header-overlap-sm header-overlap-block"
    bodyType="full-width-block"
    pageHeader={<PageHeader title="Create a Touchpoint" />}
  >
    <CreateTouchpointFormConnected />
  </PageContent>
);
