import * as React from "react";
import { maybeClassName } from "../../../utils/dom-helpers";
import { IMAGES_PREFIX } from "../../../constants";
import { CONTEXTS } from "../../../model";
import { useContext } from "react";

export const ButtonWithIcon: React.FunctionComponent<ButtonWithIconProps> = ({
  icon,
  className,
  children,
  side = "left",
  ...props
}) => {
  const _icon = (
    <div className="icon-button__icon">
      {typeof icon === "string" ? (
        <img src={`${IMAGES_PREFIX}/${icon}`} />
      ) : (
        icon
      )}
    </div>
  );
  return (
    <button
      className={`icon-button${maybeClassName(
        !children && "icon-only"
      )}${maybeClassName(className)}`}
      type="button"
      {...props}
    >
      {side === "left" && _icon}
      {children && <span className="icon-button__text">{children}</span>}
      {side === "right" && _icon}
    </button>
  );
};
ButtonWithIcon.displayName = "ButtonWithIcon";

export const LinkWithIcon: React.FunctionComponent<LinkWithIconProps> = ({
  icon,
  className,
  children,
  side = "left",
  ...props
}) => {
  const _icon = (
    <div className="icon-button__icon">
      {typeof icon === "string" ? (
        <img src={`${IMAGES_PREFIX}/${icon}`} />
      ) : (
        icon
      )}
    </div>
  );
  return (
    <a
      className={`button icon-button${maybeClassName(
        !children && "icon-only"
      )}${maybeClassName(className)}`}
      {...props}
    >
      {side === "left" && _icon}
      {children && <span className="icon-button__text">{children}</span>}
      {side === "right" && _icon}
    </a>
  );
};

export const ActionButtonWithIcon: React.FC<
  ButtonWithIconProps & {
    event: AppEvent<AppEventName>;
  }
> = ({ event, onClick, ...props }) => {
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);
  return (
    <ButtonWithIcon
      {...props}
      onClick={e => {
        e.preventDefault();
        const result = onClick ? onClick(e) : undefined;
        dispatchNow(event);
        return result;
      }}
    />
  );
};

export const ActionLinkWithIcon: React.FC<
  LinkWithIconProps & {
    event: AppEvent<AppEventName>;
  }
> = ({ event, onClick, ...props }) => {
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);
  return (
    <LinkWithIcon
      {...props}
      onClick={e => {
        e.preventDefault();
        const result = onClick ? onClick(e) : undefined;
        dispatchNow(event);
        return result;
      }}
    />
  );
};
