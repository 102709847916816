import * as React from "react";
import { PageHeader } from "../../components/PageHeader";
import { PageContent } from "../../components/PageContent";
import { LEGAL_DOCS_URL } from "../../../constants";
import { DisplayPlainTextFileConnected } from "../../components/FormatPlainTextFile";

export const PrivacyPolicyPage: React.FC<{}> = () => (
  <PageContent
    id="privacy-policy"
    bodyType="full-width-block"
    className="header-overlap-sm header-overlap-block"
    pageHeader={<PageHeader title="Privacy Policy" />}
  >
    <div className="privacy-policy__body">
      <DisplayPlainTextFileConnected
        src={`${LEGAL_DOCS_URL}/PrivacyPolicy.txt`}
        id="privacy-policy"
      />
    </div>
  </PageContent>
);
PrivacyPolicyPage.displayName = "PrivacyPolicyPage";
