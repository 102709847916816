import * as React from "react";
import { useContext } from "react";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import { WithApiRequest } from "../../components/WithApiRequest";
import { EmptyList } from "../../components/EmptyList";
import { groupIsClickable, GroupListItem } from "./ListItem";
import { makeGroupListView } from "./GroupList";
import { Icon } from "../../components/Icon";
import { CONTEXTS } from "../../../model";
import { USER_ROLES } from "../../../constants";

class CommunityListBase extends React.Component<Context<"user">> {
  constructor(props) {
    super(props);
  }

  getItems = (
    data: GroupResponseModel<"GetAllCommunities">
  ): GroupListItem<GroupTypeForMethod<"GetAllCommunities">>[] => {
    if (this.props.user && data && Array.isArray(data)) {
      return data
        .map(record => ({
          item: {
            name: record.community_name,
            score: record.community_score
          },
          record: record,
          new_location: {
            community: { id: record.community_id, name: record.community_name }
          },
          clickable: groupIsClickable(record, this.props.user as AppUser)
        }))
        .sort((a, b) =>
          a.clickable === b.clickable ? 0 : a.clickable ? -1 : 1
        );
    }
    return [];
  };

  CommunityListView = makeGroupListView<"GetAllCommunities">(
    "CommunityListView",
    this.getItems,
    <div className="group-list__empty">
      <EmptyList icon={<Icon name="logo" />}>
        Looks like you're not in any communities yet.
      </EmptyList>
    </div>
  );

  render() {
    return (
      <section className="group-list__container communities__list">
        <WithApiRequest
          payload={{
            method: "GetAllCommunities",
            parameters: {}
          }}
          View={this.CommunityListView}
        />
      </section>
    );
  }
}

export const CommunityList: React.FC<{}> = () => {
  const user = useContext(CONTEXTS.user);
  return (
    <PageContent
      id="communities-list"
      className={`list-page header-overlap-sm${
        user &&
        (user.user_role === USER_ROLES.STF || !user.default_community_id)
          ? " communities__list--all"
          : undefined
      }`}
      bodyType="full-width-block"
      pageHeader={<PageHeader title="Communities" />}
    >
      <CommunityListBase user={user} />
    </PageContent>
  );
};
