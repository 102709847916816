import * as React from "react";
import { UserAvatar } from "../../components/Avatar";

export const ConnectionModalContent: React.FC<{
  user: User;
  text: string;
  buttons: React.ReactNode[];
  error?: React.ReactNode;
}> = ({ user, text, buttons, error }) => (
  <>
    <div className="response__modal__top">
      <div className="response__modal__left">
        {user && <UserAvatar user={user} size="md" />}
      </div>
      <div className="response__modal__right">{text}</div>
    </div>
    <div className="response__modal__bottom">{buttons}</div>
    <div className="response__modal__error">{error}</div>
  </>
);
