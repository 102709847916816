import * as React from "react";

export const PageHeader: React.FunctionComponent<{
  title: string | React.ReactNode;
  icon?: string | React.ReactNode;
  button?: React.ReactNode;
}> = ({ title, icon, button, children }) => (
  <div className="page-header__container">
    <div className="page-header__content">
      <div className="page-header__left">
        {icon && <div className="page-header__icon">{icon}</div>}
        <div className="page-header__title">{title}</div>
      </div>
      {button && <div className="page-header__right">{button}</div>}
    </div>
    {children}
  </div>
);
PageHeader.displayName = "PageHeader";
