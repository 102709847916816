import * as React from "react";
import { connect } from "../../model";
import { ButtonWithCounter } from "./ButtonWithCounter";
import { Icon } from "./Icon";
import { ReactionListBase } from "./ReactionList";

// todo: this might change
const LIKE_REACTION_KEY = "THUMBS_UP";

class LikeButton extends ReactionListBase {
  render() {
    const { user } = this.props;
    const { grouped_reactions } = this.state;
    const likes = grouped_reactions[LIKE_REACTION_KEY] || [];
    const liked = user
      ? likes.find(r => r.created_by.id === user.id)
      : undefined;
    return (
      <ButtonWithCounter
        className={`button like-button${liked ? " like-button--liked" : ""}`}
        count={likes.length}
        onClick={() => this.onClickReaction(LIKE_REACTION_KEY, liked)}
      >
        <Icon name={liked ? "heart-solid" : "heart-outline"} iconSize="xs" />
        <span>{liked ? "Liked" : "Like"}</span>
      </ButtonWithCounter>
    );
  }
}
export const LikeButtonConnected = connect(
  LikeButton,
  true,
  ["user", "request"]
);
