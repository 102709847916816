import * as React from "react";
import { RequestComponent } from "./RequestComponent";
import { connect, EVENTS } from "../../model";
import { path_or } from "../../utils/common";
import { DAILY_HEALTH_RATING_MAP } from "../../constants";
import { HealthIcon } from "./HealthIcon";
import { ButtonWithIcon } from "./common/button-with-icon";
import { RequestButton } from "./common/request-button";

const OPTIONS = {
  good: "I'm doing well!",
  okay: "Not great, but not bad.",
  bad: "I've been better!"
};

class HealthRaterModalBase extends RequestComponent<
  "user",
  ModalBodyProps,
  { rating?: number }
> {
  constructor(props) {
    super(props);
    const rating = path_or(-1, ["user", "health_rating"], props);
    this.state = {
      request_id: "health-rating",
      rating: rating > -1 ? rating : undefined
    };
  }

  componentDidUpdate(prevProps) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      setTimeout(this.onSuccess, 1200);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onChangeHealthRating = (value: number) => () => {
    this.setState({ rating: value });
  };

  sendHealthRatingRequest = () => {
    const { user } = this.props;
    const { rating } = this.state;
    rating != null &&
      user &&
      this.sendRequest(
        "AddHealthRating",
        {
          id: user.id,
          new_health_rating: { rating }
        },
        this.state.request_id
      );
  };

  onSuccess = () => {
    const health_rating = path_or(
      this.state.rating,
      ["response", "data", "result"],
      this.getRequest() || {}
    );
    this.props.user &&
      health_rating != null &&
      this.props.dispatchNow([
        EVENTS.SET_APP_USER,
        {
          ...this.props.user,
          health_rating,
          health_rating_created_at: new Date().toISOString()
        }
      ]);
    this.props.onDismiss();
  };

  render() {
    const { rating } = this.state;
    return (
      <div className="health__modal__body">
        <h4>How are you doing today?</h4>
        <p>Pick an option that best describes how you’re feeling right now.</p>
        <div className="health__modal__buttons">
          {DAILY_HEALTH_RATING_MAP.slice(1).map((key, i) => (
            <ButtonWithIcon
              key={i}
              className="button bordered"
              icon={<HealthIcon rating={i} />}
              onClick={this.onChangeHealthRating(i)}
              data-value={key}
              data-active={rating === i}
            >
              {OPTIONS[key]}
            </ButtonWithIcon>
          ))}
        </div>
        <div className="health__modal__submit">
          <button className="button bordered" onClick={this.props.onDismiss}>
            Cancel
          </button>
          <RequestButton
            className="button filled"
            pending={this.requestIsPending()}
            success={this.requestSucceeded()}
            successText="Success!"
            onClick={this.sendHealthRatingRequest}
          >
            Save
          </RequestButton>
        </div>
      </div>
    );
  }
}

const HealthRaterModal = connect(
  HealthRaterModalBase,
  true,
  ["user", "modal", "request"]
);

export const HealthRaterModalSpec = {
  title: "Check-In",
  body: HealthRaterModal,
  className: "health__modal"
};
