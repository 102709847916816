import * as React from "react";
import { connect, EVENTS } from "../../model";
import { RequestComponent } from "./RequestComponent";
import { deep_equals, generate_id, pick } from "../../utils/common";
import { LoadingDots } from "./common/common";
import { ReactNode } from "react";
import { ApiRequestErrorPage } from "./ApiRequestErrorPage";
import { without_query_defaults } from "../../utils/query-string";

export interface ApiReqViewProps<M extends ApiMethod>
  extends Pick<SuccessfulAppRequest<M>, "response" | "payload"> {
  pending: boolean;
}

interface ApiReqProps<M extends ApiMethod> {
  id?: string;
  View: React.ComponentType<ApiReqViewProps<M>>;
  payload: ApiMethodPayload<M>;
  PendingView?: React.ReactNode;
  ErrorView?: React.ComponentType<{
    error: Error;
    response?: SuperAgentResponse;
  }>;
  refresh?: (ref_func: () => void) => void;
  onSuccess?: (request: SuccessfulAppRequest<M>) => void;
  overrideRecord?: M extends ApiSingleRecMethod ? ApiResponseModel<M> : never;
  syncQueryPropsWithUrlQuery?: M extends ApiPagedMethod
    ? (keyof ApiQuery<ApiPagedResponseModel<M>>)[]
    : never;
  getRequestId?: (func: () => string) => void;
}

/** Component to that dispatches a request and passes the response
 *  to the View provided
 * Props:
 *   View: A React.ComponentType that takes as a prop the response
 *         method specified in the payload
 *   payload: event payload to dispatch, specifying the api method
 *            and parameters to pass
 */
class WithApiRequestBase<
  M extends ApiMethod,
  R extends ApiResponse<M>
> extends RequestComponent<
  "app_location",
  ApiReqProps<M>,
  {
    pending: boolean;
    quiet_refresh: boolean;
    request?: Diff<AppRequest<M>, { payload; pending: true }>;
    request_id: string;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      quiet_refresh: false,
      request_id: generate_id(props.id || "request")
    };
    props.refresh && props.refresh(this.refresh);
    props.getRequestId && props.getRequestId(this.getRequestId);
  }

  static defaultProps = {
    retryAfterRelogin: true,
    syncQueryPropsWithUrlQuery: false,
    ErrorView: ApiRequestErrorPage
  };

  componentDidMount() {
    const { method, parameters } = this.props.payload;
    this.sendRequest(method, parameters, this.state.request_id);
    this.updateAppLocationQuery();
  }

  componentDidUpdate(prevProps) {
    if (
      this.requestFinished(prevProps) &&
      (this.state.pending || this.state.quiet_refresh)
    ) {
      // @ts-ignore
      this.setState(
        {
          pending: false,
          quiet_refresh: false,
          request: this.props.request[this.state.request_id] as
            | SuccessfulAppRequest<M>
            | FailedAppRequest<M>
        },
        () => {
          if (
            this.state.request &&
            this.state.request.success &&
            this.props.onSuccess
          ) {
            this.props.onSuccess(this.state.request);
          }
        }
      );
    }

    if (!deep_equals(prevProps.payload, this.props.payload)) {
      this.updateAppLocationQuery();
      this.refresh();
    }
    if (!prevProps.overrideRecord && this.props.overrideRecord) {
      // @ts-ignore
      this.setState({
        pending: false,
        request: {
          success: true,
          response: {
            status: 200,
            data: this.props.overrideRecord
          },
          pending: false,
          payload: this.props.payload
        }
      });
    }
  }

  updateAppLocationQuery = () => {
    if (
      !this.props.syncQueryPropsWithUrlQuery ||
      this.props.syncQueryPropsWithUrlQuery.length === 0
    ) {
      return;
    }
    const new_query = pick(
      this.props.syncQueryPropsWithUrlQuery,
      this.props.payload.parameters
    ) as ApiQuery<ApiResponseModel<M>>;
    if (
      !deep_equals(
        // @ts-ignore
        without_query_defaults(this.props.app_location.query || {}),
        without_query_defaults(new_query)
      )
    ) {
      this.props.dispatchNow([
        EVENTS.GO_TO_PAGE,
        {
          ...this.props.app_location,
          // @ts-ignore
          query: new_query
        }
      ]);
    }
  };

  getRequestId = () => this.state.request_id;

  refresh = (quiet = false) => {
    const { method, parameters } = this.props.payload;
    this.setState({ quiet_refresh: quiet, pending: !quiet }, () =>
      this.sendRequest(method, parameters, this.state.request_id)
    );
  };

  render() {
    const {
      View,
      ErrorView = ApiRequestErrorPage,
      PendingView = <LoadingDots />
    } = this.props;
    const { request } = this.state;
    let content: any;

    // render pending view until request is complete
    if (request) {
      if (!request.success) {
        content = (
          <ErrorView error={request.error} response={request.response} />
        );
      } else {
        content = (
          <View
            payload={Object.assign(
              {},
              { ...(request.payload as ApiMethodPayload<M>) }
            )}
            pending={this.state.pending}
            response={Object.assign(
              {},
              { ...(request.response as SuccessfulAppRequest<M>["response"]) }
            )}
          />
        );
      }
    }

    return (
      <>
        <div data-pending={!request} className="with-api-request__pending">
          <div className="with-api-request__pending__content">
            {PendingView}
          </div>
        </div>
        <div className="with-api-request__content">{content}</div>
      </>
    );
  }
}

export const WithApiRequest = connect(
  WithApiRequestBase,
  true,
  ["request", "app_location"]
) as <M extends ApiMethod>(
  props: ApiReqProps<M> & { children?: ReactNode },
  context?: any
) => React.ReactElement | null;
