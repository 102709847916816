import * as React from "react";
import * as ReactDOM from "react-dom";
import { ListView, makeListView } from "../../components/ListView";
import { WithApiRequest } from "../../components/WithApiRequest";
import { connect } from "../../../model";
import { RequestComponent } from "../../components/RequestComponent";
import { TextAreaWithFormHelpers } from "../../components/common/inputs";
import { RequestButton } from "../../components/common/request-button";
import { Avatar } from "../../components/Avatar";
import { format_time_passed, get_user_full_name } from "../../utils";
import { DetailPageLink } from "../../components/DetailPageLink";
import { ScreenSize } from "../../../constants";

const CommentsListViewItem: React.FC<
  { item: TouchpointComment } & Maybe<Context<"window_size">>
> = ({ item, window_size }) => {
  const name = get_user_full_name(item.created_by);
  const health =
    item.created_by.health_rating != null
      ? {
          rating: item.created_by.health_rating,
          date: item.created_by.health_rating_created_at
        }
      : undefined;
  const created_at = new Date(item.created_at);
  return (
    <div className="comments__list__item">
      <div className="comments__list__item__left people-list__item__left">
        <Avatar
          url={item.created_by.profile_photo_url}
          name={name}
          size={!window_size || window_size > ScreenSize.xs ? "md" : "sm"}
          health={
            !window_size || window_size > ScreenSize.xs ? health : undefined
          }
        />
      </div>
      <div className="comments__list__item__right">
        <div className="comments__list__item__name">
          <DetailPageLink place="users" record={item.created_by}>
            {name}
          </DetailPageLink>
          <span
            className="comments__list__item__date"
            title={created_at.toLocaleString()}
          >
            {format_time_passed(
              created_at,
              !!window_size && window_size < ScreenSize.md
            )}
          </span>
        </div>
        <div className="comments__list__item__text">{item.comment_text}</div>
      </div>
    </div>
  );
};

class NewCommentSection extends RequestComponent<
  "window_size" | "app_location",
  {
    touchpoint_id: number;
    deleteAddedComments: (func: (ids: number[]) => void) => void;
    total_comments: number;
  },
  {
    comment_text: string;
    added_comments: TouchpointComment[];
    submitted: boolean;
    total_comments: number;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id: `add-new-comment-${props.touchpoint_id}`,
      comment_text: "",
      added_comments: [],
      submitted: false,
      total_comments: props.total_comments
    };
    props.deleteAddedComments(this.deleteAddedComments);
    this.input = React.createRef();
    this.form = React.createRef();
  }
  input: React.RefObject<HTMLTextAreaElement>;
  form: React.RefObject<HTMLFormElement>;

  componentDidMount(): void {
    const elem = document.getElementById("comment_box");
    // @ts-ignore
    if (this.props.app_location.hash === "comment_box" && elem) {
      elem.scrollIntoView({ behavior: "auto" });
      elem.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.requestFinished(prevProps)) {
      if (this.requestSucceeded()) {
        const { added_comments } = this.state;
        const req = this.getRequest() as SuccessfulAppRequest<"PostComment">;
        this.setState({
          comment_text: "",
          added_comments: [req.response.data].concat(added_comments)
        });
        this.form.current && this.form.current.reset();
      } else {
        this.setState({ submitted: false });
      }
    } else if (
      this.state.submitted &&
      this.state.comment_text !== prevState.comment_text
    ) {
      this.setState({
        submitted: false,
        total_comments: this.state.total_comments + 1
      });
    } else if (
      prevProps.total_comments !== this.props.total_comments &&
      this.props.total_comments !== this.state.total_comments
    ) {
      this.setState({ total_comments: this.props.total_comments });
    }
  }

  deleteAddedComments = (ids: number[]) => {
    this.setState({
      added_comments: this.state.added_comments.filter(c => !ids.includes(c.id))
    });
  };

  onChange = e => {
    if (e.target && e.target.value != null) {
      this.setState({ comment_text: e.target.value });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    const { touchpoint_id } = this.props;
    const { comment_text, request_id } = this.state;
    this.setState({ submitted: true }, () => {
      if (comment_text && touchpoint_id) {
        this.sendRequest(
          "PostComment",
          { comment: { touchpoint_id, comment_text } },
          request_id
        );
      }
    });
  };

  render() {
    const { window_size } = this.props;
    const { added_comments, total_comments } = this.state;
    const badge = document.getElementById("comment-count-badge");
    return (
      <>
        <form
          className="comments__form"
          onSubmit={this.onSubmit}
          ref={this.form}
        >
          <TextAreaWithFormHelpers
            id="comment_box"
            placeholder="Add a comment..."
            onChange={this.onChange}
            disabled={this.requestIsPending()}
          />
          <RequestButton
            className="comments__form__submit filled"
            pending={this.requestIsPending()}
            successText=""
            type="submit"
          >
            Add Comment
          </RequestButton>
        </form>
        <div
          className="comments__added"
          data-empty={added_comments.length === 0}
        >
          {added_comments.map(c => (
            <CommentsListViewItem
              key={c.id}
              item={c}
              window_size={window_size}
            />
          ))}
        </div>
        {badge && ReactDOM.createPortal(total_comments || "0", badge)}
      </>
    );
  }
}
export const NewCommentSectionConnected = connect(
  NewCommentSection,
  true,
  ["request", "window_size", "app_location"]
);

export class CommentsList extends ListView<
  "GetCommentsByTouchpointId",
  { touchpoint_id: number; total_comments: number },
  { total_comments: number; query: ApiQuery<TouchpointComment> }
> {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        page: 0,
        pageSize: 10,
        sortBy: "created_at",
        sortDir: "desc"
      },
      total_comments: 0
    };
  }

  CommentsListView = makeListView(
    "CommentsListView",
    this.nextPage,
    CommentsListViewItem,
    undefined,
    <div className="empty-list__text-only">No comments yet.</div>
  );

  setDeleteAddedComments = f => {
    this.deleteAddedComments = f;
  };
  deleteAddedComments = (ids: number[]) => {};

  onSuccess = (req: SuccessfulAppRequest<"GetCommentsByTouchpointId">) => {
    this.deleteAddedComments(req.response.data.map(c => c.id));
    this.setState({ total_comments: req.response.metadata.totalCount });
  };

  render() {
    const { touchpoint_id } = this.props;
    const { total_comments } = this.state;
    return (
      <div className="comments__container">
        <NewCommentSectionConnected
          touchpoint_id={touchpoint_id}
          deleteAddedComments={this.setDeleteAddedComments}
          total_comments={total_comments}
        />
        <WithApiRequest
          payload={{
            method: "GetCommentsByTouchpointId",
            parameters: {
              ...this.state.query,
              touchpoint_id
            }
          }}
          refresh={f => {
            this._refresh_list = f;
          }}
          onSuccess={this.onSuccess}
          View={this.CommentsListView}
        />
      </div>
    );
  }
}
