import * as React from "react";
import { EVENTS, connect } from "../../model";
import { RequestComponent } from "../components/RequestComponent";
import { IMAGES_PREFIX } from "../../constants";
import { DefaultPendingComponent } from "../components/common/common";
import { SetPasswordFormConnected } from "../components/SetPasswordForm";
import { WithFloatingTitle } from "../components/common/floating-title";
import { InputWithFormHelpers } from "../components/common/inputs";
import { RequestButton } from "../components/common/request-button";
import { FormErrors } from "../components/Errors";
import {
  GET_USER_WITH_TEMP_TOKEN_REQUEST_ID,
  PASSWORD_RESET_REQUEST_ID
} from "../../model/effects/constants";

class ForgotPassword extends RequestComponent<
  "app_location",
  {},
  { user_response?: Token; reset_request_sent: boolean; email?: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      request_id:
        props.app_location.email && props.app_location.token
          ? GET_USER_WITH_TEMP_TOKEN_REQUEST_ID
          : PASSWORD_RESET_REQUEST_ID,
      reset_request_sent: false
    };
  }

  componentDidMount(): void {
    const { app_location } = this.props;
    // @ts-ignore
    app_location.email &&
      // @ts-ignore
      app_location.token &&
      this.props.dispatchNow([EVENTS.GET_USER_WITH_TEMP_TOKEN]);
  }

  componentDidUpdate(prevProps) {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      if (this.state.request_id === PASSWORD_RESET_REQUEST_ID) {
        this.setState({ reset_request_sent: true });
        return;
      }
      const req = this.getRequest() as SuccessfulAppRequest<
        "ExchangeRegistrationToken"
      >;
      if (req.response.data.user.needs_password_reset) {
        this.setState({ user_response: req.response.data });
      }
    }
  }

  onChangeInput = e => {
    this.setState({ email: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { email } = this.state;
    if (email) {
      this.props.dispatchNow([
        EVENTS.REQUEST_PASSWORD_RESET,
        { email, request_id: this.state.request_id }
      ]);
    }
  };

  render() {
    const { user_response, email, request_id } = this.state;
    if (request_id === PASSWORD_RESET_REQUEST_ID) {
      return (
        <div className="login-page">
          <div className="login-page__logo">
            <img src={`${IMAGES_PREFIX}/logo_stacked.svg`} />
          </div>
          <form
            className="login-page__form"
            id="forgot_password"
            onSubmit={this.onSubmit}
          >
            <div className="forgot-password-page__prompt">
              Please confirm your email address. You will receive a reset link
              shortly.
            </div>

            <WithFloatingTitle title="Email Address">
              <InputWithFormHelpers
                type="email"
                name="email"
                placeholder="Email Address"
                onChange={this.onChangeInput}
                value={email}
                submitOnEnter={true}
              />
            </WithFloatingTitle>
            <FormErrors<"ForgotPassword">
              response={this.getRequestErrors()}
              formSubmitted={!!this.getRequest()}
            />
            <RequestButton
              pending={this.requestIsPending()}
              success={this.requestSucceeded()}
              className="login-page__submit filled"
              type="submit"
              successText="Success!"
            >
              Send Reset Email
            </RequestButton>
            {this.requestSucceeded() && (
              <div className="forgot-password-page__success">
                Check your email for a reset password link.
              </div>
            )}
          </form>
        </div>
      );
    }

    const request = this.getRequest();
    if (!request) {
      return null;
    }
    return user_response ? (
      <SetPasswordFormConnected user_response={user_response} />
    ) : this.requestIsPending() ? (
      <div className="with-api-request__pending">
        <div className="with-api-request__pending__content">
          <DefaultPendingComponent />
        </div>
      </div>
    ) : !request.pending && request.success === false ? (
      <div className="with-api-request__default-error">
        <div>Something went wrong!</div>
        {(request as FailedAppRequest).response ? (
          <>
            <div>
              {//
              // @ts-ignore
              request.response.status === 401
                ? "This password reset link has expired."
                : "Please try again in a moment."}
            </div>
            <div>
              Status Code:{" "}
              {
                // @ts-ignore
                request.response.status
              }
            </div>
          </>
        ) : (
          <div>Please try again in a moment.</div>
        )}
      </div>
    ) : null;
  }
}
export const ForgotPasswordConnected = connect(
  ForgotPassword,
  true,
  ["request", "app_location"]
);
