import * as React from "react";
import * as ReactDOM from "react-dom";
import { EventBus } from "@thi.ng/interceptors";
import { View } from "../view";
import api from "../api/index";
import {
  Model,
  Provider,
  watch_app_location,
  track_window_size,
  is_registering,
  resetting_password,
  NO_AUTH_PAGES,
  DEFAULT_APP_LOCATION,
  parse_setup_page_url,
  EVENTS
} from "../model";
import { events } from "../model/events/events";
import { effects } from "../model/effects/effects";
import { path_or } from "../utils/common";
import { INITIAL_APP_STATE } from "./initial-state";

class App extends React.PureComponent<{ bus: EventBus }> {
  render() {
    return (
      <Provider bus={this.props.bus}>
        <View />
      </Provider>
    );
  }
}

/** Do the side-effecting. */
export async function start(user?: User, register?: boolean) {
  const set_google_init_success = {
    func: e => {
      console.warn(`Failed to initialize Google login button`, e);
      INITIAL_APP_STATE.google_signin_available = false;
    }
  };
  Object.assign(INITIAL_APP_STATE, {
    // Always return the same promise instance, to avoid repeatedly loading.
    ensure_gapi: (() => {
      // Envirionment is loaded here and not currently used elsewhere.
      const auth2 = fetch("/env.json")
        .then(response => response.json())
        .then(
          env =>
            new Promise(resolve =>
              gapi.load("auth2", () => {
                try {
                  gapi.auth2
                    .init({ client_id: env.google_client_id })
                    .then(() => resolve(gapi));
                } catch (e) {
                  set_google_init_success.func(e);
                }
              })
            )
        );
      return () => auth2;
    })()
  });

  let initial_location: AppLocation = INITIAL_APP_STATE.app_location;
  if (!register) {
    if (!user) {
      if (!NO_AUTH_PAGES.includes(initial_location.place)) {
        INITIAL_APP_STATE.app_location = { place: "login" };
      }
    } else {
      INITIAL_APP_STATE.user = user;
      if (initial_location.place === "login" || !initial_location.place) {
        initial_location = DEFAULT_APP_LOCATION;
        INITIAL_APP_STATE.app_location = DEFAULT_APP_LOCATION;
      }
    }
  }
  const model = Model(INITIAL_APP_STATE);
  const bus = new EventBus(model, events, effects);
  set_google_init_success.func = e => {
    bus.dispatchNow([
      EVENTS.GOOGLE_AUTH_SET_AVAILABLE,
      { available: false, error: e }
    ]);
  };
  Object.assign(window, { bus });
  const DEBUG = false;
  if (DEBUG)
    bus.instrumentWith([
      (state, [name, payload]) => console.log(name, payload, state)
    ]);

  track_window_size(bus);

  if (!register) watch_app_location(bus, initial_location);

  // Set an attribute on the html body whenever any request is pending
  // Used in css to set the cursor to `progress`
  // This is mainly to indicate when the auth token is being refreshed,
  // which occurs before navigating to a different page
  bus.state.addWatch("request-pending", (_, __, new_state: AppContexts) => {
    document.body.setAttribute(
      "data-request-pending",
      `${Object.values(new_state.request).some(req => req.pending)}`
    );
  });

  if (register)
    bus.state.addWatch("app_location_register", (_, old_state, new_state) => {
      if (
        (old_state.app_location.place === "register" ||
          old_state.app_location.place === "forgot_password") &&
        new_state.app_location.place !== old_state.app_location.place
      ) {
        bus.state.removeWatch("app_location_register");
        watch_app_location(bus, { place: "login" });
      }
    });

  const container = document.getElementById("app");
  ReactDOM.render(<App bus={bus} />, container);
}

const location = parse_setup_page_url(window.location);
if (is_registering(location) || resetting_password(location)) {
  start(undefined, true);
} else {
  api
    .refresh_auth()
    .then(res => start(path_or(undefined, ["body", "data", "user"], res)))
    .catch(err => start());
}
