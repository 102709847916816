import * as React from "react";
import { class_names } from "../../../utils/dom-helpers";
import { Icon } from "../Icon";

export interface Crumb {
  label: React.ReactNode;
  href?: string;
}

export const Breadcrumb: React.FC<{
  items: Crumb[];
  separator?: React.ReactNode;
}> = ({ items = [], separator }) => (
  <div className="breadcrumb">
    {items.map(({ label, href }, i) => {
      const item =
        href && i !== items.length - 1 ? (
          <a
            data-text={label}
            className="breadcrumb__item__content"
            href={href}
          >
            {label}
          </a>
        ) : (
          <div data-text={label} className="breadcrumb__item__content">
            {label}
          </div>
        );
      return (
        <div key={i} className="breadcrumb__item">
          {item}
          {i < items.length - 1 && (
            <div
              className={class_names(
                {
                  ["--default"]: separator == null
                },
                "breadcrumb__item--separator"
              )}
            >
              {separator == null ? <Icon name="arrow-right" /> : separator}
            </div>
          )}
        </div>
      );
    })}
  </div>
);
