export const IMAGES_PREFIX = `/static/images`;
export const PLACEHOLDER_AVATAR_URL = `${IMAGES_PREFIX}/icon-avatar.svg`;
export const LEGAL_DOCS_URL = `https://raw.githubusercontent.com/ThriveSoftware/legaldocs/master`;

export const MIN_PASSWORD_LENGTH = 8;

export const MODAL_ANIMATION_DELAY = 600; // ms

export const INPUT_DEBOUNCE_TIME = 100; // ms

export const FACEPILE_DEFAULT_MAX_ITEMS = 4;

export const MAX_FILE_SIZE = Math.pow(2, 20); // 1MB

// for comparing screen sizes
export enum ScreenSize {
  xs = 480,
  sm = 768,
  md = 1024,
  lg = 1390,
  xl = 1920,
  xxl = 1921
}

export enum USER_ROLES {
  CM = 1, // Community Manager
  GP = 2, // Grandparent
  HOF = 3, // Head Of Family
  FM = 4, // Family Member
  ST = 5, // Student
  STF = 6, // Staff
  RTL = 7, // RT Lead
  RTM = 8 // RT Member
}

export const CommunityLeaderLabels: { [K in keyof GroupTypes]: string } = {
  community: "Community Manager",
  thread: "Grandparent",
  family: "Head of Family"
};

export const TouchpointTypes: { [K in TouchpointType]: string } = {
  Summary: "Summary",
  "In-person": "In-person",
  Call: "Call",
  Text: "Text",
  Email: "Email",
  Video: "Video",
  Other: "Other"
};

export const TouchpointCategories = {
  Schoolwork: "Schoolwork",
  "Coaching: Day-to-Day": "Coaching: Day-to-Day",
  "Coaching: Monthly": "Coaching: Monthly",
  "College/post-secondary program access support":
    "College/post-secondary program access support",
  "Family Meeting": "Family Meeting",
  "Resource Access: Childcare": "Resource Access: Childcare",
  "Resource Access: Clothing": "Resource Access: Clothing",
  "Resource Access: Food": "Resource Access: Food",
  "Resource Access: Health": "Resource Access: Health",
  "Resource Access: Housing": "Resource Access: Housing",
  "Resource Access: Legal": "Resource Access: Legal",
  "Rides: School-Related": "Rides: School-Related",
  "Rides: Work-Related": "Rides: Work-Related",
  "School/Teacher advocacy": "School/Teacher advocacy",
  "Teambuilding and/or social activities":
    "Teambuilding and/or social activities",
  "Tutoring and homework assistance": "Tutoring and homework assistance",
  "Workforce readiness and employment assistance":
    "Workforce readiness and employment assistance",
  Other: "Other"
};

export const ExpenseCategories = {
  Admission: "Admission",
  Food: "Food",
  Transportation: "Transportation",
  StudentSupplies: "Student Supplies"
};

export const ExpenseReimbursementTypes = {
  Check: "Check",
  Paypal: "Paypal"
};

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const DAILY_HEALTH_RATING_MAP = ["ghosting", "good", "okay", "bad"];
export const HEALTH_RATING_MAP = ["ghosting", "bad", "okay", "good"];

export const DEFAULT_API_QUERY: ApiQuery<any> = {
  page: 0,
  pageSize: 10,
  sortBy: "created_at",
  sortDir: "desc"
};
