import * as React from "react";
import { maybeClassName } from "../../../utils/dom-helpers";
import { Icon } from "../Icon";
import { INPUT_DEBOUNCE_TIME } from "../../../constants";

/**
 * Adds data attributes (empty, dirty, valid, value) to an input
 * to assist with styling
 */
export class ElementWithFormHelpers<
  T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
  V extends string | number,
  P = {},
  S = {}
> extends React.PureComponent<
  Omit<PropsOfElem<T>, "value"> & {
    validate?: (value?: V, input?: T | null) => boolean;
    onSubmitInput?: (any) => void;
    submitOnEnter?: boolean;
    value?: V;
    onChangeDebounce?: boolean;
  } & P,
  S
> {
  constructor(props) {
    super(props);
    this.initialValue = props.value;
    this.value = props.value;
    if (props.value) {
      this.updateAttributes(props);
    }
    this.mounted = false;
    this.input = React.createRef();
    this.onChange = this.onChange.bind(this);
  }
  static defaultProps = {
    submitOnEnter: true,
    onChangeDebounce: true
  };

  input: React.RefObject<T>;
  valid: boolean = true;
  dirty: boolean = false;
  empty: boolean = true;
  value?: V;
  initialValue?: V;
  validate?: (value: V, input: React.RefObject<T>) => boolean;
  mounted: boolean;
  debounce?: any;

  componentDidMount() {
    this.mounted = true;
  }

  updateAttributes = props => {
    let update = false;
    const { value, validate = this.validate, required } = props;
    const invalid = validate
      ? !validate(value, this.input ? this.input.current : undefined)
      : false;
    const empty = value == null || value === "";
    const dirty = this.dirty || !empty;
    const valid = required ? !(dirty && empty) && !invalid : empty || !invalid;

    if (
      this.value !== value ||
      this.empty !== empty ||
      this.dirty !== dirty ||
      this.valid !== valid
    ) {
      update = true;
    }

    this.value = value;
    this.empty = empty;
    this.dirty = dirty;
    this.valid = valid;
    update && this.mounted && this.forceUpdate();
  };

  handleChange = ev => {
    // @ts-ignore
    this.props.onChange && this.props.onChange(ev);
    this.updateAttributes({
      // @ts-ignore
      value: ev.target.value,
      required: this.props.required
    });
  };

  onChange(e: React.ChangeEvent<T>) {
    const ev = e.nativeEvent || e;
    if (!this.props.onChangeDebounce) {
      this.handleChange(ev);
      return;
    }
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    this.debounce = setTimeout(() => {
      this.handleChange(ev);
      clearTimeout(this.debounce);
      this.debounce = null;
    }, INPUT_DEBOUNCE_TIME);
  }

  onKeyDown = (e: React.KeyboardEvent<T>) => {
    if (e.key && e.key === "Enter") {
      if (this.props.submitOnEnter) {
        this.props.onSubmitInput && this.props.onSubmitInput(this.props.value);
      } else {
        e.stopPropagation();
        e.preventDefault();
      }
    }
    // @ts-ignore
    this.props.onKeyDown && this.props.onKeyDown(e.nativeEvent);
    return this.props.submitOnEnter;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { value, validate } = this.props;
    const { value: pValue, validate: pValidate } = prevProps;
    if (
      pValue !== value ||
      pValue !== this.value ||
      (validate && validate(value, this.input.current)) !==
        (pValidate && pValidate(pValue, this.input.current))
    ) {
      this.updateAttributes(this.props);
    }
  }

  withElement = (func: (elem: T) => void): void => {
    if (this.input && this.input.current) {
      func(this.input.current);
    }
  };
}

export const RadioButton: React.FunctionComponent<RadioButtonProps> = ({
  color,
  label,
  ...props
}) => {
  const _for =
    props.id || props.name ? props.id || `radio-${props.name}` : undefined;
  return (
    <label
      className={`radio-button__label${maybeClassName(color)}`}
      htmlFor={_for}
    >
      <input id={_for} {...props} type="radio" />
      <span className="radio-button__button">
        <Icon name="check" />
      </span>
      <span data-text={label} className="radio-button__text">
        {label}
      </span>
    </label>
  );
};

export const RadioButtons: React.FunctionComponent<
  RadioListProps<string | number>
> = ({ label, className, value, onSelectOption, options, ...props }) => (
  <div className={`radio-button__list${maybeClassName(className)}`}>
    <div className="radio-button__list__label">{label}</div>
    {options.map((opt, i) => (
      <RadioButton
        {...props}
        key={opt.value}
        name={props.name || props.id}
        id={`${props.id || props.name}-${i}`}
        value={opt.value}
        checked={value === opt.value}
        onChange={() => onSelectOption(opt)}
        label={opt.label}
      />
    ))}
  </div>
);

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  label,
  children,
  ...props
}) => {
  const _for = props.id || undefined;
  return (
    <label className="checkbox__label" htmlFor={_for}>
      <input {...props} type="checkbox" />
      <span data-text={label} className="checkbox__text">
        {label}
      </span>
      {children}
    </label>
  );
};

export const CheckList: React.FunctionComponent<
  CheckListProps<string | number>
> = ({
  color,
  label,
  className,
  values,
  onSelectOption,
  options,
  ...props
}) => {
  return (
    <div className={`checkbox__list${maybeClassName(className)}`}>
      {label && <div className="checkbox__list__label">{label}</div>}
      {options.map(opt => (
        <Checkbox
          {...props}
          key={opt.value}
          name={props.name || props.id}
          value={opt.value}
          color={color}
          checked={values.findIndex(val => val.value === opt.value) !== -1}
          onChange={e => onSelectOption(opt, e.target.checked)}
          label={opt.label}
        />
      ))}
    </div>
  );
};

export class InputWithFormHelpers<
  V extends string | number,
  P = {},
  S = never
> extends ElementWithFormHelpers<
  HTMLInputElement,
  V,
  P,
  S & { cur_value?: V }
> {
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, step } = this.props;
    if (
      (type === "number" && typeof step === "number") ||
      typeof step === "string"
    ) {
      const factor =
        1 /
        (typeof step === "number"
          ? (step as number)
          : parseFloat(step as string));
      e.target.valueAsNumber =
        Math.round(e.target.valueAsNumber * factor) / factor;
      e.target.value = e.target.valueAsNumber.toString();
    }
    this.props.onChange && this.props.onChange(e);
    this.updateAttributes({
      value: e.target.value,
      required: this.props.required
    });
  };

  render() {
    const {
      value,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      ...props
    } = this.props;
    return (
      // @ts-ignore
      <input
        data-value={this.value}
        data-empty={this.empty}
        data-valid={this.valid}
        data-dirty={this.dirty}
        defaultValue={this.initialValue}
        {...props}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}

export class TextAreaWithFormHelpers<
  T extends string | number
> extends ElementWithFormHelpers<
  HTMLTextAreaElement,
  T,
  { charLimit?: number; showCharLimit?: boolean },
  { charsLeft: number }
> {
  constructor(props) {
    super(props);
    this.onKeyDown = props.onKeyDown || (() => {});
    this.state = {
      charsLeft: 10000
    };
    this.onChange = this.onChange.bind(this);
  }

  static defaultProps = {
    submitOnEnter: true,
    onChangeDebounce: true,
    charLimit: 10000,
    showCharLimit: false
  };

  onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (this.state.charsLeft >= 0) {
      let input = e.target.value;
      this.setState({
        // @ts-ignore
        charsLeft: this.props.charLimit - input.length
      });

      super.onChange(e);
    }
  }

  render() {
    const {
      value,
      validate,
      submitOnEnter,
      onSubmitInput,
      onChangeDebounce,
      showCharLimit,
      charLimit,
      ...props
    } = this.props;
    return (
      <>
        <textarea
          maxLength={charLimit}
          data-value={value}
          data-empty={this.empty}
          data-valid={this.valid}
          data-dirty={this.dirty}
          defaultValue={this.initialValue as string}
          {...props}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
        />
        {showCharLimit && <p>{this.state.charsLeft} Characters Remaining</p>}
      </>
    );
  }
}
