import * as React from "react";
import { EmptyList } from "./EmptyList";
import { SvgLoadingSpinner, SvgLoadMore } from "./icons";
import { deep_equals } from "../../utils/common";
import { ApiReqViewProps } from "./WithApiRequest";
import { RequestButton } from "./common/request-button";
import { connect } from "../../model";
import { scroll_top } from "../../utils/dom-helpers";

const LOAD_BUFFER_HEIGHT = 60;

export interface InfListProps<
  M extends ApiPagedMethod,
  T extends ApiPagedResponseModel<M>,
  MappedItem extends object = T
> extends ApiReqViewProps<M> {
  ItemView: React.ComponentType<
    { item: MappedItem; firstOfPage?: number } & Maybe<Context<"window_size">>
  >;
  load_more: (current_page: number) => void;
  buttonLabel?: string;
  emptyText?: React.ReactNode;
  map_items?: (records: T[]) => MappedItem[];
}

export class InfiniteList<
  M extends ApiPagedMethod,
  T extends ApiResponseModel<M>,
  MappedItem extends object = T
> extends React.Component<
  InfListProps<M, T, MappedItem> & Context<"window_size">,
  {
    records: T[];
    will_request_load_more: boolean;
    load_more_requested: boolean;
    at_end_of_scroll: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      records: this.props.response.data as T[],
      will_request_load_more: false,
      load_more_requested: false,
      at_end_of_scroll: false
    };
    this.bottom = React.createRef();
    this.list = React.createRef();
    this.observer = new IntersectionObserver(this.observerCallback);
  }
  list: React.RefObject<HTMLDivElement>;
  bottom: React.RefObject<HTMLDivElement>;
  observer: IntersectionObserver;
  observerDebounce: any;

  componentDidMount(): void {
    this.setScrollListener(true);
    this.forceUpdate();
  }

  componentWillUnmount() {
    if (this.observerDebounce) {
      clearTimeout(this.observerDebounce);
    }
    this.setScrollListener(false);
    this.observer.disconnect();
  }

  componentDidUpdate(
    prevProps: Readonly<InfListProps<M, T, MappedItem> & Context<"window_size">>
  ) {
    const { records, will_request_load_more, load_more_requested } = this.state;
    const { payload, response } = this.props;
    const { parameters: prev_params } = prevProps.payload;
    const newState: any = {};

    const payload_changed = this.payloadChanged(prev_params);
    const is_next_page =
      payload.parameters.page === prev_params.page + 1 && !payload_changed;
    const is_previous_page =
      payload.parameters.page < prev_params.page && !payload_changed;
    const list_has_reset =
      !is_previous_page &&
      !is_next_page &&
      !payload_changed &&
      payload.parameters.page !== prev_params.page &&
      payload.parameters.page === 0;

    this.setScrollListener(true);

    if (will_request_load_more && !load_more_requested && !payload_changed) {
      this.loadMore();
      return;
    }

    // if the list resets, remove the listener so we can scroll the list back to the top
    if (payload_changed || list_has_reset) {
      this.scrollTop();
    }

    let recs = response.data.slice() as T[];
    // check if/how to handle the new records
    // if the payload has changed or the page got reset, replace the record list
    if (payload_changed || list_has_reset) {
      newState.records = recs;
      newState.load_more_requested = false;
      // if the page was incremented, append the new records
    } else if (
      is_next_page &&
      !this.pageAlreadyLoaded(payload.parameters.page)
    ) {
      newState.records = records.slice().concat(recs);
      newState.load_more_requested = false;
    } else if (is_previous_page && this.list.current) {
      this.scrollToPage(payload.parameters.page);
    }

    if (this.shouldLoadMore() && !will_request_load_more) {
      newState.will_request_load_more = true;
    } else if (will_request_load_more) {
      newState.will_request_load_more = false;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }
  }

  payloadChanged = (
    prev_params: Readonly<InfListProps<M, T>>["payload"]["parameters"]
  ): boolean => {
    const { page: _, ...p_rest_params } = prev_params;
    const { page: __, ...rest_params } = this.props.payload.parameters;
    return !deep_equals(p_rest_params, rest_params);
  };

  atEndOfList = () => {
    const { totalCount, page, pageSize } = this.props.response.metadata;
    return (
      this.state.records.length === totalCount || page * pageSize >= totalCount
    );
  };

  shouldLoadMore = () =>
    !this.props.pending &&
    !this.state.load_more_requested &&
    !this.props.buttonLabel &&
    !this.atEndOfList() &&
    this.state.at_end_of_scroll;

  loadMore = (delay?: number) => {
    if (this.shouldLoadMore()) {
      this.setState(
        { load_more_requested: true, will_request_load_more: false },
        () =>
          setTimeout(
            () => this.props.load_more(this.props.response.metadata.page),
            delay || 0
          )
      );
    }
  };

  pageAlreadyLoaded = (page: number = this.props.payload.parameters.page) => {
    const { totalCount } = this.props.response.metadata;
    const { pageSize } = this.props.payload.parameters;
    const { records } = this.state;
    return records.length >= Math.min((page + 1) * pageSize, totalCount);
  };

  setScrollListener = (enable: boolean) => {
    if (!this.bottom.current) {
      return;
    }
    if (enable) {
      this.observer.observe(this.bottom.current);
    } else {
      this.observer.unobserve(this.bottom.current);
    }
  };

  observerCallback = entries => {
    entries.forEach(e => {
      if (!e.target) {
        return;
      }
      if (this.observerDebounce) {
        clearTimeout(this.observerDebounce);
      }
      if (e.isIntersecting != this.state.at_end_of_scroll) {
        this.observerDebounce = setTimeout(() => {
          this.setState(
            { at_end_of_scroll: e.isIntersecting },
            this.onScrollEnd
          );
          clearTimeout(this.observerDebounce);
          this.observerDebounce = null;
        }, 200);
      }
    });
  };

  onScrollEnd = () => {
    if (this.shouldLoadMore()) {
      this.loadMore(400);
    }
  };

  scrollTop = (top: number = 0) => {
    if (
      this.list.current &&
      this.list.current.scrollHeight !== this.list.current.clientHeight
    ) {
      this.list.current.scrollTo(0, top);
    } else {
      scroll_top(top);
    }
  };

  scrollToPage = (page: number) => {
    const el = document.querySelector(`[data-page-marker="${page}"]`);
    let _top = 0;
    if (el) {
      const { top } = el.getBoundingClientRect();
      _top = Math.max(window.pageYOffset + top - 50, 0);
    }
    this.scrollTop(_top);
  };

  render() {
    const { records, load_more_requested, will_request_load_more } = this.state;
    const {
      response,
      ItemView,
      load_more,
      buttonLabel,
      emptyText,
      map_items,
      window_size
    } = this.props;
    const { totalCount, page, pageSize } = response.metadata;
    const show_spinner = load_more_requested || will_request_load_more;
    const items = map_items ? map_items(records) : records;
    return (
      <div
        data-length={items.length}
        data-complete={records.length === totalCount}
        className="record-list__container"
      >
        <div className="record-list" ref={this.list}>
          {items.map((p, i) => (
            <ItemView
              key={i}
              firstOfPage={
                i % pageSize === 0 ? Math.floor(i / pageSize) : undefined
              }
              item={p}
              window_size={window_size}
            />
          ))}
          {!buttonLabel && totalCount > records.length && (
            <div
              className="record-list__scroll-to-load"
              style={{
                height: `${LOAD_BUFFER_HEIGHT}px`
              }}
            >
              <SvgLoadMore className={!show_spinner ? "active" : ""} />
              <SvgLoadingSpinner className={show_spinner ? "active" : ""} />
            </div>
          )}
          <div ref={this.bottom} />
        </div>

        {items.length === 0 &&
          (emptyText || <EmptyList>No records found</EmptyList>)}
        {buttonLabel && !this.atEndOfList() && (
          <div className="record-list__load-more">
            <RequestButton
              pending={this.props.pending}
              success={false}
              className="filled"
              onClick={() => load_more(page)}
              successText={buttonLabel}
            >
              {buttonLabel}
            </RequestButton>
          </div>
        )}
      </div>
    );
  }
}

export const InfiniteListConnected = connect(
  InfiniteList,
  false,
  ["window_size"]
);

export const makeInfiniteList = <
  M extends ApiPagedMethod,
  T extends ApiResponseModel<M>,
  MappedItem extends object
>(
  load_more: (current_page: number) => void,
  ItemView: React.ComponentType<
    { item: MappedItem } & Maybe<Context<"window_size">>
  >,
  buttonLabel?: string,
  emptyText?: React.ReactNode,
  map_items?: (records: T[]) => MappedItem[]
): React.FunctionComponent<ApiReqViewProps<M>> => (
  request: ApiReqViewProps<M>
) => (
  <InfiniteListConnected
    {...{ load_more, ItemView, buttonLabel, emptyText, map_items, ...request }}
  />
);
