import * as React from "react";

const decode_html = text => {
  const el = document.createElement("textarea");
  el.innerHTML = text;
  return el.value;
};

export const parse_text = (text: string): React.ReactNode[] => {
  if (!text) {
    return [];
  }
  let i;
  let start = 0;
  let children: React.ReactNode[] = [];

  // replace whitespace from whitespace-only lines
  text = text.replace(/\n( |\t)\n/g, "\n\n").trim();
  if (text.search(/&(((#|\\u)\d{1,4})|([a-z]{1,5}));/gi) !== -1) {
    text = decode_html(text);
  }

  const detect_list = (part: string) => {
    if (part[0] === "*") {
      const end_of_list = part.search(/\n+(?![*\n])/);
      if (end_of_list > 0 && end_of_list < part.length - 1) {
        children.push(
          <dl key={children.length}>
            {parse_list(part.slice(1, end_of_list))}
          </dl>
        );
        text = part.slice(end_of_list + 1);
      } else {
        children.push(
          <dl key={children.length}>{parse_list(part.slice(1))}</dl>
        );
        text = "";
      }
      return true;
    } else if (part.search(/1\. /) === 0) {
      const end = part.search(/\n+(?!((\d+\. )|(\s)))/);
      const { items, remainder } = parse_numbered_list(part.slice(0, end));
      if (items.length < 2) {
        return false;
      }
      children.push(<ol key={children.length}>{items}</ol>);
      if (end > 0 && end < part.length - 1) {
        text = `${remainder}${part.slice(end + 1)}`;
      } else {
        text = remainder;
      }
      return true;
    }
    return false;
  };

  detect_list(text);
  while ((i = text.indexOf("\n", start)) !== -1) {
    start = i + 1;
    if (i === 0) {
      if (text.length > 1 && text[1] === "\n") {
        children.push(<br key={children.length} />);
        text = text.slice(2);
      } else {
        text = text.slice(1);
      }
      start = 0;
      continue;
    }
    if (i === text.length - 1) {
      text = text.slice(0, i);
      start = 0;
      continue;
    }
    const head = text.match(/^\s*.*\n=+\n*/);
    if (head) {
      children.push(parse_heading(head[0], children.length));
      text = text.slice(head[0].length);
      start = 0;
      continue;
    }
    const num_head = text.match(/^\s*\d+\. .*\n+(?!(\s*\d+\. ))/);
    if (num_head) {
      children.push(parse_numbered_heading(num_head[0], children.length));
      text = text.slice(num_head[0].length);
      start = 0;
      continue;
    }
    if (!detect_list(text)) {
      children.push(
        <section key={children.length}>{text.slice(0, i)}</section>
      );
      text = text.slice(i + 1);
    }
    start = 0;
  }
  if (text) {
    children.push(<section key={children.length}>{text}</section>);
  }
  return children;
};

const parse_list = (text: string): React.ReactNode[] => {
  const list = text.split("\n* ");
  return list.map((item, i) => {
    if (!item) {
      return null;
    }
    const j = item.indexOf(" - ");
    const name = j === -1 ? "" : item.slice(0, j).trim();
    const desc = j === -1 ? item : item.slice(j + 3).trim();
    return (
      <React.Fragment key={i}>
        {name && <dt>{name}:</dt>}
        <dd>{`${desc[0].toUpperCase()}${desc.slice(1)}`}</dd>
      </React.Fragment>
    );
  });
};

const parse_numbered_list = (
  text: string
): { items: React.ReactNode[]; remainder: string } => {
  const list = text.match(/(.*)(\n+|$)/g);
  const empty = { items: [], remainder: text };
  if (!list || list.length < 2) {
    return empty;
  }
  const items: React.ReactNode[] = [];

  let count = 0;
  let item = list[count];
  let num_match = item.match(/(\d+)\.\s*/);
  if (!num_match || num_match[1] !== "1") {
    return empty;
  }
  let trailing_space = "";

  while (num_match && !!item.trim() && parseInt(num_match[1]) === count + 1) {
    count++;
    items.push(<li key={count}>{item.slice(num_match[0].length).trim()}</li>);
    trailing_space = (match => (match ? match[0] : ""))(item.match(/\s*$/));
    item = list[count];
    num_match = item ? item.match(/(\d+)\.\s*/) : null;
  }
  let remainder = "";
  if (count < list.length) {
    remainder = `${trailing_space}${list.slice(count).join("")}`;
  }

  return {
    items,
    remainder
  };
};

const parse_heading = (text: string, key: number): React.ReactNode => {
  const i = text.search(/\S/);
  const end = text.search(/\n=+\n*/) + 1;
  return (
    <>
      <h1 key={key}>{text.slice(i, end)}</h1>
      <hr key={key + 1} />
      <br key={key + 2} />
    </>
  );
};

const parse_numbered_heading = (text: string, key: number): React.ReactNode => {
  const text_start = text.search(/\S/);
  const end = text.search(/\n*$/);
  return <h3 key={key}>{text.slice(text_start, end)}</h3>;
};
