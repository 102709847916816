import * as React from "react";
import { maybeClassName } from "../../../utils/dom-helpers";

export const SvgLoadingSpinner: React.FunctionComponent<MaybeClass> = ({
  className
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="32"
    height="32"
    className={`inline-svg loading-spinner-icon${maybeClassName(className)}`}
  >
    <g>
      <path d="M1,16 L11,16 L6,21.5" stroke="none" />
      <path
        d="M6,16 A 10,10 0 0 1 22.234898018587334,8.1816851753197"
        strokeWidth="3"
        fill="none"
      />
      <path d="M31,16 L21,16 L26,10.5" stroke="none" />
      <path
        d="M26,16 A 10,10 0 0 1 9.765101981412666,23.8183148246803"
        strokeWidth="3"
        fill="none"
      />
    </g>
  </svg>
);
SvgLoadingSpinner.displayName = "SvgLoadingSpinner";
