import * as React from "react";
import { EmptyList } from "../../components/EmptyList";
import { Avatar } from "../../components/Avatar";
import { capitalize } from "../../../utils/common";
import { HEALTH_RATING_MAP, ScreenSize, USER_ROLES } from "../../../constants";
import { connect } from "../../../model";
import { Icon } from "../../components/Icon";

export const NODE_COUNT_LIMIT = 9;
const NODE_TRANSITION_DURATION = 500;
const NODE_POSITIONS: ObjOf<ObjOf<[number, number]>> = {
  "0": {
    "0": [0, 0]
  },
  "1": {
    "0": [-1, 0],
    "1": [1, 0]
  },
  "2": {
    "0": [0, 0],
    "1": [-1, 0],
    "2": [1, 0]
  }
};
const NODE_OFFSET_RADIUS = {
  "0": 0,
  "1": 28 / 3,
  "2": 9.75,
  "3": 9.75,
  "4": 11.25,
  "5": 9.75,
  "6": 9.75,
  "7": 11.25,
  "8": 11.25
};
const NODE_CONNECTION_ANGLES = {
  "0": {
    "0": 0
  },
  "1": {
    "0": 0,
    "1": 180
  },
  "2": {
    "0": 0,
    "1": 0,
    "2": 180
  }
};

const rnd = n => Math.round(n * 1e10) / 1e10;
for (let total = 3; total < NODE_COUNT_LIMIT; total++) {
  let start = 90;
  const ang = 360 / total;
  if (total % 2 === 0) {
    start += ang / 2;
  }
  NODE_POSITIONS[`${total}`] = {};
  NODE_POSITIONS[`${total}`]["0"] = [0, 0];
  NODE_CONNECTION_ANGLES[`${total}`] = {};
  NODE_CONNECTION_ANGLES[`${total}`]["0"] = 0;
  for (let pos = 1; pos <= total; pos++) {
    const angle = (2 * Math.PI * (start + ang * (pos - 1))) / 360;
    NODE_POSITIONS[`${total}`][`${pos}`] = [
      rnd(Math.cos(angle)),
      -rnd(Math.sin(angle))
    ];
    let con_angle = start + ((ang * (total - 2)) / 2) * (pos - 1);
    if (total % 2 === 0) {
      con_angle -= ang;
    }
    if (pos % 2 === 0) {
      con_angle += 180;
    }
    NODE_CONNECTION_ANGLES[`${total}`][`${pos}`] = con_angle % 360;
  }
}

const getNodeId = <T extends keyof GroupTypes>(
  node: GroupGraphNode<T>
): number => (node.user ? node.user.id : node.group ? node.group.id : -1);

class GroupGraphBase<T extends keyof GroupTypes> extends React.Component<
  Context<"window_size" | "user"> & {
    center_id?: number;
    group_type: T;
    nodes: GroupGraphNode<T>[];
    ListItemView: React.ComponentType<{ node: GroupGraphNode<T> }>;
    calculateConnections: (center_id?: number) => ObjectOf<number>;
  },
  {
    center_id?: number;
    old_center_id?: number;
    connections: ObjOf<number>;
    positions: ObjOf<number>;
    allow_change: boolean;
    hovered?: number;
  }
> {
  constructor(props) {
    super(props);
    // @ts-ignore
    this.state = {
      ...this.initState(props),
      allow_change: false
    };
  }
  rotation = 0;

  componentDidMount(): void {
    this.setState({ allow_change: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { nodes } = this.props;
    const { old_center_id } = this.state;
    if (nodes.length > 0 && prevProps.nodes.length === 0) {
      // @ts-ignore
      this.setState(this.initState());
    } else if (nodes.length > 0 && old_center_id != null) {
      setTimeout(
        () => this.setState({ allow_change: true, old_center_id: undefined }),
        NODE_TRANSITION_DURATION
      );
    }
  }

  initState = (props = this.props) => {
    const { nodes } = props;
    if (nodes.length === 0) {
      return {};
    }
    const positions: ObjOf<number> = {};
    nodes.forEach((n, i) => {
      positions[getNodeId(n)] = i;
    });
    const { center_id = getNodeId(nodes[0]) } = props;
    return {
      center_id,
      allow_change: true,
      positions,
      connections: props.calculateConnections(center_id)
    };
  };

  setCenterNode = (new_center_id: number) => () => {
    const { nodes } = this.props;
    const { allow_change } = this.state;
    if (!allow_change || nodes.length === 0) {
      return;
    }
    const { center_id = getNodeId(nodes[0]), positions } = this.state;
    this.setState({
      center_id: new_center_id,
      old_center_id: center_id,
      positions: {
        ...positions,
        [center_id]: positions[new_center_id],
        [new_center_id]: 0
      },
      connections: this.props.calculateConnections(new_center_id),
      allow_change: false
    });
  };

  hoverNode = (id: number = -1) => () => {
    this.setState({ hovered: id });
  };

  render() {
    const {
      window_size,
      group_type,
      nodes,
      center_id: p_center_id,
      ListItemView
    } = this.props;
    const {
      hovered,
      center_id: s_center_id,
      old_center_id,
      connections,
      positions,
      allow_change
    } = this.state;

    const is_static = p_center_id != null;
    const center_id = is_static ? s_center_id : p_center_id;

    const nodes_ =
      nodes.length > NODE_COUNT_LIMIT
        ? nodes.slice(0, NODE_COUNT_LIMIT)
        : nodes;
    const node_count = nodes_.length - 1;
    if (node_count === 0) {
      return (
        <div className="family-graph">
          <EmptyList>This {group_type} doesn't have any members yet.</EmptyList>
        </div>
      );
    }

    const transitionDuration = `${
      !allow_change ? NODE_TRANSITION_DURATION : 0
    }ms`;

    const scale = NODE_OFFSET_RADIUS[`${node_count}`];
    let section = "";

    return (
      <div className="family-graph">
        <div className="family-graph__left">
          <div
            className="family-graph__graph"
            data-count={nodes.length}
            data-static={is_static}
          >
            {nodes_.map(n => {
              const id = getNodeId(n);
              const pos = positions[id];
              const coordinates = NODE_POSITIONS[`${node_count}`][`${pos}`];
              const translate = `translate(${coordinates[0] *
                scale}em, ${coordinates[1] * scale}em)`;

              let angle = NODE_CONNECTION_ANGLES[`${node_count}`][`${pos}`];
              if (id === center_id) {
                if (old_center_id != null) {
                  angle =
                    NODE_CONNECTION_ANGLES[`${node_count}`][
                      `${positions[old_center_id]}`
                    ];
                } else if (hovered != null) {
                  angle = NODE_CONNECTION_ANGLES[`${node_count}`][`${hovered}`];
                }
              }

              return (
                <React.Fragment key={id}>
                  <div
                    key={`${id}-node`}
                    className="family-graph__graph__node"
                    data-pos={pos}
                    data-hovered={hovered === id}
                    style={{
                      transitionDuration,
                      transform: translate
                    }}
                  >
                    {n.user ? (
                      <Avatar
                        url={n.user.profile_photo_url}
                        name={n.user.name}
                        size={window_size < ScreenSize.sm ? "md" : "lg"}
                        health={n.user.health}
                        onClick={
                          pos === 0 || is_static
                            ? undefined
                            : this.setCenterNode(id)
                        }
                        onMouseEnter={
                          pos === 0 || is_static
                            ? undefined
                            : this.hoverNode(id)
                        }
                        onMouseLeave={is_static ? undefined : this.hoverNode()}
                      />
                    ) : (
                      <div
                        className="family-graph__graph__node__placeholder"
                        data-size={window_size < ScreenSize.sm ? "md" : "lg"}
                      >
                        <Icon name="help" />
                      </div>
                    )}
                  </div>
                  <div
                    key={`${id}-connection`}
                    className="family-graph__graph__connection"
                    style={{
                      transform: translate,
                      transitionDuration /*:
                        n.id === old_center_id ? "0ms" : transitionDuration*/
                    }}
                  >
                    <div
                      key={`${id}-line`}
                      className="family-graph__graph__line"
                      data-placeholder={!n.user}
                      data-score={HEALTH_RATING_MAP[connections[id]]}
                      style={{
                        width: `${
                          id === center_id || pos === 0
                            ? 0
                            : node_count == 1
                            ? 2 * scale
                            : scale
                        }em`,
                        transform: `translate(0, 0) rotate(${angle}deg) scale(${
                          id === center_id ? 0 : 1
                        }, 1)`,
                        transition: `transform ${
                          (id === center_id && allow_change) ||
                          id === old_center_id
                            ? "0ms"
                            : transitionDuration
                        }, opacity ${NODE_TRANSITION_DURATION}ms, border-color ${NODE_TRANSITION_DURATION}ms, width ${NODE_TRANSITION_DURATION}ms`
                      }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="family-graph__right">
          <div className="family-graph__user-list">
            <h3 className="family-graph__user-list__title">
              {capitalize(group_type)}{" "}
              {group_type === "family" ? "Members" : "Leadership"}
            </h3>
            <ul onMouseLeave={this.hoverNode()}>
              {nodes.map(n => {
                const id = getNodeId(n);
                const item = (
                  <li
                    key={id}
                    className="family-graph__user-list__item"
                    onMouseEnter={this.hoverNode(id)}
                  >
                    <ListItemView node={n} />
                  </li>
                );
                if (
                  this.props.user &&
                  this.props.user.user_role === USER_ROLES.STF
                ) {
                  return item;
                }
                if (!section && n.clickable) {
                  section = "clickable";
                  return (
                    <React.Fragment key="my-groups">
                      <li>
                        <h3>
                          My{" "}
                          {group_type === "community" ? "Threads" : "Families"}
                        </h3>
                      </li>
                      {item}
                    </React.Fragment>
                  );
                } else if (section === "clickable" && !n.clickable) {
                  section = "other";
                  return (
                    <React.Fragment key="other-groups">
                      <h3>
                        Other{" "}
                        {group_type === "community" ? "Threads" : "Families"}
                      </h3>
                      {item}
                    </React.Fragment>
                  );
                }
                return item;
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export const GroupGraph = connect(
  GroupGraphBase,
  false,
  ["window_size", "user"]
);
