import { capitalize } from "../utils/common";
import { FACEPILE_DEFAULT_MAX_ITEMS, MONTH_NAMES } from "../constants";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const YEAR = DAY * 365;

export const get_touchpoint_desc = (
  tp: Touchpoint | Interaction,
  self_id: number,
  max_names?: number
): string => {
  const { created_by } = tp;
  const participants = tp.participants.filter(p => p.id !== created_by.id);
  const creator = capitalize(get_user_name(created_by, self_id));
  const partic_text = get_list_of_names(participants, self_id, max_names);
  switch (tp.type) {
    case "In-person":
      return `${creator} met with ${partic_text} in-person.`;
    case "Text":
      return `${creator} texted with ${partic_text}.`;
    case "Email":
      return `${creator} emailed with ${partic_text}.`;
    case "Video":
      return `${creator} had a video conversation with ${partic_text}.`;
    case "Call":
      return `${creator} had a phone call with ${partic_text}.`;
    default:
      return `${creator} had an interaction with ${partic_text}.`;
  }
};

export const get_user_full_name = (user: User): string =>
  `${capitalize(user.first_name)} ${capitalize(user.last_name)}`;

export const get_user_name = (user: User, self_id: number): string =>
  user.id === self_id ? "you" : capitalize(user.first_name);

export const get_list_of_names = (
  users: User[],
  self_id: number,
  max?: number
): string => {
  if (users.length === 0) {
    return "";
  }
  if (users.length === 1) {
    return get_user_name(users[0], self_id);
  }

  max = max || FACEPILE_DEFAULT_MAX_ITEMS;

  let names: any[] = users;
  if (users.length > max) {
    names = users.slice(0, max - 1);
  }
  names = names
    .sort(a => (a.id === self_id ? -1 : 1))
    .map(p => get_user_name(p, self_id));

  if (users.length === 2) {
    return names.join(" and ");
  }

  if (users.length > max) {
    const diff = users.length - max + 1;
    names.push(`${diff} other${diff > 1 ? "s" : ""}`);
  }
  const last = names.slice(-1);

  return `${names.slice(0, names.length - 1).join(", ")}, and ${last}`;
};

export const format_time_passed = (
  time: Date,
  short?: boolean,
  show_time_with_date_string?: boolean
) => {
  const now = new Date();
  const diff = now.getTime() - time.getTime();
  const units_ago = (num: number, unit: string, abbr: string) =>
    `${num}${short ? abbr : ` ${unit}`}${short || num === 1 ? "" : "s"}${
      short ? "" : " ago"
    }`;
  if (diff <= 10 * SECOND && !short) {
    return `Just now`;
  }
  if (diff < MINUTE) {
    return units_ago(Math.max(0, Math.floor(diff / SECOND)), "second", "S");
  }
  if (diff < HOUR) {
    return units_ago(Math.floor(diff / MINUTE), "minute", "m");
  }
  if (diff < DAY) {
    return units_ago(Math.floor(diff / HOUR), "hour", "H");
  }
  if (diff < 2 * DAY && !short) {
    return `yesterday`;
  }
  if (diff < WEEK) {
    return units_ago(Math.floor(diff / DAY), "day", "D");
  }
  if (short && diff < 4 * WEEK) {
    return units_ago(Math.floor(diff / WEEK), "week", "W");
  }
  if (short && diff >= YEAR) {
    return `${Math.floor(diff / YEAR)}Y`;
  }
  if (short && diff > 4 * WEEK) {
    const that_month = time.getFullYear() * 12 + time.getMonth();
    const this_month = now.getFullYear() * 12 + now.getMonth();
    const month_diff =
      this_month - that_month - (now.getDate() < time.getDate() ? 1 : 0);
    return month_diff < 1 ? `${Math.floor(diff / WEEK)}W` : `${month_diff}M`;
  }

  if (show_time_with_date_string) {
    return `${time.toLocaleDateString()} at ${time
      .toLocaleTimeString()
      .replace(/(\d+:\d+):\d+ /i, "$1 ")}`;
  }
  return time.toLocaleDateString();
};

export const format_date_string = (date: Date) =>
  `${MONTH_NAMES[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

export const ensure_emoji_colons: (string) => string = str =>
  typeof str === "string" && str[0] === ":" ? str : `:${str}:`;

export const get_emoji = (str: string): FontIconName => {
  /*str = ensure_emoji_colons(str).toLowerCase();
  return Emoji.emojify(str, () =>
    Emoji.emojify(str.replace(/_/g, "-"), () =>
      Emoji.emojify(str.replace(/_/g, ""))
    )
  );*/
  return {
    THUMBS_UP: "thumbs-up"
  }[str];
};

export const group_reactions = (
  reactions: EmojiResponse[] = [],
  touchpoint_id: number
): ObjectOf<EmojiResponse[]> => {
  const grouped_reactions = {};
  reactions.forEach(r => {
    if (r.touchpoint_id !== touchpoint_id) {
      return;
    }
    if (grouped_reactions[r.emoji_key] === undefined) {
      grouped_reactions[r.emoji_key] = [];
    }
    grouped_reactions[r.emoji_key].push(r);
  });
  if (Object.keys(grouped_reactions).length === 0) {
    // TODO: make this into a reaction selection menu when we have our full dictionary of emojis
    grouped_reactions["THUMBS_UP"] = [];
  }
  return grouped_reactions;
};

export const user_to_graph_node = (
  user?: User | null
): GraphNodeUser | undefined =>
  user
    ? {
        id: user.id,
        name: get_user_full_name(user),
        profile_photo_url: user.profile_photo_url,
        health:
          user.health_rating != null
            ? {
                rating: user.health_rating,
                date: user.health_rating_created_at
              }
            : undefined
      }
    : undefined;

export const group_to_graph_node = <T extends keyof GroupTypes>(
  group?: GroupTypes[T],
  is_root?: boolean
): GraphNodeGroup<T> | undefined => {
  if (!group) {
    return;
  }
  if ("group_id" in group) {
    return {
      id: group["group_id"],
      name: group["group_name"],
      manager: group["group_manager"],
      score: group["group_score"],
      parent_id: is_root ? undefined : group["thread_id"]
    };
  } else if ("thread_id" in group) {
    return {
      id: group["thread_id"],
      name: group["thread_name"],
      manager: group["thread_manager"],
      score: group["thread_score"],
      parent_id: is_root ? undefined : group["community_id"]
    };
  } else {
    return {
      id: group["community_id"],
      name: group["community_name"],
      manager: group["community_manager"],
      score: group["community_score"]
    };
  }
};
