import * as React from "react";
import { Avatar } from "./Avatar";
import { get_user_full_name } from "../utils";
import { makeListView } from "./ListView";
import { EmptyList } from "./EmptyList";
import { PagedEndpointsFor, RecordChecklist } from "./RecordChecklist";
import { Icon } from "./Icon";

type UserListEndpoints = PagedEndpointsFor<User>;

export const UserChecklistItemView: React.FC<{
  size: IconSize;
  item: User;
  button?: React.ReactNode;
}> = ({ item, size, button }) => {
  const name = get_user_full_name(item);
  return (
    <label
      htmlFor={`user-${item.id}`}
      className="record-list__item user-checklist__item"
    >
      <div className="user-checklist__item__label">
        <Avatar url={item.profile_photo_url} name={name} size={size} />
        <div className="user__name">{name}</div>
      </div>
      <div className="user-checklist__item__checkbox">
        {button || (
          <span className="radio-button__button">
            <Icon name="check" />
          </span>
        )}
      </div>
    </label>
  );
};

export class UserChecklist extends RecordChecklist<User> {
  constructor(props) {
    super(props);
    this.state = {
      // @ts-ignore
      query: {
        page: 0,
        pageSize: 10,
        excludeSelf: true,
        filter: this.props.filter || ""
      }
    };
  }

  ChecklistItemView: React.FunctionComponent<{ item: User }> = ({ item }) => {
    return (
      <>
        <input
          className="user-checklist__item__input"
          type="checkbox"
          id={`user-${item.id}`}
          value={item.id}
          checked={!!this.props.selected.find(i => i.id === item.id)}
          onChange={e => this.onChange(e, item)}
        />
        <UserChecklistItemView item={item} size="sm" />
      </>
    );
  };

  ChecklistView = makeListView<UserListEndpoints>(
    "UserChecklistView",
    this.nextPage,
    this.ChecklistItemView,
    undefined,
    <EmptyList>
      Use the search bar above to find and add connections on the Thrive
      network.
    </EmptyList>
  );
}
