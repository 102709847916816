import * as React from "react";
import {
  ApiReqViewProps,
  WithApiRequest
} from "../../components/WithApiRequest";
import { CONTEXTS, EVENTS } from "../../../model";
import { CreateTouchpointFormConnected } from "./Form";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import { useContext } from "react";

const UpdateTouchpointView: React.FC<ApiReqViewProps<"GetTouchpointById">> = ({
  response: { data }
}) => <CreateTouchpointFormConnected record={data} />;
UpdateTouchpointView.displayName = "UpdateTouchpointView";

export const UpdateTouchpointPage: React.FC<{}> = () => {
  const app_location = useContext(CONTEXTS.app_location) as AppLocationAt<
    "activity/id"
  >;
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);
  return (
    <PageContent
      id="update-touchpoint"
      className="touchpoint-page touchpoint-form header-overlap-block header-overlap-sm"
      bodyType="full-width-block"
      pageHeader={<PageHeader title="Update Touchpoint" />}
    >
      <WithApiRequest
        payload={{
          method: "GetTouchpointById",
          parameters: {
            touchpoint_id: app_location.id
          }
        }}
        View={UpdateTouchpointView}
        id={`touchpoint-read-for-update-${app_location.id}`}
        onSuccess={req =>
          req.response.data &&
          dispatchNow([
            EVENTS.GO_TO_PAGE,
            {
              ...app_location,
              record: req.response.data
            }
          ])
        }
        overrideRecord={app_location.record}
      />
    </PageContent>
  );
};
