let _next_id = 0;
export const next_id = () => _next_id++;
export const generate_id = (prefix?: string) =>
  `${prefix ? `${prefix}-` : ""}${next_id()}`;

/** True if argument is a non-empty array; typechecks the result accordingly. */
export const has_items = <T>(x?: T[]): x is { shift(): T; pop(): T } & T[] =>
  Array.isArray(x) && x.length > 0;

export const clamp = (min: number, max: number, n: number) =>
  Math.min(Math.max(n, min), max);

export const capitalize = (str: string) =>
  str != null
    ? str.length > 1
      ? `${str[0].toUpperCase()}${str.slice(1, str.length)}`
      : str.toUpperCase()
    : "";

// helper functions to manipulate arrays without mutating the original copy
export const add_item_to = <T>(list: T[], item: T): T[] =>
  !list ? [item] : item ? list.concat([item]) : list;
export const remove_item_from = <T>(
  list: T[],
  match: (item: T, index?: number) => boolean
): T[] => {
  const index = list.findIndex(match);
  if (index === -1) return list;
  return list.slice(0, index).concat(list.slice(index + 1));
};

export const project_from_list = <T extends object, C extends keyof T>(
  list: T[],
  query: Partial<T>,
  project?: C | C[]
): T[C] | Partial<T> | null => {
  // find the item in the list that matches the query
  const index = list.findIndex(
    item =>
      Object.entries(query).filter(([key, value]) => item[key] !== value)
        .length === 0
  );
  if (index === -1) return null;

  // if a projection is specified, pick those props, otherwise return the whole object
  return (!project
    ? list[index]
    : Array.isArray(project)
    ? pick(project as string[], list[index])
    : list[index][project]) as any;
};

export const pick = <T extends object>(keys: string[], obj: T): object => {
  let ret: any = {};
  keys.forEach(k => {
    // @ts-ignore
    if (Object.keys(obj).includes(k)) {
      ret[k] = obj[k];
    }
  });
  return ret;
};

export const deep_equals = (
  a: any,
  b: any,
  max_depth: number = 10,
  depth: number = 0
) => {
  if (depth > max_depth) {
    return true;
  }
  const ta = typeof a;
  const tb = typeof b;
  if (tb !== ta) {
    return false;
  }
  if (a === null && b === null) {
    return true;
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    return (
      a.length === b.length &&
      a.filter((v, i) => !deep_equals(v, b[i], max_depth, depth + 1)).length ===
        0
    );
  }
  if (ta === "object") {
    const ea = Object.entries(a);
    const eb = Object.entries(b);
    return (
      ea.length === eb.length &&
      !ea
        .map(([k, v]) => k in b && deep_equals(v, b[k], max_depth, depth + 1))
        .includes(false)
    );
  }
  return a === b;
};

export const sort_by_id = <T extends { id: number }>(list: T[]) =>
  list.sort((a, b) => (a.id < b.id ? -1 : a.id === b.id ? 0 : 1));

/** Wrap a handler so that it first run any existing handler for the specified
 * prop.  This is useful when creating drop-in replacements for standard
 * components (i.e. ones where certain props have defined semantics). */
export const handle_after = (target, name, handler) => event => {
  if (typeof target.props[name] === "function") target.props[name](event);
  handler.call(target, event);
};

/** copied from mindgrub library */
export const path_or = (def, path: Array<string | number>, obj: object) => {
  let ret = obj;
  for (let key of path) {
    if (ret == null) return def;
    ret = ret[key];
  }
  return ret == null ? def : ret;
};

export const get_error_response_status = (
  err: any,
  response?: SuperAgentResponse<ErrorResponse>
) =>
  response
    ? path_or(
        path_or(response.status || err.status, ["statusCode"], response),
        ["body", "status"],
        response
      )
    : path_or(
        path_or(err.status, ["response", "statusCode"], err),
        ["response", "body", "status"],
        err
      );

export const get_file_name = (url: string): string => {
  const [, name] = url.match(/.+\/(.*\.([a-z]+))(\?.*)?$/i) || ["", ""];
  return name || "<unknown filename>";
};
