import * as React from "react";
import { DetailPageLink } from "../../components/DetailPageLink";
import { FacePile } from "../../components/FacePile";
import { WithApiRequest } from "../../components/WithApiRequest";
import { ListView, makeListView } from "../../components/ListView";
import { EmptyList } from "../../components/EmptyList";
import { Icon } from "../../components/Icon";

export const UserFamilyListViewItem: React.FunctionComponent<{
  item: Score & { is_default?: boolean };
}> = ({ item, children }) => {
  const content = (
    <>
      {item.group_data.users && (
        <FacePile users={item.group_data.users} type="pile" />
      )}
      <div className="family__name">{item.group_name}</div>
    </>
  );
  return item.is_default ? (
    <DetailPageLink
      place="communities"
      record={item}
      location={{
        place: "communities",
        record: item,
        community: {
          id: item.community_id,
          name: item.community_name
        },
        thread: {
          id: item.thread_id,
          name: item.thread_name
        },
        family: {
          id: item.group_id,
          name: item.group_name
        }
      }}
      className="record-list__item family-list__item"
    >
      {content}
      <div
        className="family__default"
        title="This is your default family."
        data-size="xs"
      >
        <Icon name="family" />
      </div>
    </DetailPageLink>
  ) : (
    <div className="record-list__item family-list__item">{content}</div>
  );
};

export class UserFamilyList extends ListView<
  "GetGroupsWithScoresByUser",
  Context<"user">
> {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        page: 0,
        pageSize: 12,
        sortBy: "group_id",
        sortDir: "asc"
      }
    };
  }

  map_items = (records: Score[]): (Score & { is_default?: boolean })[] => {
    const { user } = this.props;
    return records.map(r => {
      r["is_default"] = !!user && user.default_group_id === r.group_id;
      return r;
    });
  };

  UserFamilyListView = makeListView(
    "FamilyListView",
    this.nextPage,
    UserFamilyListViewItem,
    "Load More Families",
    <EmptyList icon={<Icon name="family" />}>
      You aren't in any families yet.
    </EmptyList>,
    this.map_items
  );

  render() {
    return (
      <WithApiRequest
        payload={{
          method: "GetGroupsWithScoresByUser",
          parameters: this.state.query
        }}
        View={this.UserFamilyListView}
        refresh={func => {
          this.refreshList = func;
          this.forceUpdate();
        }}
      />
    );
  }
}
