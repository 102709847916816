import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  ApiReqViewProps,
  WithApiRequest
} from "../../components/WithApiRequest";
import {
  DetailSection,
  Error404PageNotFound,
  FormSection
} from "../../components/common/common";
import { ExpenseCategories } from "../../../constants";
import { EVENTS, CONTEXTS } from "../../../model";
import { PageContent } from "../../components/PageContent";
import { PageHeader } from "../../components/PageHeader";
import moment from "../../../utils/moment";
import { DropdownMenu } from "../../components/common/dropdown-menu";
import { Icon } from "../../components/Icon";
import { ActionButton, ActionLink } from "../../components/common/action-link";
import { DeleteExpenseModalConnected } from "./DeleteModal";
import { ExpandableImage } from "../../components/ExpandableImage";
import { useContext } from "react";

export const ExpenseDetailView: React.FC<
  ApiReqViewProps<"GetTouchpointById">
> = ({ response, pending }) => {
  const app_location = useContext(CONTEXTS.app_location) as AppLocationAt<
    "activity/id/expense/id"
  >;
  const user = useContext(CONTEXTS.user);
  if (!response || pending || !response.data.expenses) {
    return null;
  }
  const expense = response.data.expenses.find(
    e => e.id === app_location.expense
  );
  if (!expense) {
    return <Error404PageNotFound />;
  }

  const { participants } = response.data;

  const {
    id,
    receipt_amount,
    total_amount_requested,
    receipt_image_url,
    expense_category,
    description,
    reimbursement_type,
    vendor_name,
    is_pre_approved,
    is_supplemental,
    created_at,
    exported_on,
    // @ts-ignore
    created_by,
    created_by_user_id
  } = expense;

  const header_date_node = document.getElementById(
    `expense-header-date-${app_location.id}-${id}`
  );
  const header_title_node = document.getElementById(
    `expense-header-title-${app_location.id}-${id}`
  );

  const m_created_at = moment(created_at);

  const editable =
    !!user &&
    user.id === (created_by ? created_by.id : created_by_user_id) &&
    exported_on == null;

  const deleteModalAction: AppEvent<AppEventName> = [
    EVENTS.OPEN_APP_MODAL,
    {
      title: "Delete Expense?",
      body: props => (
        <DeleteExpenseModalConnected
          {...props}
          expense_id={id}
          touchpoint_id={app_location.id}
        />
      ),
      className: "touchpoint-delete__modal"
    }
  ];

  return (
    <div className="expense-detail__container">
      <FormSection
        className="expense-detail__top-header"
        title={
          <div data-editable={editable}>
            <div>Details</div>
            {editable && (
              <DropdownMenu
                className="actions-menu"
                button={<Icon name="more" />}
                items={[
                  <ActionLink location={{ ...app_location, edit: true }}>
                    Edit Expense
                  </ActionLink>,
                  <ActionButton
                    // icon={<Icon name="remove" />}
                    event={deleteModalAction}
                  >
                    Delete Expense
                  </ActionButton>
                ]}
              />
            )}
          </div>
        }
      >
        <dl className="expense-detail__details">
          <DetailSection
            title="Expense Date and Time"
            className="expense-detail__date"
          >
            {m_created_at.format("h:mm A")},{" "}
            {m_created_at.format("MMMM D, YYYY")}
          </DetailSection>
          <DetailSection
            title="Participants"
            className="expense-detail__participants"
          >
            {participants.length}
          </DetailSection>
          <DetailSection title="Category" className="expense-detail__category">
            {ExpenseCategories[expense_category]}
          </DetailSection>
          <DetailSection
            title="Supplemental Expense"
            className="expense-detail__supplemental"
          >
            {is_supplemental ? "Yes" : "No"}
          </DetailSection>
          <DetailSection
            title="Pre-Approved by Site Coordinator"
            className="expense-detail__preapproved"
          >
            {is_pre_approved ? "Yes" : "No"}
          </DetailSection>
        </dl>
      </FormSection>
      <FormSection title="Receipt Information">
        <dl className="expense-detail__details">
          <DetailSection title="Vendor Name" className="expense-detail__vendor">
            {vendor_name}
          </DetailSection>
          <DetailSection
            title="Receipt Amount"
            className="expense-detail__amount"
          >
            ${receipt_amount.toFixed(2)}
          </DetailSection>
          <DetailSection
            title="Total Amount Requested ($)"
            className="expense-detail__requested"
          >
            ${total_amount_requested.toFixed(2)}
          </DetailSection>
          <DetailSection
            title="Reimbursement Method"
            className="expense-detail__method"
          >
            {reimbursement_type}
          </DetailSection>
          <div className="expense-detail__receipt-image">
            <ExpandableImage
              src={receipt_image_url}
              alt="Photo of receipt"
              maxThumbnailHeight={360}
            />
          </div>
          {description && (
            <DetailSection
              title="Description"
              className="expense-detail__description"
            >
              {description}
            </DetailSection>
          )}
        </dl>
      </FormSection>
      {header_title_node &&
        ReactDOM.createPortal(` for ${vendor_name}`, header_title_node)}
      {header_date_node &&
        ReactDOM.createPortal(
          m_created_at.format("MMMM D, YYYY"),
          header_date_node
        )}
    </div>
  );
};

export const ExpenseDetail: React.FC<{}> = () => {
  const app_location = useContext(CONTEXTS.app_location) as AppLocationAt<
    "activity/id/expense/id"
  >;
  const { dispatchNow } = useContext(CONTEXTS.dispatchers);
  return (
    <PageContent
      id="expense-detail"
      className="expense-page detail-page header-overlap-block header-overlap-sm"
      bodyType="full-width-block"
      pageHeader={
        <PageHeader
          title={
            <div
              id={`expense-header-title-${app_location.id}-${
                app_location.expense
              }`}
            >
              Expense
            </div>
          }
          button={
            <div
              id={`expense-header-date-${app_location.id}-${
                app_location.expense
              }`}
              className="expense-detail__header-date"
            />
          }
        />
      }
    >
      <WithApiRequest
        payload={{
          method: "GetTouchpointById",
          parameters: {
            touchpoint_id: app_location.id
          }
        }}
        View={ExpenseDetailView}
        id={`expense-detail-${app_location.id}-${app_location.expense}`}
        onSuccess={req =>
          req.response.data &&
          dispatchNow([
            EVENTS.GO_TO_PAGE,
            {
              ...app_location,
              record: req.response.data
            }
          ])
        }
        overrideRecord={app_location.record}
      />
    </PageContent>
  );
};
