import * as React from "react";
import { deep_equals } from "../../../utils/common";
import { InputWithFormHelpers } from "./inputs";

export abstract class FakeDateOrTimeInput<
  T extends "date" | "time"
> extends InputWithFormHelpers<
  string,
  FakeDateOrTimeProps<T>,
  FakeDateOrTimeState<T>
> {
  focus?: boolean;
  blurTimeout;
  abstract validateParts: (parts: SomeDateOrTimeParts<T>) => boolean;
  abstract partsToString: (parts: SomeDateOrTimeParts<T>) => string;
  abstract stringToParts: (value: string) => DateOrTimeParts<T>;
  abstract getInputLimits: (
    name: NumericDateOrTimeParts<T>
  ) => { min: number; max: number };
  abstract calcMinMax: <K extends "min" | "max">(
    key: K,
    value: string,
    state?: Partial<FakeDateOrTimeState<T>>
  ) => FakeDateOrTimeState<T>[K] | null;
  adjustForNewValue?: (value: SomeDateOrTimeParts<T>) => SomeDateOrTimeParts<T>;

  inputs: {
    [K in keyof DateOrTimeParts<T>]: React.RefObject<HTMLInputElement>
  };

  componentDidMount() {
    // necessary because apparently the normal listener we set in render() doesn't get
    // called when we manually dispatch a change event
    this.withElement(elem => {
      // @ts-ignore
      elem.onchange = this.onChange;
    });
  }

  componentDidUpdate(prevProps: FakeDateOrTimeProps<T>, prevState) {
    const new_state = this.refreshMinMax(prevProps);
    if (Object.keys(new_state).length > 0) {
      this.setState(new_state);
    }
    if (this.state.cur_value !== prevState.cur_value) {
      this.withElement(elem => {
        elem.dispatchEvent(new Event("change"));
      });
    }
  }

  /** if the min/max props have changed, update component state to reflect them */
  refreshMinMax = (
    prevProps: FakeDateOrTimeProps<T>
  ): Pick<FakeDateOrTimeState<T>, "min" | "max"> => {
    const { min, max } = this.props;
    let state: Pick<FakeDateOrTimeState<T>, "min" | "max"> = {};
    if (max == null && prevProps.max != null) {
      state.max = undefined;
    } else if (max) {
      const new_max = this.calcMinMax("max", max, state);
      if (
        new_max !== null &&
        (prevProps.max !== max || !deep_equals(new_max, this.state.max))
      ) {
        state.max = new_max;
      }
    }
    if (min == null && prevProps.min != null) {
      state.min = undefined;
    } else if (min) {
      const new_min = this.calcMinMax("min", min, state);
      if (
        new_min !== null &&
        (prevProps.min !== min || !deep_equals(new_min, this.state.min))
      ) {
        state.min = new_min;
      }
    }
    return state;
  };

  clearValue = (name: keyof DateOrTimeParts<T>) => {
    const new_value = {
      ...this.state.value,
      [name]: undefined
    } as SomeDateOrTimeParts<T>;
    const new_state: any = { value: new_value };
    if (!Object.values(new_value).some(v => v != null)) {
      new_state.cur_value = undefined;
    }
    this.setState(new_state);
  };

  updateValue = (parts: SomeDateOrTimeParts<T>) => {
    parts = Object.assign({}, this.state.value, parts);
    if (this.adjustForNewValue) {
      parts = this.adjustForNewValue(parts);
    }
    if (!deep_equals(this.state.value, parts)) {
      if (!this.partsToString(parts)) {
        this.setState({ value: parts });
      } else if (this.validateParts(parts)) {
        this.setState({ value: parts, cur_value: this.partsToString(parts) });
      }
    }
  };

  /** onKeyDown listener to attach to all [type="number"] inputs */
  onKeyDownNumber = (name: NumericDateOrTimeParts<T>) => (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      // @ts-ignore
      this.clearValue(name);
      return this.onKeyDown(e);
    }
    if (!/[0-9]/.test(e.key)) {
      return this.onArrowKey(name)(e);
    }
    const input = this.inputs[name].current;
    const cur_value =
      // @ts-ignore
      (this.state.value[name] as number) ||
      parseInt(`${input ? input.value : undefined}`);
    let new_value = parseInt(e.key);
    const { min, max } = this.getInputLimits(name);
    if (cur_value != null && !isNaN(cur_value)) {
      const len = name === "year" ? 4 : 2;
      let new_str: any = `${cur_value}${new_value}`;
      new_str = parseInt(
        new_str.length > len ? new_str.slice(-len) : new_str.padStart(len, "0")
      );
      new_value = max < new_str ? new_value : new_str;
    }
    if (new_value < min) {
      this.setState({ value: { ...this.state.value, [name]: new_value } });
    } else {
      // @ts-ignore
      this.updateValue({ [name]: new_value });
    }

    this.selectText(name);
    return this.onKeyDown(e);
  };

  onArrowKey = (name: NumericDateOrTimeParts<T>) => (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const { min, max } = this.getInputLimits(name);
    // @ts-ignore
    const value = this.state.value[name];
    // @ts-ignore
    let new_val = value == null ? parseInt(e.target.value) : (value as number);
    switch (e.key) {
      case "ArrowUp":
        new_val++;
        if (value === null) {
          new_val = min;
        }
        break;
      case "ArrowDown":
        new_val--;
        if (value === null) {
          new_val = max;
        }
        break;
      case "ArrowLeft":
      case "ArrowRight":
        e.preventDefault();
      default:
        this.selectText(name);
        return this.onKeyDown(e);
    }
    if (new_val > max) {
      new_val = min;
    }
    if (new_val < min) {
      new_val = max;
    }
    // @ts-ignore
    this.updateValue({ [name]: new_val });
    this.selectText(name);
    return this.onKeyDown(e);
  };

  withInput = <R extends any>(
    name: keyof DateOrTimeParts<T>,
    func: (el: HTMLInputElement) => R
  ): R | undefined => {
    const input = this.inputs[name].current;
    return input ? func(input) : undefined;
  };

  focusInput = (name: keyof DateOrTimeParts<T>) =>
    this.withInput(name, el => el.focus());
  selectText = (name: keyof DateOrTimeParts<T>) =>
    this.withInput(name, el => el.select());
  onFocus = (name: keyof DateOrTimeParts<T>) => e => {
    this.props.onFocus && this.props.onFocus(e);
    this.withInput(name, el => {
      el.dataset.focus = "true";
    });
    e.target.select();
  };
  onBlur = (name: keyof DateOrTimeParts<T>) => e => {
    this.withInput(name, el => {
      el.dataset.focus = "false";
    });
    const { onBlur } = this.props;
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }
    if (onBlur) {
      const ev = e.nativeEvent;
      this.blurTimeout = setTimeout(() => {
        const has_focus = Object.keys(this.inputs)
          .map(n =>
            // @ts-ignore
            this.withInput(n, el => el.dataset.focus === "true")
          )
          .includes(true);
        const value = this.partsToString(this.state.value);
        if (!has_focus && value) {
          this.setState({ cur_value: value });
          onBlur(ev);
        }
        this.blurTimeout = null;
      }, 25);
    }
  };
}
