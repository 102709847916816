export const LOGIN_LOCATION: AppLocationAt<"login"> = { place: "login" };

export const DEFAULT_APP_LOCATION: AppLocation = { place: "activity" };

export const PUBLIC_PAGES: AppLocation["place"][] = [
  "login",
  "privacy-policy",
  "terms-of-service",
  "zendesk-login"
];

export const SETUP_PAGES: AppLocation["place"][] = [
  "forgot_password",
  "register"
];

export const ANON_ONLY_PAGES: AppLocation["place"][] = ["register", "login"];

export const NO_AUTH_PAGES = PUBLIC_PAGES.concat(SETUP_PAGES);
