import { parse, stringify } from "query-string";
import { DEFAULT_API_QUERY } from "../constants";

const as_int = (value, def) =>
  !isNaN(parseInt(value)) ? parseInt(value) : def;

export const string_to_query = (
  query_str = window.location.search
): ApiQuery<any> =>
  with_query_defaults(adjust_page_number(parse(query_str), false) as any);

export const query_to_string = (query: ApiQuery<any>) => {
  const search = stringify(
    adjust_page_number(
      without_blank_values(without_query_defaults(query)),
      true
    )
  );
  return search ? `?${search}` : "";
};

export const with_query_defaults = (query: ApiQuery<any>): ApiQuery<any> => ({
  ...query,
  page: as_int(query.page, DEFAULT_API_QUERY.page),
  pageSize: as_int(query.pageSize, DEFAULT_API_QUERY.pageSize)
});

export const without_query_defaults = (
  query: ApiQuery<any>
): Partial<ApiQuery<any>> => {
  const output = {};
  Object.entries(query).forEach(([key, val]) => {
    if (!(key in DEFAULT_API_QUERY) || val !== DEFAULT_API_QUERY[key]) {
      output[key] = val;
    }
  });
  return output;
};

export const without_blank_values = (
  query: Partial<ApiQuery<any>>
): Partial<ApiQuery<any>> => {
  const output = {};
  Object.entries(query).forEach(([key, val]) => {
    if (val != null && val !== "") {
      output[key] = val;
    }
  });
  return output;
};

export const adjust_page_number = (
  query: Partial<ApiQuery<any>>,
  to_query_string: boolean
) => {
  if (query.page != null) {
    query.page = as_int(query.page, 0) + (to_query_string ? 1 : -1);
  }
  return query;
};
