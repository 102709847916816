import * as React from "react";
import { maybeClassName } from "../../../utils/dom-helpers";

const Row: React.StatelessComponent<HTMLDivProps> = ({
  className,
  ...props
}) => <div className={`row${maybeClassName(className)}`} {...props} />;

const sizes = ["", "xs", "sm", "md", "lg", "xl"];
const Cols: {
  [key: string]: React.StatelessComponent<HTMLDivProps>;
} = {};

for (let w = 1; w <= 12; w++) {
  const key = `Col${w}`;
  Cols[key] = ({ className, ...props }) => (
    <div className={`col-${w}${maybeClassName(className)}`} {...props} />
  );
}

Cols.Auto = ({ className, ...props }) => (
  <div className={`col-auto${maybeClassName(className)}`} {...props} />
);

const Col: React.StatelessComponent<HTMLDivProps & { sizes: string[] }> = ({
  sizes,
  className,
  ...props
}) => (
  <div
    className={`${sizes.map(s => `col-${s}`).join(" ")}${maybeClassName(
      className
    )}`}
    {...props}
  />
);

export { Row, Col, Cols, sizes };
