import * as React from "react";
import * as ReactDOM from "react-dom";
import { connect } from "../../../model";
import { ListView, makeListView } from "../../components/ListView";
import { EmptyList } from "../../components/EmptyList";
import { TouchpointListViewItem } from "../Touchpoint/List";
import {
  ApiReqViewProps,
  WithApiRequest
} from "../../components/WithApiRequest";
import { get_user_full_name } from "../../utils";
import { Avatar } from "../../components/Avatar";
import { path_or } from "../../../utils/common";
import { FormSection } from "../../components/common/common";
import { Icon } from "../../components/Icon";

export const HEADER_AVATAR_ELEM_ID = "user-detail__header__avatar__image";
export const HEADER_NAME_ELEM_ID = "user-detail__header__name";

class UserDetailTouchpointList extends ListView<
  "GetAuthorizedTouchpoints",
  ApiReqViewProps<"GetUserById"> & Context<"user" | "app_location">
> {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        page: 0,
        pageSize: 10,
        sortBy: "started_at",
        sortDir: "desc",
        // @ts-ignore
        withUser: props.app_location.id || 0
      }
    };
    this.name_portal = React.createRef();
  }
  name_portal: React.RefObject<HTMLSpanElement>;

  TouchpointListView = makeListView(
    "TouchpointListView",
    this.nextPage,
    TouchpointListViewItem,
    "Load More Activity",
    <EmptyList icon={<Icon name="logo" />}>
      Looks like you don’t have any shared activity with{" "}
      <span id="user-detail__empty-list__first-name">
        {path_or("this person", ["response", "data", "first_name"], this.props)}
      </span>{" "}
      yet.
    </EmptyList>
  );

  render() {
    const { response, pending } = this.props;

    if (!response || pending) {
      return null;
    }

    const user = response.data;
    const name = get_user_full_name(user);
    const avatar = document.getElementById(HEADER_AVATAR_ELEM_ID);
    const header_name = document.getElementById(HEADER_NAME_ELEM_ID);
    const health =
      user.health_rating != null
        ? {
            rating: user.health_rating,
            date: user.health_rating_created_at
          }
        : undefined;

    return (
      <FormSection title="Activity" className="user-detail__touchpoints">
        {avatar &&
          ReactDOM.createPortal(
            <Avatar
              name={name}
              size="lg"
              url={user.profile_photo_url}
              health={health}
            />,
            avatar
          )}
        {header_name && ReactDOM.createPortal(name, header_name)}
        <WithApiRequest
          payload={{
            method: "GetAuthorizedTouchpoints",
            parameters: this.state.query
          }}
          View={this.TouchpointListView}
        />
      </FormSection>
    );
  }
}
export const UserDetailTouchpointListConnected = connect(
  UserDetailTouchpointList,
  true,
  ["user", "app_location"]
);
