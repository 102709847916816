import * as React from "react";
import { connect, EVENTS } from "../../../model";
import { Filename } from "../../components/common/file-name";
import { ButtonWithIcon } from "../../components/common/button-with-icon";
import { Icon } from "../../components/Icon";
import { FieldError, FormErrors } from "../../components/Errors";
import { FormSectionList } from "../../components/FormSectionList";
import { MAX_FILE_SIZE, ScreenSize } from "../../../constants";
import { RequestComponent } from "../../components/RequestComponent";
import { get_file_name } from "../../../utils/common";
import { RequestButton } from "../../components/common/request-button";
import { ExpandableImage } from "../../components/ExpandableImage";
import { class_names } from "../../../utils/dom-helpers";

class TouchpointPhotoUpload extends RequestComponent<
  "app_location" | "window_size",
  {
    touchpoint_id: number;
    touchpoint_photo?: TouchpointPhoto;
    editable: boolean;
    refresh: (quiet?: boolean) => void;
  },
  {
    touchpoint_photo_file?: string;
    file_too_big?: boolean;
    image_preview?: {
      name: string;
      data: string;
    };
    form_submitted: boolean;
    form_dirty: boolean;
  }
> {
  constructor(props) {
    super(props);
    let image_preview: any = undefined;
    if (props.touchpoint_photo) {
      image_preview = {
        name: get_file_name(props.touchpoint_photo.photo_url),
        data: props.touchpoint_photo.photo_url
      };
    }

    this.state = {
      request_id: "touchpoint-photo-upload",
      image_preview,
      form_submitted: false,
      form_dirty: false
    };

    this.file_input = React.createRef();
    this.form = React.createRef();
  }
  file_input: React.RefObject<HTMLInputElement>;
  form: React.RefObject<HTMLFormElement>;

  componentDidUpdate(prevProps, prevState): void {
    if (this.requestFinished(prevProps) && this.requestSucceeded()) {
      const req = this.getRequest() as SuccessfulAppRequest<
        "UploadTouchpointPhoto"
      >;
      setTimeout(() => {
        this.props.dispatchNow([
          EVENTS.SET_APP_LOCATION,
          {
            ...this.props.app_location,
            // @ts-ignore
            form_dirty: false
          }
        ]);
        this.setState({
          image_preview: req.response.data.touchpoint_photo
            ? {
                name: get_file_name(
                  req.response.data.touchpoint_photo.photo_url
                ),
                data: req.response.data.touchpoint_photo.photo_url
              }
            : undefined,
          form_submitted: false,
          form_dirty: false
        });
      }, 1200);
    }
    if (prevState.form_dirty !== this.state.form_dirty) {
      this.props.dispatchNow([
        EVENTS.SET_APP_LOCATION,
        {
          ...this.props.app_location,
          // @ts-ignore
          form_dirty: this.state.form_dirty
        }
      ]);
    }
    if (
      prevProps.app_location.form_dirty &&
      // @ts-ignore
      !this.props.app_location.form_dirty
    ) {
      this.props.refresh(true);
    }
  }

  uploadPhoto = e => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.iconSize > MAX_FILE_SIZE) {
      this.setState({ file_too_big: true });
      return;
    }

    const reader = new FileReader();

    reader.onload = ev => {
      ev.target &&
        this.setState({
          form_dirty: true,
          touchpoint_photo_file: file,
          image_preview: {
            name: file.name,
            data: ev.target["result"] as string
          },
          file_too_big: false
        });
    };

    reader.readAsDataURL(file);
  };

  removePhoto = e => {
    e.preventDefault();
    e.stopPropagation();
    this.form.current && this.form.current.reset();
    this.setState({
      image_preview: undefined,
      touchpoint_photo_file: undefined,
      form_dirty: !!this.props.touchpoint_photo
    });
  };

  submitPhoto = () => {
    if (!this.props.editable) {
      return;
    }
    const { form_submitted, touchpoint_photo_file, request_id } = this.state;
    !form_submitted && this.setState({ form_submitted: true });
    this.sendRequest(
      touchpoint_photo_file ? "UploadTouchpointPhoto" : "DeleteTouchpointPhoto",
      {
        touchpoint_id: this.props.touchpoint_id,
        // @ts-ignore
        photo_file: touchpoint_photo_file
      },
      request_id
    );
  };

  render() {
    const { editable, window_size } = this.props;
    const {
      file_too_big,
      image_preview,
      touchpoint_photo_file,
      form_dirty,
      form_submitted
    } = this.state;

    const save_button = (
      <RequestButton
        type="button"
        className="filled expense-create__receipt__image__save"
        disabled={form_submitted}
        onClick={this.submitPhoto}
        pending={this.requestIsPending()}
        successText="Success!"
        success={this.requestSucceeded() && !(form_dirty && !form_submitted)}
      >
        Save Changes
      </RequestButton>
    );

    const upload_button = (
      <ButtonWithIcon
        type="button"
        className="filled expense-create__receipt__image__upload"
        icon={<Icon name="add" />}
        side="left"
        disabled={form_submitted}
        onClick={e => {
          e.preventDefault();
          this.file_input &&
            this.file_input.current &&
            this.file_input.current.click();
        }}
      >
        {touchpoint_photo_file || image_preview ? "Change" : "Upload"} Photo
      </ButtonWithIcon>
    );

    return (
      <>
        <FormSectionList
          className={class_names(
            {
              "--dirty": form_dirty,
              "--empty": !(touchpoint_photo_file || image_preview)
            },
            "expense-create__receipt__image"
          )}
          items={image_preview ? [image_preview] : []}
          title="Touchpoint Photo"
          renderItem={f =>
            editable ? (
              <div
                key={0}
                className="expense-create__receipt__preview empty-list"
              >
                <div className="touchpoint-detail__photo__preview">
                  <div
                    className="expense-create__receipt__image__remove"
                    onClick={this.removePhoto}
                  >
                    <Icon name="remove-circle" iconSize="sm" />
                  </div>
                  <ExpandableImage
                    filename={form_dirty ? f.name : undefined}
                    src={f.data}
                    alt="Touchpoint photo"
                    maxThumbnailHeight={240}
                  />
                </div>
                {form_dirty && (
                  <div className="expense-create__receipt__filename">
                    <Filename>{f.name}</Filename>
                  </div>
                )}
                {upload_button}
                {form_dirty && window_size <= ScreenSize.xs
                  ? save_button
                  : null}
              </div>
            ) : (
              <div key={0} className="touchpoint-detail__photo">
                <ExpandableImage
                  filename={f.name}
                  src={f.data}
                  alt="Touchpoint photo"
                  maxThumbnailHeight={240}
                />
              </div>
            )
          }
          button={
            editable ? (
              <div className="form-section__title__buttons">
                {upload_button}
                {form_dirty &&
                (!touchpoint_photo_file || window_size > ScreenSize.xs)
                  ? save_button
                  : null}
              </div>
            ) : (
              undefined
            )
          }
          emptyLabel="No touchpoint photo has been added."
        >
          <form ref={this.form}>
            <input
              ref={this.file_input}
              id="upload-touchpoint-photo"
              type="file"
              accept="image/*"
              onChange={this.uploadPhoto}
              tabIndex={-1}
            />
          </form>
          <FieldError hasError={!!file_too_big}>
            Maximum file size is 1MB.
          </FieldError>
          <FormErrors response={this.getRequestErrors()} />
        </FormSectionList>
      </>
    );
  }
}
export const TouchpointPhotoUploadConnected = connect(
  TouchpointPhotoUpload,
  true,
  ["request", "app_location", "window_size"]
);
